import React from "react";
import ReleaseAug2024Frame1 from "../Images/ReleaseAug2024Frame1.png";
import ReleaseAug2024Frame2 from "../Images/ReleaseAug2024Frame2.png";
import ReleaseAug2024Frame3 from "../Images/ReleaseAug2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsAug24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">AGOSTO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Mejoras en amueblado, estilos personalizados y preservación
            de ventanas
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Foto de Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Este agosto hemos estado mejorando todo lo que ya ofrece Pedra, y
              alguna novedad que te sorprenderá:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Resultados super-realistas en la herramienta de amueblar
              </h2>
              Verás que los resultados al amueblar espacios son mejores que
              nunca. Hemos actualizado nuestros modelos de IA para que tus fotos
              salgan lo más realistas posible ✨
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame1}
                  alt="Ejemplo de amueblado super-realista"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Utiliza tus propios estilos para renovar propiedades
              </h2>
              Ahora puedes subir una fotografía de cualquier estilo, y Pedra lo
              utilizará de referencia para renovar la fotografía. Ideal si
              quieres mostrar una propiedad en el estilo más de moda o que más
              agrade a tu cliente. Puedes ver cómo funciona aquí.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame2}
                  alt="Ejemplo de renovación con estilo personalizado"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mejora en la preservación de ventanas al renovar
              </h2>
              Preserva las vistas de las ventanas de la manera más realista! Así
              te aseguras de que tus propuestas de renovación sean fáciles de
              interpretar por tus clientes.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseAug2024Frame3}
                alt="Ejemplo de preservación de ventanas"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>

            <div>
              <li>
                Mejoras en la detección del cielo al poner el{" "}
                <strong>"Cielo azul"</strong>
              </li>
              <li>
                La funcionalidad de <strong>"Añadir objeto"</strong> es más
                intuitiva de usar: feedback de cuando los objetos se están
                cargando y más facilidad al mover los muebles.
              </li>
              <li>
                Ahora puedes ampliar el número de imágenes si se te acaban las
                generaciones de imagen mensuales
              </li>
              <li>
                Arreglos varios: cambio de marca de agua, prevención de que
                todas las imágenes fallen si una imagen en HD falla y crea
                nuevos proyectos más rápido que nunca.
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsAug24;
