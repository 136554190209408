import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeSalon from "./Images/curtain-effect-hero/BeforeSalon.png";
import AfterSalon from "./Images/curtain-effect-hero/AfterSalon.png";
import BeforeSalonStaging1 from "./Images/curtain-effect/BeforeSalonStaging1.png";
import AfterSalonStaging1 from "./Images/curtain-effect/AfterSalonStaging1.png";
import BeforeSalonStaging2 from "./Images/curtain-effect/BeforeSalonStaging2.png";
import AfterSalonStaging2 from "./Images/curtain-effect/AfterSalonStaging2.png";
import BeforeSalonStaging3 from "./Images/curtain-effect/BeforeSalonStaging3.png";
import AfterSalonStaging3 from "./Images/curtain-effect/AfterSalonStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeStagingLivingRoomFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Moderne",
    beforeImage: BeforeSalon,
    afterImage: AfterSalon,
  });

  const handleExample = (example) => {
    if (example === "Moderne") {
      setExample({
        name: "Moderne",
        beforeImage: BeforeSalon,
        afterImage: AfterSalon,
      });
    } else if (example === "Traditionnel") {
      setExample({
        name: "Traditionnel",
        beforeImage: BeforeSalonStaging1,
        afterImage: AfterSalonStaging1,
      });
    } else if (example === "Rustique") {
      setExample({
        name: "Rustique",
        beforeImage: BeforeSalonStaging2,
        afterImage: AfterSalonStaging2,
      });
    } else {
      setExample({
        name: "Contemporain",
        beforeImage: BeforeSalonStaging3,
        afterImage: AfterSalonStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que le home staging de salon ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging de salon est une technique de marketing immobilier qui se concentre sur la préparation et la présentation du salon d'une propriété de manière attrayante pour les acheteurs ou locataires potentiels. Cela peut inclure le nettoyage, l'organisation, la décoration stratégique et des améliorations mineures pour mettre en valeur les points forts du salon et minimiser ses défauts.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages du home staging virtuel pour les salons ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging virtuel pour les salons offre plusieurs avantages, notamment : la possibilité de montrer le potentiel du salon sans changements physiques coûteux, la capacité d'expérimenter rapidement différents styles et configurations, l'amélioration de la présentation en ligne de la propriété et l'opportunité de se démarquer sur un marché immobilier concurrentiel.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de home staging virtuel pour les salons ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus de home staging virtuel pour les salons implique le téléchargement de photos actuelles du salon sur notre plateforme, la sélection des options d'édition souhaitées (comme changer les finitions, ajouter ou supprimer des meubles, ou modifier l'agencement), puis la réception d'images éditées montrant le salon transformé.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeSalonStaging1,
    afterImage: AfterSalonStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeSalonStaging1,
      afterImage: AfterSalonStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeSalonStaging2,
      afterImage: AfterSalonStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeSalonStaging3,
      afterImage: AfterSalonStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging Salon</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Le meilleur home staging virtuel de salon à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Transformez votre salon en
                    quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Concevez des salons attrayants
                    sans effort
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyperréalistes pour les
                    salons
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance experte en design de
                    salon
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant le home staging virtuel du salon"}
                  altAfter={"Après le home staging virtuel du salon"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Moderne" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderne")}
                    >
                      Exemple de salon 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditionnel" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditionnel")}
                    >
                      Exemple de salon 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustique" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustique")}
                    >
                      Exemple de salon 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporain" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporain")}
                    >
                      Exemple de salon 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>
      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtuel de salon pour{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vendre plus rapidement
          </span>{" "}
          tout type de propriété.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Rénovez votre salon virtuellement
                </h3>
                <div className="feature-text">
                  Transformez votre salon en un clic, sans travaux ni dépenses
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Mettez en valeur les caractéristiques uniques
                </h3>
                <div className="feature-text">
                  Soulignez les points forts de votre salon avec du texte
                  descriptif
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Améliorez et corrigez les photos de salon
                </h3>
                <div className="feature-text">
                  Optimisez la qualité et la perspective des photos de votre
                  salon
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Ajoutez des meubles et une décoration moderne
                </h3>
                <div className="feature-text">
                  Actualisez votre salon avec les derniers styles de façon
                  virtuelle
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renouvelez les finitions</h3>
                <div className="feature-text">
                  Changez les sols, les murs et les finitions en un seul clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Nettoyez et organisez virtuellement
                </h3>
                <div className="feature-text">
                  Éliminez le désordre et les objets indésirables pour un salon
                  impeccable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de notre home staging virtuel de salon.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Avant le home staging virtuel du salon"}
            altAfter={"Après le home staging virtuel du salon"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne le home staging virtuel de salon ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez des photos de votre salon</h2>
            <div className="value-prop-text">
              Chargez une ou plusieurs images de votre salon sur Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos de salon pour créer un home staging virtuel sur Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez les options de transformation pour votre salon</h2>
            <div className="value-prop-text">
              Sélectionnez parmi plusieurs options pour rénover virtuellement
              votre salon.
              <p>
                Vous pouvez changer les finitions, ajouter des meubles,
                réorganiser l'espace et bien plus encore.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer un home staging virtuel de salon – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Recevez et téléchargez votre salon transformé</h2>
            <div className="value-prop-text">
              En quelques minutes, vous obtiendrez des images réalistes de votre
              salon rénové.
              <p>
                Téléchargez les images et utilisez-les pour promouvoir votre
                propriété.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger un home staging virtuel de salon – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtuel de salon pour 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Et sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer les salons
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changer les finitions du salon
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajouter des meubles et de la décoration
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Supprimer les objets indésirables
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution des photos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Modifier l'agencement du salon
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie des salons
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans de salon illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajout de filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20€ par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions et réponses sur le home staging de salon
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging de salon ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging de salon est une technique de marketing
                immobilier qui se concentre sur la préparation et la
                présentation du salon d'une propriété de manière attrayante pour
                les acheteurs ou locataires potentiels. Cela peut inclure le
                nettoyage en profondeur, l'organisation, la décoration
                stratégique et des améliorations mineures pour mettre en valeur
                les points forts du salon et minimiser ses défauts.
                <br />
                <br />
                Dans le cas du home staging virtuel de salon, nous utilisons une
                technologie avancée pour transformer numériquement l'espace,
                montrant son potentiel sans avoir besoin de réaliser des
                changements physiques coûteux.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages du home staging virtuel pour les salons
                ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le home staging virtuel pour les salons offre de nombreux
                avantages :
                <br />
                <br />
                1. Économie de coûts : Pas besoin de rénovations physiques
                coûteuses.
                <br />
                2. Gain de temps : Les transformations se font en minutes, pas
                en semaines.
                <br />
                3. Polyvalence : Vous pouvez montrer plusieurs styles et
                configurations du même salon.
                <br />
                4. Attrait visuel : Aide les acheteurs à visualiser le potentiel
                du salon.
                <br />
                5. Différenciation sur le marché : Mettez en valeur votre
                propriété avec des images de salon attrayantes.
                <br />
                6. Sans dérangement : Pas besoin de perturber la vie des
                occupants actuels.
                <br />
                7. Amélioration de la présentation en ligne : Idéal pour les
                annonces immobilières sur internet.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Que puis-je faire avec le home staging de salon de Pedra ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Avec le home staging virtuel de salon de Pedra, vous pouvez
                effectuer une grande variété de transformations :
                <br />
                <br />
                1. Rénover les salons : Vous pouvez utiliser les styles
                prédéfinis de Pedra ou créer vos propres styles personnalisés
                pour transformer complètement l'aspect du salon.
                <br />
                2. Améliorer la lumière et la couleur : Optimisez l'éclairage et
                les couleurs des photographies du salon pour les rendre plus
                attrayantes et professionnelles.
                <br />
                3. Supprimer des objets : Effacez les éléments indésirables des
                photos, comme les vieux meubles, le désordre ou les objets
                personnels.
                <br />
                4. Ajouter des éléments : Incorporez de nouveaux objets dans le
                salon, comme des meubles modernes, des accessoires décoratifs ou
                des éléments de design.
                <br />
                5. Changer les finitions : Modifiez l'apparence des sols, des
                murs et d'autres finitions pour montrer différentes options de
                design.
                <br />
                6. Réorganiser l'espace : Visualisez différentes configurations
                du salon sans avoir besoin de changements physiques.
                <br />
                7. Améliorer l'éclairage : Ajustez l'éclairage virtuel pour
                créer des ambiances plus accueillantes et attrayantes.
                <br />
                8. Mettre en valeur les caractéristiques architecturales :
                Soulignez les éléments uniques comme les cheminées, les grandes
                fenêtres ou les hauts plafonds.
                <br />
                <br />
                Toutes ces transformations sont réalisées de manière virtuelle,
                vous permettant de montrer le potentiel du salon rapidement et
                économiquement.
              </div>
            )}
          </div>

          {/* Add more FAQs here */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingLivingRoomFr;
