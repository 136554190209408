import React from 'react';

const OrangeSunIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
        <path d="M13.6923 10.5C13.6923 12.54 12.04 14.1923 10 14.1923C7.96 14.1923 6.30769 12.54 6.30769 10.5C6.30769 8.46 7.96 6.80769 10 6.80769C12.04 6.80769 13.6923 8.46 13.6923 10.5ZM10.6154 2.5H9.38462V5.57692H10.6154V2.5ZM9.38462 18.5H10.6154V15.4231H9.38462V18.5ZM6.95385 6.58308L4.77846 4.40769L3.90769 5.27846L6.08308 7.45385L6.95385 6.58308ZM13.0462 14.4169L15.2215 16.5923L16.0923 15.7215L13.9169 13.5462L13.0462 14.4169ZM5.07692 9.88462H2V11.1154H5.07692V9.88462ZM14.9231 9.88462V11.1154H18V9.88462H14.9231ZM3.90769 15.7215L4.77846 16.5923L6.95385 14.4169L6.08308 13.5462L3.90769 15.7215ZM16.0923 5.27846L15.2215 4.40769L13.0462 6.58308L13.9169 7.45385L16.0923 5.27846Z" fill="#F8792A"></path>
    </svg>
  );
};

export default OrangeSunIcon;