import React from "react";

const OrangeRemoveObject = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M24.5 12L18.5 6L11.75 12.75L17.75 18.75L24.5 12Z"
        fill="#F8792A"
      />
      <path d="M9.5 15L15.5 21V23.25H8.75L5 19.5L9.5 15Z" fill="#F8792A" />
      <path
        d="M25.25 23.25H15.5M15.5 23.25H8.75L5 19.5L9.5 15L15.5 21V23.25ZM18.5 6L24.5 12L17.75 18.75L11.75 12.75L18.5 6Z"
        stroke="#F8792A"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default OrangeRemoveObject;
