import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import { Link } from "react-router-dom";
import CustomerLogos from "../CustomerLogos";
import ArrowIcon from "../Icons/ArrowIcon";

const FreeDescriptionGenerator = () => {
  function redirectToPage() {
    if (window.location.href.includes("/en")) {
      window.open("https://app.pedra.so/en", "_blank");
    } else {
      window.open("https://app.pedra.so", "_blank");
    }
  }

  const [formData, setFormData] = useState({
    propertyType: "",
    condition: "",
    numberOfBedrooms: "",
    location: "",
    locationVibe: "",
    area: "",
    otherWarnings: "",
    highlightedFeatures: "",
  });
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "propertyType",
      "condition",
      "numberOfBedrooms",
      "area",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "This field is required";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateListing = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedOutput("");

    try {
      const response = await fetch(
        "/.netlify/functions/generate-ad-description-en",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to generate listing");
      }

      setGeneratedOutput(data.output);
    } catch (err) {
      console.error("Error in generateListing:", err);
      setError(
        "We are experiencing high traffic volume – please try again in a few minutes"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (
    name,
    label,
    type = "text",
    rows = 1,
    placeholder = ""
  ) => (
    <div>
      <label htmlFor={name} className="free-tool-label">
        {label}:
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          rows={rows}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px", height: "100px" }}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px" }}
          placeholder={placeholder}
        />
      )}
      {fieldErrors[name] && (
        <div style={{ color: "red", fontSize: "0.8em" }}>
          {fieldErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            <span className="text-highlight">Free</span> Real Estate Ad
            Generator
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% free</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% AI-powered</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Ads in 10 seconds</span>
            </p>
          </div>
        </div>
        <h2>Enter property details</h2>
        <form
          onSubmit={generateListing}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {renderField(
            "propertyType",
            "Property Type",
            "text",
            1,
            "Apartment, House, Villa"
          )}
          {renderField(
            "condition",
            "Condition",
            "text",
            1,
            "New, Renovated, Needs renovation"
          )}
          {renderField(
            "numberOfBedrooms",
            "Number of Bedrooms",
            "text",
            1,
            "2, 3, 4+"
          )}
          {renderField(
            "location",
            "Location (optional)",
            "text",
            1,
            "Downtown, Salamanca District"
          )}
          {renderField(
            "locationVibe",
            "Neighborhood Vibe (optional)",
            "text",
            1,
            "Quiet, Lively, Family-friendly"
          )}
          {renderField("area", "Area", "text", 1, "80m², 100m², 150m²")}
          {renderField(
            "highlightedFeatures",
            "Highlighted Features",
            "textarea",
            3,
            "Spacious terrace, Equipped kitchen, Panoramic views"
          )}
          {renderField(
            "otherWarnings",
            "Other Warnings (optional)",
            "textarea",
            3,
            "Needs minor repairs, No pets allowed"
          )}

          <button
            type="submit"
            disabled={isLoading}
            style={{ cursor: "pointer" }}
            className="free-tool-button"
          >
            {isLoading ? "Generating..." : "Generate Ad"}
          </button>
        </form>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <strong>Error:</strong> {error}
          </div>
        )}

        {generatedOutput && (
          <div className="step-container article">
            <article className="article-text free-tool-result-container">
              <div style={{ marginTop: "20px" }}>
                <h2 className="article-subtitleh2">
                  <span className="text-highlight">Generated Ad:</span>
                </h2>
                <p>{generatedOutput}</p>
              </div>
            </article>
          </div>
        )}

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              What is a real estate ad generator?
            </h2>
            <p>
              A real estate ad generator is a tool that helps real estate agents
              and property owners create attractive and professional
              descriptions for their properties for sale or rent. This tool uses
              advanced algorithms and artificial intelligence to generate
              persuasive and detailed descriptions based on the information
              provided about the property.
            </p>

            <h2 className="article-subtitleh2">
              How to create real estate ads
            </h2>
            <p>
              If you want to get the most effective and attractive property
              descriptions, use Pedra. Our description generator uses a unique
              method that combines advanced artificial intelligence with local
              real estate market knowledge.
            </p>
            <p>
              The procedure for creating a real estate ad for free is as
              follows:
            </p>

            <h2 className="article-subtitleh2">
              Complete the Basic Property Details
            </h2>
            <p>
              Property Type: Select the type of property you're promoting, such
              as apartment, house, or villa. This helps to correctly categorize
              the ad and set buyer expectations from the start.
            </p>
            <p>
              Property Condition: Indicate whether the property is new,
              renovated, or needs renovation. This information is crucial as it
              establishes the current condition of the property and can
              influence buyers' decisions.
            </p>

            <h2 className="article-subtitleh2">Specify the Main Features</h2>
            <p>
              Number of Bedrooms: Enter how many bedrooms the property has. This
              is one of the most sought-after details by buyers and should be
              clear and precise.
            </p>
            <p>
              Area: Provide the total surface area of the property in square
              meters. Be sure to be accurate, as this helps interested parties
              easily compare different properties.
            </p>

            <h2 className="article-subtitleh2">
              Add Optional Information to Enrich the Ad
            </h2>
            <p>
              Location: Although optional, adding details about the location,
              such as "Downtown" or "Salamanca District", can make your ad more
              relevant to those searching in specific areas.
            </p>
            <p>
              Neighborhood Vibe: Describe the atmosphere of the neighborhood,
              for example, "Quiet", "Lively" or "Family-friendly". This helps
              interested parties imagine the lifestyle they could have in that
              property.
            </p>

            <h2 className="article-subtitleh2">
              Highlight Key Parts of the Property
            </h2>
            <p>
              Highlighted Features: Use this field to highlight special features
              such as "Spacious terrace", "Equipped kitchen" or "Panoramic
              views". These details can be decisive in capturing attention and
              differentiating your ad from similar ones.
            </p>

            <h2 className="article-subtitleh2">
              Include Warnings or Restrictions
            </h2>
            <p>
              Other Warnings (optional): Add any important restrictions, such as
              "Needs minor repairs" or "No pets allowed". Being transparent with
              these conditions will avoid misunderstandings and attract
              interested parties who are willing to accept these conditions.
            </p>

            <h2 className="article-subtitleh2">
              Advantages of the real estate ad generator
            </h2>
            <h3>Time efficiency</h3>
            <p>
              Creating a real estate ad from scratch can be a long and detailed
              process, especially if you want to make sure it's persuasive and
              professional. With the ad generator, you only need to enter the
              key information about the property, and in a matter of seconds,
              you'll get a complete and well-structured description. This allows
              you to save valuable time that you can dedicate to other important
              tasks, such as customer service or managing more properties.
            </p>

            <h3>Professional quality</h3>
            <p>
              A poorly written or unattractive description can discourage
              potential buyers or tenants from the first moment. The generator
              uses professional and convincing language, ensuring that each ad
              highlights the best features of the property and captures the
              reader's attention. Additionally, the writing adapts to real
              estate market best practices, which increases the likelihood of
              attracting interested parties.
            </p>

            <h3>Ease of use</h3>
            <p>
              The ad generator is designed to be intuitive and easy to use, even
              for those who have no previous experience in content writing or
              real estate marketing. You only need to fill in a few fields with
              basic information about the property, such as the type, location,
              and highlighted features, and the tool takes care of the rest.
              This simplicity makes it easy for anyone to create effective ads
              without complications.
            </p>

            <h3>Free</h3>
            <p>
              Unlike other writing tools or services that may have an associated
              cost, this ad generator is completely free. This allows users,
              whether independent agents, small agencies, or large real estate
              companies, to access high-quality descriptions without incurring
              additional costs.
            </p>

            <h2 className="article-subtitleh2">
              Best use cases of the description generator
            </h2>
            <h3>For independent real estate agents</h3>
            <p>
              Independent real estate agents often handle multiple properties
              and have the responsibility of managing the entire sales or rental
              process, from property acquisition to creating attractive ads. The
              description generator allows them to save time and effort in
              writing ads, ensuring that each description is professional and
              persuasive. This allows them to focus on other critical tasks,
              such as negotiating with clients or organizing visits, increasing
              their productivity and effectiveness in closing deals.
            </p>

            <h3>For owners selling on their own</h3>
            <p>
              Owners who choose to sell or rent their properties on their own
              (without intermediaries) often lack the real estate marketing
              experience necessary to write attractive and effective
              descriptions. The description generator provides them with a
              simple and free solution to create ads that highlight the best
              features of their property. This helps them capture the attention
              of potential buyers or tenants, increasing the chances of success
              in selling or renting, without the need to hire an agent or
              professional writer.
            </p>

            <h3>For real estate agencies</h3>
            <p>
              Real estate agencies handle a large volume of properties and need
              to maintain consistency in the tone and quality of the ads they
              publish. The description generator allows them to standardize
              content creation, ensuring that all properties are presented
              consistently and professionally. Additionally, it reduces the time
              and costs associated with manual writing, allowing marketing teams
              to focus on broader promotion strategies. By offering high-quality
              descriptions efficiently, agencies can improve their brand image
              and attract more clients.
            </p>

            <h2 className="article-subtitleh2">
              Who is the real estate ad generator designed for?
            </h2>
            <p>
              Although anyone can use Pedra's description generator, these are
              the most common users:
            </p>
            <ul>
              <li>Real estate agents</li>
              <li>Homeowners</li>
              <li>Real estate investors</li>
            </ul>
            <p>
              Discover how our tool can transform your real estate listings
              today.
            </p>

            <h2 className="article-subtitleh2">
              Improve the visual presentation of your real estate ad
            </h2>
            <p>
              In addition to a convincing description, the visual presentation
              of your property is crucial to attract potential buyers. At Pedra,
              we understand the importance of showing the full potential of each
              property. That's why we offer additional services to improve the
              presentation of your listings:
            </p>
            <ul>
              <li>
                Professional real estate photography: Our software will enhance
                your images in 1 click, to make them look with better
                resolution, light, and color.
              </li>
              <li>
                Virtual home staging: We use advanced technology to show how the
                property would look with different decoration styles, helping
                buyers visualize its potential.
              </li>
            </ul>
            <p>
              These complementary services, along with our AI-generated
              descriptions, will help you create real estate listings that stand
              out in today's competitive market. Contact us to find out how we
              can help you present your property in the best possible way.
            </p>

            <h2 className="article-subtitleh2">
              Create your ad ready to advertise on real estate portals
            </h2>
            <p>
              With our free description generator and the{" "}
              <Link to="/home-staging-virtual" className="article-link">
                virtual home staging
              </Link>{" "}
              tool to optimize your real estate photos, you'll be able to manage
              all your property information quickly and easily. These tools will
              allow you to automate complex tasks, significantly reducing work
              hours and improving the quality of results, without the need to
              dedicate too much time and effort.
            </p>
            <p>
              Once you've created the description and optimized your photos with
              Pedra's help, they'll be ready to be published on different job
              portals to attract future tenants.
            </p>
          </article>
        </div>
        <br />
        <div className="customer-logo-container">
          OVER 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
        <div className="section-first-button-container">
          <button className="button-top-of-page" onClick={redirectToPage}>
            Try Pedra <ArrowIcon />
          </button>
        </div>
      </section>
    </section>
  );
};

export default FreeDescriptionGenerator;
