import React from "react";

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6 10L8.58234 13.4431C8.78752 13.7167 9.20063 13.7082 9.39436 13.4264L14.5 6"
        stroke="#828282"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckIcon;
