import React from "react";
import ReleaseJul2024Frame1 from "../Images/ReleaseJul2024Frame1.png";
import ReleaseJul2024Frame2 from "../Images/ReleaseJul2024Frame2.png";
import ReleaseJul2024Frame3 from "../Images/ReleaseJul2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJul24Fr() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">JUILLET 2024</div>
          <h1 className="article-titleh1">
            Pedra : Ajoutez des objets 3D, rendez le ciel bleu en 1 clic, et
            améliorez ou éclaircissez automatiquement les images
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Couverture illustrative sur l'IA et le secteur immobilier"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Voici les nouveautés pour ce mois de juillet – j'espère que vous
              serez impatients de les essayer sur Pedra !
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Ajoutez des objets 3D à vos photos
              </h2>
              Vous pouvez maintenant choisir les meubles à ajouter à vos photos.
              Avec l'option Ajouter un objet, vous pouvez sélectionner sa
              taille, son orientation et son agencement. Vous pouvez voir
              comment{" "}
              <Link
                to="/fr/help/comment-ajouter-des-objets-3d"
                className="article-link"
              >
                utiliser l'ajout d'objets 3D ici
              </Link>
              .
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame1}
                  alt="Rénover les espaces avec l'IA depuis un mobile"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Dégagez le ciel dans vos photos en 1 clic
              </h2>
              Nous voulons tous que nos photos montrent un ciel clair et
              magnifique. Maintenant, vous pouvez mettre un ciel bleu dans vos
              photos en seulement 1 clic et ~30 secondes.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame2}
                  alt="Déplacer et modifier la transparence du filigrane"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nous avons combiné les options Embellir et Illuminer
              </h2>
              En cliquant sur Embellir, Pedra détectera si les photos ont une
              faible luminosité et améliorera automatiquement la lumière, la
              couleur et le contraste.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJul2024Frame3}
                alt="Modifier la légende d'une création IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>

            <div>
              <li>
                Meilleure qualité lors de la préservation des fenêtres dans
                "Rénovation avancée"
              </li>
              <li>
                Réduction des erreurs dans "Rénovation avancée" avec
                préservation des fenêtres
              </li>
              <li>
                Expérience plus intuitive lors de la peinture sur les images
              </li>
              <li>
                Option pour télécharger les factures depuis les paramètres
              </li>
              <li>Améliorations de l'utilisabilité et diverses corrections</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJul24Fr;
