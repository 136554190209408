import React from "react";

const OrangeVirtualVideoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2857 17.6031V11.1429C19.2857 9.03436 17.9657 7.71436 15.8571 7.71436H6.42857C4.32 7.71436 3 9.03436 3 11.1429V18.8572C3 20.9658 4.32 22.2858 6.42857 22.2858H13.7419L13.5731 21.5805C13.3279 20.562 12.3228 19.5181 11.3421 19.2634L9.17247 18.7033C8.19184 18.4487 8.19184 18.0286 9.17247 17.7739L11.3421 17.2138C12.3228 16.9592 13.3279 15.9152 13.5731 14.8967L14.1124 12.6433C14.3576 11.6249 14.7621 11.6249 15.0073 12.6433L15.5466 14.8967C15.7918 15.9152 16.7969 16.9592 17.7776 17.2138L19.2857 17.6031ZM19.2857 18.8741L17.7776 19.2634C16.7969 19.5181 15.7918 20.562 15.5466 21.5805L15.3778 22.2858H15.8571C17.9601 22.2858 19.2787 20.9728 19.2857 18.8741ZM21 13.2858L25.2857 9.00007H27V21.0001H25.2857L21 16.7144V13.2858ZM11.5256 11.9685C11.8443 12.0958 11.8443 12.2995 11.5256 12.4141L9.79719 13.1016C9.47848 13.2289 9.123 13.5981 9.01269 13.9164L8.35076 15.7114C8.22817 16.0424 8.03205 16.0424 7.92172 15.7114L7.25979 13.9164C7.13722 13.5853 6.78174 13.2162 6.47529 13.1016L4.74691 12.4141C4.4282 12.2868 4.4282 12.0831 4.74691 11.9685L6.47529 11.281C6.794 11.1537 7.14948 10.7845 7.25979 10.4663L7.92172 8.6712C8.04431 8.34018 8.24043 8.34018 8.35076 8.6712L9.01269 10.4663C9.13526 10.7973 9.49074 11.1665 9.79719 11.281L11.5256 11.9685Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangeVirtualVideoIcon;
