import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBedroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeBedroom from "../Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "../Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBedroomEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual para dormitorios
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual para espacios de
            descanso
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual para un dormitorio?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un home staging virtual para dormitorios en
              pocos clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona "Dormitorio" como tipo de espacio y en unos 2 o 3
                minutos tendrás la imagen de tu home staging virtual de
                dormitorio lista para descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Cómo crear un home staging virtual de dormitorio – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para dormitorios?
            </h2>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-dormitorio">
                home staging virtual de dormitorio
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube la imagen del dormitorio que quieres amueblar virtualmente
              </li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menú de cómo crear un home staging virtual de dormitorio"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los muebles
                </span>
                . Para dormitorios, recomendamos usar "Dibujar" para indicar
                áreas específicas donde colocar la cama, armarios, mesitas de
                noche, etc.
              </li>
              <li>
                Selecciona{" "}
                <span style={{ fontWeight: "500" }}>"Dormitorio"</span> como
                tipo de espacio. Esto asegurará que Pedra coloque muebles
                apropiados como camas, armarios, cómodas, etc.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                Para dormitorios, estilos como "Moderno", "Clásico" o
                "Minimalista" suelen funcionar bien.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes para tener opciones
                diferentes de diseño de dormitorio.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para un mejor home staging virtual de dormitorio:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente las
                    dimensiones del dormitorio para un amueblado más preciso
                  </li>
                  <li>
                    Si el dormitorio tiene características especiales como
                    ventanales o techos altos, intenta incluirlos en la foto
                    para un resultado más atractivo
                  </li>
                  <li>
                    Usa la opción "Dibujar" para indicar áreas específicas para
                    la cama, armarios, zona de lectura o elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Ejemplo de como crear un home staging virtual de dormitorio"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué hacer un home staging virtual de dormitorio?
            </h2>
            <p className="article-text">
              Un dormitorio bien presentado puede ser un gran atractivo para
              potenciales compradores. El home staging virtual de dormitorios te
              permite mostrar el potencial de estos espacios de descanso,
              creando ambientes acogedores y funcionales que ayudan a los
              compradores a visualizar cómo podrían disfrutar de un sueño
              reparador y relajación.
            </p>
            <p className="article-text">
              Con un buen home staging virtual de dormitorio podrás:
            </p>
            <ul>
              <li>Mostrar el potencial de espacios vacíos o poco atractivos</li>
              <li>Crear una atmósfera de confort y tranquilidad</li>
              <li>
                Destacar características como la luminosidad, el espacio de
                almacenamiento o la versatilidad del dormitorio
              </li>
              <li>
                Ayudar a los compradores a imaginar momentos de relax y descanso
                en el dormitorio
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual de dormitorios
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de home stagings virtuales que
              pueden aplicarse a dormitorios:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroom}
              afterImage={AfterBedroom}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual para dormitorios
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/qRlXiyWp3AU?si=L484Id6o6n-9ViGN"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBedroomEs;
