import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1Es from "../Images/HowToRenovateFrame1Es.jpg";
import HowToRenovateFrame2Es from "../Images/HowToRenovateFrame2Es.jpg";
import HowToRenovateFrame3Es from "../Images/HowToRenovateFrame3Es.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderKitchenEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Cómo crear renders de cocinas</h1>

          <div className="title-secondary gray">
            Tutorial sobre cómo crear renders de cocinas
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirven las herramientas de render de cocinas de Pedra?
            </h2>
            <p className="article-text">
              Con las herramientas de Pedra, podrás mostrar una potencial
              reforma de una cocina en pocos segundos.
              <p style={{ marginTop: "10px" }}>
                Mostrar el potencial de una reforma de cocina es especialmente
                útil para la venta de propiedades de segunda mano, ya que la
                cocina suele ser uno de los espacios que más inversión requiere
                por parte del comprador.
              </p>
              <p style={{ marginTop: "10px" }}>
                Gracias a Pedra, no hace falta que contrates a renderistas o
                diseñadores de cocinas para tener una propuesta de renovación
                atractiva y profesional.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo creo un render de cocina con Pedra?
            </h2>
            <p className="article-text">
              Una vez hayas subido las imágenes de la cocina que quieres
              transformar, selecciónalas.
              <p style={{ marginTop: "10px" }}>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>, y
                verás la opción de{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1Es}
                alt="Cómo usar la herramienta de renovación para crear renders de cocinas – Captura del software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovación rápida: </span>
              Genera un render de cocina en segundos
            </h2>
            <p style={{ marginTop: "10px" }}>
              Haciendo 1 click en{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span> generarás
              una propuesta de renovación de tu cocina con el estilo
              "Minimalista". En unos 25 segundos la tendrás lista.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2Es}
                alt="Cómo usar la herramienta de renovación para crear renders de cocinas – Captura del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovación con ajustes avanzados:{" "}
              </span>
              Personaliza el render de tu cocina
            </h2>
            <p className="article-text">
              Con Pedra también tienes la opción de renovar tu cocina a tu
              gusto.
              <p style={{ marginTop: "10px" }}>
                Dentro del mismo menú de{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>, podrás
                configurar varios parámetros:
                <p style={{ marginTop: "10px" }}>
                  Podrás escoger el estilo de cocina, el grado de creatividad de
                  los cambios e incluso preservar elementos importantes como
                  ventanas o la disposición de los electrodomésticos.
                </p>
                <p style={{ marginTop: "10px" }}>
                  A continuación, te mostramos una guía paso a paso:
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3Es}
                  alt="Cómo usar la herramienta de renovación para crear renders de cocinas – Captura del software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Estilo de cocina
            </h3>
            <p className="article-text">
              Pedra ofrece 9 estilos básicos de renovación: minimalista,
              tradicional, mediterráneo, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "600" }}>
                  También puedes subir tu propio estilo de cocina.
                </span>{" "}
                Al subir tus propias referencias, podrás crear propuestas
                basadas en el estilo específico que desees replicar.
              </p>
              <p className="article-text" style={{ marginTop: "10px" }}>
                A continuación, te mostramos un video de 40 segundos que explica
                cómo funciona:
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xMm8hKmSMSA?si=TpCyOIZDhtFHm-r1&amp;controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 – </span>
              Creatividad de la renovación
            </h3>
            <p className="article-text">
              Puedes escoger el grado de renovación entre Baja, Media, Alta y
              Muy Alta.
              <p style={{ marginTop: "10px" }}>
                Baja y Media conservarán la distribución básica de tu cocina,
                actualizando acabados y elementos decorativos.
              </p>
              <p style={{ marginTop: "10px" }}>
                Alta y Muy Alta están pensadas para transformar completamente el
                espacio, pudiendo modificar la distribución de muebles y
                electrodomésticos significativamente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Preservar elementos importantes
            </h3>
            <p className="article-text">
              Tienes la opción de preservar las ventanas durante la renovación.
              Esto es especialmente útil en cocinas con vistas o iluminación
              natural específica que quieras mantener en el resultado final.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 4 – </span>
              Generar el render de tu cocina
            </h2>
            <p>
              Una vez hayas seleccionado el estilo, creatividad y si preservar o
              no las ventanas, podrás generar las imágenes. Tardará unos 25
              segundos cada imagen, o un poco más si has elegido preservar
              elementos. Si tarda un poco más de la cuenta, no te inquietes.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Consejos para mejores renders de cocina
                </h4>
                <p className="callout-p">
                  Para obtener los mejores resultados en tus renders de cocina:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Genera varias versiones de la renovación
                      </span>
                      . Cada render es único, y algunos conservarán mejor los
                      elementos importantes de tu cocina.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Mantén el grado de creatividad bajo si quieres conservar
                      la distribución actual.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Si hay reflejos en electrodomésticos o superficies
                        brillantes, elimínalos antes de generar el render
                      </span>
                      . Esto ayudará a obtener resultados más precisos y
                      profesionales.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Ejemplos de renders de cocinas
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de renovaciones de cocinas creadas
              con nuestros renders:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Cocina antes del render"}
              altAfter={"Cocina después del render"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Cocina antes del render"}
              altAfter={"Cocina después del render"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Cocina antes del render"}
              altAfter={"Cocina después del render"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Cocina antes del render"}
              altAfter={"Cocina después del render"}
            />
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un render de cocina
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/wXwcyecypVg?si=UALcIAE6eE8Q0Sqc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderKitchenEs;
