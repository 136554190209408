import React from "react";

function LegalEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Aviso legal</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <h2 className="article-subtitleh2">Identificación del Titular:</h2>
            <div className="article-text">
              Nombre de la Empresa: Pedra Software S.L.
              <br />
              CIF: B16459992
              <br />
              Domicilio Social: Calle Vista Bella núm. 8, (08022), Barcelona,
              Cataluña, España. Actividad: Desarrollo y suministro de software y
              otros servicios relacionados con las tecnologías de la información
              y la informática. CNAE: 6209 - Otros servicios relacionados con
              las tecnologías de la información y la informática.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Información General:</h2>
            <div className="article-text">
              Este sitio web es propiedad de Pedra Software S.L. El uso de este
              sitio web está sujeto a las siguientes condiciones de uso. Le
              rogamos que las lea atentamente. El acceso a este sitio web y el
              uso de sus contenidos y servicios implica la aceptación plena y
              sin reservas de estas condiciones.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">
              Propiedad Intelectual e Industrial
            </h2>
            <div className="article-text">
              Nombre de la Empresa: Pedra Software S.L. Todos los contenidos de
              este sitio web, incluyendo sin limitación, textos, gráficos,
              imágenes, su diseño y los derechos de propiedad intelectual que
              pudieran corresponder a dichos contenidos, así como todas las
              marcas, nombres comerciales o cualquier otro signo distintivo son
              propiedad de Pedra Software S.L., quedando reservados todos los
              derechos sobre los mismos.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Condiciones de Uso</h2>
            <div className="article-text">
              El usuario se compromete a hacer un uso adecuado de los contenidos
              y servicios que Pedra Software S.L. ofrece y no emplearlos para
              realizar actividades ilícitas o constitutivas de delito y/o que
              infrinjan la regulación sobre propiedad intelectual e industrial,
              o cualquier otra norma del ordenamiento jurídico aplicable.
            </div>
            <br />
            <h2 className="article-subtitleh2">Protección de Datos</h2>
            <div className="article-text">
              En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679 del
              Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo
              a la protección de las personas físicas en lo que respecta al
              tratamiento de datos personales y a la libre circulación de estos
              datos, todos los datos personales recabados durante el uso de
              nuestro sitio web serán tratados conforme a nuestra{" "}
              <a
                className="article-link"
                href="https://www.pedra.so/es/privacy"
              >
                Política de Privacidad
              </a>
              , la cual todos los usuarios deben aceptar antes de proporcionar
              datos personales a través de este sitio web.
            </div>
            <br />
            <h2 className="article-subtitleh2">Contacto</h2>
            <div className="article-text">
              Para cualquier pregunta o propuesta, contáctenos en el siguiente
              correo electrónico:{" "}
              <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalEs;
