import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import ChangeSize from "../Images/HowTo3DChangeSize.jpeg";
import Move from "../Images/HowTo3DMove.jpeg";
import Rotate from "../Images/HowTo3DRotate.jpeg";

function HowTo3DEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo usar la herramienta de "Añadir objeto" para crear un home
            staging virtual
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre cómo añadir objetos 3D en un espacio y crear un "home
            staging virtual"
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {typeof window !== "undefined" && (
              <iframe
                src="https://www.loom.com/embed/5197afe1bbaa496aabfadbb82ebbbea5?sid=a616c5f8-7fde-448a-8325-1ba07f360ab3"
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                  height: "500px",
                }}
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                title="AI to sell real estate properties"
              ></iframe>
            )}
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirve la herramienta de "Añadir objeto"?
            </h2>
            <div className="article-text">
              Con la herramienta de "Añadir objeto" podrás poner muebles,
              plantas, objetos y más en tu imagen.
              <br />
              <br />
              Lo bueno de esta herramienta es que podrás escoger donde colocar
              los objetos, su tamaño y su orientación.
              <br />
              <br />
              Una vez colocados los objetos, podrás darle a la opción de
              generar. Entonces, se generará una imagen super realista de tu
              distribución.
              <br />
              <br />
            </div>
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Añadir los objetos
            </h2>
            <div className="article-text">
              Haz click en el objeto que quieras añadir. Al hacer click se
              añadirá automaticamente el objeto a la imagen.
              <div style={{ marginTop: "10px" }}>
                Si quieres quitar los objetos que has añadido, con el botón de
                "Borrar objetos" los eliminarás de la imagen.
              </div>
            </div>
            <br />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 –</span>{" "}
              Editar posición, orientación y tamaño de los objetos
            </h2>
            <div className="article-text">
              Ahora que has añadido los objetos a la imagen, tienes que
              colocarlos en la posición, orientación y tamaño correspondientes.
              <div style={{ marginTop: "10px", fontWeight: "500" }}>
                {" "}
                Recomendamos que coloqueis un objeto en la posición, tamaño y
                orientación adecuada antes de añadir otro objeto a la imagen
              </div>
            </div>
            <br />
            <h3 className="article-subtitle">
              Cambiar posición de los objetos
            </h3>
            <div className="article-text">
              Utiliza las flechas verde, amarilla y roja para mover el objeto.
              Recuerda que estás trabajando en 3 dimensiones, así que podrás
              mover el objeto a través del espacio.
              <div style={{ marginTop: "10px" }}>
                También puedes hacer click en el rectangulo amarillo en medio
                del objeto para arrastrarlo en el espacio.
              </div>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={Move}
                alt="Cambiar posición del objeto 3D para hacer un home staging virtual – Caputar del software Pedra"
              />
              <br />
            </div>
            <h3 className="article-subtitle">Rotar los objetos</h3>
            <div className="article-text">
              Utiliza las esferas verde, amarilla y roja para rotar el objeto.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={Rotate}
              alt="Rotar objeto 3D para hacer un home staging virtual – Caputar del software Pedra"
            />
            <h3 className="article-subtitle">Cambiar tamaño de los objetos</h3>

            <div className="article-text">
              Utiliza las lineas verdes, amarilla y roja para cambiar el tamaño
              del objeto.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={ChangeSize}
              alt="Cambiar tamaño 3D para hacer un home staging virtual – Caputar del software Pedra"
            />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Generar imagen
            </h2>
            <div className="article-text">
              Una vez hayas colocado los objetos, dale a generar imagen. Se
              creará una representación hiperrealista del espacio.
            </div>
            <div style={{ marginTop: "10px" }}>
              Si tenéis cualquier duda, contactarme via email en felix@pedra.so
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTo3DEs;
