import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article18Frame1 from "../Images/Article18Frame1.jpg";
import Article18Frame2 from "../Images/Article18Frame2.jpg";
import Article18Frame3 from "../Images/Article18Frame3.jpg";
import Article18Frame4 from "../Images/Article18Frame4.jpg";

function BlogFrArticle18() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Comment relancer une propriété qui ne se vend pas
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fondateur de Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fondateur de Pedra
                </div>
              </div>
            </div>
            <article className="article-text">
              <p>
                Avez-vous déjà eu une propriété qui ne se vendait pas ? Qui
                était publiée depuis des mois et qui n'avait tout simplement pas
                d'appels ? C'était sûrement une situation désagréable. La
                pression du propriétaire n'aide pas. Baisser le prix ? Mais vous
                l'avez déjà baissé il y a une semaine...{" "}
              </p>
              <p>
                Il existe plusieurs options pour débloquer une maison qui ne se
                vend pas — et ce que nous allons vous expliquer peut être
                efficace dans le cas où vous n'avez même pas d'appels depuis les
                portails immobiliers.
              </p>
              <h2 className="article-subtitleh2">
                Changez la couverture de la propriété qui ne se vend pas
              </h2>
              <p>
                L'une des options les plus simples pour recevoir plus de visites
                sur une annonce est de faire en sorte qu'elle paraisse nouvelle.
                Les nouvelles annonces reçoivent plus de visites car
                généralement les acheteurs recherchent des propriétés par zones,
                essayant de voir si le nouveau leur convient.{" "}
              </p>
              <p>
                Mais comment faire pour qu'une annonce paraisse nouvelle ? Eh
                bien, en changeant la photo de couverture.
              </p>
              <p>Voici trois exemples de comment le faire.</p>
              <h3 className="article-subtitle">
                1. Propriété d'occasion qui ne se vend pas
              </h3>
              <p>
                Si vous avez une propriété d'occasion qui ne se vend pas, vous
                pouvez créer un{" "}
                <Link className="article-link" to="/fr/home-staging-virtual">
                  home staging virtuel
                </Link>{" "}
                de à quoi elle ressemblerait rénovée et meublée.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame3}
                  alt="Rénover une propriété qui ne se vend pas"
                />
              </div>
              Ou bien, simplement re-meublée, en laissant de côté les meubles
              démodés.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame1}
                  alt="Home staging virtuel dans une propriété déjà meublée neuve"
                />
              </div>
              <h3 className="article-subtitle">
                2. Propriété neuve qui ne se vend pas
              </h3>
              <p>
                Dans le cas d'une propriété neuve, donnez-lui un aspect plus
                attrayant et vivable avec un home staging virtuel. Vous pouvez
                meubler l'espace virtuellement pour qu'il ressemble à une
                nouvelle publication.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame2}
                  alt="Home staging virtuel dans une propriété neuve"
                />
              </div>
              <h3 className="article-subtitle">
                3. Propriété qui ne se vend pas, remplie d'objets
              </h3>
              <p>
                Donnez un coup de frais à votre propriété en enlevant tous les
                objets et meubles qui n'aident pas à la vendre. Cela la fera
                paraître mieux, et de plus, elle ressemblera à une nouvelle
                publication.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame4}
                  alt="Vider les espaces dans une propriété qui ne se vend pas"
                />
              </div>
              <h3 className="article-subtitle">Conclusion</h3>
              <p>
                Personne n'aime avoir une propriété qui ne se vend pas, mais
                parfois ce genre de situations se produit. Il est nécessaire
                d'être préparé pour pouvoir tirer parti de ces moments.
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Bonjour ! Je suis Felix Ingla, le fondateur de{" "}
              <Link className="article-link" to="/fr">
                Pedra
              </Link>
              , une application web innovante pour le home staging virtuel et la
              photographie immobilière qui transforme la façon dont les
              propriétés sont présentées dans le monde numérique.
            </p>
            <p>
              Si vous souhaitez vous connecter et êtes intéressé par une
              discussion sur la technologie immobilière, veuillez me contacter
              via mon{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Vous pouvez également en savoir plus{" "}
              <Link className="article-link" to="/fr/about">
                sur Pedra ici
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogFrArticle18;
