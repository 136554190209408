import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToEmptyRoomFrame1 from "../Images/HowToEmptyRoomFrame1.jpg";
import HowToEmptyRoomFrame2 from "../Images/HowToEmptyRoomFrame2.jpg";
import Example1Before from "../Images/curtain-effect-empty-room/Example1Before.jpeg";
import Example1After from "../Images/curtain-effect-empty-room/Example1After.jpeg";
import Example2Before from "../Images/curtain-effect-empty-room/Example2Before.jpeg";
import Example2After from "../Images/curtain-effect-empty-room/Example2After.jpeg";
import Example3Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example3After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import Example4Before from "../Images/curtain-effect-empty-room/Example4Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example4After.jpeg";
import CurtainEffect from "../CurtainEffect";

function HowToEmptyRoom() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">How to Empty Rooms Virtually</h1>
          <h2 className="title-secondary gray">
            Tutorial on how to empty rooms virtually
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I empty rooms virtually?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Pedra offers an option that allows you to empty rooms in just 1
                click.
              </p>
              <p style={{ marginTop: "10px" }}>
                To empty a room, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and click on{" "}
                <span style={{ fontWeight: "500" }}>"Empty room"</span>. In 2 or
                3 minutes, you'll have an image of your emptied room, ready to
                download.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame1}
                alt="How to empty rooms in houses and properties – Screenshot of Pedra software"
              />
            </p>
            <br />
            <h2 className="article-subtitleh2">
              How can I get better results when emptying a room?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                If the <span style={{ fontWeight: "500" }}>"Empty room"</span>{" "}
                feature has left objects or parts unemptied, or added additional
                windows or doors, you can use the{" "}
                <span style={{ fontWeight: "500" }}>"Delete object"</span> tool
                on the emptied image to finish removing everything you don't
                want from the photo.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame2}
                alt="How to empty rooms in parts – Screenshot of Pedra software"
              />
              <p>
                And if you want higher quality in the photograph, click on the{" "}
                <span style={{ fontWeight: "500" }}>"Convert to HD"</span>{" "}
                option when you've finished deleting objects.
              </p>
              <p style={{ marginTop: "10px" }}>
                To empty, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and click on{" "}
                <span style={{ fontWeight: "500" }}>"Empty room"</span>. In 2 or
                3 minutes, you'll have your emptied room, ready to download.
              </p>
              <aside className="callout" style={{ marginTop: "10px" }}>
                <div>
                  <InfoIcon />
                </div>
                <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                  If you can't empty the images correctly, you can send an email
                  to <span style={{ fontWeight: "500" }}>felix@pedra.so</span>{" "}
                  and we'll return them emptied within 24-48 hours. You must be
                  subscribed to Pedra to access this service.
                </h4>
              </aside>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Why should I virtually empty a property?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Showing rooms without furniture can be key when selling a
                property.
              </p>
              <p style={{ marginTop: "10px" }}>
                On one hand, the seller will feel more comfortable knowing that
                we're not showing all their personal possessions online. Imagine
                if everyone could see your room online – nobody likes that.
              </p>
              <p style={{ marginTop: "10px" }}>
                On the other hand, the buyer will be more attracted to a
                depersonalized home, and will be able to really see the
                potential of the property, or better imagine their future home.
              </p>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtually emptying a room
            </h2>
            <p className="article-text">
              Here are several examples of rooms virtually emptied with Pedra:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Before virtually emptying the room"}
              altAfter={"After virtually emptying the room"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Before virtually emptying the room"}
              altAfter={"After virtually emptying the room"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Before virtually emptying the room"}
              altAfter={"After virtually emptying the room"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Before virtually emptying the room"}
              altAfter={"After virtually emptying the room"}
            />

            <br />
            {/* 
            <h2 className="article-subtitleh2">
              Video on how to virtually empty a room
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    maxWidth: "900px",
                    height: "500px",
                  }}
                  frameBorder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  title="AI to sell real estate properties"
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToEmptyRoom;
