import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBedroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeBedroom from "../Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "../Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBedroomFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer un home staging virtuel pour les chambres
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création d'un home staging virtuel pour les espaces
            de repos
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je créer un home staging virtuel pour une chambre ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez créer un home staging virtuel pour les
              chambres en quelques clics.
              <p>
                Pour commencer, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>. Choisissez
                "Chambre" comme type d'espace et en 2 ou 3 minutes, vous aurez
                l'image de votre home staging virtuel de chambre prête à être
                téléchargée.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Comment créer un home staging virtuel de chambre – Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour les chambres ?
            </h2>
            <p className="article-text">
              Pour obtenir votre{" "}
              <Link className="article-link" to="/fr/home-staging-chambre">
                home staging virtuel de chambre
              </Link>
              , suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez l'image de la chambre que vous souhaitez meubler
                virtuellement
              </li>
              <li>Sélectionnez l'image pour commencer à l'éditer</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu pour créer un home staging virtuel de chambre"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Meubler"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez la{" "}
                <span style={{ fontWeight: "500" }}>position des meubles</span>.
                Pour les chambres, nous recommandons d'utiliser "Dessiner" pour
                indiquer les zones spécifiques où placer le lit, les armoires,
                les tables de chevet, etc.
              </li>
              <li>
                Sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Chambre"</span> comme type
                d'espace. Cela garantira que Pedra place des meubles appropriés
                comme des lits, des armoires, des commodes, etc.
              </li>
              <li>
                Sélectionnez le <span style={{ fontWeight: "500" }}>style</span>
                . Pour les chambres, des styles comme "Moderne", "Classique" ou
                "Minimaliste" fonctionnent généralement bien.
              </li>
              <li>
                Choisissez le{" "}
                <span style={{ fontWeight: "500" }}>
                  nombre d'images à générer
                </span>
                . Nous recommandons de choisir 2 ou 3 images pour avoir
                différentes options de design de chambre.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour un meilleur home staging virtuel de chambre :
                <ul>
                  <li>
                    Assurez-vous que la photographie montre clairement les
                    dimensions de la chambre pour un ameublement plus précis
                  </li>
                  <li>
                    Si la chambre a des caractéristiques spéciales comme de
                    grandes fenêtres ou des plafonds hauts, essayez de les
                    inclure dans la photo pour un résultat plus attrayant
                  </li>
                  <li>
                    Utilisez l'option "Dessiner" pour indiquer des zones
                    spécifiques pour le lit, les armoires, le coin lecture ou
                    les éléments décoratifs
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemple de création d'un home staging virtuel de chambre"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi faire un home staging virtuel de chambre ?
            </h2>
            <p className="article-text">
              Une chambre bien présentée peut être un grand atout pour les
              acheteurs potentiels. Le home staging virtuel de chambres vous
              permet de montrer le potentiel de ces espaces de repos, en créant
              des ambiances chaleureuses et fonctionnelles qui aident les
              acheteurs à visualiser comment ils pourraient profiter d'un
              sommeil réparateur et de la relaxation.
            </p>
            <p className="article-text">
              Avec un bon home staging virtuel de chambre, vous pourrez :
            </p>
            <ul>
              <li>Montrer le potentiel d'espaces vides ou peu attrayants</li>
              <li>Créer une atmosphère de confort et de tranquillité</li>
              <li>
                Mettre en valeur des caractéristiques telles que la luminosité,
                l'espace de rangement ou la polyvalence de la chambre
              </li>
              <li>
                Aider les acheteurs à imaginer des moments de détente et de
                repos dans la chambre
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de home staging virtuel de chambres
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de home stagings virtuels qui peuvent
              être appliqués aux chambres :
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroom}
              afterImage={AfterBedroom}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <br />
            {/*
            <h2 className="article-subtitleh2">
              Vidéo sur la création d'un home staging virtuel pour les chambres
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/YOUTUBE_VIDEO_ID"
                  title="Tutoriel de Home Staging Virtuel pour les Chambres"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBedroomFr;
