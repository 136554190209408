import React from 'react';

const ToggleIconDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M7.50002 11.1712C7.6729 11.1712 7.82035 11.105 7.95256 10.9729L11.8322 7.00678C11.9441 6.89492 12 6.75763 12 6.59492C12 6.26441 11.7458 6 11.4153 6C11.2525 6 11.1051 6.0661 10.9881 6.17797L7.50002 9.75761L4.0119 6.17797C3.90003 6.07118 3.7526 6 3.58985 6C3.25424 6 3 6.26441 3 6.59492C3 6.75763 3.05593 6.89492 3.16781 7.00678L7.04748 10.9729C7.17968 11.1102 7.32713 11.1712 7.50002 11.1712Z" fill="#828282"/>
    </svg>
  );
};

export default ToggleIconDown;