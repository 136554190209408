import React from "react";

const RenderIAHeaderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25 17.3629L21.0007 21.3728L20.2442 20.6142C19.8443 20.2133 19.7443 19.7822 19.9209 19.2476L20.1309 18.6127L19.661 18.1415L19.0278 18.352C18.4912 18.5325 18.0613 18.4289 17.6647 18.0279L17.0815 17.4431L9.82948 24.7143L7 21.8773L14.252 14.6062L12.9989 13.3498L15.5851 10.7568L15.4218 10.593C15.0019 10.172 14.5986 10.0049 14.0087 10.0049H12.3323V8H14.0087C15.1951 8 15.9983 8.33415 16.8382 9.17287L21.664 14.0114C22.0639 14.4124 22.1639 14.8434 21.9872 15.3781L21.7773 16.013L22.2472 16.4841L22.8804 16.2736C23.417 16.0932 23.8469 16.1967 24.2435 16.5977L25 17.3563V17.3629Z"
        fill="#f8792a"
      />
    </svg>
  );
};

export default RenderIAHeaderIcon;
