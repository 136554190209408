import React from 'react';

const BlueSmileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
        <path d="M10 3C6.13542 3 3 6.13542 3 10C3 13.8646 6.13542 17 10 17C13.8646 17 17 13.8646 17 10C17 6.13542 13.8646 3 10 3ZM5.33333 9.42542C5.46458 8.5125 6.115 7.375 7.375 7.375C8.635 7.375 9.28542 8.5125 9.41667 9.42542L8.285 9.70833C8.18 9.29417 8.07792 8.54167 7.375 8.54167C6.67208 8.54167 6.57 9.29417 6.465 9.70833L5.33333 9.42542ZM10 14.375C8.22667 14.375 6.79167 13.3308 6.79167 12.0417H13.2083C13.2083 13.3308 11.7733 14.375 10 14.375ZM13.535 9.70833C13.43 9.29417 13.3279 8.54167 12.625 8.54167C11.9221 8.54167 11.82 9.29417 11.715 9.70833L10.5833 9.42542C10.7146 8.5125 11.365 7.375 12.625 7.375C13.885 7.375 14.5354 8.5125 14.6667 9.42542L13.535 9.70833Z" fill="#57A4EB"/>
    </svg>
  );
};

export default BlueSmileIcon;