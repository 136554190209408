import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article18Frame1 from "../Images/Article18Frame1.jpg";
import Article18Frame2 from "../Images/Article18Frame2.jpg";
import Article18Frame3 from "../Images/Article18Frame3.jpg";
import Article18Frame4 from "../Images/Article18Frame4.jpg";

function BlogArticle18() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              How to Revive a Property That Isn't Selling
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Founder of Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Founder of Pedra
                </div>
              </div>
            </div>
            <article className="article-text">
              <p>
                Have you ever had a property that just wouldn't sell? One that
                had been listed for months and simply wasn't getting any calls?
                It was surely an unpleasant situation. The owner's pressure
                doesn't help. Lower the price? But you already reduced it a week
                ago...{" "}
              </p>
              <p>
                There are several options to unstick a house that isn't selling
                — and what we're about to explain can be effective in cases
                where you're not even getting calls from property portals.
              </p>
              <h2 className="article-subtitleh2">
                Change the Cover of the Property That Isn't Selling
              </h2>
              <p>
                One of the simplest options to receive more visits to a listing
                is to make it appear new. New listings receive more visits
                because buyers typically search for properties by area, trying
                to see if the new ones fit their needs.{" "}
              </p>
              <p>
                But how do I make a listing look new? Well, by changing the
                cover photo.
              </p>
              <p>Here are three examples of how to do it.</p>
              <h3 className="article-subtitle">
                1. Second-hand Property That Isn't Selling
              </h3>
              <p>
                If you have a second-hand property that isn't selling, you can
                create a{" "}
                <Link className="article-link" to="/home-staging-virtual">
                  virtual home staging
                </Link>{" "}
                of how it would look renovated and furnished.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame3}
                  alt="Renovating a property that isn't selling"
                />
              </div>
              Or simply re-furnished, leaving behind the outdated furniture.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame1}
                  alt="Virtual home staging in an already furnished new property"
                />
              </div>
              <h3 className="article-subtitle">
                2. New Property That Isn't Selling
              </h3>
              <p>
                In the case of a new property, give it a more attractive and
                livable look with virtual home staging. You can virtually
                furnish the space to make it look like a new listing.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame2}
                  alt="Virtual home staging in a new property"
                />
              </div>
              <h3 className="article-subtitle">
                3. Property That Isn't Selling, Full of Clutter
              </h3>
              <p>
                Give your property a facelift by removing all objects and
                furniture that don't help it sell. This will make it look
                better, and moreover, it will look like a new listing.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame4}
                  alt="Clearing spaces in a property that isn't selling"
                />
              </div>
              <h3 className="article-subtitle">Conclusion</h3>
              <p>
                No one likes having a property that isn't selling, but sometimes
                these situations occur. It's necessary to be prepared to take
                advantage of these moments.
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hello! I'm Felix Ingla, the founder of{" "}
              <Link className="article-link" to="/en">
                Pedra
              </Link>
              , an innovative web application for virtual home staging and real
              estate photography that is transforming how properties are
              presented in the digital world.
            </p>
            <p>
              If you'd like to connect and are interested in discussing real
              estate technology, please contact me through my{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . You can also read more{" "}
              <Link className="article-link" to="/about">
                about Pedra here
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle18;
