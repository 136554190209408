import React from "react";

function PrivacyEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Política de Privacidad</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Política de Privacidad para Pedra.so
              </h2>
              <div className="article-text">
                Esta Política de Privacidad explica cómo Pedra.so ("nosotros,"
                "nos," o "nuestro") recopila, usa, comparte y protege su
                información personal cuando usa nuestra aplicación. Al usar
                Pedra.so, usted acepta los términos descritos en esta Política
                de Privacidad.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Información que Recopilamos:
              </h2>
              <div className="article-text">
                1. <strong>Información Personal:</strong> Podemos recopilar
                información personal que usted proporciona directamente, como su
                nombre, dirección de correo electrónico y detalles de contacto
                cuando se registra para una cuenta o se pone en contacto con
                nosotros.
                <br />
                2. <strong>Información de Uso:</strong> Podemos recopilar
                información sobre sus interacciones con nuestra aplicación,
                incluyendo las páginas que visita, las funciones que utiliza y
                las acciones que realiza.
                <br />
                3. <strong>Información del Dispositivo:</strong> Podemos
                recopilar información sobre el dispositivo que usa para acceder
                a nuestra aplicación, incluyendo el tipo de dispositivo, sistema
                operativo y identificadores únicos del dispositivo.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Cómo Usamos Su Información:
              </h2>
              <div className="article-text">
                Podemos usar su información para los siguientes propósitos:
                <br />
                1. Para proporcionar y mejorar nuestros servicios.
                <br />
                2. Para responder a sus consultas y solicitudes.
                <br />
                3. Para enviarle actualizaciones, boletines informativos y
                materiales promocionales (si ha optado por ello).
                <br />
                4. Para personalizar su experiencia y proporcionar contenido y
                funciones adaptadas a sus intereses.
              </div>
              <br />
              <h2 className="article-subtitleh2">Compartir Información:</h2>
              <div className="article-text">
                Podemos compartir su información en las siguientes
                circunstancias:
                <br />
                1. Con proveedores de servicios externos que nos ayudan con la
                operación de nuestra aplicación y servicios.
                <br />
                2. Para cumplir con obligaciones legales o responder a
                solicitudes de las autoridades.
                <br />
                3. En conexión con una transacción comercial, como una fusión,
                adquisición o venta de activos.
                <br />
              </div>
              <br />
              <h2 className="article-subtitleh2">Seguridad de Datos:</h2>
              <div className="article-text">
                Tomamos medidas razonables para proteger su información personal
                del acceso y la divulgación no autorizados. Sin embargo, ningún
                método de transmisión o almacenamiento de datos es completamente
                seguro, y no podemos garantizar su seguridad absoluta.
              </div>
              <br />
              <h2 className="article-subtitleh2">Sus Opciones:</h2>
              <div className="article-text">
                Usted tiene el derecho de acceder, corregir o eliminar su
                información personal. También puede optar por no recibir correos
                electrónicos promocionales en cualquier momento.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Cambios en esta Política de Privacidad:
              </h2>
              <div className="article-text">
                Podemos actualizar esta Política de Privacidad de vez en cuando.
                Cualquier cambio se publicará en esta página con una fecha
                revisada.
              </div>
              <br />
              <h2 className="article-subtitleh2">Contáctenos:</h2>
              <div className="article-text">
                Para cualquier pregunta o propuesta, contáctenos al siguiente
                correo electrónico:{" "}
                <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyEs;
