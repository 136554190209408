import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageKitchenFrame1 from "../Images/HowToHomeStageKitchenFrame1Es.jpg";
import HowToHomeStageKitchenFrame2 from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageKitchenFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingKitchenFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment Créer un Home Staging Virtuel pour Cuisines
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création de home staging virtuel pour espaces de
            cuisine
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je créer un home staging virtuel pour une cuisine ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez créer un home staging virtuel pour
              cuisines en quelques clics seulement.
              <p>
                Pour commencer, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>. Choisissez
                "Cuisine" comme type d'espace, et en environ 2 ou 3 minutes,
                vous aurez votre image de home staging virtuel de la cuisine
                prête à télécharger.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageKitchenFrame1}
              alt="Comment créer un home staging virtuel pour une cuisine – Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour cuisines ?
            </h2>
            <p className="article-text">
              Pour réaliser votre{" "}
              <Link className="article-link" to="/fr/home-staging-cuisine">
                home staging virtuel pour une cuisine
              </Link>
              , suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez l'image de la cuisine que vous souhaitez meubler
                virtuellement
              </li>
              <li>Sélectionnez l'image pour commencer l'édition</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageKitchenFrame2}
              alt="Menu pour créer un home staging virtuel pour une cuisine"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Meubler"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez la{" "}
                <span style={{ fontWeight: "500" }}>position des éléments</span>
                . Pour les cuisines, nous recommandons d'utiliser "Dessiner"
                pour indiquer des zones spécifiques où placer les
                électroménagers, la décoration et les accessoires.
              </li>
              <li>
                Sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Cuisine"</span> comme type
                d'espace. Cela garantira que Pedra place des éléments appropriés
                tels que des électroménagers, des ustensiles de cuisine et des
                éléments décoratifs.
              </li>
              <li>
                Sélectionnez le <span style={{ fontWeight: "500" }}>style</span>
                . Pour les cuisines, des styles comme "Moderne", "Contemporain"
                ou "Traditionnel" fonctionnent généralement bien.
              </li>
              <li>
                Choisissez le{" "}
                <span style={{ fontWeight: "500" }}>
                  nombre d'images à générer
                </span>
                . Nous recommandons de choisir 2 ou 3 images pour avoir
                différentes options d'aménagement de cuisine.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour un meilleur home staging virtuel d'une cuisine :
                <ul>
                  <li>
                    Assurez-vous que la photographie montre clairement toute la
                    configuration de la cuisine pour un résultat plus précis
                  </li>
                  <li>
                    Capturez toutes les caractéristiques uniques comme les
                    îlots, les fenêtres ou les détails architecturaux
                  </li>
                  <li>
                    Utilisez l'option "Dessiner" pour indiquer des zones
                    spécifiques pour les électroménagers, les accessoires de
                    plan de travail ou les éléments décoratifs
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageKitchenFrame4}
                    alt="Exemple de création de home staging virtuel pour une cuisine"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi faire un home staging virtuel pour une cuisine ?
            </h2>
            <p className="article-text">
              La cuisine est souvent considérée comme le cœur de la maison et
              peut être un élément décisif dans la vente. Le home staging
              virtuel pour cuisines vous permet de montrer tout le potentiel de
              l'espace, en créant des environnements chaleureux et fonctionnels
              qui aident les acheteurs à s'imaginer cuisiner et recevoir dans
              cet espace.
            </p>
            <p className="article-text">
              Avec un bon home staging virtuel pour une cuisine, vous pouvez :
            </p>
            <ul>
              <li>
                Transformer des cuisines vides ou démodées en espaces
                accueillants
              </li>
              <li>
                Mettre en valeur la fonctionnalité et l'ergonomie de
                l'agencement
              </li>
              <li>
                Présenter les solutions de rangement et le potentiel des
                surfaces de travail
              </li>
              <li>
                Aider les acheteurs à s'imaginer en train de préparer des repas
                et de recevoir dans l'espace
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de home staging virtuel pour cuisines
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de home staging virtuel qui peuvent être
              appliqués aux cuisines :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingKitchenFr;
