import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import ChangeSize from "../Images/HowTo3DChangeSize.jpeg";
import Move from "../Images/HowTo3DMove.jpeg";
import Rotate from "../Images/HowTo3DRotate.jpeg";

function HowTo3DFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment Utiliser l'Outil "Add Object" pour Créer une Mise en Scène
            Virtuelle
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur comment ajouter des objets 3D à un espace et créer une
            mise en scène virtuelle
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {typeof window !== "undefined" && (
              <iframe
                src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                  height: "500px",
                }}
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                title="AI to sell real estate properties"
              ></iframe>
            )}
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi sert l'outil "Add Object" ?
            </h2>
            <div className="article-text">
              Avec l'outil "Add Object", vous pouvez placer des meubles, des
              plantes, des objets, et plus encore dans votre image.
              <br />
              <br />
              Ce qui est génial avec cet outil, c'est que vous pouvez choisir où
              placer les objets, leur taille et leur orientation.
              <br />
              <br />
              Une fois les objets placés, vous pouvez cliquer sur l'option
              "generate". Cela créera une image extrêmement réaliste de votre
              agencement.
              <br />
              <br />
            </div>
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Ajouter des Objets
            </h2>
            <div className="article-text">
              Cliquez sur l'objet que vous souhaitez ajouter. Lorsque vous
              cliquez, l'objet sera automatiquement ajouté à l'image.
              <div style={{ marginTop: "10px" }}>
                Si vous souhaitez supprimer les objets que vous avez ajoutés,
                utilisez le bouton "Delete Objects" pour les retirer de l'image.
              </div>
            </div>
            <br />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 –</span>{" "}
              Modifier la Position, l'Orientation et la Taille des Objets
            </h2>
            <div className="article-text">
              Maintenant que vous avez ajouté les objets à l'image, vous devez
              les placer dans la bonne position, orientation et taille.
              <div style={{ marginTop: "10px", fontWeight: "500" }}>
                {" "}
                Nous vous recommandons de placer un objet dans la position, la
                taille et l'orientation correctes avant d'ajouter un autre objet
                à l'image.
              </div>
            </div>
            <br />
            <h3 className="article-subtitle">Changer la Position des Objets</h3>
            <div className="article-text">
              Utilisez les flèches vertes, jaunes et rouges pour déplacer
              l'objet. N'oubliez pas que vous travaillez en trois dimensions,
              donc vous pouvez déplacer l'objet dans l'espace.
              <div style={{ marginTop: "10px" }}>
                Vous pouvez également cliquer sur le rectangle jaune au milieu
                de l'objet pour le faire glisser dans l'espace.
              </div>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={Move}
                alt="Change position of 3D object for virtual home staging – Screenshot from Pedra software"
              />
              <br />
            </div>
            <h3 className="article-subtitle">Faire Pivoter les Objets</h3>
            <div className="article-text">
              Utilisez les sphères vertes, jaunes et rouges pour faire pivoter
              l'objet.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={Rotate}
              alt="Rotate 3D object for virtual home staging – Screenshot from Pedra software"
            />
            <h3 className="article-subtitle">Changer la Taille des Objets</h3>

            <div className="article-text">
              Utilisez les lignes vertes, jaunes et rouges pour changer la
              taille de l'objet.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={ChangeSize}
              alt="Change size 3D for virtual home staging – Screenshot from Pedra software"
            />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Générer l'Image
            </h2>
            <div className="article-text">
              Une fois que vous avez placé les objets, cliquez sur "generate
              image". Une représentation hyper-réaliste de l'espace sera créée.
            </div>
            <div style={{ marginTop: "10px" }}>
              Si vous avez des questions, contactez-moi par e-mail à
              felix@pedra.so
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTo3DFr;
