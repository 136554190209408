import React from "react";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function AboutUsEs() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h2 className="article-subtitleh2">Hola,</h2>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Soy Felix Ingla, el fundador de Pedra.
              </div>
              <div style={{ marginTop: "10px" }}>
                En la primavera del 2023 empecé{" "}
                <Link className="article-link" to="/es">
                  Pedra
                </Link>
                , una herramienta online para transformar tus propiedades de
                inmobiliarias de manera fácil y sencilla.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Pedra nace de{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>una realidad</span>{" "}
                del sector inmobiliario.
              </h2>
              <div style={{ marginTop: "10px" }}>
                Para poner un piso a la venta – y que se venda rápido – las
                inmobiliarias requieren de buenas fotografías, vaciar los pisos
                de trastos, colocar el mobiliario adecuado en las habitaciones e
                incluso proporcionar propuestas de renovación.
              </div>
              <div style={{ marginTop: "10px" }}>
                Todo esto conlleva una inversión de tiempo y dinero. Pero no
                siempre tenemos ese dinero y tiempo, sobre todo si no estamos
                trabajando en exclusiva.
              </div>
              <div style={{ marginTop: "10px" }}>
                Por eso nace Pedra: una manera fácil, rápida y económica de
                mejorar como presentamos un piso al mercado, y poderlo vender
                más rápido.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nuestros clientes son{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  nuestra prioridad
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                En{" "}
                <Link className="article-link" to="/es">
                  Pedra
                </Link>
                , queremos asegurar la mejor experiencia cuando uséis nuestro
                producto. Si en cualquier momento tenéis feedback, o necesitáis
                cualquier ayuda, por favor, escríbeme en felix@pedra.so.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Una herramienta{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  potente pero sencilla
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Con Pedra facilitamos a las inmobiliarias{" "}
                <Link className="article-link" to="/es/real-estate-photography">
                  acceso a inteligencia artificial para que podáis mejorar
                  vuestras fotografías de inmuebles
                </Link>
                ,{" "}
                <Link className="article-link" to="/es/render">
                  generar propuestas de renovación
                </Link>
                , vaciarlos y más. Y sobretodo, que lo podáis hacer de manera
                fácil y sencilla – queremos que aparte de ahorrar costes
                ahorreis tiempo.
              </div>
              <br />
              <br />
              Estamos orgullosos de presentarte Pedra.
              <br />Y esperamos que te ánimes a usarlo.
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "50%",
                    height: "64px",
                    width: "auto",
                  }}
                  src={FelixIngla}
                  alt="Felix Ingla"
                />
                <div
                  style={{
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "100%",
                  }}
                >
                  <div className="article-text" color="#333 !important">
                    Felix Ingla
                  </div>
                  <div className="article-text" style={{ color: "#878787" }}>
                    Fundador de Pedra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsEs;
