import React from "react";
import Article4Frame from "../Images/Article4Frame.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogFrArticle4() {
  return (
    <>
      <div className="section-one-column-left">
        <article
          className="title-container article"
          style={{ marginTop: "100px" }}
        >
          <h1 className="article-titleh1">
            Comment l'IA peut-elle aider les agents immobiliers à vendre ?
          </h1>
          <div className="title-secondary gray">
            Une explication de ce qu'est l'IA et comment elle peut aider à la
            vente de biens immobiliers
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
              }}
              src={Article4Frame}
              alt="Couverture de l'article sur comment l'IA peut aider les agents immobiliers à vendre"
            />
          </div>
        </article>
      </div>

      <div className="section-one-column-left" style={{ marginTop: "0px" }}>
        <section className="step-container article">
          <div>
            <div className="article-text">
              <div className="article-text">
                Dans ce texte, je veux partager des informations sur l'IA et
                essayer de démystifier ce qu'elle est, et comment elle peut
                réellement aider au quotidien d'un agent immobilier.
              </div>
              <br />
              <div className="article-text">
                Cet article tournera autour de la question suivante :
              </div>
              <br />
              <div className="article-text" style={{ fontWeight: "600" }}>
                Comment pouvons-nous vendre plus avec l'IA ?
              </div>
              <br />
              <div className="article-text">
                Mais d'abord, nous allons partager ce qu'est l'IA :
              </div>
              <br />
              <h2 className="article-titleh2">Qu'est-ce que l'IA</h2>
              <br />
              <div className="article-text">
                Un programme qui a appris à effectuer des tâches similaires à
                celles d'un humain. Des tâches comme écrire, parler, avoir des
                conversations, penser, imaginer, etc.
              </div>
              <br />
              <h2 className="article-titleh2">
                Comment pouvons-nous créer une IA ?
              </h2>
              <div className="article-text">
                La première étape consiste à entraîner un modèle avec des
                millions de points de données.
              </div>
              <br />
              <div className="article-text">
                La deuxième étape consiste à mettre en pratique ce modèle d'IA
                que nous avons déjà entraîné. C'est comme quand Rocky
                s'entraînait très dur – c'était tout pour ce combat final.
              </div>
              <br />
              <h2 className="article-titleh2">
                Quels modèles pourrions-nous créer pour aider les agents
                immobiliers ?
              </h2>
              <br />
              <div className="article-text">
                Par exemple, nous pourrions entraîner une IA à identifier
                quelles sont les images qui génèrent le plus de clics dans une
                annonce, comme par exemple sur{" "}
                <a
                  className="article-link"
                  href="https://www.idealista.com"
                  rel="nofollow"
                >
                  Idealista
                </a>
                .
              </div>
              <br />
              <div className="article-text">
                Nous devrions essentiellement commencer à entraîner un modèle,
                et lui transmettre des millions d'images en lui indiquant
                lesquelles ont été mises en couverture de l'annonce. Une fois
                que le modèle aurait été entraîné avec ces millions de points de
                données, nous aurions notre petit modèle intelligent pour nous
                aider à choisir la meilleure image pour notre couverture. Nous
                pourrions partager les 20 images de l'une de nos propriétés, et
                il nous dirait laquelle serait celle qui pourrait nous aider à
                vendre le plus.
              </div>
              <br />
              <div className="article-text">
                Mais obtenir un petit robot qui nous donne cette aide n'est pas
                bon marché, ni facile, ni rapide, car :
              </div>
              <br />
              <li className="article-text">
                Nous avons besoin de nous entraîner avec des millions de points
                de données :
              </li>
              <li className="article-text">
                nous aurions besoin de ressources d'ingénierie coûteuses, comme
                des professionnels et des ordinateurs
              </li>
              <li className="article-text">
                Nous aurions besoin de beaucoup de temps
              </li>
              <br />
              Je n'entrerai donc pas dans la manière de créer des modèles d'IA,
              bien que ce soit un sujet très intéressant. Mais ce n'est
              certainement pas un sujet que vous devez connaître pour tirer le
              meilleur parti de l'IA facilement. Dans cet article, je vais
              aborder des modèles qui existent déjà et qui peuvent vous aider à
              vendre plus.
              <br />
              <h2 className="article-titleh2">
                Existe-t-il des modèles d'IA qui peuvent vendre une maison pour
                moi ?
              </h2>
              <div className="article-text">
                Non.
                <br />
                <br /> Il n'y a pas de modèles d'IA qui remplacent l'agent
                immobilier ou qui vont vendre pour vous. Parce que l'IA se
                concentre sur des activités répétitives et non sur des activités
                humaines et de haute complexité. L'agent immobilier nécessite
                des relations interpersonnelles et un degré de complexité que la
                technologie d'aujourd'hui ne peut pas assumer.
              </div>
              <br />
              <h2 className="article-titleh2">
                Alors que peut faire l'IA pour l'agent immobilier ?
              </h2>
              <div className="article-text">
                Eh bien, l'IA peut vous décharger du travail répétitif. Et
                ainsi, vous aurez plus de temps pour vous concentrer sur la
                vente et la prospection.
              </div>
              <br />
              <div className="article-text">
                Alors, comment l'IA peut-elle nous aider ?
              </div>
              <br />
              <h2 className="article-titleh2">
                Créer des images qui nous aident à vendre.
              </h2>
              <div className="article-text">
                Les images sont l'une des tâches les plus répétitives auxquelles
                les agents immobiliers doivent faire face. Elles sont
                virtuellement présentes dans toutes les transactions
                immobilières.
              </div>
              <br />
              <div className="article-text">
                Dans le monde de l'imagerie immobilière, il y a des tâches très
                répétitives :
              </div>
              <br />
              <div className="article-text">
                Un exemple clair est lorsque nous avons la maison de
                "grand-mère" à vendre. Dans ces situations, cela peut être très
                difficile, car même si la propriété est bonne, le mobilier et la
                décoration peuvent nuire beaucoup au processus de vente.
              </div>
              <br />
              <div className="article-text">L'IA résout ce problème.</div>
              <br />
              <div className="article-text">
                En 1 clic, nous pouvons{" "}
                <Link className="article-link" to="/real-estate-photography">
                  vider des pièces avec l'IA
                </Link>{" "}
                et faire en sorte que ces pièces paraissent au mieux
              </div>
              <br />
              <div className="article-text">
                Une autre tâche répétitive dans le monde de l'immobilier est
                l'obtention de rendus présentant des propositions de rénovation
                d'appartements très anciens. Si une propriété a vraiment besoin
                d'une rénovation, le mieux serait de montrer directement à quoi
                elle ressemble une fois rénovée. Et c'est pourquoi les agents
                immobiliers commandent des rendus aux architectes !
              </div>
              <br />
              <div className="article-text">
                Le problème est que les rendus sont coûteux et nécessitent
                quelques jours ou semaines pour être réalisés par un
                professionnel.
              </div>
              <br />
              <div className="article-text">
                L'IA résout ces problèmes. Avec l'IA, vous pouvez{" "}
                <Link className="article-link" to="/render">
                  générer des propositions de rénovation en quelques secondes
                </Link>
                , quand vous voulez, où vous voulez, quand vous en avez besoin.
              </div>
              <br />
              <div className="article-text">
                L'IA aidera également à remplir les espaces de vie, et à
                s'assurer que cela ressemble à un endroit que quelqu'un peut
                appeler chez soi. Lorsque les propriétés sont vides, les
                visiteurs ont du mal à comprendre à quoi elles ressemblent. Les
                acheteurs sont confus et ont du mal à comprendre à quoi cela
                pourrait vraiment ressembler.
              </div>
              <br />
              <div className="article-text">
                Le home staging physique réel est devenu très populaire ces
                dernières années. C'est une excellente solution, mais elle peut
                être coûteuse, lente et complexe à mettre en œuvre.
              </div>
              <br />
              <div className="article-text">
                Avec l'IA, vous ne pouvez pas reproduire l'effet d'un home
                staging physique, mais vous pouvez reproduire le même effet que
                lorsque cette propriété est vue en ligne. Et c'est important,
                car la majorité des processus de vente commencent en ligne. Les
                gens vont en ligne, et s'ils voient des photos qui leur
                plaisent, ils pourraient être plus intéressés à réserver un
                appel pour une visite.
              </div>
              <br />
              <div className="article-text">
                Et notre emploi du temps appréciera également l'IA. Nous savons
                à quel point la météo est importante lorsque nous voulons faire
                une séance photo d'une propriété. Nous voulons tous{" "}
                <Link className="article-link" to="/real-estate-photography">
                  obtenir les meilleures photographies immobilières
                </Link>{" "}
                possibles. Mais ce n'est pas toujours facile. Coordonner avec le
                photographe, avec la météo, avec le propriétaire de la maison,
                etc. c'est un vrai casse-tête ! L'IA apporte un remède à ces
                choses. D'un coup de touche sur votre ordinateur, vous pouvez
                dégager ces ciels de nuages et vous assurer que l'herbe paraît
                aussi verte que jamais.
              </div>
              <br />
              <h2 className="article-titleh2">
                En conclusion, l'IA vous donne des superpouvoirs
              </h2>
              <br />
              <div className="article-text">
                Elle vous aidera à vendre plus rapidement. Vous pouvez créer des
                images qui agissent comme des aimants à prospects sur les places
                de marché immobilières et les réseaux sociaux.
              </div>
              <br />
              <div className="article-text">
                Vous augmenterez la qualité, la rapidité et la flexibilité de
                vos services. Face au client et au vendeur, vous pouvez offrir
                plus d'expériences et d'aide dans leurs opérations et besoins.{" "}
                <Link className="article-link" to="/render">
                  Le client veut-il voir la propriété après une rénovation ?
                </Link>{" "}
                Voulez-vous améliorer les images le plus rapidement possible ?
                Voulez-vous publier plus rapidement ?
              </div>
              <br />
              <div className="article-text">
                Automatisez et réduisez les coûts. Ce qui prenait auparavant
                beaucoup d'argent et de temps, vous pouvez maintenant le faire
                facilement avec un ordinateur ou un téléphone. Merci l'IA.
              </div>
              <br />
              <div className="article-text" style={{ color: "#878787" }}>
                Ce contenu a été initialement présenté lors d'un événement en
                direct de{" "}
                <a className="article-link" href="https://www.iadespana.es">
                  IAD Espagne
                </a>{" "}
                le 18 juin 2024
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Bonjour ! Je suis Felix Ingla, le fondateur de{" "}
              <Link className="article-link" to="/fr">
                Pedra
              </Link>
              , une application web innovante de home staging virtuel et de
              photographie immobilière qui transforme la façon dont les
              propriétés sont présentées dans le monde numérique. Mon dévouement
              à créer une technologie de pointe et une solution intuitive a
              établi Pedra comme un outil de confiance pour les professionnels
              de l'immobilier cherchant à améliorer leurs annonces de
              propriétés.
            </p>
            <p>
              Si vous souhaitez entrer en contact et discuter de la technologie
              immobilière, n'hésitez pas à me contacter via mon{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Vous pouvez également en savoir plus{" "}
              <Link className="article-link" to="/fr/about">
                sur Pedra ici
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogFrArticle4;
