import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const urlMappings = {
  en: {
    "/es": "/",
    "/fr": "/",
    "/es/realestate": "/realestate",
    "/fr/realestate": "/realestate",
    "/es/real-estate-photography": "/real-estate-photography",
    "/fr/real-estate-photography": "/real-estate-photography",
    "/es/floorplan": "/floorplan",
    "/fr/floorplan": "/floorplan",
    "/es/interiordesign": "/interiordesign",
    "/fr/interiordesign": "/interiordesign",
    "/es/render": "/render",
    "/fr/render": "/render",
    "/es/api-documentation": "/api-documentation",
    "/fr/api-documentation": "/api-documentation",
    "/es/blog/como-vender-casa": "/",
    "/es/blog/home-staging-ejemplos": "/blog/home-staging-examples",
    "/fr/blog/exemples-de-home-staging": "/blog/home-staging-examples",
    "/es/pricing": "/pricing",
    "/fr/pricing": "/pricing",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/blog/how-to-sell-properties-with-ai",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/blog/how-to-sell-properties-with-ai",
    "/es/blog": "/blog",
    "/fr/blog": "/blog",
    "/es/blog/renovar-casa": "/",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/blog/sell-more-properties-with-ai",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/blog/sell-more-properties-with-ai",
    "/es/releases": "/releases",
    "/fr/releases": "/releases",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/releases/renovate-interiors-with-ai-from-phone",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/releases/renovate-interiors-with-ai-from-phone",
    "/es/home-staging-virtual": "/home-staging-virtual",
    "/fr/home-staging-virtual": "/home-staging-virtual",
    "/es/legal": "/legal",
    "/fr/legal": "/legal",
    "/es/privacy": "/privacy",
    "/fr/privacy": "/privacy",
    "/es/mls": "/",
    "/es/about": "/about",
    "/fr/about": "/about",
    "/es/help/como-anadir-objetos-3d": "/help/how-to-add-3d-objects",
    "/fr/help/comment-ajouter-des-objets-3d": "/help/how-to-add-3d-objects",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/releases/empty-rooms-and-remove-objects-with-ai",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/releases/empty-rooms-and-remove-objects-with-ai",
    "/es/reviews-and-testimonials": "/reviews-and-testimonials",
    "/fr/reviews-and-testimonials": "/reviews-and-testimonials",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/releases/add-3d-objects-and-make-sky-blue",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/releases/add-3d-objects-and-make-sky-blue",
    "/es/help/como-renovar-casas-virtualmente":
      "/help/how-to-renovate-houses-virtually",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/help/how-to-renovate-houses-virtually",
    "/es/help/como-amueblar-virtualmente": "/help/how-to-furnish-virtually",
    "/fr/help/comment-meubler-en-virtuel": "/help/how-to-furnish-virtually",
    "/es/help/como-borrar-objeto": "/help/how-to-remove-object",
    "/fr/help/comment-supprimer-un-objet": "/help/how-to-remove-object",
    "/es/blog/espacios-multifuncionales": "/blog/multifunctional-spaces",
    "/fr/blog/espaces-multifonctionnels": "/blog/multifunctional-spaces",
    "/es/blog/aumenta-valor-vivienda": "/blog/increase-home-value",
    "/fr/blog/augmentez-valeur-maison": "/blog/increase-home-value",
    "/es/blog/marketing-inmobiliario": "/blog/real-estate-marketing",
    "/fr/blog/marketing-immobilier": "/blog/real-estate-marketing",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/free-tools/generate-real-estate-listing-description-free",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/free-tools/generate-real-estate-listing-description-free",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/blog/real-estate-market-trends",
    "/fr/blog/tendances-marche-immobilier": "/blog/real-estate-market-trends",
    "/es/releases/amueblado-realista": "/releases/realistic-furnishing",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/releases/realistic-furnishing",
    "/es/blog/publicidad-inmobiliaria": "/blog/real-estate-advertising",
    "/fr/blog/publicite-immobiliere": "/blog/real-estate-advertising",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/help/how-to-empty-rooms-virtually",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/help/how-to-empty-rooms-virtually",
    "/es/blog/pricing-inmobiliario": "/blog/real-estate-pricing",
    "/fr/blog/pricing-immobilier": "/blog/real-estate-pricing",
    "/es/blog/mentor-inmobiliario": "/blog/real-estate-mentor",
    "/fr/blog/mentor-immobilier": "/blog/real-estate-mentor",
    "/es/blog/como-personalizar-habitacion": "/blog/how-to-personalize-room",
    "/fr/blog/comment-personnaliser-chambre": "/blog/how-to-personalize-room",
    "/es/help/como-crear-home-staging-virtual":
      "/help/how-to-create-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/help/how-to-create-virtual-home-staging",
    "/es/blog/salas-hundidas": "/blog/sunken-living-rooms",
    "/fr/blog/salons-en-contrebas": "/blog/sunken-living-rooms",
    "/es/blog/domotizar-casa": "/blog/home-automation",
    "/fr/blog/domotiser-maison": "/blog/home-automation",
    "/es/blog/propiedad-no-se-vende": "/blog/property-that-wont-sell",
    "/fr/blog/propriete-qui-ne-se-vend-pas": "/blog/property-that-wont-sell",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/releases/renovate-and-furnish-in-one-click",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/releases/renovate-and-furnish-in-one-click",
    "/es/blog/flipping": "/blog/flipping",
    "/fr/blog/flipping": "/blog/flipping",
    "/fr/blog/modernizar-casa": "/blog/modernize-home",
    "/es/blog/moderniser-maison": "/blog/modernize-home",
    "/es/blog/diseñador-ambientes": "/blog/environmental-designer",
    "/fr/blog/designer-environnement": "/blog/environmental-designer",
    "/es/home-staging-cocina": "/home-staging-kitchen",
    "/fr/home-staging-cuisine": "/home-staging-kitchen",
    "/fr/home-staging-salon": "/home-staging-living-room",
    "/es/home-staging-salon": "/home-staging-living-room",
    "/es/blog/short-sale": "/blog/short-sale",
    "/fr/blog/short-sale": "/blog/short-sale",
    "/fr/blog/erreurs-vente-maison": "/blog/mistakes-when-selling-house",
    "/es/blog/errores-al-vender-casa": "/blog/mistakes-when-selling-house",
    "/es/home-staging-terraza": "/home-staging-terrace",
    "/fr/home-staging-terrasse": "/home-staging-terrace",
    "/es/home-staging-dormitorio": "/home-staging-bedroom",
    "/fr/home-staging-chambre": "/home-staging-bedroom",
    "/fr/home-staging-salle-de-bain": "/home-staging-bathroom",
    "/es/home-staging-bano": "/home-staging-bathroom",
    "/es/blog/bienes-raices": "/blog/real-estate",
    "/fr/blog/immobilier": "/blog/real-estate",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/es/blog/frases-inmobiliarias": "/blog/real-estate-phrases",
    "/fr/blog/phrases-immobilieres": "/blog/real-estate-phrases",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/fr/blog/fiducie-immobiliere": "/blog/real-estate-trust",
    "/es/blog/fideicomiso-inmobiliario": "/blog/real-estate-trust",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/free-tools/free-image-format-converter",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/free-tools/free-image-format-converter",
    "/fr/blog/terrains-constructibles": "/blog/buildable-land",
    "/es/blog/terrenos-urbanizables": "/blog/buildable-land",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/releases/pedra-improvements-pools-and-preservation",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/releases/pedra-improvements-pools-and-preservation",
    "/fr/rendus-de-renovation": "/renders-for-renovations",
    "/es/renders-para-reformas": "/renders-for-renovations",
    "/es/blog/como-vender-casa-a-reformar":
      "/blog/how-to-sell-a-house-to-renovate",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/blog/how-to-sell-a-house-to-renovate",
    "/es/render-cocina": "/render-kitchen",
    "/fr/rendu-cuisine": "/render-kitchen",
    "/es/render-dormitorio": "/render-bedroom",
    "/fr/rendu-chambre": "/render-bedroom",
    "/fr/rendu-salle-de-bain": "/render-bathroom",
    "/es/render-bano": "/render-bathroom",
    "/es/render-salon": "/render-living-room",
    "/fr/rendu-salon": "/render-living-room",
    "/fr/rendu-terrasse": "/render-terrace",
    "/es/render-terraza": "/render-terrace",
    "/es/render-casa": "/render-house",
    "/fr/rendu-maison": "/render-house",
    "/fr/help/comment-creer-rendu-cuisine":
      "/help/how-to-create-kitchen-render",
    "/es/help/como-crear-render-cocina": "/help/how-to-create-kitchen-render",
  },
  es: {
    "/": "/es",
    "/fr": "/es",
    "/realestate": "/es/realestate",
    "/fr/realestate": "/es/realestate",
    "/real-estate-photography": "/es/real-estate-photography",
    "/fr/real-estate-photography": "/es/real-estate-photography",
    "/floorplan": "/es/floorplan",
    "/fr/floorplan": "/es/floorplan",
    "/interiordesign": "/es/interiordesign",
    "/fr/interiordesign": "/es/interiordesign",
    "/render": "/es/render",
    "/fr/render": "/es/render",
    "/api-documentation": "/es/api-documentation",
    "/fr/api-documentation": "/es/api-documentation",
    "/es/mls": "/es",
    "/es/blog/como-vender-casa": "/es/blog/como-vender-casa",
    "/blog/home-staging-examples": "/es/blog/home-staging-ejemplos",
    "/fr/blog/exemples-de-home-staging": "/es/blog/home-staging-ejemplos",
    "/pricing": "/es/pricing",
    "/fr/pricing": "/es/pricing",
    "/es/blog/renovar-casa": "/es/blog/renovar-casa",
    "/blog/how-to-sell-properties-with-ai":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/blog": "/es/blog",
    "/fr/blog": "/es/blog",
    "/blog/sell-more-properties-with-ai":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/releases": "/es/releases",
    "/fr/releases": "/es/releases",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/es/releases/renueva-espacios-desde-el-movil-con-ia",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/es/releases/renueva-espacios-desde-el-movil-con-ia",
    "/home-staging-virtual": "/es/home-staging-virtual",
    "/fr/home-staging-virtual": "/es/home-staging-virtual",
    "/legal": "/es/legal",
    "/fr/legal": "/es/legal",
    "/privacy": "/es/privacy",
    "/fr/privacy": "/es/privacy",
    "/about": "/es/about",
    "/fr/about": "/es/about",
    "/help/how-to-add-3d-objects": "/es/help/como-anadir-objetos-3d",
    "/fr/help/comment-ajouter-des-objets-3d": "/es/help/como-anadir-objetos-3d",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
    "/reviews-and-testimonials": "/es/reviews-and-testimonials",
    "/fr/reviews-and-testimonials": "/es/reviews-and-testimonials",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/es/releases/anade-objetos-3d-y-cielo-azul",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/es/releases/anade-objetos-3d-y-cielo-azul",
    "/help/how-to-renovate-houses-virtually":
      "/es/help/como-renovar-casas-virtualmente",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/es/help/como-renovar-casas-virtualmente",
    "/help/how-to-furnish-virtually": "/es/help/como-amueblar-virtualmente",
    "/fr/help/comment-meubler-en-virtuel":
      "/es/help/como-amueblar-virtualmente",
    "/help/how-to-remove-object": "/es/help/como-borrar-objeto",
    "/fr/help/comment-supprimer-un-objet": "/es/help/como-borrar-objeto",
    "/blog/multifunctional-spaces": "/es/blog/espacios-multifuncionales",
    "/fr/blog/espaces-multifonctionnels": "/es/blog/espacios-multifuncionales",
    "/blog/increase-home-value": "/es/blog/aumenta-valor-vivienda",
    "/fr/blog/augmentez-valeur-maison": "/es/blog/aumenta-valor-vivienda",
    "/blog/real-estate-marketing": "/es/blog/marketing-inmobiliario",
    "/fr/blog/marketing-immobilier": "/es/blog/marketing-inmobiliario",
    "/free-tools/generate-real-estate-listing-description-free":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/blog/real-estate-market-trends":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/fr/blog/tendances-marche-immobilier":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/releases/realistic-furnishing": "/es/releases/amueblado-realista",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/es/releases/amueblado-realista",
    "/blog/real-estate-advertising": "/es/blog/publicidad-inmobiliaria",
    "/fr/blog/publicite-immobiliere": "/es/blog/publicidad-inmobiliaria",
    "/help/how-to-empty-rooms-virtually":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/blog/real-estate-pricing": "/es/blog/pricing-inmobiliario",
    "/fr/blog/pricing-immobilier": "/es/blog/pricing-inmobiliario",
    "/blog/real-estate-mentor": "/es/blog/mentor-inmobiliario",
    "/fr/blog/mentor-immobilier": "/es/blog/mentor-inmobiliario",
    "/blog/how-to-personalize-room": "/es/blog/como-personalizar-habitacion",
    "/fr/blog/comment-personnaliser-chambre":
      "/es/blog/como-personalizar-habitacion",
    "/help/how-to-create-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/es/help/como-crear-home-staging-virtual",
    "/blog/sunken-living-rooms": "/es/blog/salas-hundidas",
    "/fr/blog/salons-en-contrebas": "/es/blog/salas-hundidas",
    "/blog/home-automation": "/es/blog/domotizar-casa",
    "/fr/blog/domotiser-maison": "/es/blog/domotizar-casa",
    "/blog/property-that-wont-sell": "/es/blog/propiedad-no-se-vende",
    "/fr/blog/propriete-qui-ne-se-vend-pas": "/es/blog/propiedad-no-se-vende",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
    "/releases/renovate-and-furnish-in-one-click":
      "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
    "/blog/flipping": "/es/blog/flipping",
    "/fr/blog/flipping": "/es/blog/flipping",
    "/fr/blog/moderniser-maison": "/es/blog/modernizar-casa",
    "/blog/modernize-home": "/es/blog/modernizar-casa",
    "/blog/environmental-designer": "/es/blog/diseñador-ambientes",
    "/fr/blog/designer-environnement": "/es/blog/diseñador-ambientes",
    "/fr/home-staging-cuisine": "/es/home-staging-cocina",
    "/home-staging-kitchen": "/es/home-staging-cocina",
    "/fr/home-staging-salon": "/es/home-staging-salon",
    "/home-staging-living-room": "/es/home-staging-salon",
    "/es/blog/short-sale": "/blog/short-sale",
    "/fr/blog/short-sale": "/blog/short-sale",
    "/fr/blog/erreurs-vente-maison": "/es/blog/errores-al-vender-casa",
    "/blog/mistakes-when-selling-house": "/es/blog/errores-al-vender-casa",
    "/home-staging-terrace": "/es/home-staging-terraza",
    "/fr/home-staging-terrasse": "/es/home-staging-terraza",
    "/fr/home-staging-chambre": "/es/home-staging-dormitorio",
    "/home-staging-bedroom": "/es/home-staging-dormitorio",
    "/home-staging-bathroom": "/es/home-staging-bano",
    "/fr/home-staging-salle-de-bain": "/es/home-staging-bano",
    "/blog/real-estate": "/es/blog/bienes-raices",
    "/fr/blog/immobilier": "/es/blog/bienes-raices",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/blog/real-estate-phrases": "/es/blog/frases-inmobiliarias",
    "/fr/blog/phrases-immobilieres": "/es/blog/frases-inmobiliarias",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/fr/blog/fiducie-immobiliere": "/es/blog/fideicomiso-inmobiliario",
    "/blog/real-estate-trust": "/es/blog/fideicomiso-inmobiliario",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
    "/free-tools/free-image-format-converter":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
    "/fr/blog/terrains-constructibles": "/es/blog/terrenos-urbanizables",
    "/blog/buildable-land": "/es/blog/terrenos-urbanizables",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/releases/pedra-improvements-pools-and-preservation":
      "/es/releases/mejoras-pedra-piscinas-y-preservacion",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/es/releases/mejoras-pedra-piscinas-y-preservacion",
    "/fr/rendus-de-renovation": "/es/renders-para-reformas",
    "/renders-for-renovations": "/es/renders-para-reformas",
    "/blog/how-to-sell-a-house-to-renovate":
      "/es/blog/como-vender-casa-a-reformar",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/es/blog/como-vender-casa-a-reformar",
    "/render-kitchen": "/es/render-cocina",
    "/fr/rendu-cuisine": "/es/render-cocina",
    "/render-bedroom": "/es/render-dormitorio",
    "/fr/rendu-chambre": "/es/render-dormitorio",
    "/fr/rendu-salle-de-bain": "/es/render-bano",
    "/render-bathroom": "/es/render-bano",
    "/render-living-room": "/es/render-salon",
    "/fr/rendu-salon": "/es/render-salon",
    "/fr/rendu-terrasse": "/es/render-terraza",
    "/render-terrace": "/es/render-terraza",
    "/render-house": "/es/render-casa",
    "/fr/rendu-maison": "/es/render-casa",
    "/fr/help/comment-creer-rendu-cuisine": "/es/help/como-crear-render-cocina",
    "/help/how-to-create-kitchen-render": "/es/help/como-crear-render-cocina",
  },
  fr: {
    "/": "/fr",
    "/es": "/fr",
    "/realestate": "/fr/realestate",
    "/es/realestate": "/fr/realestate",
    "/real-estate-photography": "/fr/real-estate-photography",
    "/es/real-estate-photography": "/fr/real-estate-photography",
    "/floorplan": "/fr/floorplan",
    "/es/floorplan": "/fr/floorplan",
    "/es/blog/como-vender-casa": "/fr",
    "/interiordesign": "/fr/interiordesign",
    "/es/interiordesign": "/fr/interiordesign",
    "/render": "/fr/render",
    "/es/render": "/fr/render",
    "/api-documentation": "/fr/api-documentation",
    "/es/api-documentation": "/fr/api-documentation",
    "/es/mls": "/fr",
    "/es/blog/renovar-casa": "/fr",
    "/blog/home-staging-examples": "/fr/blog/exemples-de-home-staging",
    "/es/blog/home-staging-ejemplos": "/fr/blog/exemples-de-home-staging",
    "/pricing": "/fr/pricing",
    "/es/pricing": "/fr/pricing",
    "/blog/how-to-sell-properties-with-ai":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/blog": "/fr/blog",
    "/es/blog": "/fr/blog",
    "/blog/sell-more-properties-with-ai":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/releases": "/fr/releases",
    "/es/releases": "/fr/releases",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
    "/home-staging-virtual": "/fr/home-staging-virtual",
    "/es/home-staging-virtual": "/fr/home-staging-virtual",
    "/legal": "/fr/legal",
    "/es/legal": "/fr/legal",
    "/privacy": "/fr/privacy",
    "/es/privacy": "/fr/privacy",
    "/about": "/fr/about",
    "/es/about": "/fr/about",
    "/help/how-to-add-3d-objects": "/fr/help/comment-ajouter-des-objets-3d",
    "/es/help/como-anadir-objetos-3d": "/fr/help/comment-ajouter-des-objets-3d",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
    "/reviews-and-testimonials": "/fr/reviews-and-testimonials",
    "/es/reviews-and-testimonials": "/fr/reviews-and-testimonials",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
    "/help/how-to-renovate-houses-virtually":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/es/help/como-renovar-casas-virtualmente":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/help/how-to-furnish-virtually": "/fr/help/comment-meubler-en-virtuel",
    "/es/help/como-amueblar-virtualmente":
      "/fr/help/comment-meubler-en-virtuel",
    "/help/how-to-remove-object": "/fr/help/comment-supprimer-un-objet",
    "/es/help/como-borrar-objeto": "/fr/help/comment-supprimer-un-objet",
    "/blog/multifunctional-spaces": "/fr/blog/espaces-multifonctionnels",
    "/es/blog/espacios-multifuncionales": "/fr/blog/espaces-multifonctionnels",
    "/blog/increase-home-value": "/fr/blog/augmentez-valeur-maison",
    "/es/blog/aumenta-valor-vivienda": "/fr/blog/augmentez-valeur-maison",
    "/blog/real-estate-marketing": "/fr/blog/marketing-immobilier",
    "/es/blog/marketing-inmobiliario": "/fr/blog/marketing-immobilier",
    "/free-tools/generate-real-estate-listing-description-free":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/blog/real-estate-market-trends": "/fr/blog/tendances-marche-immobilier",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/fr/blog/tendances-marche-immobilier",
    "/releases/realistic-furnishing":
      "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
    "/es/releases/amueblado-realista":
      "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
    "/blog/real-estate-advertising": "/fr/blog/publicite-immobiliere",
    "/es/blog/publicidad-inmobiliaria": "/fr/blog/publicite-immobiliere",
    "/help/how-to-empty-rooms-virtually":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/blog/real-estate-pricing": "/fr/blog/pricing-immobilier",
    "/es/blog/pricing-inmobiliario": "/fr/blog/pricing-immobilier",
    "/blog/real-estate-mentor": "/fr/blog/mentor-immobilier",
    "/es/blog/mentor-inmobiliario": "/fr/blog/mentor-immobilier",
    "/blog/how-to-personalize-room": "/fr/blog/comment-personnaliser-chambre",
    "/es/blog/como-personalizar-habitacion":
      "/fr/blog/comment-personnaliser-chambre",
    "/help/how-to-create-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/es/help/como-crear-home-staging-virtual":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/blog/sunken-living-rooms": "/fr/blog/salons-en-contrebas",
    "/es/blog/salas-hundidas": "/fr/blog/salons-en-contrebas",
    "/blog/home-automation": "/fr/blog/domotiser-maison",
    "/es/blog/domotizar-casa": "/fr/blog/domotiser-maison",
    "/blog/property-that-wont-sell": "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/es/blog/propiedad-no-se-vende": "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/fr/versions/renovez-et-meublez-en-un-clic",
    "/releases/renovate-and-furnish-in-one-click":
      "/fr/versions/renovez-et-meublez-en-un-clic",
    "/blog/flipping": "/fr/blog/flipping",
    "/es/blog/flipping": "/fr/blog/flipping",
    "/es/blog/modernizar-casa": "/fr/blog/moderniser-maison",
    "/blog/modernize-home": "/fr/blog/moderniser-maison",
    "/es/blog/diseñador-ambientes": "/fr/blog/designer-environnement",
    "/blog/environmental-designer": "/fr/blog/designer-environnement",
    "/es/home-staging-cocina": "/fr/home-staging-cuisine",
    "/home-staging-kitchen": "/fr/home-staging-cuisine",
    "/es/home-staging-salon": "/fr/home-staging-salon",
    "/home-staging-living-room": "/fr/home-staging-salon",
    "/es/blog/short-sale": "/fr/blog/short-sale",
    "/blog/short-sale": "/fr/blog/short-sale",
    "/blog/mistakes-when-selling-house": "/fr/blog/erreurs-vente-maison",
    "/es/blog/errores-al-vender-casa": "/fr/blog/erreurs-vente-maison",
    "/home-staging-terrace": "/fr/home-staging-terrasse",
    "/es/home-staging-terraza": "/fr/home-staging-terrasse",
    "/es/home-staging-dormitorio": "/fr/home-staging-chambre",
    "/home-staging-bedroom": "/fr/home-staging-chambre",
    "/home-staging-bathroom": "/fr/home-staging-salle-de-bain",
    "/es/home-staging-bano": "/fr/home-staging-salle-de-bain",
    "/blog/real-estate": "/fr/blog/immobilier",
    "/es/blog/bienes-raices": "/fr/blog/immobilier",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/blog/real-estate-phrases": "/fr/blog/phrases-immobilieres",
    "/es/blog/frases-inmobiliarias": "/fr/blog/phrases-immobilieres",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/es/blog/fideicomiso-inmobiliario": "/fr/blog/fiducie-immobiliere",
    "/blog/real-estate-trust": "/fr/blog/fiducie-immobiliere",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/fr/outils-gratuits/convertisseur-format-image",
    "/free-tools/free-image-format-converter":
      "/fr/outils-gratuits/convertisseur-format-image",
    "/es/blog/terrenos-urbanizables": "/fr/blog/terrains-constructibles",
    "/blog/buildable-land": "/fr/blog/terrains-constructibles",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/releases/pedra-improvements-pools-and-preservation":
      "/fr/releases/ameliorations-pedra-piscines-et-preservation",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/fr/releases/ameliorations-pedra-piscines-et-preservation",
    "/es/renders-para-reformas": "/fr/rendus-de-renovation",
    "/renders-for-renovations": "/fr/rendus-de-renovation",
    "/es/blog/como-vender-casa-a-reformar":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/blog/how-to-sell-a-house-to-renovate":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/render-kitchen": "/fr/rendu-cuisine",
    "/es/render-cocina": "/fr/rendu-cuisine",
    "/render-bedroom": "/fr/rendu-chambre",
    "/es/render-dormitorio": "/fr/rendu-chambre",
    "/es/render-bano": "/fr/rendu-salle-de-bain",
    "/render-bathroom": "/fr/rendu-salle-de-bain",
    "/render-living-room": "/fr/rendu-salon",
    "/es/render-salon": "/fr/rendu-salon",
    "/es/render-terraza": "/fr/rendu-terrasse",
    "/render-terrace": "/fr/rendu-terrasse",
    "/render-house": "/fr/rendu-maison",
    "/es/render-casa": "/fr/rendu-maison",
    "/es/help/como-crear-render-cocina": "/fr/help/comment-creer-rendu-cuisine",
    "/help/how-to-create-kitchen-render":
      "/fr/help/comment-creer-rendu-cuisine",
  },
};

const LanguageSwitcher = ({ setLocale, setLocalePath }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const getLanguageFromPath = (path) => {
    const langMatch = path.match(/^\/([a-z]{2})\/?$/);
    if (langMatch) return langMatch[1];
    const generalMatch = path.match(/^\/([a-z]{2})\//);
    return generalMatch ? generalMatch[1] : "en";
  };

  const getNewPath = (targetLang) => {
    const currentLang = getLanguageFromPath(currentPath);

    // Special handling for root language paths (/fr, /es)
    if (currentPath.match(/^\/[a-z]{2}\/?$/)) {
      if (targetLang === "en") {
        return "/";
      }
      return `/${targetLang}`;
    }

    if (currentLang === targetLang) return currentPath;

    if (urlMappings[targetLang][currentPath]) {
      return urlMappings[targetLang][currentPath];
    }

    if (currentPath.startsWith(`/${currentLang}/`)) {
      if (targetLang === "en") {
        return currentPath.replace(/^\/[a-z]{2}\//, "/");
      } else {
        return currentPath.replace(/^\/[a-z]{2}\//, `/${targetLang}/`);
      }
    }

    if (currentLang === "en" && !currentPath.startsWith("/en/")) {
      return targetLang === "en" ? currentPath : `/${targetLang}${currentPath}`;
    }

    let englishPath = currentPath;
    if (currentLang !== "en") {
      englishPath =
        Object.entries(urlMappings.en).find(
          ([key, value]) => value === currentPath
        )?.[0] || currentPath.replace(/^\/[a-z]{2}\//, "/");
    }

    if (targetLang !== "en") {
      return (
        urlMappings[targetLang][englishPath] || `/${targetLang}${englishPath}`
      );
    }

    return englishPath;
  };

  const handleLanguageChange = (lang) => {
    const newPath = getNewPath(lang);
    console.log(`Switching from ${currentPath} to ${newPath}`);
    setLocale(lang);
    setLocalePath(lang === "en" ? "/" : `/${lang}`);
    window.location.href = newPath;
  };

  const getHreflang = (lang) => {
    switch (lang) {
      case "en":
        return "en-US";
      case "es":
        return "es-ES";
      case "fr":
        return "fr-FR";
      default:
        return lang;
    }
  };

  const pagesWithoutAlternates = [
    "/es/mls",
    "/es/blog/renovar-casa",
    "/es/blog/como-vender-casa",
  ];

  return (
    <div>
      {["en", "es", "fr"].map((lang) => {
        const newPath = getNewPath(lang);
        const shouldRenderAlternate =
          !pagesWithoutAlternates.includes(currentPath);

        return (
          <Link
            key={lang}
            to={newPath}
            style={{ padding: "0" }}
            onClick={() => handleLanguageChange(lang)}
            hrefLang={shouldRenderAlternate ? getHreflang(lang) : undefined}
            rel={shouldRenderAlternate ? "alternate" : undefined}
          >
            <div className="product-header-submenu-page-container">
              <div className="product-header-title-subtitle-container">
                <div className="product-header-title">
                  {lang === "en"
                    ? "English"
                    : lang === "es"
                    ? "Español"
                    : "Français"}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default LanguageSwitcher;
