import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToFurnishFrame1 from "../Images/HowToFurnishFrame1.jpg";
import HowToFurnishFrame2 from "../Images/HowToFurnishFrame2.jpg";
import HowToFurnishFrame3 from "../Images/HowToFurnishFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToFurnish() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to use the furnish tool to furnish virtually
          </h1>

          <div className="title-secondary gray">
            Tutorial on how to virtually furnish houses and rooms
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What is Pedra's "Furnish" tool for?
            </h2>
            <p className="article-text">
              With Pedra's furnishing tools, you can add furniture to the rooms
              of your property.
              <p style={{ marginTop: "10px" }}>
                Showing furnished rooms can help speed up the sale, as buyers
                will better understand the layout of the spaces when they see
                the photos on major real estate portals.
              </p>
              <p style={{ marginTop: "10px" }}>
                Additionally, the furniture added by Pedra looks clean, new, and
                tidy, which leads to positive emotions when viewing the photos.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I virtually furnish a room with Pedra?
            </h2>
            <p className="article-text">
              Once you have uploaded the images you want to furnish, select one
              of them.
              <p style={{ marginTop: "10px" }}>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, and
                you will see the option to{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame1}
                alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
              />
            </p>
            <p className="article-text">
              Below, we show you the steps to follow to furnish a space with
              Pedra.
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Selection of the area to be virtually furnished
            </h3>
            <p className="article-text">
              The first step determines where the furniture will appear. The
              selection can be either automatic or drawn. If it is automatic,
              Pedra will decide where to place the furniture.
              <p style={{ marginTop: "10px" }}>
                {" "}
                If you need more precision when placing the furniture, select{" "}
                <span style={{ fontWeight: "500" }}>"Draw"</span>.
              </p>
              <p style={{ marginTop: "10px" }}>
                With the <span style={{ fontWeight: "500" }}>"Draw"</span>{" "}
                option, you can paint the area where the furniture will appear.
                Just paint a uniform and large area where you want the furniture
                to appear.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame2}
                alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
              />
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Type of room to be furnished
            </h3>
            <p className="article-text">
              In this second step, you need to select the type of room. If you
              select 'Bedroom', it will place a bed, a nightstand, a lamp, etc.
              If you select 'Living Room', it will place sofas, coffee tables, a
              TV, etc.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Virtual furnishing style
            </h3>
            <p className="article-text">
              You can also change the type of 'Furnish' style. You can choose
              between 'Minimalist', 'Mediterranean', 'Traditional', and more
              options.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Number of images to generate
            </h2>
            <p>
              You can also choose the number of proposals to generate. Each
              furnishing is unique, so we recommend selecting to generate 2 or 3
              proposals. This way, you can choose the proposal that suits you
              best.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToFurnishFrame3}
              alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
            />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  How to get the best virtual furnishings
                </h4>
                <p className="callout-p">
                  Follow these tips to get the best results:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        If you do not get good results with the 'Auto' furniture
                        positioning option, try 'Draw'.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        To control the placement of the furniture, choose the
                        'Draw' option in step number 1
                      </span>
                      . This way, you can indicate where the furniture appears
                      and what space it occupies.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        If you choose 'Draw', do not paint a very small area to
                        avoid furniture with too small dimensions.
                      </span>{" "}
                      Paint a uniform and large area.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        If you want to control which furniture to add, its
                        orientation, and its size, we recommend the{" "}
                        <Link
                          className="article-link"
                          to="/help/how-to-add-3d-objects"
                        >
                          'Add object'
                        </Link>
                      </span>
                      . This other Pedra functionality allows you to choose the
                      furniture to place, its orientation, and size. You can see
                      how it works{" "}
                      <Link
                        className="article-link"
                        to="/help/how-to-add-3d-objects"
                      >
                        here
                      </Link>
                      .
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToFurnish;
