import React from "react";
import ReleaseOct2024Frame1 from "../Images/ReleaseOct2024Frame1.png";
import ReleaseOct2024Frame2 from "../Images/ReleaseOct2024Frame2.png";
import ReleaseOct2024Frame3 from "../Images/ReleaseOct2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseFrOct24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">OCTOBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra : Améliorations des piscines, préservation des espaces et
            rénovation sélective
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Ce mois d'octobre, nous vous apportons des améliorations pour
              tirer le meilleur parti de Pedra :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Ajoutez de l'eau aux piscines
              </h2>
              Ne montrez pas des piscines vides – cela n'aide pas à vendre.
              Maintenant avec Pedra, vous pourrez remplir les piscines pour
              montrer votre propriété sous son meilleur jour.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame1}
                  alt="Exemple de piscine avec de l'eau"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Meilleure préservation des sols et des murs lors du vidage des
                espaces
              </h2>
              Auparavant, Pedra modifiait parfois les matériaux des sols et des
              murs. Nous avons apporté des modifications pour que cela ne se
              produise plus.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame2}
                  alt="Exemple de préservation des sols et des murs"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Préservez des parties spécifiques d'une image lors de la
                rénovation
              </h2>
              Vous pouvez maintenant indiquer à Pedra quelles parties exactes
              vous souhaitez préserver. Idéal pour conserver l'état des
              bâtiments adjacents à votre propriété.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame3}
                  alt="Exemple de préservation sélective"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>
            <div>
              <li>
                Meilleurs résultats lors de la suppression de petits objets
              </li>
              <li>
                Meilleure préservation de l'architecture des espaces lors de la
                rénovation avec une créativité basse
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseFrOct24;
