import React from "react";
import ReleaseOct2024Frame1 from "../Images/ReleaseOct2024Frame1.png";
import ReleaseOct2024Frame2 from "../Images/ReleaseOct2024Frame2.png";
import ReleaseOct2024Frame3 from "../Images/ReleaseOct2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseOct24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">OCTOBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Pool improvements, space preservation, and selective
            renovation
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              This October, we bring you improvements to get even more value
              from Pedra:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Add water to pools</h2>
              Don't show empty pools – that doesn't help sell. Now with Pedra,
              you can fill pools to show your property in its best state.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame1}
                  alt="Example of pool with water"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Better preservation of floors and walls when emptying spaces
              </h2>
              Previously, Pedra sometimes modified the materials of floors and
              walls. We've made some changes so that this no longer happens.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame2}
                  alt="Example of floor and wall preservation"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Preserve specific parts of an image when renovating
              </h2>
              Now you can tell Pedra exactly which parts you want to preserve.
              Ideal for maintaining the state of buildings adjacent to your
              property.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame3}
                  alt="Example of selective preservation"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>
            <div>
              <li>Better results when removing small objects</li>
              <li>
                Better preservation of space architecture when renovating with
                low creativity
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseOct24;
