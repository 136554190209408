import React from "react";
import ReleaseJul2024Frame1 from "../Images/ReleaseJul2024Frame1.png";
import ReleaseJul2024Frame2 from "../Images/ReleaseJul2024Frame2.png";
import ReleaseJul2024Frame3 from "../Images/ReleaseJul2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsJul24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">JULIO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Añade objetos 3D, cielo azul en 1 click, y embellece o ilumna
            automaticamente
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Portada ilustrativa sobre la IA y el sector inmobiliario"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Te dejo aquí los lanzamientos de este julio – espero que te animes
              a probarlos en Pedra!
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Añade objetos 3D a tus fotografías
              </h2>
              Ya puedes escoger los muebles que añadir a tus fotografías. Con la
              opción de Añadir objeto podrás escoger su tamaño, orientación y
              distribución. Puedes ver cómo{" "}
              <Link
                to="/es/help/como-anadir-objetos-3d"
                className="article-link"
              >
                cómo usar añadir objetos 3D aquí
              </Link>
              .
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame1}
                  alt="Renueva espacios con IA desde el móvil"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Despeja el cielo de tus fotos en 1 click
              </h2>
              Todos queremos que en nuestras fotografías se vea el cielo
              despejado y precioso. Ahora podrás poner el cielo azul en tus
              fotografías en 1 solo click y ~30 segundos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame2}
                  alt="Mueve y edita la transparencia de la marca de agua"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Hemos unido la opción de Embellecer y Iluminar
              </h2>
              Haciendo click en Embellecer, Pedra detectará si las fotografías
              tienen baja luminosidad y automaticamente mejorará la luz, color y
              contraste.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJul2024Frame3}
                alt="Cambia el pie de foto de una foto IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>

            <div>
              <li>
                Más calidad a la hora de preservar ventanas en la "Renovación
                avanzada"
              </li>
              <li>
                Reducción de los errores en "Renovación avanzada" con preservar
                ventanas
              </li>
              <li>
                Experiencia más intuitiva a la hora de pintar sobre imágenes
              </li>
              <li>Opción de descargar facturas desde ajustes</li>
              <li>Mejoras de usabilidad y arreglos varios</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsJul24;
