import React from "react";

const BrushIconPricing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.3324 12.52L15.08 17H7.8L8.1724 14.76H7.0524L6.68 17H5L5.7476 12.52H14.3352H14.3324ZM10.88 9.72V3H9.2V9.72H5V11.4H15.08V9.72H10.88Z"
        fill="#A37EE3"
      ></path>
    </svg>
  );
};

export default BrushIconPricing;
