import React from "react";
import Article5Frame from "../Images/Article5Frame.jpeg";
import Article5Frame1 from "../Images/Article5Frame1.jpeg";
import Article5Frame2 from "../Images/Article5Frame2.jpeg";
import Article5Frame3 from "../Images/Article5Frame3.jpeg";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogEsArticle5() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Cómo Asegurar que la Foto de Portada de tu Anuncio Inmobiliario
              Acelere la Venta
            </h1>
            <div className="title-secondary gray">
              Escoge y genera fotos de portada en tus anuncios inmobiliarios que
              capten la atención y te ayuden a vender
            </div>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador de Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador de Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article5Frame}
                alt="Portada del artículo sobre cómo cómo puede la IA ayudar a los agentes inmobiliarios a vender"
              />
            </div>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Hoy en día, las fotos son clave para vender inmuebles. Las
                personas miran pisos a través de portales inmobiliarios
                digitales desde sus móviles, ya sea en el tren camino al trabajo
                o cuando se sientan en el sofá al final del día.
              </div>
              <div style={{ marginTop: "10px" }}>
                Las personas deslizan sus dedos por el móvil, como si fuera
                Instagram o Twitter, buscando algo que les llame la atención.
              </div>
              <div style={{ marginTop: "10px" }}>
                Y hay muchas maneras de captar la atención, pero una de las más
                efectivas son las fotos.
              </div>
              <div style={{ marginTop: "10px" }}>
                Como inmobiliarios, debemos asegurarnos de que la primera foto
                que vean sea la que más sensaciones positivas y atención genere.
              </div>
              <div style={{ marginTop: "10px" }}>
                Las personas deslizan sus dedos por el móvil, como si fuera
                Instagram o Twitter, buscando algo que les llame la atención.
              </div>
              <div style={{ marginTop: "10px" }}>
                Y así, visiten el anuncio y podamos cerrar la venta rápido.
              </div>
              <div style={{ marginTop: "10px" }}>
                Para llamar la atención con la primera foto, hay que seguir 3
                simples pasos:
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 —</span>{" "}
                Identificar a qué tipo de personas vamos a atraer con esa
                propiedad
              </h2>
              <div style={{ marginTop: "10px" }}>
                ¿Es un piso a reformar? ¿Es un chalet espectacular? ¿Es un piso
                de alquiler?
              </div>
              <div style={{ marginTop: "10px" }}>
                Dependiendo del tipo de propiedad, habrá diferentes tipos de
                personas interesadas.
              </div>
              <div style={{ marginTop: "10px" }}>
                Necesitamos identificar qué personas se sentirán atraídas por
                ese piso para captar su atención.
              </div>
              <div style={{ marginTop: "10px" }}>
                Por ejemplo, si estamos vendiendo un chalet en una zona
                residencial de Madrid, seguramente familias adineradas con hijos
                estarán interesadas. Si es un piso en venta en el centro de la
                ciudad a reformar, atraerá a inversores o a personas que buscan
                empezar a vivir en pareja.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 —</span>{" "}
                Escoger la fotografía para esas personas
              </h2>
              <div style={{ marginTop: "10px" }}>
                Ahora que sabemos a quiénes les interesará nuestra propiedad,
                tenemos que encontrar las fotografías que más los atraigan.
              </div>
              <div style={{ marginTop: "10px" }}>
                Si estamos intentando vender un chalet perfecto para familias,
                destaquemos las fotos que muestren los mejores aspectos del
                chalet. ¿Tiene piscina y jardín? Coloquemos una foto donde se
                vean claramente. ¿Tiene un salón espectacular perfecto para
                tardes en familia? Pongamos eso como primera foto.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 —</span>{" "}
                Mejorar y mostrar el potencial de la fotografía
              </h2>
              <div style={{ marginTop: "10px" }}>
                Conseguir la fotografía no es el último paso; hay que asegurarse
                de que muestra el máximo potencial para quien la vea.
              </div>
              <div style={{ marginTop: "10px" }}>
                Recuerda, las personas escanean los portales, y debemos captar
                suficiente atención para que hagan clic en el anuncio.
              </div>
              <div style={{ marginTop: "10px" }}>
                Si tenemos la foto de nuestro chalet en venta para familias,
                pero la tomamos cuando el césped no estaba bien y el clima era
                malo, aseguremos de usar Pedra para{" "}
                <Link to="/es/real-estate-photography" className="article-link">
                  poner el césped verde y el cielo despejado
                </Link>
                .
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame1}
                  alt="Antes y después de un cielo azul y césped verde con IA"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                Si estamos vendiendo un piso para reformar, mostremos la
                propuesta de renovación en la primera imagen. Hoy en día, puedes{" "}
                <Link to="/es/render" className="article-link">
                  reformar propiedades con IA{" "}
                </Link>{" "}
                (inteligencia artificial) de manera rápida y sencilla, sin
                moverte de tu ordenador o móvil. Aprovéchalo.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame2}
                  alt="Antes y después de una renovación con IA"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                Y si tienes un piso en alquiler sin muebles, muestra cómo
                quedaría amueblado. Eso dará una visión más atractiva del piso,
                y los potenciales inquilinos tendrán más ganas de ver tu
                anuncio. Y lo mejor es que puedes{" "}
                <Link to="/es/home-staging-virtual" className="article-link">
                  amueblar pisos de manera digital usando la IA
                </Link>{" "}
                de Pedra.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame3}
                  alt="Antes y después de un amueblamiento virtual con IA"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hola! Soy Felix Ingla, el fundador de{" "}
              <Link className="article-link" to="/es">
                Pedra
              </Link>
              , una innovadora aplicación web de{" "}
              <Link to="/es/home-staging-virtual" className="article-link">
                home staging
              </Link>{" "}
              virtual y fotografía inmobiliaria que está transformando la forma
              en que se presentan las propiedades en el mundo digital. Mi
              dedicación a la creación de tecnología y soluciones intuitivas ha
              establecido a Pedra como una herramienta referente para
              profesionales inmobiliarios que buscan mejorar sus anuncios
              inmobiliarios.
            </p>
            <p>
              Si quieres conectar y estás interesado en discutir sobre
              tecnología inmobiliaria, por favor contáctame a través de mi{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . También puedes leer más{" "}
              <Link className="article-link" to="/es/about">
                sobre Pedra aquí
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogEsArticle5;
