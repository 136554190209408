import React from "react";
import ReleaseJun2024Frame1 from "../Images/ReleaseJun2024Frame1.png";
import ReleaseJun2024Frame2 from "../Images/ReleaseJun2024Frame2.png";
import ReleaseJun2024Frame3 from "../Images/ReleaseJun2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJun24Fr() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">JUIN 2024</div>
          <h1 className="article-titleh1">
            Pedra : Rénovez les espaces depuis votre mobile, modifiez les
            légendes et changez les filigranes
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Couverture illustrative sur l'IA et le secteur immobilier"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Vous pouvez maintenant utiliser Pedra depuis votre mobile. Voici
              cette version et les progrès que nous avons réalisés ce mois de
              juin :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Pedra pour mobile</h2>
              Vous pouvez maintenant utiliser Pedra depuis votre navigateur
              mobile. Idéal pour impressionner les clients lors des visites,
              créer une{" "}
              <Link to="/fr/render" className="article-link">
                proposition de rénovation rapide
              </Link>{" "}
              ou éditer des photos lorsque vous êtes loin de votre ordinateur.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame1}
                  alt="Rénover les espaces avec l'IA depuis un mobile"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Déplacer le filigrane et modifier sa transparence
              </h2>
              Vous pouvez maintenant modifier le niveau de transparence des
              filigranes, et vous pouvez également les placer où vous voulez
              dans votre image. Protégez votre marque sans obstruer vos photos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame2}
                  alt="Déplacer et modifier la transparence du filigrane"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Modifier le texte de la légende
              </h2>
              Assurez-vous que les personnes qui regardent vos infographies
              savent qu'elles ne sont pas réelles. Vous pouvez maintenant
              modifier le texte de la légende et sa taille.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJun2024Frame3}
                alt="Modifier la légende d'une photographie IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>

            <div>
              <li>
                Augmentation de la qualité des meubles insérés dans "Meubler"
              </li>
              <li>Option pour "Meubler" une entrée</li>
              <li>
                Nouveau design de la page d'accueil : voir quelle est la
                première image dans chaque projet sans avoir à l'ouvrir
              </li>
              <li>
                Nous avons ajouté un tutoriel de 2 minutes pour que vous sachiez
                comment utiliser Pedra, et vous pouvez le regarder{" "}
                <a
                  className="article-link"
                  href="https://www.youtube.com/watch?v=z6O8EX2fJPI"
                  rel="nofollow"
                >
                  ici
                </a>{" "}
              </li>{" "}
              <li>
                Option pour choisir le grès cérame lors de l'"Édition du sol"
              </li>
              <li>Diverses corrections</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJun24Fr;
