// RealEstateAgentsEs.js
import React from "react";
import Frame1RealEstateAgents from "./Images/Frame 1 RealEstateAgents.png";
import { ReactComponent as ChairIcon } from "./Images/Chair icon.svg";
import { ReactComponent as CleanIcon } from "./Images/Clean icon.svg";
import { ReactComponent as BrandIcon } from "./Images/Brand icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import Frame2RealEstateAgents from "./Images/Frame 2 RealEstateAgents.jpeg";
import Frame3RealEstateAgents from "./Images/Frame 3 RealEstateAgents.png";
import Frame4RealEstateAgents from "./Images/Frame 4 RealEstateAgents.png";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RealEstateAgentsEs() {
  return (
    <div className="body-real-estate">
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Acelera las ventas de propiedades inmobiliarias
              </h1>
              <div className="section-first-text">
                Atrae compradores, reserva más visitas y vende más rápido. Pedra
                te ayuda a mostrar el potencial de cada propiedad con un solo
                clic.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Prueba Pedra <ArrowIcon />
                </button>
              </div>
            </div>
            <div className="header-picture">
              <img
                src={Frame1RealEstateAgents}
                alt="Tool For Real Estate Agents – Software Screenshot"
              ></img>
            </div>
          </section>
        </section>

        <div style={{ borderTop: "1px solid #EEEEEE", height: "20px" }}></div>

        <div className="section-one-column-left">
          <div
            className="title-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignText: "left",
            }}
          >
            <div className="">
              Una herramienta de marketing inmobiliario como ninguna otra.
            </div>
            <div className="title-secondary">
              Pedra hace que sea simple para cualquier empresa inmobiliaria
              mejorar sus listados de propiedades.
            </div>
            <div className="title-secondary">
              No se requieren habilidades especiales, ¡solo carga tus imágenes y
              comienza!
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <RenovateIcon />
              <div className="feature-title">Renueva con IA</div>
              <div className="feature-text">
                Genera renders de IA en un clic. Los compradores quieren ver
                propuestas de renovación antes de realizar una compra. Pedra
                ofrece renders baratos, rápidos y de alta calidad para mostrar a
                tus clientes ideas de renovación.
              </div>
            </div>
            <img
              src={FrameRenovateRealEstateAgents}
              alt="Herramientas de Renders para Real Estate"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="title-container">Simple pero poderoso</div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <ChairIcon />
              <div className="feature-title">Home staging</div>
              <div className="feature-text">
                Vende un sueño. Agrega muebles a habitaciones vacías con un
                clic. Los clientes luchan por entender las limitaciones de
                espacio.
              </div>
            </div>
            <img
              src={Frame2RealEstateAgents}
              alt="Herramientas de Virtual Home Staging para Real Estate"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-small">
                <div className="feature-text-container">
                  <CleanIcon />
                  <div className="feature-title">Manténlo limpio</div>
                  <div className="feature-text">
                    Usa nuestras herramientas de IA para eliminar partes de
                    imágenes.
                  </div>
                </div>
                <img
                  src={Frame4RealEstateAgents}
                  alt="Herramientas para quitar objetos de una foto Real Estate"
                />
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-small">
                <div className="feature-text-container">
                  <BrandIcon />
                  <div className="feature-title">Protege tu marca</div>
                  <div className="feature-text">
                    Usa nuestro lienzo para agregar marcas de agua a imágenes.
                  </div>
                </div>
                <img
                  src={Frame3RealEstateAgents}
                  alt="Herramientas para agregar marcas de agua"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "¡Usar Pedra para editar nuestras imágenes nos está ayudando a
              obtener más visitas!"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logo de Fincas Lluria para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">Fincas Llúria</div>
              </div>
            </div>
          </div>
        </div>

        <div className="mid-break"> </div>

        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Prueba Pedra
          </button>
        </div>
      </div>
    </div>
  );
}

export default RealEstateAgentsEs;
