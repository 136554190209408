import React from "react";

const OrangeBlueSky = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 7.5C5.39543 7.5 4.5 8.39543 4.5 9.5V22C4.5 23.1046 5.39543 24 6.5 24H23.5C24.6046 24 25.5 23.1046 25.5 22V10.5V9.5C25.5 8.39543 24.6046 7.5 23.5 7.5H22.5C24.795 7.5 25.5 8.205 25.5 10.5C25.5 12.795 24.795 14.6538 22.5 14.6538C20.205 14.6538 18.3462 12.795 18.3462 10.5C18.3462 9.32037 18.8373 8.25597 19.6263 7.5H6.5ZM21.8077 16.0385V19.5H23.1923V16.0385H21.8077ZM13.5 9.80769H16.9615V11.1923H13.5V9.80769ZM15.6462 16.3742L16.6258 17.3538L19.0731 14.9065L18.0935 13.9269L15.6462 16.3742Z"
        fill="#F8792A"
      />
      <rect x="5" y="8" width="20" height="15.5" rx="1.5" stroke="#F8792A" />
    </svg>
  );
};

export default OrangeBlueSky;
