import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToRemoveObjectFrame1Es from "../Images/HowToRemoveObjectFrame1Es.jpg";
import HowToRemoveObjectFrame2 from "../Images/HowToRemoveObjectFrame2Es.jpg";
import HowToRemoveObjectFrame3 from "../Images/HowToRemoveObjectFrame3Es.jpg";

function HowToRemoveObjectEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo borrar objetos de casas y propiedades
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo borrar objetos de habitaciones, casas y otras
            fotografías de propiedades
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirven las herramientas de "Borrar objeto" de Pedra?
            </h2>
            <p className="article-text">
              Con las herramientas de borrar objeto de Pedra, podrás eliminar
              objetos, muebles y más de tus fotografías.
              <p style={{ marginTop: "10px" }}>
                Te será útil para mostrar habitaciones libres de trastos, quitar
                objetos no adecuados para anuncios inmobiliarios, o simplemente
                quitar mobiliario no muy atractivo.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo funciona borrar objeto?
            </h2>
            <p className="article-text">
              Selecciona una imagen que te interese borrar objetos. Luego haz
              click en{" "}
              <span style={{ fontWeight: "500" }}>"Editar con IA"</span>, y
              verás la opción de{" "}
              <span style={{ fontWeight: "500" }}>"Borrar objeto"</span>.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRemoveObjectFrame1Es}
                alt="Cómo borrar objetos de casas y propiedades – Captura del software Pedra"
              />
            </p>
            <p className="article-text">
              Entonces, pinta sobre la imagen. Aségurate de no dejarte partes
              sin cubrir. Si hay varios objetos o muebles que quitar,
              seleccionalos todos de una pintada.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame2}
              alt="Cómo borrar objetos de casas y propiedades – Captura del software Pedra"
            />
            <h2 className="article-subtitleh2">Ejemplo de borrar objeto</h2>
            <p className="article-text">
              Aquí un ejemplo de usar "Borrar objeto" en Pedra. En una pintada
              conseguimos eliminar todos los muebles de una habitación para que
              se vea más espaciosa.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame3}
              alt="Cómo borrar objetos de casas y propiedades – Captura del software Pedra"
            />
            <br />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Consejos para borrar objetos correctamente
                </h4>
                <p className="callout-p">
                  Sigue estos consejos para conseguir los mejores resultados:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Aségurate de pintar el objeto a borrar por completo.
                        También cubre las sombras.{" "}
                      </span>
                      De lo contrario, los resultados pueden ser malos.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si hay varios objetos o trastos a quitar, pintalos todos
                        a la vez
                      </span>
                      . Si solo pintas un objeto, quizá Pedra te lo subsitute
                      por otro.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si la opción de borrar objeto no te lo ha borrado del
                        todo, aplica "Borrar objeto" en la imagen resultante
                      </span>
                      . Todas las imágenes de Pedra son re-editables, incluso
                      las que generes dentro de Pedra
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si la pintada es precisa, puedes usar "Borrar objeto"
                        como alternativa de "Vaciar habitación"
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRemoveObjectEs;
