import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToRemoveObjectFrame1 from "../Images/HowToRemoveObjectFrame1.jpg";
import HowToRemoveObjectFrame2 from "../Images/HowToRemoveObjectFrame2Es.jpg";
import HowToRemoveObjectFrame3 from "../Images/HowToRemoveObjectFrame3Es.jpg";

function HowToRemoveObject() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Remove Objects from Houses and Properties
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to remove objects from rooms, houses, and other
            property photographs
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What are Pedra's "Remove Object" tools used for?
            </h2>
            <p className="article-text">
              With Pedra's remove object tools, you can eliminate objects,
              furniture, and more from your photographs.
              <p style={{ marginTop: "10px" }}>
                It will be useful for showing clutter-free rooms, removing
                objects unsuitable for real estate listings, or simply removing
                unattractive furniture.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How does object removal work?
            </h2>
            <p className="article-text">
              Select an image from which you want to remove objects. Then click
              on <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, and
              you'll see the{" "}
              <span style={{ fontWeight: "500" }}>"Remove Object"</span> option.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRemoveObjectFrame1}
                alt="How to remove objects from houses and properties – Screenshot of Pedra software"
              />
            </p>
            <p className="article-text">
              Then, paint over the image. Make sure you don't leave any parts
              uncovered. If there are several objects or pieces of furniture to
              remove, select them all in one go.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame2}
              alt="How to remove objects from houses and properties – Screenshot of Pedra software"
            />
            <h2 className="article-subtitleh2">Example of object removal</h2>
            <p className="article-text">
              Here's an example of using "Remove Object" in Pedra. In one go, we
              manage to remove all the furniture from a room to make it look
              more spacious.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame3}
              alt="How to remove objects from houses and properties – Screenshot of Pedra software"
            />
            <br />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tips for removing objects correctly
                </h4>
                <p className="callout-p">
                  Follow these tips to get the best results:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Make sure to paint the object to be removed completely.
                        Also cover the shadows.{" "}
                      </span>
                      Otherwise, the results may be poor.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        If there are several objects or items to remove, paint
                        them all at once
                      </span>
                      . If you only paint one object, Pedra might replace it
                      with another.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        If the remove object option hasn't removed it
                        completely, apply "Remove Object" to the resulting image
                      </span>
                      . All images in Pedra are re-editable, even those you
                      generate within Pedra.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        If the painting is precise, you can use "Remove Object"
                        as an alternative to "Empty Room"
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRemoveObject;
