import React from "react";

const PurpleEnhanceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.7442 12.3846C13.0308 12.3846 11.6375 13.3654 11.0577 14.8221V11.5106C11.3663 11.6865 11.7125 11.776 12.0615 11.776C13.5298 11.776 14.0779 10.4692 14.0779 9.75673C14.0779 9.60096 14.0721 8.82212 13.3654 8.22212C14.5452 8.12981 15.2288 7.1375 15.2288 6.21154C15.2288 6.00385 15.1971 5.79327 15.1308 5.58846C14.8538 4.73462 14.0606 4.19231 13.2096 4.19231C12.7048 4.19231 12.3442 4.37692 12.1538 4.49231C12.1913 4.33942 12.2115 4.18077 12.2115 4.01923C12.2115 2.90288 11.3087 2 10.1923 2C9.07596 2 8.17308 2.90288 8.17308 4.01923C8.17308 4.18365 8.19327 4.34231 8.23077 4.49231C8.04038 4.37404 7.67692 4.19231 7.175 4.19231C6.32404 4.19231 5.53365 4.73462 5.25385 5.58846C5.1875 5.79615 5.15577 6.00673 5.15577 6.21154C5.15577 7.14038 5.84808 8.12981 7.01923 8.22212C6.31538 8.82212 6.30673 9.60096 6.30673 9.75673C6.30673 10.4577 6.85192 11.776 8.32308 11.776C8.67212 11.776 9.02115 11.6865 9.32692 11.5106V14.8221C8.75 13.3654 7.35385 12.3846 5.64038 12.3846L5 13.025L8.975 17H11.4125L15.3875 13.025L14.7471 12.3846H14.7442ZM10.1923 8.34615C9.55481 8.34615 9.03846 7.82981 9.03846 7.19231C9.03846 6.55481 9.55481 6.03846 10.1923 6.03846C10.8298 6.03846 11.3462 6.55481 11.3462 7.19231C11.3462 7.82981 10.8298 8.34615 10.1923 8.34615Z"
        fill="#A37EE3"
      />
    </svg>
  );
};

export default PurpleEnhanceIcon;
