import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToFurnishFrame1Es from "../Images/HowToFurnishFrame1Es.jpg";
import HowToFurnishFrame2Es from "../Images/HowToFurnishFrame2Es.jpg";
import HowToFurnishFrame3Es from "../Images/HowToFurnishFrame3Es.jpg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToFurnishEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo usar la herramienta de amueblar para amueblar virtualmente
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre cómo amueblar virtualmente casas y habitaciones
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirven las herramientas de "Amueblar" de Pedra?
            </h2>
            <p className="article-text">
              Con las herramientas de amueblar en Pedra, podrás añadir muebles a
              las habitaciones de tu propiedad.
              <p style={{ marginTop: "10px" }}>
                Mostrar habitaciones amuebladas puede ayudar a acelerar la
                venta, ya que los compradores entenderán mejor la distribución
                de los espacios cuando vean las fotografías en los principales
                portales inmobiliarios.
              </p>
              <p style={{ marginTop: "10px" }}>
                Adicionalmente, el mobiliario que añade Pedra se ve limpio,
                nuevo y ordenado, lo cual conlleva a emociones positivas al ver
                las fotografías
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo amueblo virtualmente una habitación con Pedra?
            </h2>
            <p className="article-text">
              Una vez hayas subido las imágenes que te interesa amueblar,
              selecciona una de ellas.
              <p style={{ marginTop: "10px" }}>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>, y
                verás la opción de{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame1Es}
                alt="Cómo usar la herramienta de amueblar para amueblar virtualmente – Captura del software Pedra"
              />
            </p>
            <p className="article-text">
              A continuación, te mostramos los pasos a seguir para amueblar un
              espacio con Pedra.
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Selección del área a amueblar virtualmente
            </h3>
            <p className="article-text">
              El primer paso determina donde aparecerán los muebles. La
              selección puede ser o automática, o dibujada. Si es automática,
              Pedra pensará donde colocar los muebles.
              <p style={{ marginTop: "10px" }}>
                {" "}
                Si necesitas más precisión a la hora de colocar los muebles,
                selecciona <span style={{ fontWeight: "500" }}>"Dibujar"</span>.
              </p>
              <p style={{ marginTop: "10px" }}>
                Con la opción de{" "}
                <span style={{ fontWeight: "500" }}>"Dibujar"</span> puedes
                pintar el área donde aparecerán los muebles. Tan solo pinta un
                área uniforme y grande donde quieres que aparezacan los muebles.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame2Es}
                alt="Cómo usar la herramienta de amueblar para amueblar virtualmente – Captura del software Pedra"
              />
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 – </span>
              Tipo de habitación a amueblar
            </h3>
            <p className="article-text">
              En este segundo paso tienes que seleccionar cual es el tipo de
              habitación. Si seleccionas 'Dormitorio', colocará una cama, una
              mesita de noche, una lámpara, etc. Si seleccionas 'Salón',
              colocará sofás, mesas de salón, un televisor, etc.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Estilo de amueblado virtual
            </h3>
            <p className="article-text">
              También puedes cambiar el tipo de estilo de 'Amueblar'. Podrás
              escoger entre 'Minimalista', 'Mediterráneo', 'Tradicional' y más
              opciones.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 4 – </span>
              Número de imágenes a generar
            </h2>
            <p>
              También puedes escoger el número de propuestas a generar. Cada
              amueblado es único, así que recomendamos que selecciones en
              generar 2 o 3 propuestas. Así podrás quedarte con la propuesta que
              más te encaje.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToFurnishFrame3Es}
              alt="Cómo usar la herramienta de amueblar para amueblar virtualmente – Captura del software Pedra"
            />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Cómo conseguir los mejores amueblados virtuales
                </h4>
                <p className="callout-p">
                  Sigue estos consejos para conseguir los mejores resultados:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si no consigues buenos resultados con la opción de
                        posicionamiento 'Auto', prueba con 'Dibujar'.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Para controlar la colocación de los muebles, en el paso
                        número 1 escoge la opción de 'Dibujar'
                      </span>
                      . Así podrás indicar donde aparecen los muebles y qué
                      espacio ocupan.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si escoges 'Dibujar', no pintes un área demasiado
                        pequeña y así evitarás mobiliario con medidas demasiado
                        pequeñas
                      </span>
                      . Pinta un área uniforme y grande.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si quieres controlar que muebles añadir, su orientación
                        y su tamaño, recomendamos la opción de{" "}
                        <Link
                          className="article-link"
                          to="/es/help/como-anadir-objetos-3d"
                        >
                          'Añadir objeto'
                        </Link>
                      </span>
                      . Esta otra funcionalidad de Pedra permite escoger los
                      muebles que colocar, la orientación y el tamaño. Puedes
                      ver cómo funciona{" "}
                      <Link
                        className="article-link"
                        to="/es/help/como-anadir-objetos-3d"
                      >
                        aquí
                      </Link>
                      .
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToFurnishEs;
