import React from 'react';

const OrangePenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
        <path d="M15.7348 5.20276L14.6796 6.8256C13.9994 7.87082 13.4593 8.53846 12.5766 9.42114L10.0011 11.9967L8.23324 10.2288L10.8088 7.65328C11.6915 6.77059 12.3566 6.23048 13.4043 5.55034L15.0272 4.49512L15.7348 5.20276Z" fill="#F8792A"></path>
        <path d="M7.63562 11.0465L9.18594 12.5968L7.73564 15.4999H6.6254C6.03278 15.4999 5.6302 15.6674 5.21011 16.085L4.79502 16.5001L3.7348 15.4399L4.14989 15.0248C4.56998 14.6047 4.73501 14.2021 4.73501 13.6095V12.4993L7.63812 11.049L7.63562 11.0465Z" fill="#F8792A"></path>
        <path d="M7.21053 13.7733C7.21053 13.3582 6.87546 13.0231 6.46037 13.0231C6.04528 13.0231 5.71021 13.3582 5.71021 13.7733C5.71021 14.1884 6.04528 14.5235 6.46037 14.5235C6.87546 14.5235 7.21053 14.1884 7.21053 13.7733Z" fill="white"></path>
    </svg>
  );
};

export default OrangePenIcon;