import React from "react";

const OrangeBigBrushIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.2174 17.5316V18.5216H15.3913V24.7825H10.6957V18.5216H11.8696V15.6964L21.6522 13.2508V9.91294H20.087V12.2608H6V5.21729H20.087V7.56511H24V15.086L14.2174 17.5316Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangeBigBrushIcon;
