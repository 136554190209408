import React from 'react';

const ToggleOpenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.878 18.6283C15.1373 18.6283 15.3585 18.5291 15.5568 18.3309L21.3762 12.3818C21.544 12.214 21.6279 12.008 21.6279 11.764C21.6279 11.2682 21.2466 10.8716 20.7508 10.8716C20.5068 10.8716 20.2856 10.9707 20.1101 11.1385L14.878 16.508L9.64578 11.1385C9.47797 10.9784 9.25683 10.8716 9.01271 10.8716C8.50929 10.8716 8.12793 11.2682 8.12793 11.764C8.12793 12.008 8.21183 12.214 8.37964 12.3818L14.1991 18.3309C14.3974 18.5368 14.6186 18.6283 14.878 18.6283Z" fill="black"/>
    </svg>
  );
};

export default ToggleOpenIcon;