import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToEmptyRoomFrame1Es from "../Images/HowToEmptyRoomFrame1Es.jpg";
import HowToEmptyRoomFrame2Es from "../Images/HowToEmptyRoomFrame2Es.jpg";
import Example1Before from "../Images/curtain-effect-empty-room/Example1Before.jpeg";
import Example1After from "../Images/curtain-effect-empty-room/Example1After.jpeg";
import Example2Before from "../Images/curtain-effect-empty-room/Example2Before.jpeg";
import Example2After from "../Images/curtain-effect-empty-room/Example2After.jpeg";
import Example3Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example3After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import Example4Before from "../Images/curtain-effect-empty-room/Example4Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example4After.jpeg";
import CurtainEffect from "../CurtainEffect";

function HowToEmptyRoomEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo vaciar una habitación virtualmente
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo vaciar habitaciones virtualmente
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo vaciar habitaciones virtualmente?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Pedra ofrece una opción que te permitirá vaciar habitaciones en
                1 solo click.
              </p>
              <p style={{ marginTop: "10px" }}>
                Para vaciar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                dale a{" "}
                <span style={{ fontWeight: "500" }}>"Vaciar habitación"</span>.
                En 2 o 3 minutos tendrás una imagen de tu habitación vaciada,
                lista para descargar.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame1Es}
                alt="Cómo vaciar habitaciones de casas y propiedades – Captura del software Pedra"
              />
            </p>
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo puedo conseguir mejores resultados con el vaciado de
              habitación?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Si el{" "}
                <span style={{ fontWeight: "500" }}>"Vaciar habitación"</span>{" "}
                ha dejado objetos o partes por vaciar, o añadido ventanas o
                puertas adicionales, puedes usar la herramienta de{" "}
                <span style={{ fontWeight: "500" }}>"Borrar objeto"</span> sobre
                la imagen que has vaciado para acabar de eliminar todo lo que no
                deseas de la foto todo lo que no quieras.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame2Es}
                alt="Cómo vaciar habitaciones por partes – Captura del software Pedra"
              />
              <p>
                Y si quieres más calidad en la fotografía, dale a la opción de
                <span style={{ fontWeight: "500" }}>"Convertir a HD"</span>{" "}
                cuando hayas acabado de borrar objetos.
              </p>
              <p style={{ marginTop: "10px" }}>
                Para vaciar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                dale a{" "}
                <span style={{ fontWeight: "500" }}>"Vaciar habitación"</span>.
                En 2 o 3 minutos tendrás tu habitación vaciada, lista para
                descargar.
              </p>
              <aside className="callout" style={{ marginTop: "10px" }}>
                <div>
                  <InfoIcon />
                </div>
                <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                  Si no consigues vaciar las imágenes correctamente, puedes
                  enviar un email a{" "}
                  <span style={{ fontWeight: "500" }}>felix@pedra.so</span> y te
                  las devolveremos vaciadas 24h-48h. Debes estar suscrito a
                  Pedra para acceder a este servicio.
                </h4>
              </aside>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              ¿Porqué debería vaciar una propiedad virtualmente?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Mostrar habitaciones sin muebles puede ser clave a la hora de
                vender una propiedad.{" "}
              </p>
              <p style={{ marginTop: "10px" }}>
                Por una parte, el vendedor se sentirá más a gusto sabiendo que
                no estamos mostrando todas sus posesiones personales online.
                Imagina que todo el mundo pudiese ver tu habitación online – a
                eso no le gusta a nadie.
              </p>
              <p style={{ marginTop: "10px" }}>
                Por otra parte, el comprador se verá más atraido por una
                vivienda despersonalizada, y podrá realmente ver el potencial de
                la propiedad, o imaginarse mejor su futuro hogar.
              </p>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de vaciar habitación virtualmente
            </h2>
            <p className="article-text">
              Aquí te dejo varios ejemplos de habitaciones vaciadas virtualmente
              con Pedra:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de vaciar habitación virtualmente"}
              altAfter={"Después de vaciar habitación virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de vaciar habitación virtualmente"}
              altAfter={"Después de vaciar habitación virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de vaciar habitación virtualmente"}
              altAfter={"Después de vaciar habitación virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de vaciar habitación virtualmente"}
              altAfter={"Después de vaciar habitación virtualmente"}
            />

            <br />

            <h2 className="article-subtitleh2">
              Video de cómo vaciar habitación virtualmente
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/bxcZmRA05_U?si=f0M4V3kNT5JCHPAd"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            <p className="article-text">
              Aparte de la herramienta de vaciar habitación, en Pedra
              encontrarás otras opciones para trabajar tus propiedades y crear
              un buen{" "}
              <Link className="article-link" to="/es/home-staging-virtual">
                home staging virtual
              </Link>{" "}
              que te ayude a vender .
            </p>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToEmptyRoomEs;
