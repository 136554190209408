import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article18Frame1 from "../Images/Article18Frame1.jpg";
import Article18Frame2 from "../Images/Article18Frame2.jpg";
import Article18Frame3 from "../Images/Article18Frame3.jpg";
import Article18Frame4 from "../Images/Article18Frame4.jpg";

function BlogEsArticle18() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Cómo reactivar una propiedad que no se vende
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador de Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador de Pedra
                </div>
              </div>
            </div>
            <article className="article-text">
              <p>
                ¿Alguna vez has tenido alguna propiedad que no se vendía? ¿Que
                llevaba meses publicada y sencillamente no tenía llamadas?
                Seguramente fue una situación desagradable. La presión del
                propietario no ayuda. ¿Bajar el precio? Pero si ya lo rebajaste
                hace una semana…{" "}
              </p>
              <p>
                Hay varias opciones para desatascar una casa que no vende — y lo
                siguiente que te explicaremos puede ser efectivo en el caso de
                que no tengas ni llamadas desde los portales.
              </p>
              <h2 className="article-subtitleh2">
                Cambia la portada de la propiedad que no se vende
              </h2>
              <p>
                Una de las opciones más sencillas para recibir más visitas a un
                anuncio es parecer que sea nuevo. Los nuevos anuncios reciben
                más visitas ya que normalmente los compradores buscan
                propiedades por zonas, intentando ver si lo nuevo les encaja.{" "}
              </p>
              <p>
                Pero, ¿cómo hago que un anuncio parezca nuevo? Pues cambiando la
                foto de portada
              </p>
              <p>Aquí te dejo tres ejemplos de cómo hacerlo.</p>
              <h3 className="article-subtitle">
                1. Propiedad de segunda mano que no se vende
              </h3>
              <p>
                Si tienes una propiedad de segunda mano que no se vende, puedes
                crear un{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                de cómo quedaría reformada y amueblada.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame3}
                  alt="Renovar propiedad que no vende"
                />
              </div>
              O bien, simplemente re-amueblada, dejando atrás los muebles
              anticuados.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame1}
                  alt="Home staging virtual en propiedad ya amueblada de obra nueva"
                />
              </div>
              <h3 className="article-subtitle">
                2. Propiedad de obra nueva que no se vende
              </h3>
              <p>
                En caso de tener una obra nueva, dale una cara más atractiva y
                vivible con un home staging virtual. Puedes amueblar el espacio
                virtualmente para que parezca una nueva publicación.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame2}
                  alt="Home staging virtual en propiedad de obra nueva"
                />
              </div>
              <h3 className="article-subtitle">
                3. Propiedad que no se vende llena de trastos
              </h3>
              <p>
                Dale un lavado de cara a tu propiedad quitando todos los objetos
                y muebles que no ayuden que se venda. Eso hará que se vea mejor,
                ya demás, parecerá una nueva publicación.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame4}
                  alt="Vaciar espacios en propiedad que no vende"
                />
              </div>
              <h3 className="article-subtitle">Conclusión</h3>
              <p>
                A nadie le gusta tener una propiedad que no se vende, pero a
                veces se dan este tipo de situaciones. Es necesario estar
                preprado para poder sacar partido de estos momentos.
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              ¡Hola! Soy Felix Ingla, el fundador de{" "}
              <Link className="article-link" to="/es">
                Pedra
              </Link>
              , una innovadora aplicación web para home staging virtual y
              fotografía inmobiliaria que está transformando la forma en que se
              presentan las propiedades en el mundo digital.
            </p>
            <p>
              Si deseas conectar y estás interesado en discutir sobre tecnología
              inmobiliaria, por favor contáctame a través de mi{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . También puedes leer más{" "}
              <Link className="article-link" to="/es/about">
                sobre Pedra aquí
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogEsArticle18;
