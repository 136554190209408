import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageTerraceFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingTerraceEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual para terrazas
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual para espacios
            exteriores
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual para una terraza?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un home staging virtual para terrazas en
              pocos clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona "Terraza" como tipo de espacio y en unos 2 o 3
                minutos tendrás la imagen de tu home staging virtual de terraza
                lista para descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Cómo crear un home staging virtual de terraza – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para terrazas?
            </h2>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-terraza">
                home staging virtual de terraza
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube la imagen de la terraza que quieres amueblar virtualmente
              </li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menú de cómo crear un home staging virtual de terraza"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los muebles
                </span>
                . Para terrazas, recomendamos usar "Dibujar" para indicar áreas
                específicas donde colocar muebles de exterior.
              </li>
              <li>
                Selecciona <span style={{ fontWeight: "500" }}>"Terraza"</span>{" "}
                como tipo de espacio. Esto asegurará que Pedra coloque muebles
                de exterior apropiados como sillas de jardín, mesas de patio,
                sombrillas, etc.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                Para terrazas, estilos como "Mediterráneo", "Moderno" o
                "Rústico" suelen funcionar bien.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes para tener opciones
                diferentes de diseño de terraza.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para un mejor home staging virtual de terraza:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente los
                    límites de la terraza para un amueblado más preciso
                  </li>
                  <li>
                    Si la terraza tiene vistas, intenta incluirlas en la foto
                    para un resultado más atractivo
                  </li>
                  <li>
                    Usa la opción "Dibujar" para indicar áreas específicas para
                    muebles de exterior, plantas o elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Ejemplo de como crear un home staging virtual de terraza"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué hacer un home staging virtual de terraza?
            </h2>
            <p className="article-text">
              Una terraza bien presentada puede ser un gran atractivo para
              potenciales compradores. El home staging virtual de terrazas te
              permite mostrar el potencial de estos espacios exteriores, creando
              ambientes acogedores y funcionales que ayudan a los compradores a
              visualizar cómo podrían disfrutar del espacio al aire libre.
            </p>
            <p className="article-text">
              Con un buen home staging virtual de terraza podrás:
            </p>
            <ul>
              <li>
                Mostrar el potencial de espacios exteriores vacíos o poco
                atractivos
              </li>
              <li>
                Crear una extensión visual del espacio interior de la vivienda
              </li>
              <li>
                Destacar características como vistas, privacidad o versatilidad
                del espacio
              </li>
              <li>
                Ayudar a los compradores a imaginar momentos de relax o
                entretenimiento en la terraza
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual de terrazas
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de home stagings virtuales que
              pueden aplicarse a terrazas:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual para terrazas
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/jGt3mStlVZQ?si=VZQFx9fLhf6YQiX8"
                  title="Tutorial de Home Staging Virtual para Terrazas"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingTerraceEs;
