import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import ChangeSize from "../Images/HowTo3DChangeSize.jpeg";
import Move from "../Images/HowTo3DMove.jpeg";
import Rotate from "../Images/HowTo3DRotate.jpeg";

function HowTo3D() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Use the "Add Object" Tool to Create a Virtual Home Staging
          </h1>

          <div className="title-secondary gray">
            Tutorial on how to add 3D objects to a space and create a virtual
            home staging
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {typeof window !== "undefined" && (
              <iframe
                src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                  height: "500px",
                }}
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                title="AI to sell real estate properties"
              ></iframe>
            )}
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What is the "Add Object" Tool for?
            </h2>
            <div className="article-text">
              With the "Add Object" tool, you can place furniture, plants,
              objects, and more into your image.
              <br />
              <br />
              The great thing about this tool is that you can choose where to
              place the objects, their size, and orientation.
              <br />
              <br />
              Once you have placed the objects, you can click on the generate
              option. This will create a highly realistic image of your layout.
              <br />
              <br />
            </div>
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Add Objects
            </h2>
            <div className="article-text">
              Click on the object you want to add. When you click, the object
              will be automatically added to the image.
              <div style={{ marginTop: "10px" }}>
                If you want to remove the objects you have added, use the
                "Delete Objects" button to remove them from the image.
              </div>
            </div>
            <br />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 –</span> Edit
              Position, Orientation, and Size of the Objects
            </h2>
            <div className="article-text">
              Now that you have added the objects to the image, you need to
              place them in the correct position, orientation, and size.
              <div style={{ marginTop: "10px", fontWeight: "500" }}>
                {" "}
                We recommend that you place one object in the correct position,
                size, and orientation before adding another object to the image.
              </div>
            </div>
            <br />
            <h3 className="article-subtitle">Change Position of Objects</h3>
            <div className="article-text">
              Use the green, yellow, and red arrows to move the object. Remember
              that you are working in 3 dimensions, so you can move the object
              through space.
              <div style={{ marginTop: "10px" }}>
                You can also click on the yellow rectangle in the middle of the
                object to drag it through space.
              </div>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={Move}
                alt="Change position of 3D object for virtual home staging – Screenshot from Pedra software"
              />
              <br />
            </div>
            <h3 className="article-subtitle">Rotate Objects</h3>
            <div className="article-text">
              Use the green, yellow, and red spheres to rotate the object.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={Rotate}
              alt="Rotate 3D object for virtual home staging – Screenshot from Pedra software"
            />
            <h3 className="article-subtitle">Change Size of Objects</h3>

            <div className="article-text">
              Use the green, yellow, and red lines to change the size of the
              object.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={ChangeSize}
              alt="Change size 3D for virtual home staging – Screenshot from Pedra software"
            />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Generate Image
            </h2>
            <div className="article-text">
              Once you have placed the objects, click on generate image. A
              hyper-realistic representation of the space will be created.
            </div>
            <div style={{ marginTop: "10px" }}>
              If you have any questions, contact me via email at felix@pedra.so
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTo3D;
