import React from "react";

const PurpleRenovateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.0769 10.8425L13.7271 14.2011L13.0934 13.5658C12.7585 13.2299 12.6747 12.8689 12.8227 12.421L12.9985 11.8892L12.6049 11.4946L12.0745 11.6709C11.6251 11.8221 11.265 11.7353 10.9328 11.3994L10.4443 10.9096L4.36999 17L2 14.6237L8.07432 8.53339L7.02471 7.48101L9.19092 5.30908L9.05414 5.17193C8.70241 4.81927 8.36463 4.67933 7.87054 4.67933H6.46641V3H7.87054C8.86431 3 9.53707 3.27989 10.2405 3.98241L14.2826 8.03519C14.6176 8.37105 14.7014 8.73211 14.5534 9.17993L14.3775 9.71171L14.7711 10.1064L15.3015 9.93003C15.751 9.77889 16.1111 9.86565 16.4433 10.2015L17.0769 10.8369V10.8425Z"
        fill="#A37EE3"
      />
    </svg>
  );
};

export default PurpleRenovateIcon;
