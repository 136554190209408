import React from 'react';

const OrangeHDIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M27 7C27 6.44772 26.5523 6 26 6H4C3.44772 6 3 6.44772 3 7V23C3 23.5523 3.44772 24 4 24H26C26.5523 24 27 23.5523 27 23V7ZM7.18354 10.7727V19.5H9.02871V15.8949H12.7745V19.5H14.6154V10.7727H12.7745V14.3736H9.02871V10.7727H7.18354ZM16.1367 19.5H19.2304C20.1168 19.5 20.8781 19.3253 21.5145 18.9759C22.1537 18.6264 22.6438 18.125 22.9847 17.4716C23.3284 16.8182 23.5003 16.0369 23.5003 15.1278C23.5003 14.2216 23.3284 13.4432 22.9847 12.7926C22.6438 12.142 22.1566 11.6435 21.523 11.2969C20.8895 10.9474 20.1338 10.7727 19.256 10.7727H16.1367V19.5ZM19.1537 17.919H17.9818V12.3537H19.158C19.7034 12.3537 20.1622 12.4503 20.5344 12.6435C20.9066 12.8366 21.1864 13.1378 21.3739 13.5469C21.5642 13.956 21.6594 14.483 21.6594 15.1278C21.6594 15.7784 21.5642 16.3097 21.3739 16.7216C21.1864 17.1307 20.9051 17.4332 20.5301 17.6293C20.158 17.8224 19.6992 17.919 19.1537 17.919Z" fill="#F8792A"/>
    </svg>
  );
};

export default OrangeHDIcon;