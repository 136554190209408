import React from "react";

const VirtualStagingAIHeaderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M11.2621 14.4787C11.9624 14.4704 18.1124 14.4787 18.1124 14.4787L20.3889 7H21L19.6329 16.3371L21 24.1111H20.3889L18.1124 16.7778H11.2621L10.6111 24.1111H10.0028V15.9404C9.95351 15.3439 10.5618 14.4869 11.2621 14.4787Z"
        fill="#f8792a"
        stroke="#f8792a"
        strokeWidth="1.9"
      />
    </svg>
  );
};

export default VirtualStagingAIHeaderIcon;
