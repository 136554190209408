import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos";

//Icons
import RedEyeIcon from "./Icons/RedEyeIcon";
import RedHammerIcon from "./Icons/RedHammerIcon";
import RedMultipleIcon from "./Icons/RedMultipleIcon";
import RedBrushIcon from "./Icons/RedBrushIcon";
import RedDownloadIcon from "./Icons/RedDownloadIcon";
import RedPlantIcon from "./Icons/RedPlantIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import posterImageVideo from "./Images/poster-images/poster-image-video.png";
import RenderFeature1 from "./Images/RenderFeature1.png";
import RenderFeature2 from "./Images/RenderFeature2.png";
import RenderFeature3 from "./Images/RenderFeature3.png";
import RenderFeature4 from "./Images/RenderFeature4.png";
import RenderFeature5 from "./Images/RenderFeature5.png";
import RenderFeature6 from "./Images/RenderFeature6.png";
import RenderStep1 from "./Images/RenderStep1.png";
import RenderStep2 from "./Images/RenderStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RenderFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Rendus avec l'IA</h1>
            <div className="section-first-text">
              Utilisez Pedra pour générer des rendus professionnels de
              rénovation avec l'intelligence artificielle. Il suffit de
              télécharger votre image et de sélectionner une option de
              rénovation.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#EB5757",
                  border: "1px solid #EB5757",
                }}
              >
                Essayez Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5 000+ professionnels utilisent déjà Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                  title="Rendus avec ia"
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Rendus immobiliers en 1 clic</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedHammerIcon />
                <h2 className="feature-title">
                  Créez des rendus avec l'IA à partir d'une image
                </h2>
                <div className="feature-text">
                  Générez un rendu professionnel à partir d'une seule image
                </div>
              </div>
              <img
                src={RenderFeature1}
                alt="Créez des rendus avec l'IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedMultipleIcon />
                <h2 className="feature-title">
                  Générez plusieurs rendus à la fois
                </h2>
                <div className="feature-text">
                  Vous pouvez également générer plusieurs rendus à la fois en
                  les sélectionnant et en choisissant une option de rénovation
                </div>
              </div>
              <img
                src={RenderFeature2}
                alt="Rendus avec intelligence artificielle"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedEyeIcon />
                <h2 className="feature-title">
                  Sélectionnez des styles de rendu
                </h2>
                <div className="feature-text">
                  Choisissez parmi nos différents styles de rendu : minimaliste,
                  scandinave, bohème, etc.
                </div>
              </div>
              <img
                src={RenderFeature3}
                alt="Sélectionner des styles de rendu - Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedDownloadIcon />
                <h2 className="feature-title">
                  Téléchargez le rendu dans n'importe quel format
                </h2>
                <div className="feature-text">
                  Téléchargez les rendus aux formats PNG, JPG ou JPEG
                </div>
              </div>
              <img
                src={RenderFeature4}
                alt="Télécharger le rendu dans n'importe quel format - Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedPlantIcon />
                <h2 className="feature-title">
                  Ajoutez n'importe quel objet avec l'IA
                </h2>
                <div className="feature-text">
                  Ajoutez des objets spécifiques aux pièces : plantes, tables,
                  etc.
                </div>
              </div>
              <img
                src={RenderFeature5}
                alt="Ajouter n'importe quel objet avec l'IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedBrushIcon />
                <h2 className="feature-title">
                  Supprimez des objets avec l'IA
                </h2>
                <div className="feature-text">
                  Supprimez des parties d'une image avant ou après le rendu
                </div>
              </div>
              <img
                src={RenderFeature6}
                alt="Supprimer des objets avec l'IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Comment faire un rendu de rénovation avec l'IA ?
        </h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep1}
            alt="Comment télécharger un fichier – Diagramme"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>ÉTAPE 1</h5>
          <h2>Créez un nouveau projet et téléchargez 1 ou plusieurs images</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep2}
            alt="Comment créer un rendu – Diagramme"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>ÉTAPE 2</h5>
          <h2>Sélectionnez l'une des options de rénovation avec l'IA</h2>
          <div className="value-prop-text">
            La rénovation prendra 15 secondes. Vous pouvez générer plusieurs
            rénovations en même temps.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button className="button" onClick={redirectToPage}>
          Essayez Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Questions et réponses</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Qu'est-ce qu'un rendu IA ?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un rendu IA, dans le contexte du design et de la visualisation
                architecturale, fait référence à l'application de l'intelligence
                artificielle (IA) pour générer des images rendues de haute
                qualité d'espaces architecturaux. L'intelligence artificielle
                est utilisée pour améliorer et optimiser le processus de rendu,
                permettant des résultats plus réalistes et efficaces.
                <br />
                <br />
                L'IA dans le rendu permet d'obtenir des résultats plus
                rapidement qu'avec la méthode traditionnelle. Les anciennes
                méthodes impliquent des heures ou des semaines de travail car il
                faut recréer des modèles 3D des pièces et traiter les systèmes
                de rendu. Avec l'IA, cela a changé. Les rendus peuvent être
                générés à partir d'images et créer des résultats spectaculaires
                à partir de l'image originale avec de nouveaux styles appliqués.
                <br />
                <br />
                L'intelligence artificielle dans le rendu d'images de propriétés
                immobilières ne fait pas que gagner du temps, elle donne de la
                flexibilité pour imaginer des espaces pour les agents
                immobiliers, les propriétaires et les acheteurs. La technologie
                aide à visualiser le potentiel des espaces d'une manière jamais
                vue auparavant.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages d'utiliser des rendus avec l'IA ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <h4>Qualité améliorée :</h4>Les rendus avec l'IA peuvent
                produire des résultats visuellement impressionnants avec un haut
                degré de réalisme. Les algorithmes d'IA peuvent simuler les
                effets de lumière, d'ombres et de textures de manière plus
                précise que les méthodes traditionnelles.
                <br />
                <br />
                <h4>Efficacité du temps de rendu :</h4>L'intelligence
                artificielle peut accélérer le processus de rendu en optimisant
                les calculs nécessaires pour générer une image. Cela peut
                réduire considérablement les temps de rendu, ce qui est
                particulièrement bénéfique pour les projets avec des délais
                serrés.
                <br />
                <br />
                <h4>Automatisation des tâches répétitives :</h4>L'IA peut
                automatiser certaines tâches dans le processus de rendu. Cela
                libère du temps pour les artistes et les designers pour se
                concentrer sur les aspects créatifs et conceptuels.
                <br />
                <br />
                <h4>Personnalisation et adaptabilité :</h4>Les algorithmes d'IA
                peuvent apprendre des modèles et des préférences spécifiques, ce
                qui permet la personnalisation des rendus selon les besoins du
                projet ou les préférences du client. Cela peut conduire à des
                résultats plus adaptés et satisfaisants.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Comment fonctionne un rendu avec l'IA ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Capture de données et entraînement du modèle :</h4>
                <li>
                  Le processus commence par la collecte de données d'entrée, qui
                  peuvent inclure des images de référence, des modèles 3D, des
                  textures et d'autres éléments liés à la scène à rendre.
                </li>
                <li>
                  Ces données sont utilisées pour entraîner un modèle d'IA,
                  généralement un réseau neuronal, qui apprendra à mapper les
                  caractéristiques de l'entrée à la sortie souhaitée,
                  c'est-à-dire l'image rendue.
                </li>
                <br />
                <br />
                <h4>Traitement de la scène :</h4>
                <li>
                  Une fois que le modèle est entraîné, il est utilisé pour
                  traiter la scène à rendre. Cela implique la génération de
                  pixels pour chaque point de l'image finale.
                </li>
                <li>
                  Pendant ce processus, le modèle d'IA peut prendre en compte
                  une variété de facteurs, tels que l'éclairage, les matériaux,
                  les ombres, les textures et la géométrie de la scène.
                </li>
                <br />
                <br />
                <h4>Génération de l'image rendue :</h4>
                <li>
                  Le modèle d'IA génère l'image rendue pixel par pixel, en
                  utilisant les informations apprises pendant l'entraînement
                  pour prendre des décisions sur la façon de rendre chaque
                  partie de la scène.
                </li>
                <li>
                  Cela peut inclure l'application d'effets d'éclairage global,
                  d'ombrage, de réflexions, de réfractions et d'autres effets
                  visuels pour améliorer la qualité et le réalisme de l'image
                  finale.
                </li>
                <br />
                <br />
                <h4>Raffinement et post-traitement :</h4>
                <li>
                  Une fois que l'image initiale a été générée, elle peut passer
                  par un processus de raffinement et de post-traitement pour
                  améliorer davantage sa qualité.
                </li>
                <li>
                  Cela peut inclure la correction d'artefacts visuels,
                  l'application d'effets de post-traitement comme le flou ou la
                  luminosité, et d'autres techniques pour améliorer l'esthétique
                  générale de l'image.
                </li>
                <br />
                <br />
                <h4>Itération et ajustement :</h4>
                <li>
                  Selon les résultats obtenus, le processus de rendu avec l'IA
                  peut impliquer des itérations supplémentaires, où les
                  paramètres du modèle sont ajustés et les résultats affinés
                  jusqu'à atteindre le niveau souhaité de qualité et de
                  réalisme.
                </li>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">
                Essayez gratuitement les rendus avec l'IA
              </h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Après la période d'essai gratuite, le prix est de 29€ plus TVA
                par mois.
                <br />
                <br />
                Il n'y a pas d'engagement, vous pouvez donc vous désabonner
                quand vous n'en avez plus besoin.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderFr;
