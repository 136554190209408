import React from "react";

function PrivacyFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Politique de Confidentialité</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Politique de Confidentialité pour Pedra.so
              </h2>
              <div className="article-text">
                Cette Politique de Confidentialité explique comment Pedra.so
                ("nous", "notre", ou "nos") collecte, utilise, partage, et
                protège vos informations personnelles lorsque vous utilisez
                notre application. En utilisant Pedra.so, vous acceptez les
                termes décrits dans cette Politique de Confidentialité.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Informations que Nous Collectons :
              </h2>
              <div className="article-text">
                1. <strong>Informations Personnelles :</strong> Nous pouvons
                collecter des informations personnelles que vous fournissez
                directement, telles que votre nom, adresse e-mail, et
                coordonnées lorsque vous vous inscrivez pour un compte ou nous
                contactez.
                <br />
                2. <strong>Informations d'Utilisation :</strong> Nous pouvons
                collecter des informations sur vos interactions avec notre
                application, y compris les pages que vous visitez, les
                fonctionnalités que vous utilisez, et les actions que vous
                entreprenez.
                <br />
                3. <strong>Informations sur l'Appareil :</strong> Nous pouvons
                collecter des informations sur l'appareil que vous utilisez pour
                accéder à notre application, y compris le type d'appareil, le
                système d'exploitation, et les identifiants uniques de
                l'appareil.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Comment Nous Utilisons Vos Informations :
              </h2>
              <div className="article-text">
                Nous pouvons utiliser vos informations pour les finalités
                suivantes :
                <br />
                1. Pour fournir et améliorer nos services.
                <br />
                2. Pour répondre à vos demandes et requêtes.
                <br />
                3. Pour vous envoyer des mises à jour, newsletters, et documents
                promotionnels (si vous avez choisi de les recevoir).
                <br />
                4. Pour personnaliser votre expérience et fournir des contenus
                et fonctionnalités adaptés à vos intérêts.
              </div>
              <br />
              <h2 className="article-subtitleh2">Partage d'Informations :</h2>
              <div className="article-text">
                Nous pouvons partager vos informations dans les circonstances
                suivantes :
                <br />
                1. Avec des prestataires de services tiers qui nous aident dans
                l'exploitation de notre application et de nos services.
                <br />
                2. Pour respecter des obligations légales ou répondre à des
                demandes des autorités judiciaires.
                <br />
                3. Dans le cadre d'une transaction commerciale, telle qu'une
                fusion, une acquisition, ou une vente d'actifs.
                <br />
              </div>
              <br />
              <h2 className="article-subtitleh2">Sécurité des Données :</h2>
              <div className="article-text">
                Nous prenons des mesures raisonnables pour protéger vos
                informations personnelles contre l'accès non autorisé et la
                divulgation. Cependant, aucune méthode de transmission ou de
                stockage de données n'est totalement sécurisée, et nous ne
                pouvons garantir une sécurité absolue.
              </div>
              <br />
              <h2 className="article-subtitleh2">Vos Choix :</h2>
              <div className="article-text">
                Vous avez le droit d'accéder à vos informations personnelles, de
                les corriger ou de les supprimer. Vous pouvez également choisir
                de ne plus recevoir de courriels promotionnels à tout moment.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Modifications de cette Politique de Confidentialité :
              </h2>
              <div className="article-text">
                Nous pouvons mettre à jour cette Politique de Confidentialité de
                temps à autre. Toutes les modifications seront publiées sur
                cette page avec une date révisée.
              </div>
              <br />
              <h2 className="article-subtitleh2">Contactez-nous :</h2>
              <div className="article-text">
                Pour toute question ou proposition, contactez-nous à l'adresse
                e-mail suivante :{" "}
                <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyFr;
