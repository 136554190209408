import React, { useState, useRef, useEffect } from "react";

const CurtainEffect = ({ beforeImage, afterImage, altBefore, altAfter }) => {
  const [curtainPosition, setCurtainPosition] = useState(50);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const beforeImageRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const maxWidth = 1000;
      const width = Math.min(img.width, maxWidth);
      const height = width * aspectRatio;
      setDimensions({ width, height });
    };
    img.src = beforeImage;
  }, [beforeImage]);

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setCurtainPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        marginTop: "10px",
        width: "100%",
        maxWidth: "1000px",
        aspectRatio: `${dimensions.width} / ${dimensions.height}`,
        overflow: "hidden",
        cursor: "ew-resize",
        borderRadius: "6px",
      }}
      onMouseMove={handleMouseMove}
      onTouchMove={(e) => {
        const touch = e.touches[0];
        handleMouseMove(touch);
      }}
    >
      <img
        ref={beforeImageRef}
        src={beforeImage}
        alt={altBefore}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          clipPath: `inset(0 0 0 ${curtainPosition}%)`,
        }}
      >
        <img
          src={afterImage}
          alt={altAfter}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "2px",
          backgroundColor: "white",
          left: `${curtainPosition}%`,
          transform: "translateX(-50%)",
        }}
      />
    </div>
  );
};

export default CurtainEffect;
