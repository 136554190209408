import React from "react";
import ReleaseMay2024Frame1 from "../Images/ReleaseMay2024Frame1.png";
import ReleaseMay2024Frame2 from "../Images/ReleaseMay2024Frame2.png";
import ReleaseMay2024Frame3 from "../Images/ReleaseMay2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseMay24Fr() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">MAI 2024</div>
          <h1 className="article-titleh1">
            Pedra : Vider les pièces et supprimer des objets, styles de
            rénovation et nouvelle interface
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Couverture illustrative sur l'IA et le secteur immobilier"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Découvrez ici les dernières nouveautés de Pedra pour le mois de
              mai :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Amélioration du vidage des pièces et de la suppression d'objets
              </h2>
              Nous avons perfectionné notre algorithme pour vider les pièces et
              supprimer les objets, assurant des résultats beaucoup plus
              réalistes. C'est idéal pour montrer des propriétés sans objets
              superflus, permettant à vos clients d'apprécier pleinement
              l'esthétique de la propriété. Voici un exemple de comment un
              espace peut être transformé :
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame1}
                  alt="Vider les espaces avec l'IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Styles de rénovation : Nouveaux et améliorés
              </h2>
              Vous pouvez maintenant choisir de nouveaux{" "}
              <Link className="article-link" to="/fr/render">
                styles de rénovation
              </Link>{" "}
              et remarquer plus de changements. Ainsi, vous pouvez offrir plus
              de propositions de rénovation de haute qualité à vos clients en
              quelques secondes.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame2}
                  alt="Styles de rénovation IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nouvelle interface : Plus simple et plus efficace
              </h2>
              Nous avons complètement transformé notre application, laissant
              derrière nous la complexité du design précédent. Avant, les images
              s'empilaient, rendant difficile le maintien d'une perspective
              claire du projet et ralentissant le processus. Maintenant, nous
              présentons un design où chaque image est méticuleusement ordonnée
              et étiquetée, rendant l'édition de photos non seulement facile
              mais aussi intuitive.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseMay2024Frame3}
                alt="Nouvelle interface de Pedra"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>

            <div>
              <li>
                Nous avons complètement transformé notre application, laissant
                derrière nous la complexité du design précédent. Avant, les
                images s'empilaient, rendant difficile le maintien d'une
                perspective claire du projet et ralentissant le processus.
                Maintenant, nous présentons un design où chaque image est
                méticuleusement ordonnée et étiquetée, rendant l'édition de
                photos non seulement facile mais aussi intuitive.
              </li>
              <li>Ajustez le niveau de créativité dans les rénovations</li>
              <li>
                Ajoutez un filigrane à toutes vos images en une seule fois
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseMay24Fr;
