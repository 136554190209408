import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeTerraza from "./Images/curtain-effect-hero/BeforeTerraza.png";
import AfterTerraza from "./Images/curtain-effect-hero/AfterTerraza.png";
import BeforeTerrazaStaging1 from "./Images/curtain-effect/BeforeTerrazaStaging1.png";
import AfterTerrazaStaging1 from "./Images/curtain-effect/AfterTerrazaStaging1.png";
import BeforeTerrazaStaging2 from "./Images/curtain-effect/BeforeTerrazaStaging2.png";
import AfterTerrazaStaging2 from "./Images/curtain-effect/AfterTerrazaStaging2.png";
import BeforeTerrazaStaging3 from "./Images/curtain-effect/BeforeTerrazaStaging3.png";
import AfterTerrazaStaging3 from "./Images/curtain-effect/AfterTerrazaStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeStagingTerrasseFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeTerraza,
    afterImage: AfterTerraza,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeTerraza,
        afterImage: AfterTerraza,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeTerrazaStaging1,
        afterImage: AfterTerrazaStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeTerrazaStaging2,
        afterImage: AfterTerrazaStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeTerrazaStaging3,
        afterImage: AfterTerrazaStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que le home staging de terrasse ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging de terrasse est une technique de marketing immobilier qui se concentre sur la préparation et la présentation de la terrasse d'une propriété de manière attractive pour les acheteurs ou locataires potentiels. Cela peut inclure le nettoyage, l'organisation, la décoration stratégique et de petites améliorations pour mettre en valeur les points forts de la terrasse et minimiser ses défauts.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages du home staging virtuel pour les terrasses ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging virtuel pour les terrasses offre plusieurs avantages, notamment : la possibilité de montrer le potentiel de la terrasse sans effectuer de changements physiques coûteux, la capacité d'expérimenter rapidement différents styles et configurations, l'amélioration de la présentation en ligne de la propriété, et l'opportunité de se démarquer sur un marché immobilier concurrentiel.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de home staging virtuel pour les terrasses ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus de home staging virtuel pour les terrasses implique le téléchargement de photos de la terrasse actuelle sur notre plateforme, la sélection des options d'édition souhaitées (comme changer les finitions, ajouter ou supprimer des éléments, ou modifier l'agencement), puis la réception des images éditées montrant la terrasse transformée.",
        },
      },
      {
        "@type": "Question",
        name: "Combien coûte le home staging virtuel de terrasses ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le prix de notre service de home staging virtuel, qui inclut les terrasses et d'autres zones de la maison, est de 29€ plus TVA par mois. Ce plan vous permet de générer jusqu'à 100 images par mois, sans engagement.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeTerrazaStaging1,
    afterImage: AfterTerrazaStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeTerrazaStaging1,
      afterImage: AfterTerrazaStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeTerrazaStaging2,
      afterImage: AfterTerrazaStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeTerrazaStaging3,
      afterImage: AfterTerrazaStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home staging terrasse</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Le meilleur home staging virtuel de terrasses à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Transformez votre terrasse en
                    quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Concevez des terrasses attrayantes
                    sans effort
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyperréalistes pour les
                    terrasses
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance experte en conception de
                    terrasses
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant le home staging virtuel de terrasse"}
                  altAfter={"Après le home staging virtuel de terrasse"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Exemple terrasse 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Exemple terrasse 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Exemple terrasse 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Exemple terrasse 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtuel de terrasses pour{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vendre plus rapidement
          </span>{" "}
          tout type de propriété.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Rénovez votre terrasse virtuellement
                </h3>
                <div className="feature-text">
                  Transformez votre terrasse en un clic, sans travaux ni
                  dépenses
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Mettez en valeur les caractéristiques uniques
                </h3>
                <div className="feature-text">
                  Soulignez les points forts de votre terrasse avec du texte
                  descriptif
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Améliorez et corrigez les photos de terrasses
                </h3>
                <div className="feature-text">
                  Optimisez la qualité et la perspective des photos de votre
                  terrasse
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Ajoutez du mobilier d'extérieur
                </h3>
                <div className="feature-text">
                  Actualisez votre terrasse avec des meubles modernes de manière
                  virtuelle
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renouvelez les finitions</h3>
                <div className="feature-text">
                  Changez les sols, les balustrades et les finitions en un seul
                  clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Nettoyez et organisez virtuellement
                </h3>
                <div className="feature-text">
                  Éliminez le désordre et les objets indésirables pour une
                  terrasse impeccable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de notre home staging virtuel de terrasses.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Avant le home staging virtuel de terrasse"}
            altAfter={"Après le home staging virtuel de terrasse"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne le home staging virtuel de terrasses ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez des photos de votre terrasse</h2>
            <div className="value-prop-text">
              Chargez une ou plusieurs images de votre terrasse sur Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos de terrasse pour créer un home staging virtuel sur Pedra - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>
              Choisissez les options de transformation pour votre terrasse
            </h2>
            <div className="value-prop-text">
              Sélectionnez parmi plusieurs options pour rénover virtuellement
              votre terrasse.
              <p>
                Vous pouvez changer les finitions, ajouter des meubles,
                réorganiser l'espace et bien plus encore.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer un home staging virtuel de terrasse - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Recevez et téléchargez votre terrasse transformée</h2>
            <div className="value-prop-text">
              En quelques minutes, vous obtiendrez des images réalistes de votre
              terrasse rénovée.
              <p>
                Téléchargez les images et utilisez-les pour promouvoir votre
                propriété.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger un home staging virtuel de terrasse - Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtuel de terrasses pour 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Et sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclus</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer les terrasses
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changer les finitions de terrasse
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajouter du mobilier d'extérieur
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Retirer les objets indésirables
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution des photos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Changer la conception de la terrasse
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie des terrasses
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans de terrasse illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajouter des filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20€ par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions et réponses sur le home staging de terrasses
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging de terrasse ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging de terrasse est une technique de marketing
                immobilier qui se concentre sur la préparation et la
                présentation de la terrasse d'une propriété de manière
                attractive pour les acheteurs ou locataires potentiels. Cela
                peut inclure le nettoyage, l'organisation, la décoration
                stratégique et de petites améliorations pour mettre en valeur
                les points forts de la terrasse et minimiser ses défauts.
                <br />
                <br />
                Dans le cas du home staging virtuel de terrasses, nous utilisons
                une technologie avancée pour transformer numériquement l'espace,
                montrant son potentiel sans avoir besoin de réaliser des
                changements physiques coûteux.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages du home staging virtuel pour les
                terrasses ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le home staging virtuel pour les terrasses offre de nombreux
                avantages :
                <br />
                <br />
                1. Économie de coûts : Pas besoin de réaliser des rénovations
                physiques coûteuses.
                <br />
                2. Gain de temps : Les transformations se font en minutes, pas
                en semaines.
                <br />
                3. Polyvalence : Vous pouvez montrer plusieurs styles et
                configurations de la même terrasse.
                <br />
                4. Attrait visuel : Aide les acheteurs à visualiser le potentiel
                de la terrasse.
                <br />
                5. Différenciation sur le marché : Démarquez votre propriété
                avec des images de terrasses attrayantes.
                <br />
                6. Sans dérangement : Pas besoin d'interrompre la vie des
                occupants actuels.
                <br />
                7. Amélioration de la présentation en ligne : Idéal pour les
                annonces de propriétés sur internet.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Que puis-je faire avec le home staging de terrasses de Pedra ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Avec le home staging virtuel de terrasses de Pedra, vous pouvez
                réaliser une grande variété de transformations :
                <br />
                <br />
                1. Rénover les terrasses : Vous pouvez utiliser les styles
                prédéfinis de Pedra ou créer vos propres styles personnalisés
                pour transformer complètement l'aspect de la terrasse.
                <br />
                2. Améliorer la lumière et la couleur : Optimisez l'éclairage et
                les couleurs des photographies de la terrasse pour qu'elles
                paraissent plus attrayantes et professionnelles.
                <br />
                3. Supprimer des objets : Effacez les éléments indésirables des
                photos, comme les meubles anciens, le désordre ou les objets
                personnels.
                <br />
                4. Ajouter des éléments : Incorporez de nouveaux objets à la
                terrasse, comme des meubles d'extérieur modernes, des plantes ou
                des accessoires décoratifs.
                <br />
                5. Changer les finitions : Modifiez l'aspect des sols, des
                balustrades et d'autres finitions pour montrer différentes
                options de design.
                <br />
                6. Réorganiser l'espace : Visualisez différentes configurations
                de la terrasse sans avoir besoin de réaliser des changements
                physiques.
                <br />
                <br />
                Toutes ces transformations sont réalisées de manière virtuelle,
                vous permettant de montrer le potentiel de la terrasse de
                manière rapide et économique.
              </div>
            )}
          </div>

          {/* Ajoutez plus de FAQs ici */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingTerrasseFr;
