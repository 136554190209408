// Pricing.js
import React from "react";
import CheckIcon from "./Icons/CheckIcon";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple";
import EmptyIcon from "./Icons/EmptyIcon";
import HammerIconPricing from "./Icons/HammerIconPricing";
import BrushIconPricing from "./Icons/BrushIconPricing";
import HDIconPricing from "./Icons/HDIconPricing";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon";
import VideoPricingIcon from "./Icons/VideoPricingIcon";
import CustomerLogos from "./CustomerLogos.js";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function Pricing() {
  return (
    <div
      className="section-one-column-left"
      style={{ display: "flex", flexDirection: "column", gap: "0px" }}
    >
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container" style={{ gap: "0px" }}>
            <div
              className="section-first-title"
              style={{ paddingBottom: "0px" }}
            >
              Try our Pro Plan
            </div>
            <h1 className="section-first-text" style={{ fontSize: "20px" }}>
              Pay per month,
              <span style={{ color: "#F8792A" }}> cancel any time.</span>
            </h1>
            <div className="section-first-button-container"></div>
          </div>
        </section>
      </section>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 image generations
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovate and redecorate
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Empty rooms
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Furnish
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remove objects
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Increase resolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Change walls and floors
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Improve photography
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generate virtual videos
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited floor plans
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited project shares
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Add watermarks
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancel anytime.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Buy more images at 0.20€ per image if you need them.
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="section-one-column-left" style={{ marginBottom: "0px" }}>
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "You thought of everything! Pedra has everything we need!"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logo of Vives Homes for Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
