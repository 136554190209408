import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageSalonFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingLivingRoom() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Virtual Home Staging for Living Rooms
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on creating virtual home staging for living spaces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create virtual home staging for a living room?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home staging for living rooms
              in just a few clicks.
              <p>
                To begin, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Choose "Living Room" as the space type and in 2 or 3 minutes,
                you'll have your virtual home staging image of the living room
                ready to download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create virtual home staging for a living room – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for living rooms?
            </h2>
            <p className="article-text">
              To achieve your{" "}
              <Link className="article-link" to="/home-staging-living-room">
                virtual home staging for a living room
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the living room you want to virtually
                furnish
              </li>
              <li>Select the image to start editing it</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu for creating virtual home staging for a living room"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>furniture position</span>.
                For living rooms, we recommend using "Draw" to indicate specific
                areas to place the sofa, coffee table, TV unit, etc.
              </li>
              <li>
                Select <span style={{ fontWeight: "500" }}>"Living Room"</span>{" "}
                as the space type. This will ensure that Pedra places
                appropriate furniture such as sofas, armchairs, coffee tables,
                etc.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. For
                living rooms, styles like "Modern", "Contemporary" or
                "Scandinavian" usually work well.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to have different living
                room design options.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better virtual home staging of a living room:
                <ul>
                  <li>
                    Make sure the photograph clearly shows the dimensions of the
                    living room for more accurate furnishing
                  </li>
                  <li>
                    If the living room has special features like fireplaces or
                    large windows, try to include them in the photo for a more
                    attractive result
                  </li>
                  <li>
                    Use the "Draw" option to indicate specific areas for the
                    seating area, dining space, and decorative elements
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Example of creating virtual home staging for a living room"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why create virtual home staging for a living room?
            </h2>
            <p className="article-text">
              A well-presented living room can be a decisive factor for
              potential buyers. Virtual home staging for living rooms allows you
              to show the potential of these living spaces, creating welcoming
              and functional environments that help buyers visualize how they
              could enjoy family time and entertaining friends.
            </p>
            <p className="article-text">
              With good virtual home staging for a living room, you can:
            </p>
            <ul>
              <li>Show the potential of empty or unattractive spaces</li>
              <li>Create a welcoming and social atmosphere</li>
              <li>
                Highlight features such as brightness, layout or the versatility
                of the living room
              </li>
              <li>
                Help buyers imagine moments of socializing and entertainment in
                the living room
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging for living rooms
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings that can be
              applied to living rooms:
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingLivingRoom;
