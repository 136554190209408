import React from 'react';

const RedHammerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M26.3078 16.2637L21.2831 21.3017L20.3325 20.3487C19.8301 19.8449 19.7045 19.3033 19.9264 18.6315L20.1902 17.8339L19.5998 17.2419L18.8042 17.5064C18.1301 17.7331 17.5899 17.603 17.0916 17.0992L16.3588 16.3645L7.24737 25.5L3.69238 21.9356L12.8039 12.8001L11.2294 11.2215L14.4788 7.96361L14.2736 7.7579C13.746 7.22891 13.2393 7.01899 12.4982 7.01899H10.392V4.5H12.4982C13.9889 4.5 14.998 4.91983 16.0532 5.97361L22.1163 12.0528C22.6188 12.5566 22.7444 13.0982 22.5225 13.7699L22.2587 14.5676L22.8491 15.1595L23.6447 14.895C24.3188 14.6683 24.859 14.7985 25.3573 15.3023L26.3078 16.2553V16.2637Z" fill="#EB5757"/>
    </svg>
  );
};

export default RedHammerIcon;