import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageTerraceFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";
import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingTerraceFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment Créer un Home Staging Virtuel pour Terrasses
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création de home staging virtuel pour espaces
            extérieurs
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je créer un home staging virtuel pour une terrasse ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez créer un home staging virtuel pour
              terrasses en quelques clics seulement.
              <p>
                Pour commencer, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>. Choisissez
                "Terrasse" comme type d'espace, et en environ 2 ou 3 minutes,
                vous aurez votre image de home staging virtuel de la terrasse
                prête à télécharger.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Comment créer un home staging virtuel pour une terrasse – Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour terrasses ?
            </h2>
            <p className="article-text">
              Pour réaliser votre{" "}
              <Link className="article-link" to="/fr/home-staging-terrasse">
                home staging virtuel pour une terrasse
              </Link>
              , suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez l'image de la terrasse que vous souhaitez meubler
                virtuellement
              </li>
              <li>Sélectionnez l'image pour commencer l'édition</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu pour créer un home staging virtuel pour une terrasse"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Meubler"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez la{" "}
                <span style={{ fontWeight: "500" }}>position des meubles</span>.
                Pour les terrasses, nous recommandons d'utiliser "Dessiner" pour
                indiquer des zones spécifiques où placer les meubles
                d'extérieur.
              </li>
              <li>
                Sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Terrasse"</span> comme type
                d'espace. Cela garantira que Pedra place des meubles d'extérieur
                appropriés tels que des chaises de jardin, des tables de patio,
                des parasols, etc.
              </li>
              <li>
                Sélectionnez le <span style={{ fontWeight: "500" }}>style</span>
                . Pour les terrasses, des styles comme "Méditerranéen",
                "Moderne" ou "Rustique" fonctionnent généralement bien.
              </li>
              <li>
                Choisissez le{" "}
                <span style={{ fontWeight: "500" }}>
                  nombre d'images à générer
                </span>
                . Nous recommandons de choisir 2 ou 3 images pour avoir
                différentes options de design de terrasse.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour un meilleur home staging virtuel d'une terrasse :
                <ul>
                  <li>
                    Assurez-vous que la photographie montre clairement les
                    limites de la terrasse pour un ameublement plus précis
                  </li>
                  <li>
                    Si la terrasse a une vue, essayez de l'inclure dans la photo
                    pour un résultat plus attractif
                  </li>
                  <li>
                    Utilisez l'option "Dessiner" pour indiquer des zones
                    spécifiques pour les meubles d'extérieur, les plantes ou les
                    éléments décoratifs
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemple de création de home staging virtuel pour une terrasse"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi faire un home staging virtuel pour une terrasse ?
            </h2>
            <p className="article-text">
              Une terrasse bien présentée peut être une grande attraction pour
              les acheteurs potentiels. Le home staging virtuel pour terrasses
              vous permet de montrer le potentiel de ces espaces extérieurs, en
              créant des environnements confortables et fonctionnels qui aident
              les acheteurs à visualiser comment ils pourraient profiter de
              l'espace extérieur.
            </p>
            <p className="article-text">
              Avec un bon home staging virtuel pour une terrasse, vous pouvez :
            </p>
            <ul>
              <li>
                Montrer le potentiel d'espaces extérieurs vides ou peu
                attrayants
              </li>
              <li>Créer une extension visuelle de l'espace de vie intérieur</li>
              <li>
                Mettre en valeur des caractéristiques telles que la vue,
                l'intimité ou la polyvalence de l'espace
              </li>
              <li>
                Aider les acheteurs à imaginer des moments de détente ou de
                divertissement sur la terrasse
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de home staging virtuel pour terrasses
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de home staging virtuel qui peuvent être
              appliqués aux terrasses :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingTerraceFr;
