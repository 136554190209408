import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article2Frame from "./Images/Article2Frame.png";
import Article3Frame from "./Images/Article3Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import Article10Frame from "./Images/Article10Frame.png";
import Article11Frame from "./Images/Article11Frame.png";
import Article12Frame from "./Images/Article12Frame.png";
import Article13Frame from "./Images/Article13Frame.png";
import Article14Frame from "./Images/Article14Frame.png";
import Article15Frame from "./Images/Article15Frame.png";
import Article16Frame from "./Images/Article16Frame.png";
import Article17Frame from "./Images/Article17Frame.png";
import Article18Frame from "./Images/Article18Frame.png";
import Article19Frame from "./Images/Article19Frame.png";
import Article20Frame from "./Images/Article20Frame.png";
import Article21Frame from "./Images/Article21Frame.png";
import Article22Frame from "./Images/Article22Frame.png";
import Article23Frame from "./Images/Article23Frame.png";
import Article24Frame from "./Images/Article24Frame.png";
import Article25Frame from "./Images/Article25Frame.png";
import Article26Frame from "./Images/Article26Frame.png";
import Article27Frame from "./Images/Article27Frame.png";
import Article28Frame from "./Images/Article28Frame.png";
import { Link } from "react-router-dom";

function BlogEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Blog</h1>
          <h2 className="title-secondary">
            Bienvenido al blog de Pedra. <br />
            En este blog aprenderás como sacarle provecho la IA aplicada al
            mundo de inmobiliarias.
          </h2>
        </div>
      </div>
      <br />
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Noviembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-vender-casa-a-reformar"
              >
                Cómo Vender una Casa a Reformar: Guía Completa
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre estrategias clave para vender una casa a reformar.
              Aprende a destacar su potencial, fijar el precio correcto y atraer
              compradores interesados en proyectos de renovación.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article28Frame}
              alt="Cómo vender casa a reformar – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>25 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/terrenos-urbanizables"
              >
                Terrenos urbanizables: Qué son y tipos de terrenos
              </Link>
            </h2>
            <div className="value-prop-text">
              Te explicamos qué son los terrenos urbanizables, los tipos de
              terrenos urbanizables que hay y cómo prepararlos para la venta.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article27Frame}
              alt="Terreno urbanizable – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/fideicomiso-inmobiliario"
              >
                ¿Qué es un fideicomiso inmobiliario y qué tipos de hay?
              </Link>
            </h2>
            <div className="value-prop-text">
              El fideicomiso inmobiliario es un contrato legal entre un
              fiduciante que transfiere la propiedad de ciertos bienes a un
              fiduciario que se encarga de administrarlo.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article26Frame}
              alt="Fideicomiso inmobiliario – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>18 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/frases-inmobiliarias"
              >
                Frases Inmobiliarias, ejemplos y consejos para captar
                compradores
              </Link>
            </h2>
            <div className="value-prop-text">
              Frases Inmobiliarias para atraer clientes, destacar y describir
              inmuebles. Te enseñamos cómo redactar tus frases y ejemplos de las
              mejores frases inmobiliarias.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article25Frame}
              alt="Frases inmobiliarias – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/bienes-raices"
              >
                Bienes Raíces: Consejos para Comprar, Vender e Invertir
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre todo sobre los bienes raíces. Desde consejos para
              compradores primerizos hasta estrategias de inversión y tendencias
              del mercado.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article24Frame}
              alt="Bienes raíces – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>10 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/errores-al-vender-casa"
              >
                Errores Comunes al Vender una Casa y Cómo Evitarlos
              </Link>
            </h2>
            <div className="value-prop-text">
              Errores comunes al vender una casa y cómo evitarlos: consejos
              clave para lograr una venta exitosa sin perder dinero ni tiempo.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article23Frame}
              alt="Errores Comunes al Vender una Casa y Cómo Evitarlos – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/es/blog/short-sale">
                Guía sobre sobre Short Sale: Qué es y objetivos
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre qué es un short sale, sus beneficios y requisitos para
              vendedores y compradores. Ejemplos de short sale en el mercado
              inmobiliario.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article22Frame}
              alt="Short sale – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/diseñador-ambientes"
              >
                Diseñador de ambientes: Guía sobre el diseño de ambientes
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre el papel del diseñador de ambientes, sus habilidades
              clave, procesos creativos y tendencias actuales en el diseño de
              ambientes únicos.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article21Frame}
              alt="Diseñador ambientes – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/modernizar-casa"
              >
                ¿Cómo modernizar tu casa con muebles antiguos?
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre cómo puedes modernizar tu casa con los muebles que
              tengas, dale una segunda vida a tus muebles antiguos y moderniza
              tu hogar con estas ideas.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article20Frame}
              alt="Modernizar casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Octubre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/es/blog/flipping">
                Flipping inmobiliario | Qué es y beneficios del flipping
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre cómo hacer flipping inmobiliario exitoso: desde la
              investigación de mercado hasta la venta, optimiza cada paso para
              maximizar tus ganancias.
            </div>
          </div>
          <div className="picture">
            <img src={Article19Frame} alt="Flipping – Ilustración decorativa" />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>24 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/propiedad-no-se-vende"
              >
                Propiedad que no se vende
              </Link>
            </h2>
            <div className="value-prop-text">
              Una propiedad que no se vende es una situación complicada. Aquí te
              explciamos qué puedes hacer para ayudarte a vender una propiedad
              que no se vende.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article18Frame}
              alt="Propiedad que no se vende – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>20 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/domotizar-casa"
              >
                Domotizar tu casa: Qué ventajas tiene y cómo domotizarla
              </Link>
            </h2>
            <div className="value-prop-text">
              Domotizar tu casa es un proceso muy sencillo que permite una gran
              comodidad diaria, te enseñamos cómo domotizarla y qué productos
              comprar.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article17Frame}
              alt="Domotizar tu casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/salas-hundidas"
              >
                Salas hundidas: ¿Qué son y cómo diseñarlas?
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre el encanto de las salas hundidas, espacios
              arquitectónicos únicos que combinan diseño moderno con comodidad,
              creando ambientes acogedores y elegantes.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article16Frame}
              alt="Salas hundidas – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>15 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-personalizar-habitacion"
              >
                ¿Cómo personalizar mi habitación? Pasos y consejos
              </Link>
            </h2>
            <div className="value-prop-text">
              Te enseñamos cómo personalizar tu habitación, los pasos a seguir y
              consejos para personalizar tu espacio.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article15Frame}
              alt="Cómo personalizar mi habitación – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>10 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/mentor-inmobiliario"
              >
                Qué es un Mentor Inmobiliario y cómo seleccionarlo
              </Link>
            </h2>
            <div className="value-prop-text">
              Los mentores inmobiliarios son expertos en el sector y aconsejan a
              personas interesadas en el mercado inmobiliario, te enseñamos cómo
              elegir a tu mentor.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article14Frame}
              alt="Mentor inmobiliario – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>5 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/pricing-inmobiliario"
              >
                Pricing inmobiliario | Qué es y cómo fijar los precios
              </Link>
            </h2>
            <div className="value-prop-text">
              El pricing inmobiliario consiste en fijar el precio óptimo de tu
              inmueble y maximizar el valor, te enseñamos diferentes métodos de
              pricing inmobiliario.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article13Frame}
              alt="Pricing inmobiliario – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>3 de Septiembre, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/publicidad-inmobiliaria"
              >
                Publicidad Inmobiliaria ¿Cómo crearla y que tipos hay?
              </Link>
            </h2>
            <div className="value-prop-text">
              Crea publicidad inmobiliaria de forma sencilla usando las
              diferentes tácticas y tipos de publicidad con ejemplos de
              publicidad creativa.&#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article12Frame}
              alt="Publicidad inmobiliaria – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>30 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/tendencias-mercado-inmobiliario"
              >
                Tendencias actuales del mercado inmobiliario
              </Link>
            </h2>
            <div className="value-prop-text">
              Exploramos cómo ha evolucionado el mercado inmobiliario y cuales
              son las tendencias actuales del mercado digital inmobiliario.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article11Frame}
              alt="Tendendcias en el mercado inmobiliario – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>28 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/marketing-inmobiliario"
              >
                Marketing inmobiliario ¿Qué es y cómo aplicarlo?
              </Link>
            </h2>
            <div className="value-prop-text">
              El marketing inmobiliario es un conjunto de estrategias y técnicas
              diseñadas para vender propiedades inmobiliarias, descubre los
              tipos y cómo aplicarlo.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article10Frame}
              alt="Marketing inmobiliario – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/aumenta-valor-vivienda"
              >
                Aumenta el valor de la vivienda rápido y fácil
              </Link>
            </h2>
            <div className="value-prop-text">
              Te enseñamos cómo aumentar el valor de la vivienda de forma rápida
              y sencilla con varios consejos y tips para mejorar tu hogar antes
              de venderlo &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Aumenta valor vivienda – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>21 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/espacios-multifuncionales"
              >
                Espacios Multifuncionales ¿Qué es y cómo optimizar espacio?
              </Link>
            </h2>
            <div className="value-prop-text">
              Los espacios multifuncionales tienen el objetivo de aprovechar al
              máximo el espacio posible, usando una misma zona para diferentes
              funciones.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Espacios mutltifuncionales – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/home-staging-ejemplos"
              >
                Home Staging Ejemplos: Antes y Después
              </Link>
            </h2>
            <div className="value-prop-text">
              ¿Estás buscando ejemplos de home staging de antes y después? Para
              que te puedas hacer una idea del poder de esta técnica, aquí te
              traemos algunos ejemplos de Home Staging para ti.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Cómo poner el cielo azul en tus fotografías de inmuebles – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Julio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
              >
                Cómo Poner el Cielo Azul en tus Fotografías de Inmuebles
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre cómo poner el cielo azul y despejado en las fotografías
              de tus inmuebles
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="Cómo poner el cielo azul en tus fotografías de inmuebles – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Julio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
              >
                Cómo Asegurar que la Foto de Portada de tu Anuncio Inmobiliario
                Acelere la Venta
              </Link>
            </h2>
            <div className="value-prop-text">
              Escoge y genera fotos de portada en tus anuncios inmobiliarios que
              capten la atención y te ayuden a vender
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="Cómo asegurar que la porta de tu anuncio inmobiliario acelere la venta – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Junio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/vende-mas-propiedades-inmobiliarias-con-ia"
              >
                ¿Cómo Puede La IA Ayudar A Los Agentes Inmobiliarios A Vender?
              </Link>
            </h2>
            <div className="value-prop-text">
              Una explicación sobre qué es la IA y cómo puede ayudar a la venta
              de inmuebles
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Cómo utilizar la IA para renovar casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Mayo, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/renovar-casa"
              >
                Ideas Para Renovar Mi Casa
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo renovar tu casa sin obras y
              rápido
            </div>
          </div>
          <div className="picture">
            <img
              src={Article2Frame}
              alt="Cómo utilizar la IA para renovar casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>14 de Mayo, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                to="/es/blog/como-vender-casa"
                className="article-title-on-blog"
              >
                Cómo Vender Rápido Mi Casa
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo puedes vender de forma rápida tu
              casa con una serie de consejos
            </div>
          </div>
          <div className="picture">
            <img
              src={Article3Frame}
              alt="Cómo utilizar la IA para vender propiedades – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Abril, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                to="/es/blog/como-utilizar-ia-para-vender-propiedades"
                className="article-title-on-blog"
              >
                Cómo Utilizar La IA Para Vender Propiedades Inmobiliarias
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo puedes utiliar la IA para generar
              imágenes que ayuden a vender tu propiedad
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="Cómo utilizar la IA para vender propiedades – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogEs;
