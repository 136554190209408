// RenderEs.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RealEstatePhotographyEsPage1 from "./Images/RealEstatePhotographyEsPage1En.png";
import RealEstatePhotographyEsPage2 from "./Images/RealEstatePhotographyEsPage2En.png";
import RealEstatePhotographyEsPage3 from "./Images/RealEstatePhotographyEsPage3En.png";
import RealEstatePhotographyEsPage4 from "./Images/RealEstatePhotographyEsPage4En.png";
import RealEstatePhotographyEsPage5 from "./Images/RealEstatePhotographyEsPage5En.jpeg";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6En.jpeg";
import iadLogo from "./Images/iadLogo.png";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RealEstatePhotography() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Improve your real estate photographs with AI in 1 click
            </h1>
            <div className="section-first-text">
              <div
                className="section-first-text-bold"
                style={{ width: "100%" }}
              >
                Use Pedra's AI to easily enhance your real estate photographs
              </div>
              <div className="section-first-text-gray">
                Correct perspectives, improve light, and remove the unnecessary
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ real estate professionals already use Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Improve your real estate photography in 1 click
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">
                  Easily enhance your property photos
                </h2>
                <div className="feature-text">
                  Upload your images and click "Beautify and correct." Pedra
                  will take care of improving light and color, and correcting
                  perspectives.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage1}
                alt="Correct perspective, color and light with AI"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Remove unnecessary objects from your property photos
                </h2>
                <div className="feature-text">
                  Pedra's AI will remove objects from rooms, such as family
                  photos, flags, paintings, and more.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage2}
                alt="Remove object from real estate photography"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Automatically brighten very dark rooms
                </h2>
                <div className="feature-text">
                  Use Pedra's AI to fix lighting in rooms where it's too dark.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage3}
                alt="Brighten very dark rooms"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Increase the resolution of your house photos to HD
                </h2>
                <div className="feature-text">
                  Don't worry about low-resolution photos. With Pedra, you can
                  increase the resolution of your images to HD in just one
                  click.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage4}
                alt="HD resolution enhancement tool for walls and floors through AI renders"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Change materials of walls and floors of properties with AI
                </h2>
                <div className="feature-text">
                  Use AI to change the material of floors and walls. If you have
                  a property with poorly maintained grass, with Pedra you can
                  show it in its best version.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage5}
                alt="Tool for changing walls and floors via AI renders"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">Put the blue sky with AI</h2>
                <div className="feature-text">
                  Use Pedra's AI to fix a bad day. Don't let the weather ruin
                  your property's photo shoot.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Put the blue sky with AI"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h3 className="quote">"It's the discovery of the century"</h3>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "45px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={iadLogo}
                alt="iad logo for Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Bouchra</div>
                <div className="quote-author-company">iad Spain</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Try Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is Pedra?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Pedra is an AI application designed for real estate agents and
                companies.
                <br />
                <br />
                The goal of Pedra is to help you sell more by reducing costs.
                <br />
                <br />
                With a single click, you can enhance the photographs of your
                properties, optimizing light, color, and correcting perspectives
                or definition. This saves you hours of editing in Photoshop,
                Lightroom, or with professional photographers.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What features does Pedra's photo enhancement kit include?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Pedra's real estate photography enhancement kit includes the
                  following features:
                </div>
                <h4>Automatic perspective correction:</h4>With Pedra, you can
                straighten and correct the perspectives of your photographs
                automatically with a single click. This saves you time and
                effort. The functionality usually takes 5 seconds.
                <br />
                <br />
                <h4>Automatic photo beautification:</h4>Improve the light and
                color of your real estate photographs with a single button.
                Click and Pedra will enhance the colors and lights, making the
                photograph look more appealing. The functionality also takes 5
                seconds.
                <br />
                <br />
                <h4>Correction and beautification of photographs:</h4>You can
                also improve the light, color, and perspective of your photos
                simultaneously. This functionality also takes 5 seconds.
                <br />
                <br />
                <h4>Automatic lighting of photographs:</h4>If you have very dark
                photos, with Pedra you can illuminate them so they look clear.
                Thus, your properties will be presented in a more attractive
                manner, improving the relationship with the owner and increasing
                the chances of sale.
                <br />
                <br />
                <h4>Resolution increase of photographs to HD:</h4>You can also
                increase the resolution of your photographs to HD with a single
                click. Simply select the option "Convert to HD". Higher quality
                photographs enhance the presence of your listing on major real
                estate portals.
                <br />
                <br />
                <h4>Object removal:</h4>With Pedra, you can also remove any type
                of object from real estate photographs, such as family photos,
                trash, paintings, crosses, flags, etc. Simply use the Pedra
                brush on the objects you want to remove and select "Generate
                image".
                <br />
                <br />
                <h4>Watermark removal:</h4>You can remove watermarks from other
                real estate agencies using the same "Remove object"
                functionality.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Why should I use Pedra to enhance my property photographs?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Enhancing your property photographs before posting them can have
                a significant impact on how potential buyers or tenants perceive
                your properties. Here are some key reasons:
                <br />
                <h4>First impression:</h4> Photography is often the first
                contact an interested party has with your property. High-quality
                images create a positive first impression, which can
                significantly influence the decision to explore the property
                further.
                <br />
                <h4>Highlight features:</h4> Good photographic retouching can
                highlight the best features of your property, such as the
                spaciousness of the spaces, natural lighting, and quality
                finishes. This can help potential buyers visualize the value of
                the property.
                <br />
                <h4>Market differentiation:</h4> The real estate market can be
                very competitive. Well-produced photographs can make your
                property stand out among others on the market, attracting more
                interest and possibly accelerating the sale or rental.
                Attractive and quality images also tend to generate more
                interaction on online listing platforms and social networks.
                This can increase the visibility of your listing and attract
                more interested parties.
                <br />
                <h4>Reduction of time on the market:</h4> Properties with
                attractive images tend to sell or rent faster. Buyers are more
                inclined to make quick decisions when images provide them with a
                good representation of the property.
                <br />
                <h4>Effective promotion:</h4> High-quality images are essential
                for the effective promotion of any property. Not only are they
                useful for online ads, but also for brochures, presentations,
                and other marketing materials. In summary, investing time and
                resources in enhancing your property photographs can result in a
                better perception of your properties, differentiation in the
                market, and a quicker sale or rental.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">How much does Pedra cost?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra costs €29 per month and is a monthly subscription with no
                commitment. If you decide not to use it anymore, you can
                unsubscribe at any time.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstatePhotography;
