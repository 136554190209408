import React from "react";
import ReleaseJun2024Frame1 from "../Images/ReleaseJun2024Frame1.png";
import ReleaseJun2024Frame2 from "../Images/ReleaseJun2024Frame2.png";
import ReleaseJun2024Frame3 from "../Images/ReleaseJun2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJun24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JUNE 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renew spaces from your mobile, edit captions and change
            watermarks
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustrative cover about AI and the real estate sector"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder at Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Now you can use Pedra from your mobile. Here’s this release and
              the progress we’ve made this June:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Pedra for mobile</h2>
              Now you can use Pedra from your mobile browser. Ideal for
              impressing clients during visits, creating a{" "}
              <Link to="/render" className="article-link">
                quick renovation proposal
              </Link>{" "}
              or editing photos when you are away from your computer.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame1}
                  alt="Renew spaces with AI from mobile"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Move the watermark and edit its transparency
              </h2>
              Now you can edit the transparency level of watermarks, and also,
              you can place them wherever you want in your image. Protect your
              brand without obstructing your photos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame2}
                  alt="Move and edit watermark transparency"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Change the caption text</h2>
              Ensure that people viewing your infographics know they are not
              real. Now you can edit the caption text and its size.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJun2024Frame3}
                alt="Change the caption of an AI photograph"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>

            <div>
              <li>
                Increase in the quality of the furniture inserted in "Furnish"
              </li>
              <li>Option to "Furnish" an entrance</li>
              <li>
                New design of the homepage: see what is the first image within
                each project without having to open it
              </li>
              <li>
                We have added a 2-minute tutorial so you know how to use Pedra,
                and you can watch it{" "}
                <a
                  className="article-link"
                  href="https://www.youtube.com/watch?v=z6O8EX2fJPI"
                  rel="nofollow"
                >
                  here
                </a>{" "}
              </li>{" "}
              <li>Option to choose porcelain stoneware when "Editing floor"</li>
              <li>Various fixes</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJun24;
