import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 21.4008L8.28001 14.6808L10.0608 12.9L13.74 16.5792V4.5H16.26V16.5792L19.9392 12.9L21.72 14.6808L15 21.4008ZM5.76001 22.98V25.5H24.24V22.98H5.76001Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default DownloadIcon;
