import React from "react";
import ReleaseMay2024Frame1 from "../Images/ReleaseMay2024Frame1.png";
import ReleaseMay2024Frame2 from "../Images/ReleaseMay2024Frame2.png";
import ReleaseMay2024Frame3 from "../Images/ReleaseMay2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsMay24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">MAYO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Vaciar Habitaciones y Quitar Objetos, Estilos de Renovación y
            Nueva Interfaz
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Portada ilustrativa sobre la IA y el sector inmobiliario"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Descubre aquí las novedades de Pedra del pasado mes de Mayo:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Vaciado de habitaciones y quitar objetos mejorado
              </h2>
              Hemos perfeccionado nuestro algoritmo para vaciar habitaciones y
              eliminar objetos, asegurando resultados mucho más realistas. Esto
              es ideal para mostrar propiedades libres de objetos innecesarios,
              permitiendo que tus clientes aprecien plenamente la estética del
              inmueble. Aquí tienes un ejemplo de cómo puede transformarse un
              espacio:
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame1}
                  alt="Vacía espacios con IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Estilos de renovación: nuevos y mejorados
              </h2>
              Ahora podrás escoger nuevos{" "}
              <Link className="article-link" to="/es/render">
                estilos de renovación
              </Link>{" "}
              y notarás más cambios. Así podrás mostrar más propuestas de
              renovación de alta calidad a tus clientes en segundos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame2}
                  alt="Estilos de renovación IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Ahora podrás escoger nuevos estilos de renovación y notarás más
                cambios. Así podrás mostrar más propuestas de renovación de alta
                calidad a tus clientes en segundos.
              </h2>
              Hemos transformado completamente nuestra aplicación, dejando atrás
              la complejidad del diseño anterior. Antes, las imágenes se
              amontonaban, dificultando mantener una clara perspectiva del
              proyecto y ralentizando el proceso. Ahora, te presentamos un
              diseño donde cada imagen está meticulosamente ordenada y
              etiquetada, haciendo que editar fotos sea no solo fácil, sino
              también intuitivo.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseMay2024Frame3}
                alt="Nueva interfaz de Pedra"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>

            <div>
              <li>
                Hemos transformado completamente nuestra aplicación, dejando
                atrás la complejidad del diseño anterior. Antes, las imágenes se
                amontonaban, dificultando mantener una clara perspectiva del
                proyecto y ralentizando el proceso. Ahora, te presentamos un
                diseño donde cada imagen está meticulosamente ordenada y
                etiquetada, haciendo que editar fotos sea no solo fácil, sino
                también intuitivo.
              </li>
              <li>Ajustar el grado de creatividad de las renovaciones</li>
              <li>Añade la marca de agua a tus imágenes de golpe</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsMay24;
