import React from "react";
import ReleaseMay2024Frame1 from "../Images/ReleaseMay2024Frame1.png";
import ReleaseMay2024Frame2 from "../Images/ReleaseMay2024Frame2.png";
import ReleaseMay2024Frame3 from "../Images/ReleaseMay2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseMay24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">MAY 2024</div>
          <h1 className="article-titleh1">
            Pedra: Clear Rooms and Remove Items, Renovation Styles, and New
            Interface
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustrative cover about AI and the real estate sector"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder at Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Discover here the latest from Pedra for the past month of May:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Enhanced Room Clearing and Object Removal
              </h2>
              We have perfected our algorithm for clearing rooms and removing
              items, ensuring much more realistic results. This is ideal for
              showing properties free of unnecessary items, allowing your
              clients to fully appreciate the aesthetics of the property. Here
              is an example of how a space can be transformed:
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame1}
                  alt="Clear spaces with AI"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Renovation Styles: New and Improved
              </h2>
              Now you can choose new{" "}
              <Link className="article-link" to="/render">
                renovation styles
              </Link>{" "}
              and notice more changes. Thus, you can offer more high-quality
              renovation proposals to your clients in seconds.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame2}
                  alt="AI Renovation Styles"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Now you can choose new renovation styles and notice more
                changes. Thus, you can offer more high-quality renovation
                proposals to your clients in seconds.
              </h2>
              We have completely transformed our application, leaving behind the
              complexity of the previous design. Before, images piled up, making
              it difficult to maintain a clear perspective of the project and
              slowing down the process. Now, we present a design where each
              image is meticulously ordered and labeled, making photo editing
              not only easy but also intuitive.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseMay2024Frame3}
                alt="New Pedra interface"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>

            <div>
              <li>
                We have completely transformed our application, leaving behind
                the complexity of the previous design. Before, images piled up,
                making it difficult to maintain a clear perspective of the
                project and slowing down the process. Now, we present a design
                where each image is meticulously ordered and labeled, making
                photo editing not only easy but also intuitive.
              </li>
              <li>Adjust the level of creativity in the renovations</li>
              <li>Add watermark to your images all at once</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseMay24;
