import React from "react";
import ReleaseSep2024Frame1 from "../Images/ReleaseSep2024Frame1.png";
import ReleaseSep2024Frame2 from "../Images/ReleaseSep2024Frame2.png";
import ReleaseSep2024Frame3 from "../Images/ReleaseSep2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseFrSep24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">SEPTEMBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra : Rénovation et ameublement en un clic, résultats améliorés et
            préservation de l'espace
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Ce mois de septembre, nous vous apportons des améliorations pour
              rendre l'utilisation de Pedra plus efficace, vous permettant de
              mettre en valeur le potentiel de vos propriétés facilement :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Rénovez et meublez en 1 clic
              </h2>
              Auparavant, meubler et rénover un espace nécessitait deux étapes.
              Ce n'est plus le cas - vous pouvez désormais générer des
              propositions de rénovation qui meublent l'espace en une seule
              fois.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame1}
                  alt="Exemple de rénovation et d'ameublement en un clic"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Meilleurs résultats avec "Meubler" en mode "Auto"
              </h2>
              Nous avons amélioré le home staging virtuel de Pedra pour qu'il
              soit meilleur que jamais. Maintenant, lorsque vous cliquez sur{" "}
              <strong>"Meubler"</strong> en mode <strong>"Auto"</strong>, vous
              verrez des résultats nettement meilleurs : murs décorés,
              hyper-réalisme et meubles correctement positionnés.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame2}
                  alt="Exemple de résultats améliorés en mode Meubler Auto"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Meilleure préservation de l'espace lors du vidage des pièces
              </h2>
              Lorsque vous utilisez <strong>"Vider la pièce"</strong>, vous
              remarquerez que l'architecture de l'espace est mieux préservée.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseSep2024Frame3}
                alt="Exemple de meilleure préservation de l'espace lors du vidage d'une pièce"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>

            <div>
              <li>
                Meilleurs résultats avec <strong>"Convertir en HD"</strong>
              </li>
              <li>
                Positionnement des meubles amélioré lors de l'utilisation de{" "}
                <strong>"Meubler"</strong> en mode <strong>"Auto"</strong>
              </li>
              <li>Résultats améliorés lors de l'ajout d'un ciel bleu</li>
              <li>
                Amélioration de la suppression d'objets (les objets sont
                correctement supprimés tout en préservant la qualité de l'image)
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseFrSep24;
