import React from "react";
import ReleaseSep2024Frame1 from "../Images/ReleaseSep2024Frame1.png";
import ReleaseSep2024Frame2 from "../Images/ReleaseSep2024Frame2.png";
import ReleaseSep2024Frame3 from "../Images/ReleaseSep2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseSep24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">SEPTEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: One-click renovation and furnishing, improved results, and
            space preservation
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              This September, we bring you improvements to make using Pedra more
              efficient, allowing you to showcase your properties' potential
              easily:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Renovate and furnish in 1 click
              </h2>
              Previously, furnishing and renovating a space required two steps.
              Now, it's no longer necessary – you can generate renovation
              proposals that furnish the space in one go.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame1}
                  alt="Example of one-click renovation and furnishing"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Better results in "Furnish" with "Auto" mode
              </h2>
              We've improved Pedra's virtual home staging to be better than
              ever. Now, when you click <strong>"Furnish"</strong> in{" "}
              <strong>"Auto"</strong> mode, you'll see much better results:
              decorated walls, hyper-realism, and correctly positioned
              furniture.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame2}
                  alt="Example of improved results in Auto Furnish mode"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Improved space preservation when emptying rooms
              </h2>
              When using <strong>"Empty room"</strong>, you'll notice that the
              space's architecture is better preserved.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseSep2024Frame3}
                alt="Example of improved space preservation when emptying a room"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>

            <div>
              <li>
                Better results in <strong>"Convert to HD"</strong>
              </li>
              <li>
                Improved furniture positioning when using{" "}
                <strong>"Furnish"</strong> in <strong>"Auto"</strong> mode
              </li>
              <li>Enhanced results when adding blue sky</li>
              <li>
                Improved object removal (objects are correctly removed while
                preserving image quality)
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseSep24;
