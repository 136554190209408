import React from "react";

function Legal() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Legal Notice</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <h2 className="article-subtitleh2">Owner Identification:</h2>
            <div className="article-text">
              Company Name: Pedra Software S.L.
              <br />
              Tax ID: B16459992
              <br />
              Registered Office: Calle Vista Bella No. 8, (08022), Barcelona,
              Catalonia, Spain. Activity: Development and supply of software and
              other related services in information technology and computing.
              NACE Code: 6209 - Other information technology and computer
              service activities.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">General Information:</h2>
            <div className="article-text">
              This website is owned by Pedra Software S.L. The use of this
              website is subject to the following terms of use. Please read them
              carefully. Access to this website and the use of its content and
              services implies the full and unreserved acceptance of these
              conditions.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">
              Intellectual and Industrial Property
            </h2>
            <div className="article-text">
              Company Name: Pedra Software S.L. All contents of this website,
              including but not limited to, texts, graphics, images, its design
              and the intellectual property rights that may correspond to such
              contents, as well as all trademarks, trade names or any other
              distinctive signs are the property of Pedra Software S.L., with
              all rights reserved.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Terms of Use</h2>
            <div className="article-text">
              The user agrees to make appropriate use of the contents and
              services offered by Pedra Software S.L. and not to use them for
              illegal activities or activities constituting crime and/or that
              infringe intellectual and industrial property regulations, or any
              other applicable legal norms.
            </div>
            <br />
            <h2 className="article-subtitleh2">Data Protection</h2>
            <div className="article-text">
              In compliance with Regulation (EU) 2016/679 of the European
              Parliament and of the Council of 27 April 2016 on the protection
              of natural persons with regard to the processing of personal data
              and on the free movement of such data, all personal data collected
              during the use of our website will be treated in accordance with
              our{" "}
              <a className="article-link" href="https://www.pedra.so/privacy">
                Privacy Policy
              </a>
              , which all users must accept before providing personal data
              through this website.
            </div>
            <br />
            <h2 className="article-subtitleh2">Contact</h2>
            <div className="article-text">
              For any questions or proposals, contact us at the following email:{" "}
              <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Legal;
