import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Fr from "../Images/HowToHomeStageFrame1Es.jpg";
import HowToHomeStageFrame2Fr from "../Images/HowToHomeStageFrame2Es.jpg";
import HowToHomeStageFrame3Fr from "../Images/HowToHomeStageFrame3Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/Example1BeforeHomeStaging.jpg";
import Example1After from "../Images/Example1AfterHomeStaging.jpg";
import Example2Before from "../Images/Example2BeforeHomeStaging.jpg";
import Example2After from "../Images/Example2AfterHomeStaging.jpg";
import Example3Before from "../Images/Example3BeforeHomeStaging.jpg";
import Example3After from "../Images/Example3AfterHomeStaging.jpg";
import Example4Before from "../Images/curtain-effect-hero/BeforeBedroomStaging.png";
import Example4After from "../Images/curtain-effect-hero/AfterBedroomStaging.png";
import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer un home staging virtuel
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création d'un home staging virtuel
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je créer un home staging virtuel ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez créer un home staging virtuel en quelques
              clics.
              <p>
                Pour commencer, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>. Choisissez
                le type de pièce et en 2 ou 3 minutes environ, vous aurez votre
                image de home staging virtuel prête à être téléchargée.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Fr}
              alt="Comment créer un home staging virtuel - Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour une construction neuve
              ?
            </h2>
            <p className="article-text">
              Si vous avez une propriété neuve sans meubles, vous pouvez
              utiliser le home staging virtuel de Pedra pour la rendre plus
              attrayante pour les acheteurs potentiels et la vendre plus
              rapidement.
            </p>
            <p className="article-text">
              Pour obtenir votre{" "}
              <Link className="article-link" to="/fr/home-staging-virtual">
                {" "}
                home staging virtuel
              </Link>
              , suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez les images pour lesquelles vous souhaitez créer un
                home staging virtuel
              </li>
              <li>Sélectionnez l'une d'entre elles pour commencer l'édition</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Fr}
              alt="Menu pour créer un home staging virtuel"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Meubler"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez la{" "}
                <span style={{ fontWeight: "500" }}>position des meubles</span>.
                Si vous sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Auto"</span>, Pedra
                décidera où placer les meubles et l'espace qu'ils occupent. Si
                vous sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Dessiner"</span> vous
                pourrez dessiner sur l'image pour indiquer où apparaissent les
                meubles.
              </li>
              <li>
                Sélectionnez le{" "}
                <span style={{ fontWeight: "500" }}>type de pièce</span>. Si
                vous choisissez chambre, Pedra placera des meubles de chambre :
                lit, table de chevet, etc. Si vous choisissez salon, Pedra
                placera des canapés, une télé, un tapis, etc.
              </li>
              <li>
                Sélectionnez le <span style={{ fontWeight: "500" }}>style</span>
                . Le style aidera à déterminer le type de meubles ajoutés à
                l'espace.
              </li>
              <li>
                Choisissez le{" "}
                <span style={{ fontWeight: "500" }}>
                  nombre d'images à générer
                </span>
                . Nous recommandons de choisir 2 ou 3 images à générer pour que
                vous puissiez garder celle que vous préférez.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Problèmes courants lors de la création d'un home staging virtuel
                :
                <ul>
                  <li>
                    Si la photographie est de très mauvaise qualité, les
                    résultats peuvent ne pas être bons
                  </li>
                  <li>
                    Si le plafond n'est pas visible, les meubles peuvent
                    apparaître disproportionnés
                  </li>
                  <li>
                    L'option "Auto" peut parfois être imprécise. Si "Auto" ne
                    fonctionne pas bien pour vous, utilisez "Dessiner"
                  </li>
                  <li>
                    L'option "Auto" peut parfois être imprécise. Si le mode
                    "Auto" ne fonctionne pas bien pour toi, utilise "Draw". En
                    dessinant, tu pourras indiquer quelle partie de l'espace
                    sera meublée.{" "}
                    <strong>
                      Tout ce que tu ne dessines pas ne sera pas meublé
                    </strong>
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemple de comment créer une mise en scène virtuelle"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour des propriétés
              d'occasion ?
            </h2>
            <p className="article-text">
              Si vous avez une propriété d'occasion, qu'elle soit meublée ou
              non, nous vous recommandons d'utiliser l'option{" "}
              <span style={{ fontWeight: "500" }}>"Rénovation"</span>.
            </p>
            <p className="article-text">
              Pour obtenir votre home staging virtuel pour une propriété
              d'occasion, suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez les images pour lesquelles vous souhaitez créer un
                home staging virtuel
              </li>
              <li>Sélectionnez l'une d'entre elles pour commencer l'édition</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Rénovation"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame3Fr}
              alt="Menu pour créer un home staging virtuel pour des propriétés d'occasion"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Rénovation"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez le style. Nous recommandons "Scandinave" ou
                "Minimaliste" pour des résultats plus neutres. Si vous préférez,
                vous avez la possibilité de télécharger une image du style que
                vous voulez et d'effectuer une rénovation basée sur ce style.
              </li>
              <li>
                Ajustez les détails :
                <ul>
                  <li>
                    <span style={{ fontWeight: "500" }}>
                      Préserver les fenêtres :
                    </span>{" "}
                    choisissez de préserver ou non les vues depuis les fenêtres
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Meubler :</span>{" "}
                    choisissez si vous voulez que l'espace soit meublé. Comme
                    nous créons un home staging virtuel, nous sélectionnerons
                    oui. Cela ajoutera des meubles à chaque espace. Si la
                    propriété est meublée, la disposition sera modifiée.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Type d'espace :</span>{" "}
                    choisissez. Dans ce cas, laissons-le sur "Auto". En le
                    laissant sur "Auto", Pedra interprétera le type d'espace et
                    le meublera en conséquence. Si nous voulions qu'il meuble
                    une photo avec des meubles de salon, nous devrions
                    sélectionner salon
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Créativité :</span> la
                    créativité influencera le niveau de changements dans
                    l'architecture. Nous recommandons de la laisser basse.
                    Ainsi, nous éviterons de changer agressivement
                    l'architecture de l'espace.
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi devrais-je créer un home staging virtuel ?
            </h2>
            <p className="article-text">
              La première fois qu'un acheteur potentiel voit une propriété à
              vendre, c'est sur internet. Pour cette raison, il est extrêmement
              crucial que la première chose qu'il voit évoque des sentiments
              positifs et de l'intérêt.
            </p>
            <p className="article-text">
              Le{" "}
              <Link className="article-link" to="/fr/home-staging-virtual">
                home staging virtuel
              </Link>{" "}
              aide à générer de bonnes sensations. Avec un bon home staging
              virtuel, vous pouvez donner vie à des espaces vides ou montrer la
              meilleure version d'un bien qui a besoin d'être rénové. C'est un
              allié pour vendre rapidement des propriétés.
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de home staging virtuel
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de home stagings virtuels créés avec
              Pedra :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Avant la création du home staging virtuel"}
              altAfter={"Après la création du home staging virtuel"}
            />
            <br />
            {/* 
            <h2 className="article-subtitleh2">
              Vidéo sur la création d'un home staging virtuel
            </h2>
            
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/PLACEHOLDER_VIDEO_ID"
                  title="Tutoriel : Comment créer un home staging virtuel"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>*/}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingFr;
