import React from "react";
import Article4Frame from "../Images/Article4Frame.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle4() {
  return (
    <>
      <div className="section-one-column-left">
        <article
          className="title-container article"
          style={{ marginTop: "100px" }}
        >
          <h1 className="article-titleh1">
            How Can AI Help Real Estate Agents Sell?
          </h1>
          <div className="title-secondary gray">
            An explanation about what is AI and how it can help on the sale of
            real estate properties
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder at Pedra</div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
              }}
              src={Article4Frame}
              alt="Cover of the article about how can AI help real estate agents sell"
            />
          </div>
        </article>
      </div>

      <div className="section-one-column-left" style={{ marginTop: "0px" }}>
        <section className="step-container article">
          <div>
            <div className="article-text">
              <div className="article-text">
                In this text I want to share about AI and try to demistry about
                what is it, and how it can really help on the day to day of a
                real estate agent.
              </div>
              <br />
              <div className="article-text">
                This article will revolve around the following question:
              </div>
              <br />
              <div className="article-text" style={{ fontWeight: "600" }}>
                How can we sell more with AI?
              </div>
              <br />
              <div className="article-text">
                But first, we will shaore about what is AI:
              </div>
              <br />
              <h2 className="article-titleh2">What is AI</h2>
              <br />
              <div className="article-text">
                A program that has learned how to do tasks similar to a human.
                Tasks like writing, speaking, having converstaions, thinking,
                imagining, etc.
              </div>
              <br />
              <h2 className="article-titleh2">How can we create an AI?</h2>
              <div className="article-text">
                The first step is to train a model with millions of data points.
              </div>
              <br />
              <div className="article-text">
                The second step is about putting to practice this AI model that
                we already trained. It's like when Rocky trained very hard – it
                was all for that final combat.
              </div>
              <br />
              <h2 className="article-titleh2">
                How models could we create to help real estate agents?
              </h2>
              <br />
              <div className="article-text">
                For example, we could train an AI to identify what are the
                images that generate the most clicks in an ad, like for example{" "}
                <a
                  className="article-link"
                  href="https://www.idealista.com"
                  rel="nofollow"
                >
                  Idealista
                </a>
                .
              </div>
              <br />
              <div className="article-text">
                We would basically need to start training a model, and pass to
                it millions of images and tell the model which ones were put as
                the ad cover. Once the model had been trained with those
                millions of data points, we would have our smart and small model
                to help us pick the best image for our cover. We could share the
                20 images of one of our properties, and he would tell us which
                would be the one that could help us sell most.
              </div>
              <br />
              <div className="article-text">
                But obtaining a small robot that gives us this help is not
                cheap, nor easy, nor fast, because:
              </div>
              <br />
              <li className="article-text">
                We need to train with millions of data points:
              </li>
              <li className="article-text">
                we would need expensive engeineering resources, like
                professionals and computers
              </li>
              <li className="article-text">We would need lots of time</li>
              <br />
              So I will not go into how to create AI models, althought that
              would be a very interesting topic. But for sure, not a topic you
              need to know about to get the most out of AI easily. In this
              article I will go into models that already exist and that can help
              you sell more.
              <br />
              <h2 className="article-titleh2">
                Are there AI models that can sell a house for me?
              </h2>
              <div className="article-text">
                No.
                <br />
                <br /> No hay modelos de IA que sustituyan al agente
                inmobiliario o que vayan a vender por ti. Porque la IA se centra
                en actividades repetitivas y no en actividades humanas y de alta
                complejidad. El agente inmobiliario requiere de relaciones
                interpersonales y un grado de complejidad que no puede asumir la
                tecnología de hoy. There are no models that can subsitute real
                estate agents or that will sell on your behalf. AI is focused on
                repetitive tasks, and not on highly complex and interpersonal
                tasks. Real estate agents need to build relationships, cover a
                large and uncertain pool of tasks, like preparing specific
                contracts, ensuring they are signed, negotaiting, etc. – AI
                can't do those things yet.
              </div>
              <br />
              <h2 className="article-titleh2">
                So what can AI do for the real estate agent?
              </h2>
              <div className="article-text">
                Pues la IA puede sacarte trabajo repetitivo. Y así tendrás más
                tiempo para enfocarte en vender y captar.
              </div>
              <br />
              <div className="article-text">So, how can AI help us?</div>
              <br />
              <h2 className="article-titleh2">
                Create images that help us sell.
              </h2>
              <div className="article-text">
                Images are one of the most repetitives tasks that real estate
                agents need to deal with. They are virtually present in all real
                estate transactions.
              </div>
              <br />
              <div className="article-text">
                Within the world of real estate imagery, there are very
                reppetitive tasks:;
              </div>
              <br />
              <div className="article-text">
                A clear example is when we have "the grandma's" house for sale.
                In this situations, it can be very difficult, because even if
                the property is good, the furniture and decoration can hurt the
                sale process a lot.
              </div>
              <br />
              <div className="article-text">AI solves this.</div>
              <br />
              <div className="article-text">
                In 1 click we can{" "}
                <Link className="article-link" to="/real-estate-photography">
                  empty rooms with AI
                </Link>{" "}
                and that rooms look the best
              </div>
              <br />
              <div className="article-text">
                Another repetitive task within the world of real estate is
                getting renders showcasing renovation proposals of very old
                flats. If a property really needs a renovation, it would be the
                best if we oculd show directly how it looks renovated. And
                that's why real estate agents order renders to architects!
              </div>
              <br />
              <div className="article-text">
                The problem is that renders are expensive and need a few days or
                weeks to be done by a professional.
              </div>
              <br />
              <div className="article-text">
                AI fixes this issues. With AI, you can{" "}
                <Link className="article-link" to="/render">
                  generate proposals of renovation in seconds
                </Link>
                , when you want, wherever you want, when you need it.
              </div>
              <br />
              <div className="article-text">
                AI will also help filling up spaces with life, and ensure it
                looks like a place that someone can call home. When properties
                are empty, visitors struggle to understand how it looks. Buyers
                get confused, and they have a hard time understanding how it
                could really look like.
              </div>
              <br />
              <div className="article-text">
                Actual physical home staging has become very popualr in the last
                few years. It's a great solution, but it might be expensive,
                slow and complex to implement
              </div>
              <br />
              <div className="article-text">
                With AI, you cannot replicate the effect of a physical home
                staging, but you can replicate the same effect of when that
                property is seen online. And that's important, because the
                majority of sales processes start online. People go online, and
                if they see some pictures that they like, they might be more
                interested in booking a call for a visit.
              </div>
              <br />
              <div className="article-text">
                And our schedule will also appreciate AI. We know how key is the
                weather when we want to make a photoshoot of a property. We all
                want to{" "}
                <Link className="article-link" to="/real-estate-photography">
                  get the best real estate photographies
                </Link>{" "}
                possible. But that is not always easy. Coordinating with the
                photographer, with the weather, with the owner of the house,
                etc. it's a mess! AI puts a remedy to these things. With a
                stroke of a key in your computer, you can clear those skies of
                clouds, and ensure that the grass looks as green as ever.
              </div>
              <br />
              <h2 className="article-titleh2">
                In conclusion, AI gives you superpowers
              </h2>
              <br />
              <div className="article-text">
                It will help you sell faster. You can create images that act as
                lead magnets in real estate marketplaces and social media.
              </div>
              <br />
              <div className="article-text">
                You will augment the quality, speed and flexibility of your
                services. In fron of the customer and the seller, you can offer
                more experiences and help in their operations and needs.{" "}
                <Link className="article-link" to="/render">
                  Does the customer want to see the property after a renovation?
                </Link>{" "}
                Do you want to improve the images as fast possible? Do you want
                to publish faster?
              </div>
              <br />
              <div className="article-text">
                Automate and reduce costs. What before took lots of money and
                time, you now can do easily with a computer or phone. Thanks AI.
              </div>
              <br />
              <div className="article-text" style={{ color: "#878787" }}>
                This content was originally presented on a live event from{" "}
                <a className="article-link" href="https://www.iadespana.es">
                  IAD España
                </a>{" "}
                on the 18th of June 2024
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hello! I'm Felix Ingla, the founder of{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , an innovative virtual home staging and real estate photography
              web application that is transforming how properties are presented
              in the digital world. My dedication to creating cutting-edge
              technology and intuitive solution has established Pedra as a
              trusted tool for real estate professionals seeking to enhance
              their property listings.
            </p>
            <p>
              If you are eager to connect and interested in discussing real
              estate technology, please reach out via my{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . You can also read more{" "}
              <Link className="article-link" to="/about">
                about Pedra here
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle4;
