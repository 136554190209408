import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageSalonFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingLivingRoomFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer un home staging virtuel pour les salons
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création d'un home staging virtuel pour les espaces
            de vie
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je créer un home staging virtuel pour un salon ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez créer un home staging virtuel pour les
              salons en quelques clics.
              <p>
                Pour commencer, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>. Choisissez
                "Salon" comme type d'espace et en 2 ou 3 minutes, vous aurez
                l'image de votre home staging virtuel de salon prête à être
                téléchargée.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Comment créer un home staging virtuel de salon – Exemples"
            />
            <br />
            <h2 className="article-subtitleh2">
              Comment créer un home staging virtuel pour les salons ?
            </h2>
            <p className="article-text">
              Pour obtenir votre{" "}
              <Link className="article-link" to="/fr/home-staging-salon">
                home staging virtuel de salon
              </Link>
              , suivez ces étapes simples :
            </p>
            <ol>
              <li>
                Téléchargez l'image du salon que vous souhaitez meubler
                virtuellement
              </li>
              <li>Sélectionnez l'image pour commencer à l'éditer</li>
              <li>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>{" "}
                puis cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Meubler"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu pour créer un home staging virtuel de salon"
            />
            <p className="article-text">
              Dans <span style={{ fontWeight: "500" }}>"Meubler"</span> vous
              devrez suivre ces étapes :
            </p>
            <ol>
              <li>
                Sélectionnez la{" "}
                <span style={{ fontWeight: "500" }}>position des meubles</span>.
                Pour les salons, nous recommandons d'utiliser "Dessiner" pour
                indiquer les zones spécifiques où placer le canapé, la table
                basse, le meuble TV, etc.
              </li>
              <li>
                Sélectionnez <span style={{ fontWeight: "500" }}>"Salon"</span>{" "}
                comme type d'espace. Cela garantira que Pedra place des meubles
                appropriés comme des canapés, des fauteuils, des tables basses,
                etc.
              </li>
              <li>
                Sélectionnez le <span style={{ fontWeight: "500" }}>style</span>
                . Pour les salons, des styles comme "Moderne", "Contemporain" ou
                "Scandinave" fonctionnent généralement bien.
              </li>
              <li>
                Choisissez le{" "}
                <span style={{ fontWeight: "500" }}>
                  nombre d'images à générer
                </span>
                . Nous recommandons de choisir 2 ou 3 images pour avoir
                différentes options d'aménagement de salon.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour un meilleur home staging virtuel de salon :
                <ul>
                  <li>
                    Assurez-vous que la photographie montre clairement les
                    dimensions du salon pour un aménagement plus précis
                  </li>
                  <li>
                    Si le salon a des caractéristiques spéciales comme une
                    cheminée ou de grandes baies vitrées, essayez de les inclure
                    dans la photo pour un résultat plus attrayant
                  </li>
                  <li>
                    Utilisez l'option "Dessiner" pour indiquer des zones
                    spécifiques pour l'espace salon, la salle à manger et les
                    éléments décoratifs
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemple de création d'un home staging virtuel de salon"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi faire un home staging virtuel de salon ?
            </h2>
            <p className="article-text">
              Un salon bien présenté peut être décisif pour les acheteurs
              potentiels. Le home staging virtuel de salons vous permet de
              montrer le potentiel de ces espaces de vie, en créant des
              ambiances chaleureuses et fonctionnelles qui aident les acheteurs
              à visualiser comment ils pourraient profiter de moments en famille
              et entre amis.
            </p>
            <p className="article-text">
              Avec un bon home staging virtuel de salon, vous pourrez :
            </p>
            <ul>
              <li>Montrer le potentiel d'espaces vides ou peu attrayants</li>
              <li>Créer une atmosphère conviviale et accueillante</li>
              <li>
                Mettre en valeur des caractéristiques telles que la luminosité,
                l'agencement ou la polyvalence du salon
              </li>
              <li>
                Aider les acheteurs à imaginer des moments de convivialité et de
                divertissement dans le salon
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de home staging virtuel de salons
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de home stagings virtuels qui peuvent
              être appliqués aux salons :
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Avant de créer le home staging virtuel"}
              altAfter={"Après avoir créé le home staging virtuel"}
            />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingLivingRoomFr;
