import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeTerraza from "./Images/curtain-effect-hero/BeforeTerraza.png";
import AfterTerraza from "./Images/curtain-effect-hero/AfterTerraza.png";
import BeforeTerrazaStaging1 from "./Images/curtain-effect/BeforeTerrazaStaging1.png";
import AfterTerrazaStaging1 from "./Images/curtain-effect/AfterTerrazaStaging1.png";
import BeforeTerrazaStaging2 from "./Images/curtain-effect/BeforeTerrazaStaging2.png";
import AfterTerrazaStaging2 from "./Images/curtain-effect/AfterTerrazaStaging2.png";
import BeforeTerrazaStaging3 from "./Images/curtain-effect/BeforeTerrazaStaging3.png";
import AfterTerrazaStaging3 from "./Images/curtain-effect/AfterTerrazaStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/en")) {
    window.open("https://app.pedra.so/en", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeStagingTerrace() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeTerraza,
    afterImage: AfterTerraza,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeTerraza,
        afterImage: AfterTerraza,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeTerrazaStaging1,
        afterImage: AfterTerrazaStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeTerrazaStaging2,
        afterImage: AfterTerrazaStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeTerrazaStaging3,
        afterImage: AfterTerrazaStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is terrace home staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Terrace home staging is a real estate marketing technique that focuses on preparing and presenting a property's terrace in an attractive way for potential buyers or tenants. This may include cleaning, organizing, strategic decorating, and small improvements to highlight the terrace's strengths and minimize its flaws.",
        },
      },
      {
        "@type": "Question",
        name: "What are the benefits of virtual home staging for terraces?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtual home staging for terraces offers several benefits, including: the ability to show the terrace's potential without making costly physical changes, the capacity to quickly experiment with different styles and configurations, improving the property's online presentation, and the opportunity to stand out in a competitive real estate market.",
        },
      },
      {
        "@type": "Question",
        name: "How does the virtual home staging process for terraces work?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The virtual home staging process for terraces involves uploading photos of the current terrace to our platform, selecting desired editing options (such as changing finishes, adding or removing elements, or changing the layout), and then receiving the edited images showing the transformed terrace.",
        },
      },
      {
        "@type": "Question",
        name: "How much does virtual terrace home staging cost?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The price of our virtual home staging service, which includes terraces and other areas of the house, is €29 plus VAT per month. This plan allows you to generate up to 100 images per month, with no commitment.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeTerrazaStaging1,
    afterImage: AfterTerrazaStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeTerrazaStaging1,
      afterImage: AfterTerrazaStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeTerrazaStaging2,
      afterImage: AfterTerrazaStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeTerrazaStaging3,
      afterImage: AfterTerrazaStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Terrace Home Staging</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  The best virtual terrace home staging at your fingertips
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Fast:</strong> Transform your terrace in seconds
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Easy:</strong> Design attractive terraces
                    effortlessly
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Quality:</strong> Hyper-realistic results for
                    terraces
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Help:</strong> Expert assistance in terrace design
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 for 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Before virtual terrace home staging"}
                  altAfter={"After virtual terrace home staging"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Terrace Example 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Terrace Example 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Terrace Example 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Terrace Example 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          OVER 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtual terrace home staging to{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            sell faster
          </span>{" "}
          any type of property.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Renovate your terrace virtually
                </h3>
                <div className="feature-text">
                  Transform your terrace with a click, without works or expenses
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Highlight unique features</h3>
                <div className="feature-text">
                  Emphasize the strengths of your terrace with descriptive text
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Improve and correct terrace photos
                </h3>
                <div className="feature-text">
                  Optimize the quality and perspective of your terrace photos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div className="feature-text-container">
                  <OrangeFeatherIcon />
                  <h3 className="feature-title">Add outdoor furniture</h3>
                  <div className="feature-text">
                    Update your terrace with modern furniture virtually
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div className="feature-text-container">
                  <OrangeBigBrushIcon />
                  <h3 className="feature-title">Renew finishes</h3>
                  <div className="feature-text">
                    Change floors, railings, and finishes with a single click
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div className="feature-text-container">
                  <OrangeRemoveObject />
                  <h3 className="feature-title">
                    Clean and organize virtually
                  </h3>
                  <div className="feature-text">
                    Remove clutter and unwanted objects for an impeccable
                    terrace
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="section-one-column-left">
          <h2 className="title-container" style={{ display: "inline" }}>
            Examples of our virtual terrace home staging.
          </h2>
        </div>

        <div className="section-one-column-left">
          <div className="hero-example-container">
            <CurtainEffect
              beforeImage={stagingExample.beforeImage}
              afterImage={stagingExample.afterImage}
              altBefore={"Before virtual terrace home staging"}
              altAfter={"After virtual terrace home staging"}
            />

            <div style={{ display: "flex", flex: "1 0 0" }}></div>
            <div className="hero-example-buttons-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "6px",
                  width: "100%",
                }}
              >
                {stagingExamples.map((item) => (
                  <div
                    key={item.name}
                    className={`hero-button ${
                      stagingExample.name === item.name ? "selected" : ""
                    }`}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      borderRadius: "8px",
                      overflow: "hidden",
                      height: "50px",
                      padding: "0px",
                    }}
                    onClick={() => handleStagingExample(item.name)}
                  >
                    <img
                      src={item.beforeImage}
                      alt={item.name}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <h3 className="title-container" style={{ display: "inline" }}>
            How does virtual terrace home staging work?
          </h3>
        </div>

        <div className="section-one-column-left">
          <div className="step-container">
            <div className="step-text-container">
              <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
                STEP 1
              </h5>
              <h2>Upload photos of your terrace</h2>
              <div className="value-prop-text">
                Upload one or more images of your terrace to Pedra.
              </div>
            </div>

            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="How to upload terrace photos to create virtual home staging on Pedra - Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="step-container">
            <div className="step-text-container">
              <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
                STEP 2
              </h5>
              <h2>Choose transformation options for your terrace</h2>
              <div className="value-prop-text">
                Select from several options to virtually renovate your terrace.
                <p>
                  You can change finishes, add furniture, reorganize the space,
                  and much more.
                </p>
              </div>
            </div>

            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="How to choose options to create virtual terrace home staging - Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="step-container">
            <div className="step-text-container">
              <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
                STEP 3
              </h5>
              <h2>Receive and download your transformed terrace</h2>
              <div className="value-prop-text">
                In a matter of minutes, you'll get realistic images of your
                renovated terrace.
                <p>
                  Download the images and use them to promote your property.
                </p>
              </div>
            </div>

            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="How to download virtual terrace home staging - Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Virtual terrace home staging for €29 per month. <br />{" "}
            <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              And no commitment.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Pro Plan
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">€29</div>
                <div className="modal-product-plan-description-text-3">
                  per month
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Includes
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                  100 image generations
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>per month</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovate and redecorate terraces
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Change terrace finishes
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Add outdoor furniture
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Remove unwanted objects
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Increase photo resolution
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Change terrace design
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Improve terrace photography
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unlimited terrace plans
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unlimited project sharing
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Add watermarks
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unlimited support
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Try Pedra
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Cancel anytime.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Buy more images at €0.20 per image if you need them.
              </div>
            </div>
          </div>
        </div>

        <div className="long-break"> </div>

        <div className="section-one-column-left">
          <div className="title-container">
            Questions and answers about terrace home staging
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="faq-container">
            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ1(!FAQ1)}
              >
                <h3 className="question-title">
                  What is terrace home staging?
                </h3>
                {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ1 && (
                <div className="answer-container">
                  Terrace home staging is a real estate marketing technique that
                  focuses on preparing and presenting a property's terrace in an
                  attractive way for potential buyers or tenants. This may
                  include cleaning, organizing, strategic decorating, and small
                  improvements to highlight the terrace's strengths and minimize
                  its flaws.
                  <br />
                  <br />
                  In the case of virtual terrace home staging, we use advanced
                  technology to digitally transform the space, showing its
                  potential without the need for costly physical changes.
                </div>
              )}
            </div>

            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ2(!FAQ2)}
              >
                <h3 className="question-title">
                  What are the advantages of virtual home staging for terraces?
                </h3>
                {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ2 && (
                <div className="answer-container">
                  Virtual home staging for terraces offers numerous advantages:
                  <br />
                  <br />
                  1. Cost savings: No need for costly physical renovations.
                  <br />
                  2. Time savings: Transformations are done in minutes, not
                  weeks.
                  <br />
                  3. Versatility: You can show multiple styles and
                  configurations of the same terrace.
                  <br />
                  4. Visual appeal: Helps buyers visualize the terrace's
                  potential.
                  <br />
                  5. Market differentiation: Stand out with attractive terrace
                  images.
                  <br />
                  6. No disruption: No need to interrupt the lives of current
                  occupants.
                  <br />
                  7. Improved online presentation: Ideal for online property
                  listings.
                </div>
              )}
            </div>

            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ3(!FAQ3)}
              >
                <h3 className="question-title">
                  What can I do with Pedra's terrace home staging?
                </h3>
                {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ3 && (
                <div className="answer-container">
                  With Pedra's virtual terrace home staging, you can perform a
                  wide variety of transformations:
                  <br />
                  <br />
                  1. Renovate terraces: You can use Pedra's predefined styles or
                  create your own custom styles to completely transform the
                  appearance of the terrace.
                  <br />
                  2. Improve light and color: Optimize the lighting and colors
                  of terrace photographs to make them appear more attractive and
                  professional.
                  <br />
                  3. Remove objects: Erase unwanted elements from photos, such
                  as old furniture, clutter, or personal items.
                  <br />
                  4. Add elements: Incorporate new objects to the terrace, such
                  as modern outdoor furniture, plants, or decorative
                  accessories.
                  <br />
                  5. Change finishes: Modify the appearance of floors, railings,
                  and other finishes to show different design options.
                  <br />
                  6. Reorganize the space: Visualize different terrace
                  configurations without the need for physical changes.
                  <br />
                  <br />
                  All these transformations are done virtually, allowing you to
                  show the terrace's potential quickly and economically.
                </div>
              )}
            </div>

            {/* Add more FAQs here */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingTerrace;
