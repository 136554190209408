import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToEmptyRoomFrame1 from "../Images/HowToEmptyRoomFrame1.jpg";
import HowToEmptyRoomFrame2 from "../Images/HowToEmptyRoomFrame2.jpg";
import Example1Before from "../Images/curtain-effect-empty-room/Example1Before.jpeg";
import Example1After from "../Images/curtain-effect-empty-room/Example1After.jpeg";
import Example2Before from "../Images/curtain-effect-empty-room/Example2Before.jpeg";
import Example2After from "../Images/curtain-effect-empty-room/Example2After.jpeg";
import Example3Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example3After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import Example4Before from "../Images/curtain-effect-empty-room/Example4Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example4After.jpeg";
import CurtainEffect from "../CurtainEffect";

function HowToEmptyRoomFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment vider virtuellement des pièces
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la façon de vider virtuellement des pièces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Comment puis-je vider virtuellement des pièces ?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Pedra offre une option qui vous permet de vider des pièces en un
                seul clic.
              </p>
              <p style={{ marginTop: "10px" }}>
                Pour vider une pièce, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Vider la pièce"</span>. En
                2 ou 3 minutes, vous aurez une image de votre pièce vidée, prête
                à être téléchargée.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame1}
                alt="Comment vider virtuellement des pièces de maisons et de propriétés – Capture d'écran du logiciel Pedra"
              />
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Comment puis-je obtenir de meilleurs résultats lors du vidage
              d'une pièce ?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Si la fonction{" "}
                <span style={{ fontWeight: "500" }}>"Vider la pièce"</span> a
                laissé des objets ou des parties non vidées, ou a ajouté des
                fenêtres ou des portes supplémentaires, vous pouvez utiliser
                l'outil{" "}
                <span style={{ fontWeight: "500" }}>"Supprimer un objet"</span>{" "}
                sur l'image vidée pour finir de supprimer tout ce que vous ne
                voulez pas de la photo.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame2}
                alt="Comment vider des pièces par parties – Capture d'écran du logiciel Pedra"
              />
              <p>
                Et si vous voulez une meilleure qualité de la photographie,
                cliquez sur l'option
                <span style={{ fontWeight: "500" }}>"Convertir en HD"</span> une
                fois que vous avez fini de supprimer les objets.
              </p>
              <p style={{ marginTop: "10px" }}>
                Pour vider, accédez au menu{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span> et
                cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Vider la pièce"</span>. En
                2 ou 3 minutes, vous aurez votre pièce vidée, prête à être
                téléchargée.
              </p>
              <aside className="callout" style={{ marginTop: "10px" }}>
                <div>
                  <InfoIcon />
                </div>
                <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                  Si vous n'arrivez pas à vider correctement les images, vous
                  pouvez envoyer un email à{" "}
                  <span style={{ fontWeight: "500" }}>felix@pedra.so</span> et
                  nous vous les renverrons vidées dans les 24-48 heures. Vous
                  devez être abonné à Pedra pour accéder à ce service.
                </h4>
              </aside>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Pourquoi devrais-je vider virtuellement une propriété ?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Montrer des pièces sans meubles peut être crucial lors de la
                vente d'une propriété.
              </p>
              <p style={{ marginTop: "10px" }}>
                D'une part, le vendeur se sentira plus à l'aise en sachant que
                nous ne montrons pas toutes ses possessions personnelles en
                ligne. Imaginez si tout le monde pouvait voir votre chambre en
                ligne – personne n'aime ça.
              </p>
              <p style={{ marginTop: "10px" }}>
                D'autre part, l'acheteur sera plus attiré par une maison
                dépersonnalisée, et pourra vraiment voir le potentiel de la
                propriété, ou mieux imaginer sa future maison.
              </p>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Exemples de pièces vidées virtuellement
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de pièces vidées virtuellement avec Pedra
              :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Avant de vider virtuellement la pièce"}
              altAfter={"Après avoir vidé virtuellement la pièce"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Avant de vider virtuellement la pièce"}
              altAfter={"Après avoir vidé virtuellement la pièce"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Avant de vider virtuellement la pièce"}
              altAfter={"Après avoir vidé virtuellement la pièce"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Avant de vider virtuellement la pièce"}
              altAfter={"Après avoir vidé virtuellement la pièce"}
            />

            <br />
            <h2 className="article-subtitleh2">
              Vidéo sur comment vider virtuellement une pièce
            </h2>
            {/*
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    maxWidth: "900px",
                    height: "500px",
                  }}
                  frameBorder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  title="IA pour vendre des propriétés immobilières"
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToEmptyRoomFr;
