import React from 'react';

const OrangeVideoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M19.2857 10.9286V18.6429C19.2857 20.7514 17.9657 22.0714 15.8571 22.0714H6.42857C4.32 22.0714 3 20.7514 3 18.6429V10.9286C3 8.82 4.32 7.5 6.42857 7.5H15.8571C17.9657 7.5 19.2857 8.82 19.2857 10.9286ZM25.2857 8.78571L21 13.0714V16.5L25.2857 20.7857H27V8.78571H25.2857Z" fill="#F8792A"/>
    </svg>
  );
};

export default OrangeVideoIcon;