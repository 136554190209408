import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToRemoveObjectFrame1 from "../Images/HowToRemoveObjectFrame1.jpg";
import HowToRemoveObjectFrame2 from "../Images/HowToRemoveObjectFrame2Es.jpg";
import HowToRemoveObjectFrame3 from "../Images/HowToRemoveObjectFrame3Es.jpg";

function HowToRemoveObjectFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment supprimer des objets des maisons et des propriétés
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur comment supprimer des objets des pièces, maisons et
            autres photographies de propriétés
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi servent les outils "Remove Object" de Pedra ?
            </h2>
            <p className="article-text">
              Avec les outils de suppression d'objet de Pedra, vous pouvez
              éliminer des objets, des meubles, et plus encore de vos
              photographies.
              <p style={{ marginTop: "10px" }}>
                Cela sera utile pour montrer des pièces sans encombrement,
                retirer des objets inappropriés pour les annonces immobilières
                ou simplement enlever des meubles peu attrayants.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment fonctionne la suppression d'objet ?
            </h2>
            <p className="article-text">
              Sélectionnez une image dont vous souhaitez supprimer les objets.
              Ensuite, cliquez sur{" "}
              <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, et vous
              verrez l'option{" "}
              <span style={{ fontWeight: "500" }}>"Remove Object"</span>.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRemoveObjectFrame1}
                alt="How to remove objects from houses and properties – Screenshot of Pedra software"
              />
            </p>
            <p className="article-text">
              Ensuite, peignez sur l'image. Assurez-vous de ne laisser aucune
              partie découverte. S'il y a plusieurs objets ou pièces de mobilier
              à supprimer, sélectionnez-les tous en une seule fois.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame2}
              alt="How to remove objects from houses and properties – Screenshot of Pedra software"
            />
            <h2 className="article-subtitleh2">
              Exemple de suppression d'objet
            </h2>
            <p className="article-text">
              Voici un exemple de l'utilisation de "Remove Object" dans Pedra.
              En une seule fois, nous avons réussi à supprimer tous les meubles
              d'une pièce pour la rendre plus spacieuse.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame3}
              alt="How to remove objects from houses and properties – Screenshot of Pedra software"
            />
            <br />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Conseils pour supprimer correctement les objets
                </h4>
                <p className="callout-p">
                  Suivez ces conseils pour obtenir les meilleurs résultats :
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Assurez-vous de peindre complètement l'objet à
                        supprimer. Couvrez également les ombres.{" "}
                      </span>
                      Sinon, les résultats peuvent être médiocres.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        S'il y a plusieurs objets ou articles à supprimer,
                        peignez tous en une seule fois
                      </span>
                      . Si vous ne peignez qu'un seul objet, Pedra pourrait le
                      remplacer par un autre.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si l'option de suppression d'objet n'a pas tout supprimé
                        complètement, appliquez "Remove Object" à l'image
                        résultante
                      </span>
                      . Toutes les images dans Pedra sont rééditables, même
                      celles que vous générez dans Pedra.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si le dessin est précis, vous pouvez utiliser "Remove
                        Object" comme alternative à "Empty Room"
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRemoveObjectFr;
