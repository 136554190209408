import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBathroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeBathroom.png";
import Example4After from "../Images/curtain-effect-hero/AfterBathroom.png";
import Example1Before from "../Images/curtain-effect/BeforeBathroomStaging1.png";
import Example1After from "../Images/curtain-effect/AfterBathroomStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeBathroomStaging2.png";
import Example2After from "../Images/curtain-effect/AfterBathroomStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeBathroomStaging3.png";
import Example3After from "../Images/curtain-effect/AfterBathroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBathroom() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Virtual Home Staging for Bathrooms
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create virtual home staging for bathroom spaces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create virtual home staging for a bathroom?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home staging for bathrooms in
              just a few clicks.
              <p>
                To begin, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Choose "Bathroom" as the type of space, and in about 2 or 3
                minutes, you'll have your virtual home staging image of the
                bathroom ready to download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create virtual home staging for a bathroom – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for bathrooms?
            </h2>
            <p className="article-text">
              To achieve your{" "}
              <Link className="article-link" to="/home-staging-bathroom">
                virtual home staging for a bathroom
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the bathroom you want to virtually furnish
              </li>
              <li>Select the image to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu for creating virtual home staging for a bathroom"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>
                  position of the staging elements
                </span>
                . For bathrooms, we recommend using "Draw" to indicate specific
                areas where to place fixtures, decor, and accessories.
              </li>
              <li>
                Select <span style={{ fontWeight: "500" }}>"Bathroom"</span> as
                the type of space. This will ensure that Pedra places
                appropriate bathroom items such as toiletries, towels, and
                decorative elements.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. For
                bathrooms, styles like "Modern", "Spa", or "Contemporary"
                usually work well.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to have different bathroom
                design options.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better virtual home staging of a bathroom:
                <ul>
                  <li>
                    Ensure the photograph shows the entire bathroom layout
                    clearly for more accurate furnishing
                  </li>
                  <li>
                    Capture any unique features like vanities, shower areas, or
                    architectural details
                  </li>
                  <li>
                    Use the "Draw" option to indicate specific areas for
                    fixtures, countertop accessories, or decorative elements
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Example of how to create virtual home staging for a bathroom"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why do virtual home staging for a bathroom?
            </h2>
            <p className="article-text">
              The bathroom is one of the most important spaces in a home and can
              be a major selling point. Virtual home staging for bathrooms
              allows you to showcase the full potential of the space, creating
              spa-like and functional environments that help buyers envision
              themselves using and enjoying the space.
            </p>
            <p className="article-text">
              With good virtual home staging for a bathroom, you can:
            </p>
            <ul>
              <li>
                Transform basic or outdated bathrooms into luxurious spaces
              </li>
              <li>Highlight the functionality and layout of the room</li>
              <li>Showcase storage solutions and counter space potential</li>
              <li>
                Help buyers imagine a relaxing and refreshing personal space
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging for bathrooms
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings that can be
              applied to bathrooms:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBathroom;
