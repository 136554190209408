import React from "react";

function ApiDocsEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Documentación de la API de Pedra.so
          </h1>
          <h2 className="title-secondary gray">
            API para crear imágenes de decoración virtual de hogares
          </h2>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <p>
              Bienvenido a la documentación oficial de la API de Pedra.so.
              Nuestra API proporciona potentes capacidades de procesamiento de
              imágenes para diversas tareas de mejora y diseño de habitaciones.
            </p>
            <br />
            <h2 className="article-subtitleh2">URLs Base</h2>
            <p>Se puede acceder a la API a través de la siguiente URL base:</p>
            <ul>
              <li>
                Producción: <code>https://app.pedra.so/api</code>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Autenticación</h2>
            <p>
              Todas las llamadas a la API requieren un parámetro{" "}
              <code>apiKey</code> para la autenticación. Envía un correo
              electrónico a felix@pedra.so para solicitar tu clave de API.
            </p>
            <br />
            <h2 className="article-subtitleh2">Parámetros Comunes</h2>
            <p>Los siguientes parámetros son comunes a todos los endpoints:</p>
            <ul>
              <li>
                <code>apiKey</code> (string, obligatorio): Tu clave de API única
                para autenticación.
              </li>
              <li>
                <code>imageUrl</code> (string, obligatorio): URL de la imagen a
                procesar.
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Endpoints</h2>

            <h3 className="article-subtitle">Ejecutar Mejora</h3>
            <p>
              Mejora la calidad, el color, la luz y la resolución de la imagen
              proporcionada.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/enhance</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parámetros:</strong> Parámetros comunes (ver arriba)
            </p>
            <p>
              <strong>Tiempo de respuesta esperado:</strong> Entre 25 segundos y
              1 minuto
            </p>

            <br />
            <h3 className="article-subtitle">Ejecutar Habitación Vacía</h3>
            <p>
              Elimina muebles y objetos de la habitación en la imagen
              proporcionada.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/empty_room</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parámetros:</strong> Parámetros comunes (ver arriba)
            </p>
            <p>
              <strong>Tiempo de respuesta esperado:</strong> Entre 2 y 3 minutos
            </p>
            <br />
            <h3 className="article-subtitle">Ejecutar Amueblado</h3>
            <p>
              Amuebla (crea una decoración virtual) de un espacio según los
              parámetros especificados.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/furnish</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parámetros:</strong>
            </p>
            <ul>
              <li>Parámetros comunes (ver arriba)</li>
              <li>
                <code>roomType</code> (string, obligatorio): Tipo de habitación
                a amueblar.
              </li>
              <li>
                <code>style</code> (string, obligatorio): Estilo de diseño a
                aplicar.
              </li>
            </ul>
            <p>
              <strong>Tiempo de respuesta esperado:</strong> Entre 2 y 3 minutos
            </p>

            <h4>
              Valores posibles para <code>roomType</code>:
            </h4>
            <ul>
              <li>"Bedroom" (Dormitorio)</li>
              <li>"Living room" (Sala de estar)</li>
              <li>"Kitchen" (Cocina)</li>
              <li>"Office" (Oficina)</li>
              <li>"Terrace" (Terraza)</li>
              <li>"Dining room" (Comedor)</li>
              <li>"Kitchen + Living room" (Cocina + Sala de estar)</li>
              <li>"Dining + Living room" (Comedor + Sala de estar)</li>
              <li>"Entrance" (Entrada)</li>
            </ul>

            <h4>
              Valores posibles para <code>style</code>:
            </h4>
            <ul>
              <li>"Traditional" (Tradicional)</li>
              <li>"Minimalist" (Minimalista)</li>
              <li>"Scandinavian" (Escandinavo)</li>
              <li>"Mid-century" (Mediados de siglo)</li>
              <li>"Bohemian" (Bohemio)</li>
              <li>"Industrial" (Industrial)</li>
              <li>"Mediterranean" (Mediterráneo)</li>
              <li>"Modern" (Moderno)</li>
              <li>"Pyrenees" (Pirineos)</li>
            </ul>
            <br />
            <h3 className="article-subtitle">Ejecutar Renovación</h3>
            <p>Renueva una habitación según el estilo especificado.</p>
            <p>
              <strong>Endpoint:</strong> <code>/renovation</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parámetros:</strong>
            </p>
            <ul>
              <li>Parámetros comunes (ver arriba)</li>
              <li>
                <code>style</code> (string, obligatorio): Estilo de diseño a
                aplicar para la renovación.
              </li>
              <li>
                <code>preserveWindows</code> (boolean, obligatorio): Si se deben
                preservar las ventanas existentes durante la renovación.
              </li>
            </ul>
            <p>
              <strong>Tiempo de respuesta esperado:</strong> Entre 25 segundos y
              2 minutos
            </p>
            <br />
            <h2 className="article-subtitleh2">Formato de Respuesta</h2>
            <p>
              Todos los endpoints de la API devuelven respuestas JSON. Una
              respuesta exitosa tendrá un código de estado 200 e incluirá la
              información de la imagen procesada.
            </p>
            <br />
            <h2 className="article-subtitleh2">Manejo de Errores</h2>
            <p>
              En caso de error, la API devolverá un código de estado HTTP
              apropiado junto con una respuesta JSON que contiene detalles del
              error.
            </p>
            <br />
            <h2 className="article-subtitleh2">Soporte</h2>
            <p>
              Para cualquier pregunta o problema relacionado con la API, por
              favor contacta a nuestro equipo de soporte en felix@pedra.so.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApiDocsEs;
