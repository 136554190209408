import React from "react";

const PhotographyPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.7442 12.8846C13.0308 12.8846 11.6375 13.8654 11.0577 15.3221V12.0106C11.3663 12.1865 11.7125 12.276 12.0615 12.276C13.5298 12.276 14.0779 10.9692 14.0779 10.2567C14.0779 10.101 14.0721 9.32212 13.3654 8.72212C14.5452 8.62981 15.2288 7.6375 15.2288 6.71154C15.2288 6.50385 15.1971 6.29327 15.1308 6.08846C14.8538 5.23462 14.0606 4.69231 13.2096 4.69231C12.7048 4.69231 12.3442 4.87692 12.1538 4.99231C12.1913 4.83942 12.2115 4.68077 12.2115 4.51923C12.2115 3.40288 11.3087 2.5 10.1923 2.5C9.07596 2.5 8.17308 3.40288 8.17308 4.51923C8.17308 4.68365 8.19327 4.84231 8.23077 4.99231C8.04038 4.87404 7.67692 4.69231 7.175 4.69231C6.32404 4.69231 5.53365 5.23462 5.25385 6.08846C5.1875 6.29615 5.15577 6.50673 5.15577 6.71154C5.15577 7.64038 5.84808 8.62981 7.01923 8.72212C6.31538 9.32212 6.30673 10.101 6.30673 10.2567C6.30673 10.9577 6.85192 12.276 8.32308 12.276C8.67212 12.276 9.02115 12.1865 9.32692 12.0106V15.3221C8.75 13.8654 7.35385 12.8846 5.64038 12.8846L5 13.525L8.975 17.5H11.4125L15.3875 13.525L14.7471 12.8846H14.7442ZM10.1923 8.84615C9.55481 8.84615 9.03846 8.32981 9.03846 7.69231C9.03846 7.05481 9.55481 6.53846 10.1923 6.53846C10.8298 6.53846 11.3462 7.05481 11.3462 7.69231C11.3462 8.32981 10.8298 8.84615 10.1923 8.84615Z"
        fill="#A37EE3"
      ></path>
    </svg>
  );
};

export default PhotographyPricingIcon;
