import React from "react";

const OrangeWatermarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M25.5 13.6875V14.5625L14.5625 25.5H13.6875V24.1875C13.6875 18.3906 18.3906 13.6875 24.1875 13.6875H25.5ZM24.1875 11.9375H25.045C23.7325 7.6325 19.7337 4.5 15 4.5C9.20312 4.5 4.5 9.20312 4.5 15C4.5 19.7337 7.6325 23.7325 11.9375 25.045V24.1875C11.9375 17.4325 17.4325 11.9375 24.1875 11.9375Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangeWatermarkIcon;
