import React from "react";
import ReleaseJul2024Frame1 from "../Images/ReleaseJul2024Frame1.png";
import ReleaseJul2024Frame2 from "../Images/ReleaseJul2024Frame2.png";
import ReleaseJul2024Frame3 from "../Images/ReleaseJul2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJul24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JULY 2024</div>
          <h1 className="article-titleh1">
            Pedra: Add 3D objects, make sky blue in 1 click, and enhance or
            enlight images automatically
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustrative cover about AI and the real estate sector"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Here are the releases for this July – I hope you get excited to
              try them on Pedra!
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Add 3D objects to your photos
              </h2>
              Now you can choose the furniture to add to your photos. With the
              Add Object option, you can select its size, orientation, and
              arrangement. You can see how{" "}
              <Link to="/help/how-to-add-3d-objects" className="article-link">
                to use add 3D objects here
              </Link>
              .
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame1}
                  alt="Renew spaces with AI from mobile"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Clear the sky in your photos in 1 click
              </h2>
              We all want our photos to show a clear and beautiful sky. Now you
              can put a blue sky in your photos with just 1 click and ~30
              seconds.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame2}
                  alt="Move and edit the watermark transparency"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                We have combined the Beautify and Illuminate options
              </h2>
              By clicking Beautify, Pedra will detect if the photos have low
              brightness and automatically improve the light, color, and
              contrast.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJul2024Frame3}
                alt="Change the caption of an AI creation"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>

            <div>
              <li>
                Better quality when preserving windows in "Advanced Renovation"
              </li>
              <li>
                Reduced errors in "Advanced Renovation" with preserving windows
              </li>
              <li>More intuitive experience when painting over images</li>
              <li>Option to download invoices from settings</li>
              <li>Usability improvements and various fixes</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJul24;
