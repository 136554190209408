import React from 'react';

const RedPlantIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M21.3707 19.0385C18.9718 19.0385 17.0213 20.4115 16.2095 22.451V17.8148C16.6416 18.0612 17.1263 18.1863 17.6149 18.1863C19.6705 18.1863 20.4378 16.3569 20.4378 15.3594C20.4378 15.1413 20.4297 14.051 19.4403 13.211C21.092 13.0817 22.0491 11.6925 22.0491 10.3962C22.0491 10.1054 22.0047 9.81058 21.9118 9.52385C21.5241 8.32846 20.4136 7.56923 19.2222 7.56923C18.5155 7.56923 18.0107 7.82769 17.7441 7.98923C17.7966 7.77519 17.8249 7.55308 17.8249 7.32692C17.8249 5.76404 16.5609 4.5 14.998 4.5C13.4351 4.5 12.1711 5.76404 12.1711 7.32692C12.1711 7.55712 12.1993 7.77923 12.2518 7.98923C11.9853 7.82365 11.4765 7.56923 10.7738 7.56923C9.58241 7.56923 8.47588 8.32846 8.08414 9.52385C7.99126 9.81462 7.94684 10.1094 7.94684 10.3962C7.94684 11.6965 8.91607 13.0817 10.5557 13.211C9.5703 14.051 9.55818 15.1413 9.55818 15.3594C9.55818 16.3408 10.3215 18.1863 12.3811 18.1863C12.8697 18.1863 13.3584 18.0612 13.7865 17.8148V22.451C12.9788 20.4115 11.0241 19.0385 8.6253 19.0385L7.72876 19.935L13.2938 25.5H16.7063L22.2713 19.935L21.3747 19.0385H21.3707ZM14.998 13.3846C14.1055 13.3846 13.3826 12.6617 13.3826 11.7692C13.3826 10.8767 14.1055 10.1538 14.998 10.1538C15.8905 10.1538 16.6134 10.8767 16.6134 11.7692C16.6134 12.6617 15.8905 13.3846 14.998 13.3846Z" fill="#EB5757"/>
    </svg>
  );
};

export default RedPlantIcon;