import React from "react";

const OrangeSettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M27 15.9238V14.0762L24.0716 13.3464C23.9792 12.8476 23.8499 12.3626 23.6836 11.8961L25.8545 9.79908L24.9307 8.20092L22.03 9.03233C21.7067 8.64896 21.351 8.2933 20.9677 7.96998L21.7991 5.06928L20.2009 4.1455L18.1039 6.3164C17.6374 6.15012 17.1524 6.01617 16.6536 5.92841L15.9238 3H14.0762L13.3464 5.92841C12.8476 6.02079 12.3626 6.15012 11.8961 6.3164L9.79908 4.1455L8.20092 5.06928L9.03233 7.96998C8.64896 8.2933 8.2933 8.64896 7.96998 9.03233L5.06928 8.20092L4.1455 9.79908L6.3164 11.8961C6.15012 12.3626 6.01617 12.8476 5.92841 13.3464L3 14.0762V15.9238L5.92841 16.6536C6.02079 17.1524 6.15012 17.6374 6.3164 18.1039L4.1455 20.2009L5.06928 21.7991L7.96998 20.9677C8.2933 21.351 8.64896 21.7067 9.03233 22.03L8.20092 24.9307L9.79908 25.8545L11.8961 23.6836C12.3626 23.8499 12.8476 23.9838 13.3464 24.0716L14.0762 27H15.9238L16.6536 24.0716C17.1524 23.9792 17.6374 23.8499 18.1039 23.6836L20.2009 25.8545L21.7991 24.9307L20.9677 22.03C21.351 21.7067 21.7067 21.351 22.03 20.9677L24.9307 21.7991L25.8545 20.2009L23.6836 18.1039C23.8499 17.6374 23.9838 17.1524 24.0716 16.6536L27 15.9238ZM8.52425 15C8.52425 13.1062 9.3418 11.4111 10.6351 10.2286L13.388 15L10.6351 19.7714C9.3418 18.5889 8.52425 16.8938 8.52425 15ZM15.7898 13.6143L13.0323 8.83834C13.6513 8.64434 14.3072 8.53349 14.9908 8.53349C18.0854 8.53349 20.6721 10.709 21.3048 13.6143H15.7898ZM15.7898 16.3857H21.3048C20.6721 19.291 18.0854 21.4665 14.9908 21.4665C14.3072 21.4665 13.6513 21.3603 13.0323 21.1617L15.7898 16.3857Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangeSettingsIcon;
