import React from "react";
import Frame1RealEstateAgents from "./Images/Frame 1 RealEstateAgents.png";
import { ReactComponent as ChairIcon } from "./Images/Chair icon.svg";
import { ReactComponent as CleanIcon } from "./Images/Clean icon.svg";
import { ReactComponent as BrandIcon } from "./Images/Brand icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import Frame2RealEstateAgents from "./Images/Frame 2 RealEstateAgents.jpeg";
import Frame3RealEstateAgents from "./Images/Frame 3 RealEstateAgents.png";
import Frame4RealEstateAgents from "./Images/Frame 4 RealEstateAgents.png";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RealEstateAgentsFr() {
  return (
    <div className="body-real-estate">
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Accélérez vos ventes immobilières
              </h1>
              <div className="section-first-text">
                Attirez les acheteurs, obtenez plus de visites et vendez plus
                rapidement. Pedra vous aide à mettre en valeur le potentiel de
                chaque propriété en un clic.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Essayez Pedra <ArrowIcon />
                </button>
              </div>
            </div>
            <div className="header-picture">
              <img
                src={Frame1RealEstateAgents}
                alt="Outil pour agents immobiliers – Capture d'écran du logiciel"
              ></img>
            </div>
          </section>
        </section>

        <div style={{ borderTop: "1px solid #EEEEEE", height: "20px" }}></div>

        <div className="section-one-column-left">
          <div
            className="title-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignText: "left",
            }}
          >
            <div className="">
              Un outil de marketing immobilier sans pareil.
            </div>
            <div className="title-secondary">
              Pedra permet à toute entreprise immobilière d'améliorer facilement
              ses annonces de biens.
            </div>
            <div className="title-secondary">
              Aucune compétence spéciale requise — il suffit de télécharger vos
              images et de commencer !
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <RenovateIcon />
              <div className="feature-title">Rénovez avec l'IA</div>
              <div className="feature-text">
                Générez des rendus IA en un clic. Les acheteurs veulent voir des
                propositions de rénovation avant d'acheter. Pedra offre des
                rendus bon marché, rapides et de haute qualité pour montrer à
                vos clients des idées de rénovation.
              </div>
            </div>
            <img
              src={FrameRenovateRealEstateAgents}
              alt="Capture d'écran du logiciel de rendu Pedra – Fonctionnalités immobilières"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="title-container">Simple mais puissant</div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <ChairIcon />
              <div className="feature-title">Home staging</div>
              <div className="feature-text">
                Vendez un rêve. Ajoutez des meubles aux pièces vides en un clic.
                Les clients ont du mal à comprendre les contraintes d'espace.
              </div>
            </div>
            <img
              src={Frame2RealEstateAgents}
              alt="Capture d'écran du logiciel de rendu Pedra pour le home staging virtuel"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-small">
                <div className="feature-text-container">
                  <CleanIcon />
                  <div className="feature-title">Gardez-le propre</div>
                  <div className="feature-text">
                    Utilisez nos outils IA pour supprimer des éléments des
                    images.
                  </div>
                </div>
                <img
                  src={Frame4RealEstateAgents}
                  alt="Capture d'écran du logiciel de rendu Pedra pour la suppression d'objets"
                />
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-small">
                <div className="feature-text-container">
                  <BrandIcon />
                  <div className="feature-title">Protégez votre marque</div>
                  <div className="feature-text">
                    Utilisez notre canevas pour ajouter des filigranes aux
                    images.
                  </div>
                </div>
                <img
                  src={Frame3RealEstateAgents}
                  alt="Capture d'écran du logiciel de rendu Pedra pour l'ajout de filigrane immobilier"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "L'utilisation de Pedra pour éditer nos images nous aide à obtenir
              plus de visites !"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logo de Fincas Lluria pour Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">
                  Agence immobilière Fincas Llúria
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mid-break"> </div>

        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Essayez Pedra
          </button>
        </div>
      </div>
    </div>
  );
}

export default RealEstateAgentsFr;
