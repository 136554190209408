import React from "react";
import ReleaseOct2024Frame1 from "../Images/ReleaseOct2024Frame1.png";
import ReleaseOct2024Frame2 from "../Images/ReleaseOct2024Frame2.png";
import ReleaseOct2024Frame3 from "../Images/ReleaseOct2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsOct24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">OCTUBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra: Mejoras en piscinas, preservación de espacios y renovación
            selectiva
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Foto de Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Este octubre os traemos mejoras para sacarle más utilidad a Pedra:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Añade agua a las piscinas</h2>
              No muestres piscinas vacias – eso no ayuda a vender. Ahora con
              Pedra podrás rellenar las picinas para mostrar tu propiedad en su
              mejor estado.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame1}
                  alt="Ejemplo de piscina con agua"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mejor preservación del suelo y paredes al vaciar espacios
              </h2>
              Antes Pedra a veces modificaba los materiales de los suelos y
              paredes. Hemos hecho algunos cambios para que eso ya no sea así.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame2}
                  alt="Ejemplo de preservación de suelos y paredes"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Preserva partes de una imagen al renovar
              </h2>
              Ahora puedes indicar a Pedra qué partes exactas quieres que Pedra
              preserve. Ideal para preservar el estado de los edificios de al
              lado de nuestra propiedad.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame3}
                  alt="Ejemplo de preservación selectiva"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>
            <div>
              <li>Mejores resultados al borrar objetos pequeños</li>
              <li>
                Mejor preservación de la arquitectura de los espacios al renovar
                con creatividad baja
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsOct24;
