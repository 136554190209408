import React from 'react';

const BlueEyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
        <path d="M12.2857 9.71429C12.2857 10.9771 11.2629 12 10 12C8.73714 12 7.71429 10.9771 7.71429 9.71429C7.71429 8.45143 8.73714 7.42857 10 7.42857C11.2629 7.42857 12.2857 8.45143 12.2857 9.71429ZM18 9.71429C18 9.71429 14.8771 15.4286 10 15.4286C5.12286 15.4286 2 9.71429 2 9.71429C2 9.71429 5.12286 4 10 4C14.8771 4 18 9.71429 18 9.71429ZM14 9.71429C14 7.50571 12.2086 5.71429 10 5.71429C7.79143 5.71429 6 7.50571 6 9.71429C6 11.9229 7.79143 13.7143 10 13.7143C12.2086 13.7143 14 11.9229 14 9.71429Z" fill="#57A4EB"/>
    </svg>
  );
};

export default BlueEyeIcon;