import React from 'react';

const ToggleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M18.7568 14.75C18.7568 14.4907 18.6576 14.2695 18.4593 14.0712L12.5102 8.25169C12.3424 8.08389 12.1364 8 11.8924 8C11.3966 8 11 8.38136 11 8.87711C11 9.12118 11.0992 9.34236 11.267 9.51779L16.6364 14.75L11.267 19.9822C11.1068 20.15 11 20.3711 11 20.6152C11 21.1186 11.3966 21.5 11.8924 21.5C12.1364 21.5 12.3424 21.4161 12.5102 21.2483L18.4593 15.4288C18.6653 15.2305 18.7568 15.0093 18.7568 14.75Z" fill="black"/>
    </svg>
  );
};

export default ToggleIcon;