// 404Page.js
import React from 'react';

const NotFoundEs = () => {
  return (
    <section className='section-first-wrapper'>
        <div>
        <h1>404 - Página no encontrada</h1>
        <p>Lo sentimos, la página que estabas buscando no existe.</p>
        </div>
    </section>
  );
}

export default NotFoundEs;
