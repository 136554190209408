import React from "react";

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.00029C9.73471 8.00029 9.48028 7.8949 9.29269 7.70731C9.1051 7.51972 8.99971 7.26529 8.99971 7C8.99971 6.73471 9.1051 6.48028 9.29269 6.29269C9.48028 6.1051 9.73471 5.99971 10 5.99971C10.2653 5.99971 10.5197 6.1051 10.7073 6.29269C10.8949 6.48028 11.0003 6.73471 11.0003 7C11.0003 7.26529 10.8949 7.51972 10.7073 7.70731C10.5197 7.8949 10.2653 8.00029 10 8.00029ZM10.8571 13.4286C10.8571 13.6559 10.7668 13.8739 10.6061 14.0347C10.4453 14.1954 10.2273 14.2857 10 14.2857C9.77267 14.2857 9.55465 14.1954 9.39391 14.0347C9.23317 13.8739 9.14286 13.6559 9.14286 13.4286V10C9.14286 9.77267 9.23317 9.55465 9.39391 9.39391C9.55465 9.23317 9.77267 9.14286 10 9.14286C10.2273 9.14286 10.4453 9.23317 10.6061 9.39391C10.7668 9.55465 10.8571 9.77267 10.8571 10V13.4286ZM10 4C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10C4 11.5913 4.63214 13.1174 5.75736 14.2426C6.88258 15.3679 8.4087 16 10 16C11.5913 16 13.1174 15.3679 14.2426 14.2426C15.3679 13.1174 16 11.5913 16 10C16 8.4087 15.3679 6.88258 14.2426 5.75736C13.1174 4.63214 11.5913 4 10 4Z"
        fill="#2383E2"
      />
    </svg>
  );
};

export default InfoIcon;
