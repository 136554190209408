import React from "react";

function Privacy() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Privacy Policy</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Privacy Policy for Pedra.so
              </h2>
              <div className="article-text">
                This Privacy Policy explains how Pedra.so ("we," "us," or "our")
                collects, uses, shares, and protects your personal information
                when you use our application. By using Pedra.so, you agree to
                the terms outlined in this Privacy Policy.
              </div>
              <br />
              <h2 className="article-subtitleh2">Information We Collect:</h2>
              <div className="article-text">
                1. <strong>Personal Information:</strong> We may collect
                personal information that you provide directly, such as your
                name, email address, and contact details when you register for
                an account or contact us.
                <br />
                2. <strong>Usage Information:</strong> We may collect
                information about your interactions with our application,
                including the pages you visit, the features you use, and the
                actions you take.
                <br />
                3. <strong>Device Information:</strong> We may collect
                information about the device you use to access our application,
                including device type, operating system, and unique device
                identifiers.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                How We Use Your Information:
              </h2>
              <div className="article-text">
                We may use your information for the following purposes:
                <br />
                1. To provide and improve our services.
                <br />
                2. To respond to your inquiries and requests.
                <br />
                3. To send you updates, newsletters, and promotional materials
                (if you have opted in).
                <br />
                4. To personalize your experience and provide content and
                features tailored to your interests.
              </div>
              <br />
              <h2 className="article-subtitleh2">Information Sharing:</h2>
              <div className="article-text">
                We may share your information in the following circumstances:
                <br />
                1. With third-party service providers who help us with the
                operation of our application and services.
                <br />
                2. To comply with legal obligations or respond to law
                enforcement requests.
                <br />
                3. In connection with a business transaction, such as a merger,
                acquisition, or sale of assets.
                <br />
              </div>
              <br />
              <h2 className="article-subtitleh2">Data Security:</h2>
              <div className="article-text">
                We take reasonable measures to protect your personal information
                from unauthorized access and disclosure. However, no method of
                data transmission or storage is entirely secure, and we cannot
                guarantee its absolute security.
              </div>
              <br />
              <h2 className="article-subtitleh2">Your Choices:</h2>
              <div className="article-text">
                You have the right to access, correct, or delete your personal
                information. You can also opt-out of receiving promotional
                emails at any time.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Changes to this Privacy Policy:
              </h2>
              <div className="article-text">
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with a revised date.
              </div>
              <br />
              <h2 className="article-subtitleh2">Contact Us:</h2>
              <div className="article-text">
                For any questions or proposals, contact us at the following
                email: <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
