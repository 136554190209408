import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

function HowToRenovateFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment utiliser l'outil de rénovation pour rénover virtuellement
            des maisons
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur comment rénover virtuellement des maisons
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi servent les outils de rénovation virtuelle de Pedra ?
            </h2>
            <p className="article-text">
              Avec les outils de rénovation de Pedra, vous pouvez afficher une
              rénovation potentielle d'une propriété en quelques secondes
              seulement.
              <p style={{ marginTop: "10px" }}>
                Montrer le potentiel d'une rénovation est idéal pour aider à
                vendre des propriétés d'occasion, qui nécessiteront probablement
                un bon investissement de la part de l'acheteur pour améliorer
                leur état.
              </p>
              <p style={{ marginTop: "10px" }}>
                Grâce à Pedra, il n'est plus nécessaire de faire appel à des
                concepteurs ou des architectes pour obtenir une proposition de
                rénovation pour une propriété.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment créer une proposition de rénovation virtuelle ?
            </h2>
            <p className="article-text">
              Une fois que vous avez téléchargé les images que vous souhaitez
              rénover, sélectionnez-les.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, et
                vous verrez les options pour{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> et{" "}
                <span style={{ fontWeight: "500" }}>"Advanced Renovation"</span>
                .
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation : </span>
              Générer une rénovation virtuelle rapide
            </h2>
            <p style={{ marginTop: "10px" }}>
              En cliquant sur{" "}
              <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
              générerez une proposition de rénovation pour les images que vous
              avez sélectionnées. Vous l'aurez en environ 15 secondes.
              <p>
                Par défaut, l'option{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> génère
                une rénovation minimaliste avec un faible niveau de changement.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation Avancée : </span>
              Générer une rénovation virtuelle selon vos préférences
            </h2>
            <p className="article-text">
              Pedra propose également l'option "Rénovation Avancée".
              <p style={{ marginTop: "10px" }}>
                Comme l'option{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
                pouvez générer des propositions de rénovation en quelques
                secondes.
                <p style={{ marginTop: "10px" }}>
                  De plus, vous pouvez choisir le style de rénovation, le niveau
                  de créativité pour les changements, et même si vous souhaitez
                  conserver certaines parties de l'image originale, comme les
                  fenêtres.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Style de rénovation avancée
            </h3>
            <p className="article-text">
              Pedra propose 9 styles de base de rénovation avancée :
              minimaliste, traditionnel, méditerranéen, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Vous pouvez également télécharger votre propre style de
                  rénovation.
                </span>{" "}
                En téléchargeant votre propre style de rénovation, vous pourrez
                créer des propositions de rénovation basées sur le style que
                vous avez téléchargé.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Créativité avancée de la rénovation
            </h3>
            <p className="article-text">
              Vous pouvez également choisir le niveau de créativité avancée de
              la rénovation entre Faible, Moyenne, Élevée et Très Élevée.
              <p style={{ marginTop: "10px" }}>
                Faible et Moyenne préserveront bien l'architecture de la
                photographie originale.
              </p>
              <p style={{ marginTop: "10px" }}>
                Élevée et Très Élevée sont conçues pour changer l'architecture
                de l'espace de manière agressive, donc la photographie
                résultante ne ressemblera pas à l'originale.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Préserver les fenêtres dans la rénovation avancée
            </h3>
            <p className="article-text">
              Vous avez également l'option de conserver les fenêtres lors de la
              rénovation avancée. C'est important si vous souhaitez maintenir
              les vues depuis les fenêtres ou si vous voulez qu'elles soient
              exactement les mêmes que dans la photographie originale.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Générer l'image de la rénovation avancée
            </h2>
            <p>
              Une fois que vous avez sélectionné le style, le niveau de
              créativité et si vous souhaitez ou non conserver les fenêtres,
              vous pouvez générer les images. Cela prendra environ 15 secondes
              par image, sauf si vous conservez les fenêtres, ce qui prendra un
              peu plus de temps.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Comment éviter les changements indésirables dans les fenêtres
                  et les portes
                </h4>
                <p className="callout-p">
                  Si des portes apparaissent là où il y avait des fenêtres, ou
                  s'il y a plus de fenêtres que prévu dans les images générées,
                  nous vous suggérons les actions suivantes :
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Générer 2 ou 3 rénovations supplémentaires
                      </span>
                      . Chaque rénovation est unique, donc certaines
                      préserveront mieux les fenêtres et les portes que
                      d'autres.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      {" "}
                      Gardez le niveau de créativité bas.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si la photographie originale a des reflets ou des
                        miroirs ressemblant à des fenêtres, utilisez l'outil
                        pour les supprimer de l'image
                      </span>
                      . Ensuite, générez la rénovation sur l'image sans reflets
                      ni miroirs.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenovateFr;
