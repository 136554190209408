import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageBathroomFrame1 from "../Images/HowToHomeStageBathroomFrame1Es.jpg";
import HowToHomeStageBathroomFrame2 from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageBathroomFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeBathroom.png";
import Example4After from "../Images/curtain-effect-hero/AfterBathroom.png";
import Example1Before from "../Images/curtain-effect/BeforeBathroomStaging1.png";
import Example1After from "../Images/curtain-effect/AfterBathroomStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeBathroomStaging2.png";
import Example2After from "../Images/curtain-effect/AfterBathroomStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeBathroomStaging3.png";
import Example3After from "../Images/curtain-effect/AfterBathroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBathroomEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual para baños
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual para espacios de
            baño
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual para un baño?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un home staging virtual para baños en pocos
              clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona "Baño" como tipo de espacio y en unos 2 o 3 minutos
                tendrás la imagen de tu home staging virtual de baño lista para
                descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageBathroomFrame1}
              alt="Cómo crear un home staging virtual de baño – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para baños?
            </h2>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-bano">
                home staging virtual de baño
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>Sube la imagen del baño que quieres amueblar virtualmente</li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageBathroomFrame2}
              alt="Menú de cómo crear un home staging virtual de baño"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los elementos
                </span>
                . Para baños, recomendamos usar "Dibujar" para indicar áreas
                específicas donde colocar accesorios, decoración y elementos de
                baño.
              </li>
              <li>
                Selecciona <span style={{ fontWeight: "500" }}>"Baño"</span>{" "}
                como tipo de espacio. Esto asegurará que Pedra coloque elementos
                apropiados como toallas, productos de baño y elementos
                decorativos.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                Para baños, estilos como "Moderno", "Spa" o "Contemporáneo"
                suelen funcionar bien.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes para tener opciones
                diferentes de diseño de baño.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para un mejor home staging virtual de baño:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente toda la
                    distribución del baño para un resultado más preciso
                  </li>
                  <li>
                    Captura cualquier característica única como lavabos, duchas
                    o detalles arquitectónicos
                  </li>
                  <li>
                    Usa la opción "Dibujar" para indicar áreas específicas para
                    accesorios, elementos de baño o elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageBathroomFrame4}
                    alt="Ejemplo de como crear un home staging virtual de baño"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué hacer un home staging virtual de baño?
            </h2>
            <p className="article-text">
              El baño es uno de los espacios más importantes de la casa y puede
              ser un punto decisivo en la venta. El home staging virtual de
              baños te permite mostrar todo el potencial del espacio, creando
              ambientes tipo spa y funcionales que ayudan a los compradores a
              imaginarse usando y disfrutando del espacio.
            </p>
            <p className="article-text">
              Con un buen home staging virtual de baño podrás:
            </p>
            <ul>
              <li>
                Transformar baños básicos o anticuados en espacios lujosos
              </li>
              <li>Destacar la funcionalidad y la distribución del espacio</li>
              <li>
                Mostrar soluciones de almacenamiento y el potencial de las
                superficies
              </li>
              <li>
                Ayudar a los compradores a imaginarse un espacio personal
                relajante y refrescante
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual de baños
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de home stagings virtuales que
              pueden aplicarse a baños:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual de un baño
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/dyZ9xxuu-fA?si=2bJ3gPBSyZUL00aH&amp;controls=0"
                  title="Home staging virtual baño video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBathroomEs;
