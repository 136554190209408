import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (same imports as before)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images (same imports as before)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeKitchen from "./Images/curtain-effect-hero/BeforeKitchen.png";
import AfterKitchen from "./Images/curtain-effect-hero/AfterKitchen.png";
import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeKitchenStaging1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterKitchenStaging1.png";
import BeforeKitchenStaging2 from "./Images/curtain-effect/BeforeKitchenStaging2.png";
import AfterKitchenStaging2 from "./Images/curtain-effect/AfterKitchenStaging2.png";
import BeforeKitchenStaging3 from "./Images/curtain-effect/BeforeKitchenStaging3.png";
import AfterKitchenStaging3 from "./Images/curtain-effect/AfterKitchenStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RenderKitchenEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Moderno",
    beforeImage: BeforeKitchen,
    afterImage: AfterKitchen,
  });

  const handleExample = (example) => {
    if (example === "Moderno") {
      setExample({
        name: "Moderno",
        beforeImage: BeforeKitchen,
        afterImage: AfterKitchen,
      });
    } else if (example === "Tradicional") {
      setExample({
        name: "Tradicional",
        beforeImage: BeforeKitchenStaging1,
        afterImage: AfterKitchenStaging1,
      });
    } else if (example === "Rústico") {
      setExample({
        name: "Rústico",
        beforeImage: BeforeKitchenStaging2,
        afterImage: AfterKitchenStaging2,
      });
    } else {
      setExample({
        name: "Contemporáneo",
        beforeImage: BeforeKitchenStaging3,
        afterImage: AfterKitchenStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "¿Qué son los renders automáticos de cocinas?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Los renders automáticos de cocinas son visualizaciones impulsadas por IA que transforman fotos de cocinas en imágenes renderizadas profesionalmente. Esta tecnología te permite crear visualizaciones fotorrealistas de cocinas con diferentes estilos, acabados y diseños sin necesidad de modelado 3D manual o software complejo.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuáles son los beneficios de los renders automáticos de cocinas?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Los renders automáticos de cocinas ofrecen varios beneficios, incluyendo: transformación instantánea de espacios de cocina, capacidad para visualizar múltiples opciones de diseño rápidamente, resultados fotorrealistas de alta calidad, ahorro significativo de tiempo y costos en comparación con el renderizado tradicional, y flexibilidad para experimentar con diferentes estilos y configuraciones.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cómo funciona el proceso de renderizado automático de cocinas?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "El proceso implica subir fotos de la cocina actual a nuestra plataforma, seleccionar las transformaciones deseadas (como cambiar acabados, agregar elementos o modificar diseños) y recibir renders fotorrealistas que muestran la cocina transformada en minutos. Nuestra tecnología de IA maneja todo el trabajo complejo de renderizado automáticamente.",
        },
      },
      {
        "@type": "Question",
        name: "¿Cuánto cuestan los renders automáticos de cocinas?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Nuestro servicio de renderizado automático de cocinas está disponible por €29 más IVA al mes. Este plan te permite generar hasta 100 renders por mes, sin compromiso a largo plazo.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "ejemploRender1",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const stagingExamples = [
    {
      name: "ejemploRender1",
      beforeImage: BeforeKitchenStaging1,
      afterImage: AfterKitchenStaging1,
    },
    {
      name: "ejemploRender2",
      beforeImage: BeforeKitchenStaging2,
      afterImage: AfterKitchenStaging2,
    },
    {
      name: "ejemploRender3",
      beforeImage: BeforeKitchenStaging3,
      afterImage: AfterKitchenStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Renders de Cocina</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Crea renders fotorrealistas de cocinas automáticamente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantáneo:</strong> Genera renders en segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Sencillo:</strong> No requiere conocimientos
                    técnicos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realista:</strong> Resultados fotorrealistas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Soporte:</strong> Asistencia experta disponible
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Prueba Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 renders
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonios
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes del render de cocina"
                  altAfter="Después del render de cocina"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Moderno" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderno")}
                    >
                      Cocina Moderna
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Tradicional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Tradicional")}
                    >
                      Estilo Tradicional
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rústico" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rústico")}
                    >
                      Diseño Rústico
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporáneo" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporáneo")}
                    >
                      Look Contemporáneo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5.000 PROFESIONALES YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crea renders profesionales de cocinas{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automáticamente
          </span>{" "}
          en segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Transforma cocinas al instante
                </h3>
                <div className="feature-text">
                  Genera renders fotorrealistas con un solo clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Múltiples opciones de diseño</h3>
                <div className="feature-text">
                  Visualiza diferentes estilos y configuraciones fácilmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perspectivas perfectas</h3>
                <div className="feature-text">
                  Corrección y optimización automática de ángulos de cámara
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Iluminación automática</h3>
                <div className="feature-text">
                  Ajustes profesionales de iluminación en cada render
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Cambio de materiales</h3>
                <div className="feature-text">
                  Actualiza superficies y acabados automáticamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpieza de escena</h3>
                <div className="feature-text">
                  Elimina objetos no deseados automáticamente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Ejemplos de nuestros renders automáticos de cocinas.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Antes del render de cocina"
            altAfter="Después del render de cocina"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          ¿Cómo funcionan los renders automáticos de cocinas?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 1
            </h5>
            <h2>Sube tu foto de cocina</h2>
            <div className="value-prop-text">
              Sube cualquier foto de tu cocina a la plataforma de Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Cómo subir fotos de cocina para renderizado automático en Pedra – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 2
            </h5>
            <h2>Elige tus opciones de renderizado</h2>
            <div className="value-prop-text">
              Selecciona cómo quieres transformar tu cocina.
              <p>
                Cambia materiales, actualiza la iluminación, modifica el diseño,
                añade elementos y más.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Cómo elegir opciones para el renderizado automático de cocinas – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASO 3
            </h5>
            <h2>Obtén tus renders fotorrealistas</h2>
            <div className="value-prop-text">
              Recibe tus imágenes de cocina renderizadas profesionalmente en
              segundos.
              <p>Descarga y usa tus renders inmediatamente.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Cómo descargar renders automáticos de cocina – Infografía"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Renders profesionales de cocina por €29 al mes. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sin compromiso de permanencia.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                al mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 renders
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>al mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transforma cocinas al instante
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Cambia materiales y acabados
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Añade nuevos elementos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpieza automática de escena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Salida en alta resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Múltiples opciones de diseño
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminación profesional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Proyectos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Marcas de agua personalizadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte prioritario
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Renders adicionales disponibles por €0,20 cada uno si los
              necesitas.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Preguntas sobre los renders automáticos de cocinas
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                ¿Qué son los renders automáticos de cocinas?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Los renders automáticos de cocinas utilizan tecnología de IA
                avanzada para transformar fotos normales de cocinas en
                visualizaciones profesionales renderizadas. Esta tecnología te
                permite crear renders de alta calidad sin necesidad de modelado
                3D manual o software complejo.
                <br />
                <br />
                El sistema maneja automáticamente la iluminación, materiales,
                perspectivas y otros aspectos técnicos para producir resultados
                profesionales al instante.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Cuáles son los beneficios de los renders automáticos de
                cocinas?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Los renders automáticos de cocinas ofrecen numerosas ventajas:
                <br />
                <br />
                1. Velocidad: Genera renders en segundos, no en horas o días
                <br />
                2. Rentable: No necesitas software de modelado 3D costoso
                <br />
                3. Fácil de usar: No requiere experiencia técnica
                <br />
                4. Múltiples opciones: Prueba diferentes estilos rápidamente
                <br />
                5. Resultados fotorrealistas: Salida de calidad profesional
                <br />
                6. Flexibilidad: Realiza cambios al instante
                <br />
                7. Consistencia: Resultados fiables en cada uso
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Qué puedo hacer con los renders de cocina de Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                El sistema de renderizado automático de cocinas de Pedra ofrece
                una amplia gama de capacidades:
                <br />
                <br />
                1. Generar renders: Crea visualizaciones fotorrealistas desde
                cualquier foto de cocina al instante.
                <br />
                2. Optimización de iluminación: Mejora automáticamente la
                iluminación y sombras para resultados profesionales.
                <br />
                3. Cambios de materiales: Modifica superficies, encimeras,
                armarios y otros materiales con un clic.
                <br />
                4. Añadir elementos: Inserta nuevos electrodomésticos,
                accesorios y elementos decorativos automáticamente.
                <br />
                5. Limpieza de escena: Elimina objetos no deseados y desorden
                automáticamente.
                <br />
                6. Visualización de diseños: Prueba diferentes configuraciones
                de cocina rápidamente.
                <br />
                <br />
                Todas las transformaciones son gestionadas automáticamente por
                nuestra IA, entregando resultados profesionales en segundos.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderKitchenEs;
