import React from 'react';

const RedBrushIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M21.4986 18.78L22.62 25.5H11.7L12.2586 22.14H10.5786L10.02 25.5H7.5L8.6214 18.78H21.5028H21.4986ZM16.32 14.58V4.5H13.8V14.58H7.5V17.1H22.62V14.58H16.32Z" fill="#EB5757"/>
    </svg>
  );
};

export default RedBrushIcon;