import React from "react";
import Article5Frame from "../Images/Article5Frame.jpeg";
import Article5Frame1 from "../Images/Article5Frame1.jpeg";
import Article5Frame2 from "../Images/Article5Frame2.jpeg";
import Article5Frame3 from "../Images/Article5Frame3.jpeg";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle5() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              How to Ensure Your Real Estate Listing Cover Photo Speeds Up the
              Sale
            </h1>
            <div className="title-secondary gray">
              Choose and generate cover photos in your real estate listings that
              catch attention and help you sell
            </div>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Founder of Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Founder at Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article5Frame}
                alt="Article cover on how AI can help real estate agents sell"
              />
            </div>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Nowadays, photos are key to selling properties. People browse
                apartments through digital real estate portals from their
                phones, whether on the train to work or sitting on the couch at
                the end of the day.
              </div>
              <div style={{ marginTop: "10px" }}>
                People swipe their fingers on their phone, like on Instagram or
                Twitter, looking for something that catches their eye.
              </div>
              <div style={{ marginTop: "10px" }}>
                And there are many ways to catch attention, but one of the most
                effective is through photos.
              </div>
              <div style={{ marginTop: "10px" }}>
                As real estate agents, we must ensure that the first photo they
                see is the one that generates the most positive sensations and
                attention.
              </div>
              <div style={{ marginTop: "10px" }}>
                And thus, they visit the ad and we can close the sale quickly.
              </div>
              <div style={{ marginTop: "10px" }}>
                To grab attention with the first photo, you must follow 3 simple
                steps:
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 —</span>{" "}
                Identify the type of people we will attract with that property
              </h2>
              <div style={{ marginTop: "10px" }}>
                Is it a flat to renovate? Is it a spectacular villa? Is it a
                rental apartment?
              </div>
              <div style={{ marginTop: "10px" }}>
                Depending on the type of property, different types of people
                will be interested.
              </div>
              <div style={{ marginTop: "10px" }}>
                We need to identify which people will be drawn to that flat to
                capture their attention.
              </div>
              <div style={{ marginTop: "10px" }}>
                For example, if we are selling a villa in a residential area of
                Madrid, surely affluent families with children will be
                interested. If it is a flat for sale in the city center to be
                renovated, it will attract investors or people looking to start
                living as a couple.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 —</span>{" "}
                Choose the photograph for those people
              </h2>
              <div style={{ marginTop: "10px" }}>
                Now that we know who will be interested in our property, we need
                to find the photographs that most attract them.
              </div>
              <div style={{ marginTop: "10px" }}>
                If we are trying to sell a villa perfect for families, highlight
                the photos that show the best aspects of the villa. Does it have
                a pool and garden? Place a photo where they are clearly visible.
                Does it have a spectacular living room perfect for family
                evenings? Put that as the first photo.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 —</span>{" "}
                Enhance and show the potential of the photograph
              </h2>
              <div style={{ marginTop: "10px" }}>
                Getting the photograph is not the last step; we must ensure that
                it shows the maximum potential for the viewer.
              </div>
              <div style={{ marginTop: "10px" }}>
                Remember, people scan portals, and we need to capture enough
                attention for them to click on the ad.
              </div>
              <div style={{ marginTop: "10px" }}>
                If we have the photo of our villa for sale for families, but we
                took it when the grass was not good and the weather was bad,
                make sure to use Pedra to{" "}
                <Link to="/real-estate-photography" className="article-link">
                  make the grass green and the sky
                </Link>{" "}
                clear.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame1}
                  alt="Before and after a blue sky and green grass with AI"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                If we are selling a flat to renovate, show the renovation
                proposal in the first image. Nowadays, you can{" "}
                <Link to="/render" className="article-link">
                  renovate properties with AI{" "}
                </Link>
                (artificial intelligence) quickly and easily, without leaving
                your computer or mobile. Take advantage of it.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame2}
                  alt="Before and after a renovation with AI"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                And if you have an unfurnished rental apartment, show how it
                would look furnished. This will give a more attractive view of
                the flat, and potential tenants will be more eager to see your
                ad. And the best part is that you can{" "}
                <Link to="/home-staging-virtual" className="article-link">
                  {" "}
                  furnish apartments digitally using Pedra's AI
                </Link>
                .
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame3}
                  alt="Before and after virtual furnishing with AI"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hello! I'm Felix Ingla, the founder of{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , an innovative virtual home staging and real estate photography
              web application that is transforming how properties are presented
              in the digital world. My dedication to creating cutting-edge
              technology and intuitive solution has established Pedra as a
              trusted tool for real estate professionals seeking to enhance
              their property listings.
            </p>
            <p>
              If you are eager to connect and interested in discussing real
              estate technology, please reach out via my{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . You can also read more{" "}
              <Link className="article-link" to="/about">
                about Pedra here
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle5;
