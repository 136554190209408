import React from 'react';

const OrangeFeatherIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M24.75 4.50408V10.3601C24.75 12.2918 24.208 13.604 22.8387 14.9692L17.6225 20.1854C16.5996 21.2083 15.6134 21.6158 14.1626 21.6158H10.988L7.72789 24.876L6 23.1481L16.9745 12.1736L15.8212 11.0203L8.44512 18.3557L7.63008 17.5406V14.2112C7.63008 13.388 7.86237 12.8256 8.44512 12.2429C9.02787 11.6601 9.59025 11.4278 10.4134 11.4278H10.4827L10.4949 14.0115L12.1128 12.3937V9.92817C12.1128 8.96234 12.3858 8.30624 13.0664 7.6216L14.2767 6.41127C15.6419 5.04608 16.9541 4.5 18.8858 4.5H24.7418L24.75 4.50408Z" fill="#F8792A"/>
    </svg>
  );
};

export default OrangeFeatherIcon;