import React from "react";

const EmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    ></svg>
  );
};

export default EmptyIcon;
