import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomerLogos from "./CustomerLogos.js";
import EnlightRooms from "./Images/EnlightRooms.png";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import HomeAmueblaEstanciasEn1Click from "./Images/HomeAmueblaEstanciasEn1Click.png";
import HomeVaciaEstanciasEn1Click from "./Images/HomeVaciaEstanciasEn1Click.png";
import HomeStaging1 from "./Images/HomeStaging1.jpeg";
import HomeStaging2 from "./Images/HomeStaging2.png";
import HomeStaging3 from "./Images/HomeStaging3.png";
import HomeStaging4 from "./Images/HomeStaging4.png";
import HDImage from "./Images/HDImage.png";
import FrameFloorPlanFeature from "./Images/FrameFloorPlanFeature.png";
import UploadAnImage from "./Images/UploadAnImage.png";
import PickAnOption from "./Images/PickAnOption.png";
import WaitAndDownloadResult from "./Images/WaitAndDownloadResult.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import CurtainEffect from "./CurtainEffect.js";

// Icons
import { ReactComponent as HouseIcon } from "./Images/House icon.svg";
import { ReactComponent as ColorPaletteIcon } from "./Images/Color palette icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeRollerIcon from "./Icons/OrangeRollerIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangePenIcon from "./Icons/OrangePenIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import OrangeVirtualVideoIcon from "./Icons/OrangeVirtualVideoIcon.jsx";
import OrangeSettingsIcon from "./Icons/OrangeSettingsIcon.jsx";
import OrangeWatermarkIcon from "./Icons/OrangeWatermarkIcon.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeFr(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    console.log(example);
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
      console.log("Furnish");
    } else if (example === "Renovate") {
      setExample({
        name: "Renovate",
        beforeImage: BeforeRenovate,
        afterImage: AfterRenovate,
      });
    } else if (example === "Empty") {
      setExample({
        name: "Empty",
        beforeImage: BeforeEmpty,
        afterImage: AfterEmpty,
      });
    } else {
      setExample({
        name: "Enhance",
        beforeImage: BeforeEnhance,
        afterImage: AfterEnhance,
      });
    }
  };

  return (
    <div>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">
            Montrez le potentiel de vos propriétés{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
                borderBottom: "2px solid #ddd",
              }}
            >
              en 1 clic
            </span>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  L'outil de home staging virtuel que vous recherchez :
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Résultats en secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Intuitif dès le début
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyper-réalistes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance humaine si nécessaire
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la photographie chez aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent immobilier chez Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant de vider virtuellement la pièce"}
                  altAfter={"Après avoir vidé virtuellement la pièce"}
                />
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Meubler
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Empty" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Empty")}
                    >
                      <PurpleEmptyIcon />
                      Vider
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Renovate" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Renovate")}
                    >
                      <PurpleRenovateIcon />
                      Rénover
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Enhance" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Enhance")}
                    >
                      <PurpleEnhanceIcon />
                      Embellir
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Rendus avec l'IA</h2>
          <div className="title-secondary">
            Générez des rendus IA avec Pedra. <br /> Rénovez des espaces, videz
            des pièces, ou faites un home staging virtuel en 1 clic.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <RenovateIcon />
            <h2 className="feature-title">Rendus avec l'IA en 1 clic</h2>
            <div className="feature-text">
              Utilisez Pedra pour générer des rendus avec l'IA en 15 secondes.
              Accélérez vos ventes immobilières en montrant le vrai potentiel de
              vos propriétés. À quoi ressemblerait cette maison d'occasion après
              une rénovation ?
            </div>
          </div>
          <img
            src={FrameRenovateRealEstateAgents}
            alt="Outil de rendu IA – Capture d'écran du logiciel"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">Vider une pièce avec l'IA</h2>
                <div className="feature-text">
                  Utilisez l'IA de Pedra pour vider des pièces en 1 clic – comme
                  par magie.
                </div>
              </div>
              <img
                src={HomeVaciaEstanciasEn1Click}
                alt="Vider une pièce avec l'IA – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Meubler des pièces vides en 1 clic
                </h2>
                <div className="feature-text">
                  Utilisez l'IA pour faire un home staging virtuel. Sélectionnez
                  le style et Pedra s'occupera du reste.
                </div>
              </div>
              <img
                src={HomeAmueblaEstanciasEn1Click}
                alt="Outil de Home Staging virtuel avec l'IA – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "L'utilisation de Pedra pour éditer nos images nous aide à obtenir
              plus de visites !"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logo de Fincas Llúria pour Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">Fincas Llúria</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Home staging virtuel</h2>
          <div className="title-secondary">
            Pedra offre un outil de home staging virtuel simple mais puissant.{" "}
            <br />
            Choisissez si vous voulez une proposition automatique ou si vous
            voulez aménager une pièce élément par élément.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangeFeatherIcon />
            <h2 className="feature-title">Home staging virtuel automatique</h2>
            <div className="feature-text">
              Rendez les propriétés plus attrayantes avec l'outil de home
              staging virtuel de Pedra. Utilisez l'option automatique ou
              aménagez manuellement la pièce vous-même. Vous choisissez.
            </div>
          </div>
          <img
            src={HomeStaging1}
            alt="Home Staging virtuel avec l'IA – Capture d'écran du logiciel"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRollerIcon />
                <h2 className="feature-title">
                  Changer les sols et les murs avec l'IA
                </h2>
                <div className="feature-text">
                  Rendez un nouveau sol avec l'IA de Pedra. Changez le matériau
                  des murs.
                </div>
              </div>
              <img
                src={HomeStaging2}
                alt="Outil IA pour changer les sols et les murs virtuellement – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">Home staging manuel</h2>
                <div className="feature-text">
                  Utilisez la bibliothèque de meubles de Pedra pour faire un
                  home staging personnalisé. Ajoutez les meubles où vous en avez
                  besoin et faites un rendu avec l'IA pour que cela paraisse
                  réel.
                </div>
              </div>
              <img
                src={HomeStaging3}
                alt="Outil de Home Staging virtuel IA avec meubles personnalisés - Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">
                  Styles de home staging virtuel
                </h2>
                <div className="feature-text">
                  Pedra offre de nombreux styles pour que vous puissiez générer
                  un staging virtuel qui s'adapte le mieux aux besoins de votre
                  client.
                </div>
              </div>
              <img
                src={HomeStaging4}
                alt="Outil de Home Staging virtuel avec IA avec plusieurs styles – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeVideoIcon />
                <h2 className="feature-title">
                  Générez une vidéo montrant l'évolution du staging
                </h2>
                <div className="feature-text">
                  Créez une vidéo pour mieux positionner votre propriété sur les
                  plateformes immobilières et les réseaux sociaux. Communiquez
                  le potentiel d'une propriété avec l'outil de génération de
                  vidéo de Pedra.
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "30px",
                  paddingTop: "30px",
                  marginBottom: "-8px",
                }}
              >
                <video
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  loop
                  muted
                  alt="Logiciel pour rendus IA, Home Staging virtuel et plan d'étage – Vidéo"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "1px solid #EEEEEE",
                    borderRight: "1px solid #EEEEEE",
                    borderTop: "1px solid #EEEEEE",
                  }}
                >
                  <source src="/HomeStaging5.mp4" type="video/mp4" />
                  Votre navigateur ne prend pas en charge la balise vidéo.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Vous avez pensé à tout ! Pedra a tout ce dont nous avons besoin"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logo de Vives Homes"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">
            Éditez les plans d'étage et les images
          </h2>
          <div className="title-secondary">
            Générez des plans d'étage de manière simple et faites briller vos
            images immobilières. Vous n'aurez pas besoin de quitter Pedra pour
            améliorer vos annonces.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangePenIcon />
            <h2 className="feature-title">
              L'outil de planification d'étage le plus facile
            </h2>
            <div className="feature-text">
              Créez des plans d'étage pour mieux positionner votre propriété.
              L'outil de planification d'étage de Pedra est facile et a tout ce
              dont vous aurez besoin.
            </div>
          </div>
          <img
            src={FrameFloorPlanFeature}
            alt="Outil de planification d'étage – Capture d'écran du logiciel"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Corrigez la lumière dans vos photographies immobilières
                </h2>
                <div className="feature-text">
                  Corrigez automatiquement l'éclairage de vos photos.
                </div>
              </div>
              <img
                src={EnlightRooms}
                alt="Outil de correction de la lumière – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Augmentez la définition des rendus IA et des images
                </h2>
                <div className="feature-text">
                  Rendez vos images HD avec Pedra. Appuyez sur un bouton et
                  obtenez vos résultats !
                </div>
              </div>
              <img
                src={HDImage}
                alt="Outil pour augmenter la définition des images (HD) et des rendus avec l'IA – Capture d'écran du logiciel"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Supprimez les photos de famille, les croix, les drapeaux et
                  autres objets personnels de vos photos
                </h2>
                <div className="feature-text">
                  Attirez un public plus large en supprimant les objets
                  personnels des photos – en 5 secondes, sans flou ni bavure !
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h2 className="feature-title">
                  Ajoutez des mentions légales IA personnalisées en bas de page
                </h2>
                <div className="feature-text">
                  Ajoutez directement des mentions en bas de page indiquant que
                  les annonces immobilières sont par exemple non contractuelles,
                  générées par l'IA, etc., et évitez toute confusion.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h2 className="feature-title">
                  Corrigez la perspective de vos photos immobilières en 1 clic
                </h2>
                <div className="feature-text">
                  Oubliez l'édition de photos immobilières une par une, et
                  corrigez directement leur perspective avec Pedra.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeVirtualVideoIcon />
                <h2 className="feature-title">
                  Créez des vidéos immobilières automatiquement
                </h2>
                <div className="feature-text">
                  Créez automatiquement des vidéos immobilières de vos
                  propriétés en 1 minute, pour que les acheteurs potentiels
                  voient des vidéos au format vidéo.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSettingsIcon />
                <h2 className="feature-title">
                  Téléchargez des images immobilières dans n'importe quel format
                </h2>
                <div className="feature-text">
                  Avec Pedra, vous pouvez télécharger des images dans n'importe
                  quel format, et convertir des images vers n'importe quel
                  format comme PNG, JPG ou JPEG.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeWatermarkIcon />
                <h2 className="feature-title">
                  Supprimez parfaitement n'importe quel filigrane
                </h2>
                <div className="feature-text">
                  Supprimez les filigranes des photographies immobilières
                  facilement et avec des résultats propres !
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">Cas d'utilisation</div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <HouseIcon />
                <div className="feature-title">Immobilier</div>
                <div className="feature-text">
                  Accélérez les ventes immobilières avec l'IA et les outils de
                  Pedra.
                  <br />
                  <br />
                  <Link
                    to="/realestate"
                    className="vertical-links"
                    style={{
                      color: "#F8792A",
                      marginLeft: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    En savoir plus <ArrowIcon />
                  </Link>
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <ColorPaletteIcon />
                <div className="feature-title">Design d'intérieur</div>
                <div className="feature-text">
                  Augmentez les ventes de meubles et de design d'intérieur avec
                  l'IA et les outils de Pedra.
                  <br />
                  <br />
                  <Link
                    to={"interiordesign"}
                    className="vertical-links"
                    style={{ color: "#57A4EB", marginLeft: "0px" }}
                  >
                    En savoir plus <ArrowIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">Comment ça marche ?</div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>ÉTAPE 1</h5>
            <h2>Créez un projet et téléchargez une image</h2>
            <div className="value-prop-text">
              Téléchargez 1 ou plusieurs images sur Pedra.
            </div>
          </div>
          <div className="picture">
            <img
              src={UploadAnImage}
              alt="Comment télécharger des fichiers sur Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>ÉTAPE 2</h5>
            <h2>Choisissez une option</h2>
            <div className="value-prop-text">
              Téléchargez une image et cliquez sur générer.
            </div>
          </div>
          <div className="picture">
            <img
              src={PickAnOption}
              alt="Comment choisir une option de rendu ou d'IA avec Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>ÉTAPE 3</h5>
            <h2>Attendez et téléchargez le résultat</h2>
            <div className="value-prop-text">
              Les images prennent en moyenne 15 secondes.
            </div>
          </div>
          <div className="picture">
            <img
              src={WaitAndDownloadResult}
              alt="Comment télécharger un rendu de Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="long-break"> </div>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Essayez Pedra <ArrowIcon />
        </button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="section-one-column-left">
        <div className="title-container">Questions & réponses</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Qu'est-ce qu'un rendu IA ?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un rendu IA, dans le contexte de la conception et de la
                visualisation architecturale, fait référence à l'application de
                l'intelligence artificielle (IA) pour générer des images rendues
                de haute qualité d'espaces architecturaux. L'intelligence
                artificielle est utilisée pour recréer des espaces basés sur des
                images d'entrée, permettant des résultats réalistes et précis.
                <br />
                <br />
                L'IA permet d'obtenir des résultats plus rapides que les
                méthodes de rendu traditionnelles. Les anciennes méthodes
                impliquaient de recréer manuellement des modèles 3D des pièces
                et de traiter les images pour créer des rendus impressionnants.
                Avec l'IA, c'est maintenant différent. Nous pouvons partir
                d'images pour créer de nouvelles visualisations basées sur
                l'image originale et un nouveau style appliqué.
                <br />
                <br />
                L'intelligence artificielle dans le rendu d'images immobilières
                ne fait pas que gagner du temps, elle offre une façon plus
                flexible d'imaginer les espaces pour l'agent immobilier, les
                propriétaires et les acheteurs. La technologie aide à visualiser
                rapidement le potentiel des espaces d'une manière jamais vue
                auparavant.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Qu'est-ce qu'un home staging virtuel ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le home staging virtuel est une technique utilisée dans le
                marché immobilier pour présenter une propriété de manière plus
                attrayante et séduisante aux acheteurs ou locataires potentiels,
                mais contrairement au home staging traditionnel, qui implique
                une préparation physique et une décoration de l'espace, le home
                staging virtuel est réalisé numériquement.
                <br />
                <br />
                Dans le home staging virtuel, des outils tels que des logiciels
                de conception 3D, la réalité virtuelle (RV) et la photographie
                numérique sont utilisés pour créer des représentations
                numériques réalistes d'une propriété. Ces représentations
                peuvent inclure des images rendues de haute qualité, des visites
                virtuelles de la propriété et des visualisations interactives
                montrant à quoi ressemblerait l'espace avec différentes
                configurations de meubles et de décoration.
                <br />
                <br />
                L'objectif du home staging virtuel est de permettre aux
                acheteurs ou locataires potentiels d'avoir une meilleure idée de
                l'apparence de la propriété une fois meublée et décorée, ce qui
                peut aider à augmenter l'intérêt et les chances de vente ou de
                location. De plus, le home staging virtuel peut être
                particulièrement utile dans les situations où la propriété n'est
                pas entièrement meublée ou est vacante, car il peut aider les
                clients à visualiser le potentiel de l'espace.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Pedra est-il facile à utiliser ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Oui, Pedra est conçu pour offrir une expérience simple et agile.
                <br />
                <br />
                Après avoir créé un projet, vous pourrez télécharger une ou
                plusieurs images. Vous pouvez les éditer en cliquant dessus.
                Cela affichera un menu en barre avec des options. Les options
                incluent :{" "}
                <span style={{ fontWeight: "600px" }}> Éditer avec l'IA</span>,
                ajouter un filigrane, télécharger dans un format d'image
                spécifique et plus encore. Lors de la création d'un projet, vous
                pourrez également dessiner un plan d'étage. Le plan d'étage
                contient plusieurs figures, que vous pourrez éditer en les
                sélectionnant, en les faisant glisser ou via le menu en barre.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Combien coûte Pedra ?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra coûte 29€ plus taxes par mois.
                <br />
                <br />
                C'est un abonnement mensuel, vous pouvez donc annuler votre
                abonnement si vous n'en avez plus besoin.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFr;
