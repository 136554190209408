import React from "react";
import ReleaseAug2024Frame1 from "../Images/ReleaseAug2024Frame1.png";
import ReleaseAug2024Frame2 from "../Images/ReleaseAug2024Frame2.png";
import ReleaseAug2024Frame3 from "../Images/ReleaseAug2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseAug24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">AUGUST 2024</div>
          <h1 className="article-titleh1">
            Pedra: Improvements in furnishing, custom styles, and window
            preservation
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              This August, we've been improving everything Pedra already offers,
              plus some new features that will surprise you:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Super-realistic results in the furnishing tool
              </h2>
              You'll see that the results when furnishing spaces are better than
              ever. We've updated our AI models to make your photos look as
              realistic as possible ✨
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame1}
                  alt="Example of super-realistic furnishing"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Use your own styles to renovate properties
              </h2>
              Now you can upload a photo of any style, and Pedra will use it as
              a reference to renovate the photograph. Ideal if you want to show
              a property in the trendiest style or one that your client likes
              the most. You can see how it works here.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame2}
                  alt="Example of renovation with custom style"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Improvement in window preservation during renovation
              </h2>
              Preserve window views in the most realistic way! This ensures that
              your renovation proposals are easy for your clients to interpret.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseAug2024Frame3}
                alt="Example of window preservation"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>

            <div>
              <li>
                Improvements in sky detection when applying the{" "}
                <strong>"Blue sky"</strong> feature
              </li>
              <li>
                The <strong>"Add object"</strong> functionality is more
                intuitive to use: feedback when objects are loading and easier
                furniture movement
              </li>
              <li>
                Now you can increase the number of images if you run out of
                monthly image generations
              </li>
              <li>
                Various fixes: watermark change, prevention of all images
                failing if one HD image fails, and creating new projects faster
                than ever
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseAug24;
