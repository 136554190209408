import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";

// Images
import Bouchra from "./Images/Bouchra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";
import BeforeStagingExample1 from "./Images/curtain-effect/BeforeStagingExample1.png";
import AfterStagingExample1 from "./Images/curtain-effect/AfterStagingExample1.png";
import BeforeStagingExample2 from "./Images/curtain-effect/BeforeStagingExample2.png";
import AfterStagingExample2 from "./Images/curtain-effect/AfterStagingExample2.png";
import BeforeStagingExample3 from "./Images/curtain-effect/BeforeStagingExample3.png";
import AfterStagingExample3 from "./Images/curtain-effect/AfterStagingExample3.png";
import BeforeStagingExample4 from "./Images/curtain-effect/BeforeStagingExample4.png";
import AfterStagingExample4 from "./Images/curtain-effect/AfterStagingExample4.png";
import BeforeStagingExample5 from "./Images/curtain-effect/BeforeStagingExample5.png";
import AfterStagingExample5 from "./Images/curtain-effect/AfterStagingExample5.png";
import BeforeStagingExample6 from "./Images/curtain-effect/BeforeStagingExample6.png";
import AfterStagingExample6 from "./Images/curtain-effect/AfterStagingExample6.png";
import BeforeStagingExample7 from "./Images/curtain-effect/BeforeStagingExample7.png";
import AfterStagingExample7 from "./Images/curtain-effect/AfterStagingExample7.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function HomeStaging() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  // Images in the staging examples hero

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    console.log(example);
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
      console.log("Furnish");
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  // Images in the staging examples body

  const stagingExamples = [
    {
      name: "stagingExample1",
      image: BeforeStagingExample1,
    },
    {
      name: "stagingExample2",
      image: BeforeStagingExample2,
    },
    {
      name: "stagingExample3",
      image: BeforeStagingExample3,
    },
    {
      name: "stagingExample4",
      image: BeforeStagingExample4,
    },
    {
      name: "stagingExample5",
      image: BeforeStagingExample5,
    },
    {
      name: "stagingExample6",
      image: BeforeStagingExample6,
    },
    {
      name: "stagingExample7",
      image: BeforeStagingExample7,
    },
  ];

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeStagingExample1,
    afterImage: AfterStagingExample1,
  });

  const handleStagingExample = (example) => {
    if (example === "stagingExample1") {
      setStagingExample({
        name: "stagingExample1",
        beforeImage: BeforeStagingExample1,
        afterImage: AfterStagingExample1,
      });
      console.log("Furnish");
    } else if (example === "stagingExample2") {
      setStagingExample({
        name: "stagingExample2",
        beforeImage: BeforeStagingExample2,
        afterImage: AfterStagingExample2,
      });
    } else if (example === "stagingExample3") {
      setStagingExample({
        name: "stagingExample3",
        beforeImage: BeforeStagingExample3,
        afterImage: AfterStagingExample3,
      });
    } else if (example === "stagingExample4") {
      setStagingExample({
        name: "stagingExample4",
        beforeImage: BeforeStagingExample4,
        afterImage: AfterStagingExample4,
      });
    } else if (example === "stagingExample5") {
      setStagingExample({
        name: "stagingExample5",
        beforeImage: BeforeStagingExample5,
        afterImage: AfterStagingExample5,
      });
    } else if (example === "stagingExample6") {
      setStagingExample({
        name: "stagingExample6",
        beforeImage: BeforeStagingExample6,
        afterImage: AfterStagingExample6,
      });
    } else if (example === "stagingExample7") {
      setStagingExample({
        name: "stagingExample7",
        beforeImage: BeforeStagingExample7,
        afterImage: AfterStagingExample7,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is virtual home staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtual home staging is a real estate marketing technique that uses digital tools to prepare and present a property attractively to potential buyers or tenants. Unlike traditional home staging, which involves physical changes to the property, virtual home staging is done through image editing and digital rendering to enhance the appearance of spaces.",
        },
      },
      {
        "@type": "Question",
        name: "What does virtual home staging consist of?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtual home staging consists of editing property images using digital tools. This includes: \n\n- Image editing: Retouching and enhancing photos using AI, removing unwanted objects, adjusting lighting, and adding virtual furniture or decor. \n\n- Design rendering: Creating digital representations that show how the property could look when decorated or renovated. \n\n- Time and cost savings: It's faster and more economical than traditional home staging, as it doesn't require physical changes to the property.",
        },
      },
      {
        "@type": "Question",
        name: "What steps are included in the Virtual Home Staging process?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The virtual home staging process includes the following steps: \n\n1. Upload the project photos. \n\n2. Select virtual home staging editing options, such as emptying furnished rooms or adding virtual furniture. \n\n3. Download the project once changes are completed.",
        },
      },
      {
        "@type": "Question",
        name: "How long does it take to complete a Virtual Home Staging project?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The time to complete a virtual home staging project depends on the number of images and the time it takes to edit them. However, uploading files and downloading projects is quick, and results are obtained instantly.",
        },
      },
      {
        "@type": "Question",
        name: "Can Virtual Home Staging be done on empty properties?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes! Virtual home staging is ideal for empty properties. You can furnish them with AI or manually, adding the furniture you want, which helps visualize the potential of the spaces and improves the presentation of the property.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Virtual home staging</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  The best virtual home staging at your fingertips
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Fast:</strong> Results in seconds
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Easy:</strong> Intuitive from the start
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Quality:</strong> Hyperrealistic results
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Help:</strong> Human assistance if needed
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 for 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Before virtual home staging"}
                  altAfter={"After virtual home staging"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Living Room
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Bedroom
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Bathroom
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terrace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          OVER 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtual home staging to{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            sell faster
          </span>{" "}
          any type of property.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Furnish virtually in 1 click</h3>
                <div className="feature-text">
                  Create automatic home staging in 1 click
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Captions</h3>
                <div className="feature-text">
                  Indicate that your image is a virtual home staging with your
                  own text
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Improve and correct photos</h3>
                <div className="feature-text">
                  Enhance the quality and perspective of photos for better
                  virtual home staging
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Add your furniture</h3>
                <div className="feature-text">
                  Add personality by including your own furniture in the virtual
                  home staging
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renovate virtually</h3>
                <div className="feature-text">
                  Create renovation proposals for your second-hand properties
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Delete objects or empty spaces
                </h3>
                <div className="feature-text">
                  Remove objects or watermarks for cleaner virtual home staging
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Examples of our virtual home staging.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Before virtual home staging"}
            altAfter={"After virtual home staging"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            100+
          </span>{" "}
          testimonials of our virtual home staging.
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director of Photography at aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director of Photography at aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra is going great. I think the best thing about the tool is{" "}
            <span className="text-highlight">
              the speed with which it generates images.
            </span>
          </q>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Real Estate Advisor at iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Pedra is the discovery of the century
            </span>
          </q>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Real Estate Agent at iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impressive.
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Moreover,{" "}
              <span className="text-highlight">
                the specific space I wanted to empty seemed more complicated
                because it was deep, but it did it brilliantly.
              </span>
            </div>
          </q>
          <div className="testimonial-author-container-date">June 26, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager at Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager at Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              A couple of houses have been sold thanks to Pedra.
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              I placed proposals of how the terrace could look furnished in the
              cover photo and a lot of people started clicking on the ad
            </div>
          </q>
          <div className="testimonial-author-container-date">June 7, 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              With Pedra I managed to{" "}
              <a
                className="article-link"
                href="https://www.instagram.com/p/C4A6kCmIeMy/"
                rel="nofollow"
              >
                sell a €500k property
              </a>{" "}
              in less than a day.{" "}
            </span>{" "}
            The apartment I had for sale was a PROPERTY TO BE RENOVATED and with
            Pedra I got renders that allowed visualizing the potential of the
            spaces after renovation but without renovation!
          </q>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Real Estate Advisor at Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              I'm very satisfied with the results
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Moreover, they have been improving the product according to the
              feedback I've shared!
            </div>
          </q>
          <div className="testimonial-author-container-date">June 4, 2024</div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          How does it work?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 1
            </h5>
            <h2>Create a project and upload an image</h2>
            <div className="value-prop-text">
              Upload one or more images to Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="How to upload files to create a virtual home staging in Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 2
            </h5>
            <h2>Choose an option to create your virtual home staging</h2>
            <div className="value-prop-text">
              Upload an image and click on the appropriate option to create your
              virtual home staging.
              <p>
                You'll be able to furnish spaces, add specific objects, renovate
                them, remove parts and much more!
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="How to choose options to create a virtual home staging – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 3
            </h5>
            <h2>Wait and download the virtual home staging</h2>
            <div className="value-prop-text">
              Virtual home stagings can take from 15 seconds to a few minutes to
              generate.
              <p>
                Once generated, you can continue editing them or download them.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="How to download a virtual home staging – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          The virtual home stagings you need for €29 per month. <br />{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            And without commitment.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 image generations
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovate and redecorate
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Empty rooms
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Furnish
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remove objects
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Increase resolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Change floors
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Improve photography
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generate virtual videos
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited floor plans
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited project sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Add watermarks
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancel anytime.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Buy more images at €0.20 per image if you need them.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Questions and Answers</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is home staging?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Home staging is a real estate marketing technique that involves
                preparing a property (house, apartment, etc.) for sale or rent
                with the aim of making it more attractive to potential buyers or
                tenants. This is achieved through strategic presentation and
                decoration of the space to highlight its most positive features
                and minimize its defects.
                <br />
                <br />
                The home staging process involves cleaning, depersonalizing, and
                decluttering the property to allow potential buyers or tenants
                to easily visualize how it would be to live in that space. It
                may also include rearranging furniture, adding decorative
                elements, and in some cases, minor renovations or repairs to
                improve the overall appearance of the property.
                <br />
                <br />
                The goal of home staging is to generate a positive first
                impression on visitors and increase the chances of selling or
                renting the property quickly and at a favorable price. It is an
                increasingly popular strategy in the real estate market due to
                its ability to enhance the visual and emotional appeal of a
                property.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What does virtual home staging consist of?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Virtual home staging is a variant of traditional home staging
                  that uses digital tools and technology to present a property
                  attractively to potential buyers or tenants. Instead of making
                  physical changes to the property, such as rearranging
                  furniture or adding decorative elements, virtual home staging
                  is primarily carried out in the following ways:
                </div>
                <h4>Image editing:</h4>With Pedra, you can edit images with AI
                to retouch and improve photographs of the property. This may
                involve removing unwanted objects, adjusting lighting and color,
                and even adding virtual furniture or decor.
                <br />
                <br />
                <h4>Design rendering:</h4> Create digital representations of how
                the property could look after being decorated or renovated. This
                can help buyers visualize the potential of the space and inspire
                ideas for their own decoration.
                <br />
                <br />
                <h4>Time and cost savings:</h4>Virtual home staging is faster
                and more economical than traditional home staging, as it doesn't
                require hiring physical furniture or accessories or making
                physical changes to the property. This can be especially
                beneficial for empty properties or in situations where time is a
                critical factor.
                <br />
                <br />
                <h4>Flexibility and customization:</h4>With virtual home
                staging, it's possible to experiment with different decoration
                styles and space configurations quickly and without commitments.
                This allows adapting the presentation of the property according
                to the target market and the preferences of potential buyers or
                tenants.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Virtual home staging tips</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Know your target audience:</h4>Understanding the needs and
                preferences of your target audience will help you create virtual
                home staging content that resonates with them. Consider who the
                potential buyers or tenants are and adapt your approach
                accordingly.
                <br />
                <br />
                <h4>Be honest and transparent:</h4>Make sure that the virtual
                home staging content accurately reflects the appearance and
                features of the property. Avoid over-editing or excessive
                manipulation of images, as this can create unrealistic
                expectations.
                <br />
                <br />
                <h4>Multiple angles:</h4>Capture the property from various
                angles to provide a complete view. This allows viewers to have a
                better idea of the space layout and unique features of the
                property.
                <br />
                <br />
                <h4>Maintain stylistic consistency:</h4>Use a coherent
                decoration style in all images and virtual tours to create a
                uniform visual experience. This helps maintain viewers' interest
                and facilitates visualizing the property as a whole.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Virtual home staging price</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                The price of Pedra is €29 plus VAT per month.
                <br />
                <br />
                For that price, you can generate up to 100 images per month.
                <br />
                <br />
                There is no commitment, so you can cancel when you no longer
                need it.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                What steps are included in the Virtual Home Staging process?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                The home staging process is quite fast and simple. Here are the
                steps to follow to create virtual home staging with Pedra:
                <br />
                <br />
                1. <strong>Upload the project photos:</strong> When creating a
                project, you can upload one or more images to edit.
                <br />
                <br />
                2. <strong>Virtual Home Staging options:</strong> Once you
                select the photos, within the "Edit with AI" dropdown, you'll
                have all the options you need, from emptying rooms to adding
                furniture to your room, renovating spaces, and more.
                <br />
                <br />
                3. <strong>Download your project:</strong> Once you've made all
                the changes and edited the images, you'll just need to download
                the images of your Home Staging
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Can Virtual Home Staging be done on empty properties?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Yes! One of the functions of our virtual home staging tool is
                  to furnish the room. You can use the "Furnish" option to
                  automatically furnish the space with AI, or if you prefer, you
                  can manually add the furniture you want with the "Add object"
                  option.
                </p>
                <p>
                  With either of these options, our Virtual Home Staging tool
                  helps you visualize the potential of your spaces, facilitating
                  decision-making and improving the presentation of the
                  property.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStaging;
