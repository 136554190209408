// 404Page.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <section className='section-first-wrapper'>
        <div>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    </section>
  );
}

export default NotFoundPage;
