import React from "react";

function LegalFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Mentions Légales</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <h2 className="article-subtitleh2">
              Identification du Propriétaire :
            </h2>
            <div className="article-text">
              Nom de l'entreprise : Pedra Software S.L.
              <br />
              Identifiant Fiscal : B16459992
              <br />
              Siège Social : Calle Vista Bella No. 8, (08022), Barcelone,
              Catalogne, Espagne. Activité : Développement et fourniture de
              logiciels et autres services liés à la technologie de
              l'information et à l'informatique. Code NACE : 6209 - Autres
              activités de services en matière de technologies de l'information
              et d'informatique.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Informations Générales :</h2>
            <div className="article-text">
              Ce site web est la propriété de Pedra Software S.L. L'utilisation
              de ce site web est soumise aux conditions d'utilisation suivantes.
              Veuillez les lire attentivement. L'accès à ce site web et
              l'utilisation de son contenu et de ses services impliquent
              l'acceptation pleine et entière de ces conditions.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">
              Propriété Intellectuelle et Industrielle
            </h2>
            <div className="article-text">
              Nom de l'entreprise : Pedra Software S.L. Tous les contenus de ce
              site web, y compris, mais sans s'y limiter, les textes,
              graphiques, images, son design et les droits de propriété
              intellectuelle qui peuvent correspondre à ces contenus, ainsi que
              toutes les marques, noms commerciaux ou tout autre signe
              distinctif sont la propriété de Pedra Software S.L., avec tous les
              droits réservés.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Conditions d'Utilisation</h2>
            <div className="article-text">
              L'utilisateur s'engage à faire un usage approprié des contenus et
              services offerts par Pedra Software S.L. et à ne pas les utiliser
              pour des activités illégales ou des activités constituant un crime
              et/ou enfreignant les réglementations en matière de propriété
              intellectuelle et industrielle, ou toute autre norme légale
              applicable.
            </div>
            <br />
            <h2 className="article-subtitleh2">Protection des Données</h2>
            <div className="article-text">
              En conformité avec le Règlement (UE) 2016/679 du Parlement
              Européen et du Conseil du 27 avril 2016 relatif à la protection
              des personnes physiques à l'égard du traitement des données à
              caractère personnel et à la libre circulation de ces données,
              toutes les données personnelles collectées lors de l'utilisation
              de notre site web seront traitées conformément à notre{" "}
              <a className="article-link" href="https://www.pedra.so/privacy">
                Politique de Confidentialité
              </a>
              , que tous les utilisateurs doivent accepter avant de fournir des
              données personnelles via ce site web.
            </div>
            <br />
            <h2 className="article-subtitleh2">Contact</h2>
            <div className="article-text">
              Pour toute question ou proposition, contactez-nous à l'adresse
              e-mail suivante :{" "}
              <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalFr;
