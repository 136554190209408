import React from "react";
import ReleaseAug2024Frame1 from "../Images/ReleaseAug2024Frame1.png";
import ReleaseAug2024Frame2 from "../Images/ReleaseAug2024Frame2.png";
import ReleaseAug2024Frame3 from "../Images/ReleaseAug2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseFrAug24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/fr/releases">
            &#8592; Versions
          </Link>
          <br />

          <div className="title-secondary gray small">AOÛT 2024</div>
          <h1 className="article-titleh1">
            Pedra : Améliorations de l'ameublement, styles personnalisés et
            préservation des fenêtres
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Ce mois d'août, nous avons amélioré tout ce que Pedra offre déjà,
              en plus de quelques nouvelles fonctionnalités qui vous
              surprendront :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Résultats ultra-réalistes avec l'outil d'ameublement
              </h2>
              Vous verrez que les résultats lors de l'ameublement des espaces
              sont meilleurs que jamais. Nous avons mis à jour nos modèles d'IA
              pour rendre vos photos aussi réalistes que possible ✨
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame1}
                  alt="Exemple d'ameublement ultra-réaliste"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Utilisez vos propres styles pour rénover les propriétés
              </h2>
              Vous pouvez maintenant télécharger une photo de n'importe quel
              style, et Pedra l'utilisera comme référence pour rénover la
              photographie. Idéal si vous voulez montrer une propriété dans le
              style le plus tendance ou celui que votre client préfère. Vous
              pouvez voir comment ça fonctionne ici.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame2}
                  alt="Exemple de rénovation avec un style personnalisé"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Amélioration de la préservation des fenêtres lors de la
                rénovation
              </h2>
              Préservez les vues des fenêtres de la manière la plus réaliste !
              Cela garantit que vos propositions de rénovation sont faciles à
              interpréter pour vos clients.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseAug2024Frame3}
                alt="Exemple de préservation des fenêtres"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>

            <div>
              <li>
                Améliorations de la détection du ciel lors de l'application de
                la fonction <strong>"Ciel bleu"</strong>
              </li>
              <li>
                La fonctionnalité <strong>"Ajouter un objet"</strong> est plus
                intuitive à utiliser : retour d'information lors du chargement
                des objets et déplacement plus facile des meubles
              </li>
              <li>
                Vous pouvez maintenant augmenter le nombre d'images si vous
                épuisez vos générations mensuelles d'images
              </li>
              <li>
                Diverses corrections : changement de filigrane, prévention de
                l'échec de toutes les images si une image HD échoue, et création
                de nouveaux projets plus rapide que jamais
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseFrAug24;
