import React from "react";
import Article7Frame from "../Images/Article7Frame.jpg";
import Article7Frame1 from "../Images/Article7Frame1Es.jpg";
import Article7Frame2 from "../Images/Article7Frame2Es.jpg";
import Article7Frame3 from "../Images/Article7Frame3Es.jpg";
import Article7Frame4 from "../Images/Article7Frame4Es.jpg";
import Article7Frame5 from "../Images/Article7Frame5Es.jpg";

import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogEsArticle7() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Home Staging Ejemplos: Antes y Después
            </h1>
            <h2 className="title-secondary gray">
              ¿Estás buscando ejemplos de home staging de antes y después? Para
              que te puedas hacer una idea del poder de esta técnica, aquí te
              traemos algunos ejemplos de Home Staging para ti.
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador de Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador de Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article7Frame}
                alt="Home Staging Ejemplo –  antes y después – Portada ilustrativa"
              />
            </div>
            <article className="article-text">
              <br />
              <p style={{ marginTop: "10px" }}>
                A continuación te mostramos ejemplos de home stagings de baño,
                cocina, habitación y salón. Vamos!
              </p>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Ejemplo: Home staging de una cocina
              </h2>
              <p style={{ marginTop: "10px" }}>
                El primer ejemplo es el de esta cocina. Cómo véis, el cambio es
                radical, pero se mantiene la misma arquitectura y dsitribución
                de mobiliario.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame1}
                  alt="Home Staging Ejemplo: Cocina antes y después"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Como puedes observar en el antes y después del Home Staging, el
                antes era anticuado y cargado. El nuevo se ve mucho más simple.
                Se ha realizado virtualmente, e incluye un cambio de suelo,
                pintada de paredes, y renovación de mobiliario. El contenid
                fuera de la cocina también ha recibido un{" "}
                <Link to="/es/home-staging-virtual" className="article-link">
                  home staging
                </Link>{" "}
                para ir acorde con el resto de la imagen.
              </p>
              <h2 className="article-subtitleh2">
                Ejemplo: Home staging de un baño
              </h2>
              <p style={{ marginTop: "10px" }}>
                La siguiente imagen es un ejemplo de home staging de un baño.
              </p>
              <p style={{ marginTop: "10px" }}>
                Cómo veis, el cambio es bastante radical. Pasamos de un baño
                anticuado con necesidad desesperada de renovación, a un baño
                moderno. El tamaño del espacio y la distribución es la misma.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame5}
                  alt="Home Staging Ejemplo: Baño antes y después"
                />
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Home staging de un dormitorio
              </h2>
              <p style={{ marginTop: "10px" }}>
                El siguiente ejemplo de home staging es el de un dormitorio.
                Cómo ves, el cambio es mínimo y poco agresivo. Se mantiene el
                tamaño del espacio, y tan sólo se coloca una cama y una planta.
                De esta manera el potencial comprador entiende mejor la salida
                de este espacio.
              </p>
              <p style={{ marginTop: "10px" }}>
                ¿A que con esta decoración eres capaz de imaginar tu vida en la
                vivienda?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame3}
                  alt="Home Staging Ejemplo: Dormitorio antes y después"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Home staging de un salón</h2>
              <p style={{ marginTop: "10px" }}>
                El siguiente ejemplo de Home staging, te ilustrará la capacidad
                para renovar un salón muy anticuado.
              </p>
              <p style={{ marginTop: "10px" }}>
                Fíjate que la distribución del mobiliario de la fotografía es la
                misma, pero se han renovado absolutamente todos los muebles y
                además se ha añadido un nuevo espacio.
              </p>
              <p style={{ marginTop: "10px" }}>
                En este ejemplo de home staging también se resalta la luz del
                espacio, hecho muy diferencial a la hora de los compradores en
                intersarse en un piso.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame2}
                  alt="Home Staging Ejemplo: Salón antes y después"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                El cambio de luz se justifica con la retirada de las cortinas y
                el cambio de color de parades. Ese cambio lo hace mucho más
                agradable para el potencial comprador.
              </p>

              <h2 className="article-subtitleh2">
                Ejemplo de home staging de una terraza
              </h2>
              <p style={{ marginTop: "10px" }}>
                Para terminar con los ejemplos de Home Staging, un ejemplo de
                home staging de terraza. Cómo puedes ver bien aquí, la terraza
                queda mucho más habitable. ¿A qué te gustaría tomar los
                desayunos en esta terraza?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame4}
                  alt="Home Staging Ejemplo: Terraza antes y después"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Parece increíble cómo se puede mejorar una vivienda con una
                inversión mínima. El presupuesto se reduce a algo de pintura y
                mucha limpieza en caso de hacerlo físico, o a pocos clicks y
                menos de 50€ si se decide hacerlo virtual.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hola! Soy Felix Ingla, el fundador de{" "}
              <Link className="article-link" to="/es">
                Pedra
              </Link>
              , una innovadora aplicación web de{" "}
              <Link to="/es/home-staging-virtual" className="article-link">
                home staging virtual
              </Link>{" "}
              virtual y fotografía inmobiliaria que está transformando la forma
              en que se presentan las propiedades en el mundo digital. Mi
              dedicación a la creación de tecnología y soluciones intuitivas ha
              establecido a Pedra como una herramienta referente para
              profesionales inmobiliarios que buscan mejorar sus anuncios
              inmobiliarios.
            </p>
            <p>
              Si quieres conectar y estás interesado en discutir sobre
              tecnología inmobiliaria, por favor contáctame a través de mi{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . También puedes leer más{" "}
              <Link className="article-link" to="/es/about">
                sobre Pedra aquí
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogEsArticle7;
