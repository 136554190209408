import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (keeping same imports as they can be reused)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images (keeping same imports)
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeSalon from "./Images/BeforeRenovation1.png";
import AfterSalon from "./Images/AfterRenovation1.png";
import BeforeSalonStaging1 from "./Images/BeforeRenovation2.png";
import AfterSalonStaging1 from "./Images/AfterRenovation2.png";
import BeforeSalonStaging2 from "./Images/BeforeRenovation3.png";
import AfterSalonStaging2 from "./Images/AfterRenovation3.png";
import BeforeSalonStaging3 from "./Images/BeforeRenovation4.png";
import AfterSalonStaging3 from "./Images/AfterRenovation4.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RenovationFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeSalon,
    afterImage: AfterSalon,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeSalon,
        afterImage: AfterSalon,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeSalonStaging1,
        afterImage: AfterSalonStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeSalonStaging2,
        afterImage: AfterSalonStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeSalonStaging3,
        afterImage: AfterSalonStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Que sont les rendus pour rénovation ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Les rendus pour rénovation sont des visualisations photoréalistes qui montrent à quoi ressemblera un espace après rénovation. Ils permettent de voir le résultat final avant le début des travaux, facilitant la prise de décision et la communication avec les clients.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages des rendus pour rénovation ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Les rendus pour rénovation offrent de nombreux avantages : ils permettent de visualiser le résultat final avant de commencer les travaux, facilitent l'approbation des projets, aident à détecter les problèmes potentiels avant l'exécution, améliorent la communication avec les clients et les entrepreneurs, et constituent un outil marketing efficace pour vendre des projets de rénovation.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de création des rendus pour rénovation ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus consiste à télécharger des photos de l'espace actuel sur notre plateforme, à sélectionner les options de transformation souhaitées (comme les changements de matériaux, de finitions ou d'agencement), et à recevoir les rendus photoréalistes montrant le résultat final de la rénovation.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeSalonStaging1,
    afterImage: AfterSalonStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeSalonStaging1,
      afterImage: AfterSalonStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeSalonStaging2,
      afterImage: AfterSalonStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeSalonStaging3,
      afterImage: AfterSalonStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Rendus pour rénovation</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Les meilleurs rendus professionnels pour projets de rénovation
                  à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Visualisez votre rénovation en
                    quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple :</strong> Créez des rendus attractifs sans
                    effort
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats photoréalistes
                    professionnels
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Assistance :</strong> Aide experte en visualisation
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant la rénovation"}
                  altAfter={"Rendu de la rénovation"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Rénovation 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Rénovation 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Rénovation 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Rénovation 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS FONT CONFIANCE À PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Des rendus pour rénovation pour{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            mieux vendre
          </span>{" "}
          tout type de projet.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Visualisez avant de rénover</h3>
                <div className="feature-text">
                  Montrez le résultat final avant de commencer les travaux
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Soulignez les changements clés
                </h3>
                <div className="feature-text">
                  Mettez en valeur les principales améliorations et
                  transformations
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Multiples perspectives</h3>
                <div className="feature-text">
                  Visualisez la rénovation sous différents angles
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Matériaux et finitions réalistes
                </h3>
                <div className="feature-text">
                  Visualisez les nouvelles finitions avec un réalisme maximal
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Changements d'agencement</h3>
                <div className="feature-text">
                  Visualisez différentes dispositions de l'espace
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Avant et après</h3>
                <div className="feature-text">
                  Comparez l'avant et l'après de la rénovation
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de nos rendus pour rénovation
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"État actuel de l'espace"}
            altAfter={"Rendu de la rénovation"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne le processus de création des rendus pour rénovation
          ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez les photos de l'espace actuel</h2>
            <div className="value-prop-text">
              Chargez une ou plusieurs images de l'espace que vous souhaitez
              rénover sur Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos pour créer des rendus de rénovation sur Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez les options de rénovation</h2>
            <div className="value-prop-text">
              Sélectionnez les changements que vous souhaitez visualiser dans
              votre rénovation.
              <p>
                Changements de matériaux, d'agencement, de finitions et plus
                d'options disponibles.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer des rendus de rénovation – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Recevez vos rendus professionnels</h2>
            <div className="value-prop-text">
              En quelques minutes, vous recevrez des rendus photoréalistes de
              votre rénovation.
              <p>
                Téléchargez les images et utilisez-les pour présenter et vendre
                votre projet.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger des rendus de rénovation – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Rendus pour rénovation à 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénover et redécorer les salons
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changer les finitions du salon
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajouter des meubles et de la décoration
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Supprimer les objets indésirables
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmenter la résolution des photos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Modifier l'agencement du salon
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Améliorer la photographie des salons
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans de salon illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajout de filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Résiliez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus de rendus à 0,20€ par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions fréquentes sur les rendus pour rénovation
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Que sont les rendus pour rénovation ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Les rendus pour rénovation sont des visualisations
                photoréalistes qui montrent à quoi ressemblera un espace après
                rénovation. Cet outil permet de voir le résultat final avant de
                commencer les travaux.
                <br />
                <br />
                Avec notre technologie avancée, nous créons des images réalistes
                qui aident à :
                <br />
                - Visualiser différentes options de rénovation
                <br />
                - Prendre des décisions plus éclairées
                <br />
                - Mieux communiquer les idées aux clients
                <br />- Vendre plus efficacement les projets de rénovation
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages des rendus pour rénovation ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Les rendus pour rénovation offrent de nombreux avantages :
                <br />
                <br />
                1. Visualisation préalable : Voir le résultat final avant de
                commencer
                <br />
                2. Économie de temps et d'argent : Évitez les changements
                coûteux pendant les travaux
                <br />
                3. Meilleure communication : Facilite la compréhension avec les
                clients
                <br />
                4. Outil de vente : Aide à mieux4. Outil de vente : Aide à mieux
                vendre les projets
                <br />
                5. Prise de décision : Permet de comparer différentes options
                <br />
                6. Détection de problèmes : Identifie les problèmes potentiels
                avant les travaux
                <br />
                7. Marketing efficace : Améliore la présentation des projets
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Quel type de rénovations puis-je visualiser avec Pedra ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Avec Pedra, vous pouvez visualiser tous types de rénovations :
                <br />
                <br />
                1. Changements de matériaux : Sols, murs, plafonds, finitions
                <br />
                2. Rénovations intégrales : Visualisez des changements complets
                de l'espace
                <br />
                3. Changements d'agencement : Nouvelle disposition des espaces
                <br />
                4. Actualisation des styles : Modernisation des espaces
                <br />
                5. Rénovations partielles : Changements spécifiques dans des
                zones précises
                <br />
                6. Mobilier : Visualisez différentes options d'ameublement
                <br />
                7. Éclairage : Simulez différents schémas d'éclairage
                <br />
                <br />
                Toutes les visualisations sont réalisées de manière réaliste,
                vous permettant de montrer le potentiel de chaque rénovation de
                façon professionnelle.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenovationFr;
