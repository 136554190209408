import React from 'react';

const BlueRulerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
        <path d="M16.4349 6.53553L6.53545 16.435L2.99992 12.8995L4.06058 11.8388L6.1819 13.9602L6.88901 13.253L4.76769 11.1317L5.82835 10.0711L7.24256 11.4853L7.94967 10.7782L6.53545 9.36396L7.59611 8.3033L9.71743 10.4246L10.4245 9.71751L8.30322 7.59619L9.36388 6.53553L10.7781 7.94975L11.4852 7.24264L10.071 5.82843L11.1316 4.76777L13.253 6.88909L13.9601 6.18198L11.8388 4.06066L12.8994 3L16.4349 6.53553Z" fill="#57A4EB"/>
    </svg>
  );
};

export default BlueRulerIcon;