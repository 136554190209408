import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageKitchenFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingKitchen() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Virtual Home Staging for Kitchens
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create virtual home staging for kitchen spaces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create virtual home staging for a kitchen?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home staging for kitchens in
              just a few clicks.
              <p>
                To begin, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Choose "Kitchen" as the type of space, and in about 2 or 3
                minutes, you'll have your virtual home staging image of the
                kitchen ready to download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create virtual home staging for a kitchen – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for kitchens?
            </h2>
            <p className="article-text">
              To achieve your{" "}
              <Link className="article-link" to="/home-staging-kitchen">
                virtual home staging for a kitchen
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the kitchen you want to virtually furnish
              </li>
              <li>Select the image to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu for creating virtual home staging for a kitchen"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>
                  position of the staging elements
                </span>
                . For kitchens, we recommend using "Draw" to indicate specific
                areas where to place appliances, decor, and accessories.
              </li>
              <li>
                Select <span style={{ fontWeight: "500" }}>"Kitchen"</span> as
                the type of space. This will ensure that Pedra places
                appropriate kitchen items such as appliances, cookware, and
                decorative elements.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. For
                kitchens, styles like "Modern", "Contemporary" or "Traditional"
                usually work well.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to have different kitchen
                design options.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better virtual home staging of a kitchen:
                <ul>
                  <li>
                    Ensure the photograph shows the entire kitchen layout
                    clearly for more accurate furnishing
                  </li>
                  <li>
                    Capture any unique features like islands, windows, or
                    architectural details
                  </li>
                  <li>
                    Use the "Draw" option to indicate specific areas for
                    appliances, countertop accessories, or decorative elements
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Example of how to create virtual home staging for a kitchen"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why do virtual home staging for a kitchen?
            </h2>
            <p className="article-text">
              The kitchen is often considered the heart of the home and can be a
              major selling point. Virtual home staging for kitchens allows you
              to showcase the full potential of the space, creating warm and
              functional environments that help buyers envision themselves
              cooking and entertaining there.
            </p>
            <p className="article-text">
              With good virtual home staging for a kitchen, you can:
            </p>
            <ul>
              <li>Transform empty or outdated kitchens into inviting spaces</li>
              <li>Highlight the functionality and workflow of the layout</li>
              <li>Showcase storage solutions and counter space potential</li>
              <li>
                Help buyers imagine preparing meals and entertaining in the
                space
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging for kitchens
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings that can be
              applied to kitchens:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingKitchen;
