import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToFurnishFrame1 from "../Images/HowToFurnishFrame1.jpg";
import HowToFurnishFrame2 from "../Images/HowToFurnishFrame2.jpg";
import HowToFurnishFrame3 from "../Images/HowToFurnishFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToFurnish() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment utiliser l'outil de mobilier pour meubler virtuellement
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur comment meubler virtuellement des maisons et des pièces
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi sert l'outil "Furnish" de Pedra ?
            </h2>
            <p className="article-text">
              Avec les outils de mobilier de Pedra, vous pouvez ajouter des
              meubles aux pièces de votre propriété.
              <p style={{ marginTop: "10px" }}>
                Montrer des pièces meublées peut aider à accélérer la vente, car
                les acheteurs comprendront mieux l'agencement des espaces
                lorsqu'ils verront les photos sur les principaux portails
                immobiliers.
              </p>
              <p style={{ marginTop: "10px" }}>
                De plus, les meubles ajoutés par Pedra ont l'air propres, neufs
                et bien rangés, ce qui conduit à des émotions positives lors de
                la visualisation des photos.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment puis-je meubler virtuellement une pièce avec Pedra ?
            </h2>
            <p className="article-text">
              Une fois que vous avez téléchargé les images que vous souhaitez
              meubler, sélectionnez-en une.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, et
                vous verrez l'option{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame1}
                alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
              />
            </p>
            <p className="article-text">
              Ci-dessous, nous vous montrons les étapes à suivre pour meubler un
              espace avec Pedra.
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Sélection de la zone à meubler virtuellement
            </h3>
            <p className="article-text">
              La première étape détermine où les meubles apparaîtront. La
              sélection peut être automatique ou dessinée. Si elle est
              automatique, Pedra décidera où placer les meubles.
              <p style={{ marginTop: "10px" }}>
                {" "}
                Si vous avez besoin de plus de précision lors du placement des
                meubles, sélectionnez{" "}
                <span style={{ fontWeight: "500" }}>"Draw"</span>.
              </p>
              <p style={{ marginTop: "10px" }}>
                Avec l'option <span style={{ fontWeight: "500" }}>"Draw"</span>,
                vous pouvez peindre la zone où les meubles apparaîtront. Il
                suffit de peindre une zone uniforme et grande où vous souhaitez
                que les meubles apparaissent.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame2}
                alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
              />
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Type de pièce à meubler
            </h3>
            <p className="article-text">
              Dans cette deuxième étape, vous devez sélectionner le type de
              pièce. Si vous sélectionnez 'Chambre', il placera un lit, une
              table de nuit, une lampe, etc. Si vous sélectionnez 'Salon', il
              placera des canapés, des tables basses, une télévision, etc.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Style de mobilier virtuel
            </h3>
            <p className="article-text">
              Vous pouvez également changer le type de style de 'Furnish'. Vous
              pouvez choisir entre 'Minimaliste', 'Méditerranéen',
              'Traditionnel', et plus d'options.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Nombre d'images à générer
            </h2>
            <p>
              Vous pouvez également choisir le nombre de propositions à générer.
              Chaque mobilier est unique, donc nous recommandons de sélectionner
              la génération de 2 ou 3 propositions. Ainsi, vous pouvez choisir
              la proposition qui vous convient le mieux.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToFurnishFrame3}
              alt="How to use the furnishing tool to furnish virtually – Pedra software screenshot"
            />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Comment obtenir les meilleurs aménagements virtuels
                </h4>
                <p className="callout-p">
                  Suivez ces conseils pour obtenir les meilleurs résultats :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Si vous n'obtenez pas de bons résultats avec l'option de
                        positionnement automatique des meubles, essayez 'Draw'.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Pour contrôler le placement des meubles, choisissez
                        l'option 'Draw' à l'étape numéro 1
                      </span>
                      . De cette façon, vous pouvez indiquer où les meubles
                      apparaissent et quel espace ils occupent.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Si vous choisissez 'Draw', ne peignez pas une zone très
                        petite pour éviter des meubles de dimensions trop
                        petites.
                      </span>{" "}
                      Peignez une zone uniforme et grande.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si vous souhaitez contrôler quels meubles ajouter, leur
                        orientation et leur taille, nous recommandons la{" "}
                        <Link
                          className="article-link"
                          to="/fr/help/comment-ajouter-des-objets-3d"
                        >
                          'Add object'
                        </Link>
                      </span>
                      . Cette autre fonctionnalité de Pedra vous permet de
                      choisir les meubles à placer, leur orientation et leur
                      taille. Vous pouvez voir comment cela fonctionne{" "}
                      <Link
                        className="article-link"
                        to="/fr/help/comment-ajouter-des-objets-3d"
                      >
                        ici
                      </Link>
                      .
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToFurnish;
