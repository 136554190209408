import React from "react";
import Article4Frame from "../Images/Article4Frame.png";
import Article4Frame2 from "../Images/Article4Frame2.jpeg";
import Article4Frame3 from "../Images/Article4Frame3.jpeg";
import Article4Frame4 from "../Images/Article4Frame4.jpeg";
import Article4Frame5 from "../Images/Article4Frame5.jpeg";
import Article4Frame6 from "../Images/Article4Frame6.jpeg";
import Article4Frame7 from "../Images/Article4Frame7.png";
import Article4Frame8 from "../Images/Article4Frame8.png";
import Article4Frame9 from "../Images/Article4Frame9.jpeg";
import Article4Frame10 from "../Images/Article4Frame10.png";
import Article4Frame11 from "../Images/Article4Frame11.png";
import Article4Frame12 from "../Images/Article4Frame12.jpeg";
import Article4Frame13 from "../Images/Article4Frame13.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogEsArticle4() {
  return (
    <>
      <div className="section-one-column-left">
        <article
          className="title-container article"
          style={{ marginTop: "100px" }}
        >
          <h1 className="article-titleh1">
            ¿Cómo Puede La IA Ayudar A Los Agentes Inmobiliarios A Vender?
          </h1>
          <div className="title-secondary gray">
            Una explicación sobre qué es la IA y cómo puede ayudar a la venta de
            inmuebles
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
              }}
              src={Article4Frame}
              alt="Portada del artículo sobre cómo cómo puede la IA ayudar a los agentes inmobiliarios a vender"
            />
          </div>
        </article>
      </div>

      <div className="section-one-column-left" style={{ marginTop: "0px" }}>
        <section className="step-container article">
          <div>
            <div className="article-text">
              <div className="article-text">
                En este texto os vengo a hablar sobre la IA e intentar
                demistificar un poco sobre qué es la IA y qué puede hacer por
                vosotros en el día a día.
              </div>
              <br />
              <div className="article-text">
                Este artículo va a girar en torno a la siguiente pregunta:
              </div>
              <br />
              <div className="article-text" style={{ fontWeight: "600" }}>
                ¿Cómo podemos vender más con la IA?
              </div>
              <br />
              <div className="article-text">
                Pero primero, empecemos con qué es la IA:
              </div>
              <br />
              <h3 className="article-subtitle">¿Qué es la IA?</h3>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame2}
                alt="Diagrama explicativo sobre qué es la inteligencia artificial"
              />
              <br />
              <br />
              <div className="article-text">
                Un programa que ha aprendido a realizar tareas parecidas a las
                de un humano. Tareas como escribir, hablar, conversar, imaginar,
                etc.
              </div>
              <br />
              <h3 className="article-subtitle">¿Cómo se crea una IA?</h3>
              <div className="article-text">
                El primer paso es entrenar un modelo con miles a millones de
                datos.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame9}
                alt="Esquema sobre el proceso de creación de una inteligencia artificial"
              />
              <br />
              <br />
              <div className="article-text">
                El segundo paso sería poner en práctica a este modelo de
                inteligencia artificial que ya hemos entrenado. Para poneros una
                metáfora, es cómo cuando Rocky entrena muy duro durante mucho
                tiempo para ese combate final
              </div>
              <br />
              <h3 className="article-subtitle">
                ¿Qué modelo podríamos crear para ayudar a los agentes
                inmobiliarios a vender?
              </h3>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame10}
                alt="Ejemplo de modelo de IA para ayudar a agentes inmobiliarios a vender"
              />
              <br />
              <br />
              <div className="article-text">
                Por ejemplo, podríamos entrenar una IA para identificar cuáles
                son las imágenes que generan más clics en un anuncio en un
                portal, por ejemplo,{" "}
                <a
                  className="article-link"
                  href="https://www.idealista.com"
                  rel="nofollow"
                >
                  Idealista
                </a>
                .
              </div>
              <br />
              <div className="article-text">
                Básicamente tendríamos que empezar a entrenar un modelo, y
                pasarle millones de imágenes y decirle cuáles han tenido más
                visitas y cual fue la imagen que se puso primero. Una vez el
                modelo hubiese sido entrenado con estos millones de imágenes,
                tendríamos un pequeño robot que le podríamos pasar las 20
                imágenes del piso que queremos colgar, y nos diría cuál de ellas
                recibirá más clics.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article4Frame11}
                alt="Ejemplo de cómo una IA podría seleccionar la mejor imagen para un anuncio inmobiliario"
              />
              <br />
              <br />
              <div className="article-text">
                Pero conseguir un pequeño robot que te dé esta ayuda no es ni
                barato, ni fácil, ni rápido, ya que:
              </div>
              <br />
              <li className="article-text">
                Tendríamos que entrenarlo con millones de datos
              </li>
              <li className="article-text">
                Necesitaríamos un montón de recursos de ingeniería, tanto
                humanos como de ordenadores.{" "}
              </li>
              <li className="article-text">Necesitaríamos mucho tiempo</li>
              <br />
              Así que hoy no os voy a explicar cómo montar modelos de IA, aunque
              me encantaría, pero en vez de ello, os voy a mostrar modelos que
              ya existen, y que podéis utilizar en vuestro trabajo para sacaros
              faenas de encima y ayudar a vender.
              <br />
              <br />
              <h3 className="article-subtitle">
                ¿Hay modelos de IA que pueden vender una casa por mí?
              </h3>
              <div className="article-text">
                No.
                <br />
                <br /> No hay modelos de IA que sustituyan al agente
                inmobiliario o que vayan a vender por ti. Porque la IA se centra
                en actividades repetitivas y no en actividades humanas y de alta
                complejidad. El agente inmobiliario requiere de relaciones
                interpersonales y un grado de complejidad que no puede asumir la
                tecnología de hoy.
              </div>
              <br />
              <h3 className="article-subtitle">
                ¿Entonces, qué puede hacer la IA para el agente?
              </h3>
              <div className="article-text">
                Pues la IA puede sacarte trabajo repetitivo. Y así tendrás más
                tiempo para enfocarte en vender y captar.
              </div>
              <br />
              <div className="article-text">
                Entonces... ¿En qué cosas repetitivas nos puede ayudar?
              </div>
              <br />
              <h3 className="article-subtitle">
                Conseguir imágenes que vendan
              </h3>
              <div className="article-text">
                Las imágenes son una de las tareas repetitivas que se requieren
                para cada transacción (casi todas).
              </div>
              <br />
              <div className="article-text">
                Y dentro del mundo de las imágenes, hay muchas actividades
                repetitivas:
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame12}
                alt="Ejemplo de cómo la IA puede ayudar a mejorar la presentación de una propiedad inmobiliaria"
              />
              <br />
              <br />
              <div className="article-text">
                Un ejemplo claro es cuando tenemos "la casa de la abuela" (una
                cosa muy antigua) a vender. Estas situaciones suelen ser
                difíciles ya que aunque el piso esté genial, los muebles que la
                adornan no ayudan nada.
              </div>
              <br />
              <div className="article-text">
                La IA nos soluciona este problema.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame13}
                alt="Ejemplo de cómo la IA puede ayudar a eliminar muebles antiguos de una propiedad"
              />
              <br />
              <br />
              <div className="article-text">
                En 1 click podemos{" "}
                <Link className="article-link" to="/es/real-estate-photography">
                  vaciar habitaciones con IA
                </Link>{" "}
                y que las habitaciones luzcan de verdad.
              </div>
              <br />
              <div className="article-text">
                Otra tarea repetitiva dentro del mundo de la comercialización de
                pisos es conseguir renders de cómo podría quedar una propiedad
                después de una reforma. Y es que cuando tenemos una propiedad a
                reformar, colocar con renders es un buen acelerón a la
                comercialización y un plus en vuestros servicios de cara al
                propietario.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame3}
                alt="Ejemplo de render generado por IA para mostrar una reforma de una propiedad"
              />
              <br />
              <br />
              <div className="article-text">
                El problema es que los renders son caros y lentos (tardan al
                menos 2 semanas en hacerse).
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame4}
                alt="Ventajas de utilizar IA para generar renders de renovación rápidamente"
              />
              <br />
              <br />
              <div className="article-text">
                La IA pone solución a este problema. Con la IA puedes conseguir
                propuestas de{" "}
                <Link className="article-link" to="/es/render">
                  renovación en cuestión de segundos
                </Link>
                , cuando quieras, donde quieras, y con el estilo que desees.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame5}
                alt="Uso de IA para amueblar virtualmente una propiedad inmobiliaria"
              />
              <br />
              <br />
              <div className="article-text">
                Y también te ayudará a llenar los espacios de vida. Las
                propiedades sin muebles suelen ser difíciles de apreciar a la
                hora de comercializar. Los compradores se confunden, y les
                cuesta visualizar la vivienda. Por eso en los últimos años se ha
                puesto de moda el concepto del "home staging" en físico.
              </div>
              <br />
              <div className="article-text">
                Pero el home staging físico tiene también sus contras. Suele ser
                muy caro. Y mucho más caro que los renders. Un home staging
                físico te puede costar hasta 3,000€.
              </div>
              <br />
              <div className="article-text">
                Con la IA, no puedes replicar el efecto en físico del home
                staging, pero sí que puedes replicar el efecto cuando se ve la
                propiedad desde un móvil. Y eso importa mucho. Las personas
                suelen tomar la primera toma de contacto con un piso con la
                primera foto que ven desde un portal o una red social. Si el
                piso se ve bien amueblado, aunque realmente no esté, tienes más
                posibilidades de enamorar a ese comprador.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame6}
                alt="Ejemplo de cómo la IA puede mejorar la apariencia exterior de una propiedad"
              />
              <br />
              <br />
              <div className="article-text">
                Y nuestra agenda también agradecerá la IA. Sabemos que el buen
                tiempo es clave cuando queremos{" "}
                <Link className="article-link" to="/es/real-estate-photography">
                  sacar las mejores fotos de nuestra propiedad.
                </Link>{" "}
                Pero eso no es siempre fácil. Coordinar con fotógrafo, con
                propietario, con la madre naturaleza... un lío. La IA pone
                remedio a eso. Con una pincelada puedes conseguir cielos azules.
                Y con otra pincelada arreglar ese césped que ya lleva un buen
                tiempo sin cuidarse.
              </div>
              <br />
              <div className="article-text">Y ayudarnos con el texto</div>
              <br />
              <div className="article-text">
                El texto también forma una parte clave del día a día del
                inmobiliario.
              </div>
              <br />
              <div className="article-text">
                Y es que hay muchas tareas repetitivas con el texto que la IA
                puede ayudarnos con. Tan solo dando instrucciones y
                contextualizando, podemos generar textos espectaculares. El
                mismo{" "}
                <a className="article-link" href="https://chatgpt.com">
                  ChatGPT
                </a>{" "}
                puede ayudarnos a generar textos con instrucciones sencillas, y
                evitarnos tareas tediosas de trabajo.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame7}
                alt="Ejemplo de cómo la IA puede ayudar a generar textos para anuncios inmobiliarios"
              />
              <br />
              <br />
              <div className="article-text">
                Y si tenemos un formato de anuncio que nos encanta también
                funcionará. Le podemos mostrar a ChatGPT el formato del texto
                del anuncio y decirle que nos lo replique para una serie de
                características de un nuevo piso.
              </div>
              <br />
              <div className="article-text">
                También podemos indicarle que nos genere el texto entorno a un
                público clave. Si el producto a vender es perfecto para
                familias, se lo podemos decir, y ChatGPT se encargará de realzar
                aquellas partes del texto que más apelen a la familia.
              </div>
              <br />
              <div className="article-text">
                (Pero tampoco nos engañemos, cada vez más se leen menos los
                anuncios)
              </div>
              <br />
              <div className="article-text">
                Otra tarea tediosa es rellenar contratos de arras. Y la IA
                también nos puede agilizar ese tipo de tareas. Si queremos
                escribir un contrato de arras rápido, le podemos subir la
                plantilla de arras, los datos del piso, y los DNIs de comprador
                y vendedor. Y en unos segundos, ChatGPT escupirá un PDF con los
                datos rellenados.
              </div>
              <br />
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                }}
                src={Article4Frame8}
                alt="Ejemplo de cómo la IA puede ayudar a generar contratos de arras rápidamente"
              />
              <br />
              <br />
              <h3 className="article-subtitle">
                En conclusión, la IA te da superpoderes
              </h3>
              <br />
              <div className="article-text">
                Te ayudará a vender más rápido. Puede actuar como un lead magnet
                en los anuncios en portales o en redes sociales, para que más
                compradores entren a visitar el anuncio.
              </div>
              <br />
              <div className="article-text">
                Aumentará la calidad, rapidez y amplitud de tus servicios. De
                cara al comprador y vendedor, podrás ofrecerle más experiencias
                y ayudas dentro de la operación.{" "}
                <Link className="article-link" to="/es/render">
                  ¿Quieres ver el piso reformado?
                </Link>{" "}
                ¿Quieres que te saque el contrato de arras en un segundo? ¿Te
                publicamos el anuncio en un periquete?
              </div>
              <br />
              <div className="article-text">
                Automatiza y reduce costes. Lo que antes podías hacer de manera
                muy manual, externalizada, y cara, lo puedes hacer desde tu
                móvil, cuando quieras, a coste de consumidor.
              </div>
              <br />
              <div className="article-text">
                E incluso puedes usarlo para captar y negociar. Tenemos ejemplos
                de clientes en{" "}
                <Link className="article-link" to="/es">
                  Pedra
                </Link>
                , cómo{" "}
                <a className="article-link" href="https://flimasa.com">
                  Flimasa
                </a>{" "}
                , que puede negociar el precio de las propieades de chalets
                sobre-encarezidos. Cuando un propietario les dice que su
                propiedad hecha polvo vale un millón, Flimasa le puede decir que
                realmente no vale un millón, si no menos. Y como argumento, le
                puede mostrar la fotografía y decirle: "El comprador tiene esto
                en su cabeza, tu producto tiene este aspecto. Tienes que baja el
                precio o sino el comprador."
              </div>
              <br />
              <div className="article-text" style={{ color: "#878787" }}>
                Este contenido fue originalmente expuesto en un evento de{" "}
                <a className="article-link" href="https://www.iadespana.es">
                  IAD España
                </a>{" "}
                el 18 de Junio de 2024
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hola! Soy Felix Ingla, el fundador de{" "}
              <Link className="article-link" to="/es">
                Pedra
              </Link>
              , una innovadora aplicación web de{" "}
              <Link to="/es/home-staging-virtual" className="article-link">
                home staging virtual
              </Link>{" "}
              virtual y fotografía inmobiliaria que está transformando la forma
              en que se presentan las propiedades en el mundo digital. Mi
              dedicación a la creación de tecnología y soluciones intuitivas ha
              establecido a Pedra como una herramienta referente para
              profesionales inmobiliarios que buscan mejorar sus anuncios
              inmobiliarios.
            </p>
            <p>
              Si quieres conectar y estás interesado en discutir sobre
              tecnología inmobiliaria, por favor contáctame a través de mi{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . También puedes leer más{" "}
              <Link className="article-link" to="/es/about">
                sobre Pedra aquí
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogEsArticle4;
