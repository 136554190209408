import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBedroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeBedroom from "../Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "../Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBedroom() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Virtual Home Staging for Bedrooms
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on creating virtual home staging for sleeping spaces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create virtual home staging for a bedroom?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home staging for bedrooms in
              just a few clicks.
              <p>
                To begin, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Choose "Bedroom" as the space type and in 2 or 3 minutes, you'll
                have your virtual home staging image of the bedroom ready to
                download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create virtual home staging for a bedroom – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for bedrooms?
            </h2>
            <p className="article-text">
              To achieve your{" "}
              <Link className="article-link" to="/home-staging-bedroom">
                virtual home staging for a bedroom
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the bedroom you want to virtually furnish
              </li>
              <li>Select the image to start editing it</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu for creating virtual home staging for a bedroom"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>furniture position</span>.
                For bedrooms, we recommend using "Draw" to indicate specific
                areas to place the bed, wardrobes, nightstands, etc.
              </li>
              <li>
                Select <span style={{ fontWeight: "500" }}>"Bedroom"</span> as
                the space type. This will ensure that Pedra places appropriate
                furniture such as beds, wardrobes, dressers, etc.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. For
                bedrooms, styles like "Modern", "Classic" or "Minimalist"
                usually work well.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to have different bedroom
                design options.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better virtual home staging of a bedroom:
                <ul>
                  <li>
                    Make sure the photograph clearly shows the dimensions of the
                    bedroom for more accurate furnishing
                  </li>
                  <li>
                    If the bedroom has special features like large windows or
                    high ceilings, try to include them in the photo for a more
                    attractive result
                  </li>
                  <li>
                    Use the "Draw" option to indicate specific areas for the
                    bed, wardrobes, reading corner or decorative elements
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Example of creating virtual home staging for a bedroom"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why create virtual home staging for a bedroom?
            </h2>
            <p className="article-text">
              A well-presented bedroom can be a great asset for potential
              buyers. Virtual home staging for bedrooms allows you to show the
              potential of these resting spaces, creating cozy and functional
              environments that help buyers visualize how they could enjoy
              restful sleep and relaxation.
            </p>
            <p className="article-text">
              With good virtual home staging for a bedroom, you can:
            </p>
            <ul>
              <li>Show the potential of empty or unattractive spaces</li>
              <li>Create an atmosphere of comfort and tranquility</li>
              <li>
                Highlight features such as brightness, storage space or the
                versatility of the bedroom
              </li>
              <li>
                Help buyers imagine moments of relaxation and rest in the
                bedroom
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging for bedrooms
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings that can be
              applied to bedrooms:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroom}
              afterImage={AfterBedroom}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <br />
            {/*
            <h2 className="article-subtitleh2">
              Video on how to create virtual home staging for bedrooms
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/YOUTUBE_VIDEO_ID"
                  title="Tutorial on Virtual Home Staging for Bedrooms"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBedroom;
