import React, { useState } from "react";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RealEstatePhotographyEsPage1 from "./Images/RealEstatePhotographyEsPage1.png";
import RealEstatePhotographyEsPage2 from "./Images/RealEstatePhotographyEsPage2.png";
import RealEstatePhotographyEsPage3 from "./Images/RealEstatePhotographyEsPage3.png";
import RealEstatePhotographyEsPage4 from "./Images/RealEstatePhotographyEsPage4.png";
import RealEstatePhotographyEsPage5 from "./Images/RealEstatePhotographyEsPage5.jpeg";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6.jpeg";
import CustomerLogos from "./CustomerLogos.js";
import iadLogo from "./Images/iadLogo.png";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RealEstatePhotographyEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Mejora tus fotografías inmobiliarias con IA en 1 click
            </h1>
            <div className="section-first-text">
              <div
                className="section-first-text-bold"
                style={{ width: "100%" }}
              >
                Usa la IA de Pedra para mejorar tus fotografías inmobiliarias
                facilmente
              </div>
              <div className="section-first-text-gray">
                Corrige perspectivas, mejora la luz y elimina lo innecesario
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ profesionales inmobiliarios ya usan Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+website+demo+Jul+09+24.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Mejora tu fotografía inmobiliaria en 1 click
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">
                  Mejora tus fotografías de inmuebles facilmente
                </h2>
                <div className="feature-text">
                  Sube tus imágenes y haz click en "Embellecer y corregir".
                  Pedra se encargará de mejorar la luz y color, y corregir las
                  perspectivas.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage1}
                alt="Corrige perspectiva, color y luz con IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Borra objetos innecesarios de tus fotos de inmuebles
                </h2>
                <div className="feature-text">
                  La IA de Pedra eliminará los objetos de las habitaciones, como
                  fotos de família, banderas, cuadros y demás.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage2}
                alt="Quitar objeto fotografía inmobiliaria"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Ilumina habitaciones muy oscuras automaticamente
                </h2>
                <div className="feature-text">
                  Usa la IA de Pedra para arreglar la luz en habitaciones donde
                  la luz este demasiado oscura.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage3}
                alt="Ilumina habitaciones muy oscuras"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Aumente la resolución de tus fotografías de casas a HD
                </h2>
                <div className="feature-text">
                  No te preocupes de fotografías con baja resolución. Con Pedra
                  podrás aumentar la resolución de tus imágenes a HD en 1 solo
                  click.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage4}
                alt="Herramienta de cambio de paredes y suelos via renders con IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Cambia materiales de paredes y suelos de inmuebles con IA
                </h2>
                <div className="feature-text">
                  Usa la IA para cambiar el material de suelo y paredes. Si
                  tienes una propiedad con un césped en mal estado, con Pedra
                  podrás mostrarlo en su mejor versión.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage5}
                alt="Herramienta de cambio de paredes y suelos via renders con IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">Pon el cielo azul con IA</h2>
                <div className="feature-text">
                  Usa la IA de Pedra para arreglar un mal día. No dejes que el
                  tiempo te arruine la sesión fotográfica de la propiedad en
                  venta.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Pon el cielo azul con IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h3 className="quote">"Es el descubrimiento del siglo"</h3>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "45px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={iadLogo}
                alt="Logotipo de iad para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Bouchra</div>
                <div className="quote-author-company">iad España</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Prueba Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">¿Qué es Pedra?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Pedra es una aplicación con IA diseñada para agentes
                inmobiliarios y empresas inmobiliarias.
                <br />
                <br />
                El objetivo de Pedra es ayudarte a vender más reduciendo costos.
                <br />
                <br />
                Con un solo clic, podrás mejorar las fotografías de tus
                inmuebles, optimizando la luz, el color y corrigiendo
                perspectivas o definición. Esto te ahorrará horas de edición en
                Photoshop, Lightroom o con fotógrafos profesionales.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Qué funcionalidades incluye el kit de mejora de fotos de Pedra?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  El kit de mejora de fotografías inmobiliarias de Pedra incluye
                  las siguientes funcionalidades:
                </div>
                <h4>Corrección automática de perspectiva:</h4>Con Pedra podrás
                enderezar y corregir las perspectivas de tus fotografías
                automáticamente con un solo clic. Esto te ahorrará tiempo y
                esfuerzo. La funcionalidad suele tardar 5 segundos.
                <br />
                <br />
                <h4>Embellecimiento automático de fotografías:</h4>Mejora la luz
                y el color de tus fotografías inmobiliarias con un solo botón.
                Haz clic y Pedra se encargará de realzar los colores y luces,
                haciendo que la fotografía se vea más atractiva. La
                funcionalidad también tarda 5 segundos.
                <br />
                <br />
                <h4>Corrección y embellecimiento de fotografías:</h4>También
                puedes mejorar la luz, el color y la perspectiva de tus fotos
                simultáneamente. Esta funcionalidad también tarda 5 segundos.
                <br />
                <br />
                <h4>Iluminación automática de fotografías:</h4>Si tienes
                fotografías muy oscuras, con Pedra podrás iluminarlas para que
                se vean claras. Así, tus propiedades se presentarán de manera
                más atractiva, mejorando la relación con el propietario y
                aumentando las posibilidades de venta.
                <br />
                <br />
                <h4>Aumento de resolución de fotografías a HD:</h4>También
                puedes elevar la resolución de tus fotografías a HD con un solo
                clic. Simplemente selecciona la opción{" "}
                <span style={{ fontWeight: "500" }}>Convertir a HD</span>.
                Fotografías de mayor calidad mejoran la presencia de tu anuncio
                en los principales portales inmobiliarios.
                <br />
                <br />
                <h4>Eliminación de objetos:</h4>Con Pedra también podrás remover
                cualquier tipo de objeto de las fotografías inmobiliarias, como
                fotos familiares, basura, cuadros, cruces, banderas, etc.
                Simplemente usa el pincel de Pedra sobre los objetos que deseas
                eliminar y selecciona "Generar imagen".
                <br />
                <br />
                <h4>Eliminación de marcas de agua:</h4>Puedes eliminar las
                marcas de agua de otras inmobiliarias utilizando la misma
                funcionalidad de "Eliminar objeto".
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Por qué debería usar Pedra para mejorar mis fotografías de
                inmuebles?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Mejorar las fotografías de tus inmuebles antes de publicarlas
                puede tener un gran impacto en cómo los potenciales compradores
                o arrendatarios perciben tus propiedades. Aquí te dejo algunas
                razones clave:
                <br />
                <h4>Primera impresión:</h4> La fotografía es frecuentemente el
                primer contacto que un interesado tiene con tu propiedad.
                Imágenes de alta calidad crean una primera impresión positiva,
                lo que puede influir significativamente en la decisión de
                explorar más a fondo el inmueble.
                <br />
                <h4>Destacar características:</h4> Un buen retoque fotográfico
                puede resaltar las mejores características de tu propiedad, como
                la amplitud de los espacios, la iluminación natural, y los
                acabados de calidad. Esto puede ayudar a los potenciales
                compradores a visualizar el valor de la propiedad.
                <br />
                <h4>Diferenciación en el mercado:</h4> El mercado inmobiliario
                puede ser muy competitivo. Fotografías bien producidas pueden
                hacer que tu propiedad destaque entre otras en el mercado,
                atrayendo más interés y posiblemente acelerando la venta o el
                alquiler. Las imágenes atractivas y de calidad también tienden a
                generar más interacción en plataformas de listado en línea y
                redes sociales. Esto puede aumentar la visibilidad de tu anuncio
                y atraer a más interesados.
                <br />
                <h4>Reducción del tiempo en el mercado:</h4> Propiedades con
                imágenes atractivas suelen venderse o alquilarse más rápido. Los
                compradores se sienten más inclinados a tomar decisiones rápidas
                cuando las imágenes les proporcionan una buena representación de
                la propiedad.
                <br />
                <h4>Promoción efectiva:</h4> Las imágenes de alta calidad son
                esenciales para la promoción efectiva de cualquier propiedad. No
                solo son útiles para anuncios en línea, sino también para
                folletos, presentaciones y otros materiales de marketing. En
                resumen, invertir tiempo y recursos en mejorar las fotografías
                de tus inmuebles puede resultar en una mejor percepción de tus
                propiedades, diferenciación en el mercado, y una venta o
                alquiler más rápido.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">¿Cuánto vale Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra tiene un coste de 29€ al mes y es una suscripción mensual
                sin permanencia. Si decides no usarlo más, podrás darte de baja
                en cualquier momento.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstatePhotographyEs;
