import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageTerraceFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingTerrace() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Virtual Home Staging for Terraces
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create virtual home staging for outdoor spaces
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              How can I create virtual home staging for a terrace?
            </h2>
            <p className="article-text">
              With Pedra, you can create virtual home staging for terraces in
              just a few clicks.
              <p>
                To begin, access the{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> menu
                and select <span style={{ fontWeight: "500" }}>"Furnish"</span>.
                Choose "Terrace" as the type of space, and in about 2 or 3
                minutes, you'll have your virtual home staging image of the
                terrace ready to download.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="How to create virtual home staging for a terrace – Examples"
            />
            <br />
            <h2 className="article-subtitleh2">
              How to create virtual home staging for terraces?
            </h2>
            <p className="article-text">
              To achieve your{" "}
              <Link className="article-link" to="/home-staging-terrace">
                virtual home staging for a terrace
              </Link>
              , follow these simple steps:
            </p>
            <ol>
              <li>
                Upload the image of the terrace you want to virtually furnish
              </li>
              <li>Select the image to start editing</li>
              <li>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span> and
                then click on{" "}
                <span style={{ fontWeight: "500" }}>"Furnish"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu for creating virtual home staging for a terrace"
            />
            <p className="article-text">
              Within <span style={{ fontWeight: "500" }}>"Furnish"</span> you'll
              need to follow these steps:
            </p>
            <ol>
              <li>
                Select the{" "}
                <span style={{ fontWeight: "500" }}>
                  position of the furniture
                </span>
                . For terraces, we recommend using "Draw" to indicate specific
                areas where to place outdoor furniture.
              </li>
              <li>
                Select <span style={{ fontWeight: "500" }}>"Terrace"</span> as
                the type of space. This will ensure that Pedra places
                appropriate outdoor furniture such as garden chairs, patio
                tables, umbrellas, etc.
              </li>
              <li>
                Select the <span style={{ fontWeight: "500" }}>style</span>. For
                terraces, styles like "Mediterranean", "Modern" or "Rustic"
                usually work well.
              </li>
              <li>
                Choose the{" "}
                <span style={{ fontWeight: "500" }}>
                  number of images to generate
                </span>
                . We recommend choosing 2 or 3 images to have different terrace
                design options.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better virtual home staging of a terrace:
                <ul>
                  <li>
                    Make sure the photograph clearly shows the boundaries of the
                    terrace for more accurate furnishing
                  </li>
                  <li>
                    If the terrace has views, try to include them in the photo
                    for a more attractive result
                  </li>
                  <li>
                    Use the "Draw" option to indicate specific areas for outdoor
                    furniture, plants, or decorative elements
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Example of how to create virtual home staging for a terrace"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Why do virtual home staging for a terrace?
            </h2>
            <p className="article-text">
              A well-presented terrace can be a great attraction for potential
              buyers. Virtual home staging for terraces allows you to show the
              potential of these outdoor spaces, creating cozy and functional
              environments that help buyers visualize how they could enjoy the
              outdoor space.
            </p>
            <p className="article-text">
              With good virtual home staging for a terrace, you can:
            </p>
            <ul>
              <li>
                Show the potential of empty or unattractive outdoor spaces
              </li>
              <li>Create a visual extension of the interior living space</li>
              <li>
                Highlight features such as views, privacy, or versatility of the
                space
              </li>
              <li>
                Help buyers imagine moments of relaxation or entertainment on
                the terrace
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Examples of virtual home staging for terraces
            </h2>
            <p className="article-text">
              Here are several examples of virtual home stagings that can be
              applied to terraces:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Before creating virtual home staging"}
              altAfter={"After creating virtual home staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingTerrace;
