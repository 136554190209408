import React from "react";

const OrangePerspectiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M25.38 10.59H18.42V26.25H11.46V22.77H14.94V21.03H11.46V17.55H14.94V15.81H11.46V12.33H14.94V10.59H4.5V7.98L11.46 4.5H18.42L25.38 7.98V10.59Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangePerspectiveIcon;
