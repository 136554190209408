import React from "react";
import Article7Frame from "../Images/Article7Frame.jpg";
import Article7Frame1 from "../Images/Article7Frame1.jpg";
import Article7Frame2 from "../Images/Article7Frame2.jpg";
import Article7Frame3 from "../Images/Article7Frame3.jpg";
import Article7Frame4 from "../Images/Article7Frame4.jpg";
import Article7Frame5 from "../Images/Article7Frame5.jpg";

import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle7() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Home Staging Examples: Before and After
            </h1>
            <h2 className="title-secondary gray">
              Are you looking for home staging before and after examples? To
              give you an idea of the power of this technique, here are some
              home staging examples for you.
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Founder of Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Founder of Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article7Frame}
                alt="Home Staging Example – Before and After – Illustrative Cover"
              />
            </div>
            <article className="article-text">
              <br />
              <p style={{ marginTop: "10px" }}>
                Next, we’ll show you examples of home staging for a bathroom,
                kitchen, bedroom, and living room. Let’s go!
              </p>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Example: Home Staging of a Kitchen
              </h2>
              <p style={{ marginTop: "10px" }}>
                The first example is this kitchen. As you can see, the change is
                radical, but the same architecture and furniture layout are
                maintained.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame1}
                  alt="Home Staging Example: Kitchen Before and After"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                As you can observe in the before and after of the Home Staging,
                the “before” was outdated and cluttered. The new look is much
                simpler. It was done virtually and includes a floor change, wall
                painting, and furniture renovation. The content outside the
                kitchen has also been staged to match the rest of the image.
              </p>
              <h2 className="article-subtitleh2">
                Example: Home Staging of a Bathroom
              </h2>
              <p style={{ marginTop: "10px" }}>
                The next image is an example of home staging for a bathroom.
              </p>
              <p style={{ marginTop: "10px" }}>
                As you can see, the change is quite radical. We go from an
                outdated bathroom in desperate need of renovation to a modern
                bathroom. The size of the space and the layout remain the same.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame5}
                  alt="Home Staging Example: Bathroom Before and After"
                />
              </div>
              <br />
              <h2 className="article-subtitleh2">Home Staging of a Bedroom</h2>
              <p style={{ marginTop: "10px" }}>
                The next example of home staging is a bedroom. As you can see,
                the change is minimal and non-aggressive. The space size is
                maintained, and only a bed and a plant are added. This way, the
                potential buyer better understands the purpose of this space.
              </p>
              <p style={{ marginTop: "10px" }}>
                With this decoration, can you imagine your life in the property?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame3}
                  alt="Home Staging Example: Bedroom Before and After"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Home Staging of a Living Room
              </h2>
              <p style={{ marginTop: "10px" }}>
                The following Home Staging example illustrates the ability to
                renew a very outdated living room.
              </p>
              <p style={{ marginTop: "10px" }}>
                Notice that the furniture layout in the photo is the same, but
                absolutely all the furniture has been renewed, and a new space
                has been added.
              </p>
              <p style={{ marginTop: "10px" }}>
                This home staging example also highlights the light in the
                space, which is a very important factor for buyers when
                considering a property.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame2}
                  alt="Home Staging Example: Living Room Before and After"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                The change in light is justified by the removal of curtains and
                the change in wall color. This change makes the space much more
                pleasant for the potential buyer.
              </p>

              <h2 className="article-subtitleh2">
                Example of Home Staging for a Terrace
              </h2>
              <p style={{ marginTop: "10px" }}>
                To conclude the home staging examples, here’s an example of
                staging for a terrace. As you can see here, the terrace becomes
                much more livable. Wouldn’t you like to have breakfast on this
                terrace?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame4}
                  alt="Home Staging Example: Terrace Before and After"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                It seems incredible how a property can be improved with minimal
                investment. The budget can be as little as some paint and a lot
                of cleaning if done physically, or a few clicks and less than
                $50 if done virtually.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hello! I'm Felix Ingla, the founder of{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , an innovative virtual home staging and real estate photography
              web application that is transforming how properties are presented
              in the digital world. My dedication to creating cutting-edge
              technology and intuitive solution has established Pedra as a
              trusted tool for real estate professionals seeking to enhance
              their property listings.
            </p>
            <p>
              If you are eager to connect and interested in discussing real
              estate technology, please reach out via my{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . You can also read more{" "}
              <Link className="article-link" to="/about">
                about Pedra here
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle7;
