import React from 'react';

const RedEyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M18.2 15C18.2 16.768 16.768 18.2 15 18.2C13.232 18.2 11.8 16.768 11.8 15C11.8 13.232 13.232 11.8 15 11.8C16.768 11.8 18.2 13.232 18.2 15ZM26.2 15C26.2 15 21.828 23 15 23C8.17205 23 3.80005 15 3.80005 15C3.80005 15 8.17205 7 15 7C21.828 7 26.2 15 26.2 15ZM20.6 15C20.6 11.908 18.092 9.4 15 9.4C11.908 9.4 9.40005 11.908 9.40005 15C9.40005 18.092 11.908 20.6 15 20.6C18.092 20.6 20.6 18.092 20.6 15Z" fill="#EB5757"/>
    </svg>
  );
};

export default RedEyeIcon;