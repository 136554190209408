import React from "react";
import Article7Frame from "../Images/Article7Frame.jpg";
import Article7Frame1 from "../Images/Article7Frame1.jpg";
import Article7Frame2 from "../Images/Article7Frame2.jpg";
import Article7Frame3 from "../Images/Article7Frame3.jpg";
import Article7Frame4 from "../Images/Article7Frame4.jpg";
import Article7Frame5 from "../Images/Article7Frame5.jpg";

import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogFrArticle7() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Exemples de Home Staging : Avant et Après
            </h1>
            <h2 className="title-secondary gray">
              Vous cherchez des exemples de home staging avant et après ? Pour
              vous donner une idée de la puissance de cette technique, voici
              quelques exemples de home staging pour vous.
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fondateur de Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fondateur de Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article7Frame}
                alt="Exemple de Home Staging – Avant et Après – Couverture illustrative"
              />
            </div>
            <article className="article-text">
              <br />
              <p style={{ marginTop: "10px" }}>
                Ensuite, nous vous montrerons des exemples de home staging pour
                une salle de bain, une cuisine, une chambre et un salon.
                Allons-y !
              </p>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Exemple : Home Staging d'une Cuisine
              </h2>
              <p style={{ marginTop: "10px" }}>
                Le premier exemple est cette cuisine. Comme vous pouvez le voir,
                le changement est radical, mais la même architecture et la même
                disposition des meubles sont maintenues.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame1}
                  alt="Exemple de Home Staging : Cuisine Avant et Après"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Comme vous pouvez l'observer dans l'avant et l'après du Home
                Staging, l'aspect "avant" était démodé et encombré. Le nouveau
                look est beaucoup plus simple. Il a été réalisé virtuellement et
                comprend un changement de sol, une peinture des murs et une
                rénovation des meubles. Le contenu à l'extérieur de la cuisine a
                également été mis en scène pour correspondre au reste de
                l'image.
              </p>
              <h2 className="article-subtitleh2">
                Exemple : Home Staging d'une Salle de Bain
              </h2>
              <p style={{ marginTop: "10px" }}>
                L'image suivante est un exemple de home staging pour une salle
                de bain.
              </p>
              <p style={{ marginTop: "10px" }}>
                Comme vous pouvez le voir, le changement est assez radical. Nous
                passons d'une salle de bain démodée ayant désespérément besoin
                d'une rénovation à une salle de bain moderne. La taille de
                l'espace et la disposition restent les mêmes.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame5}
                  alt="Exemple de Home Staging : Salle de Bain Avant et Après"
                />
              </div>
              <br />
              <h2 className="article-subtitleh2">Home Staging d'une Chambre</h2>
              <p style={{ marginTop: "10px" }}>
                L'exemple suivant de home staging est une chambre. Comme vous
                pouvez le voir, le changement est minimal et non agressif. La
                taille de l'espace est maintenue, et seuls un lit et une plante
                sont ajoutés. De cette façon, l'acheteur potentiel comprend
                mieux l'objectif de cet espace.
              </p>
              <p style={{ marginTop: "10px" }}>
                Avec cette décoration, pouvez-vous imaginer votre vie dans la
                propriété ?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame3}
                  alt="Exemple de Home Staging : Chambre Avant et Après"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Home Staging d'un Salon</h2>
              <p style={{ marginTop: "10px" }}>
                L'exemple de Home Staging suivant illustre la capacité à
                renouveler un salon très démodé.
              </p>
              <p style={{ marginTop: "10px" }}>
                Remarquez que la disposition des meubles sur la photo est la
                même, mais absolument tous les meubles ont été renouvelés, et un
                nouvel espace a été ajouté.
              </p>
              <p style={{ marginTop: "10px" }}>
                Cet exemple de home staging met également en valeur la lumière
                dans l'espace, ce qui est un facteur très important pour les
                acheteurs lorsqu'ils envisagent une propriété.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame2}
                  alt="Exemple de Home Staging : Salon Avant et Après"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Le changement de luminosité se justifie par la suppression des
                rideaux et le changement de couleur des murs. Ce changement rend
                l'espace beaucoup plus agréable pour l'acheteur potentiel.
              </p>

              <h2 className="article-subtitleh2">
                Exemple de Home Staging pour une Terrasse
              </h2>
              <p style={{ marginTop: "10px" }}>
                Pour conclure les exemples de home staging, voici un exemple de
                mise en scène pour une terrasse. Comme vous pouvez le voir ici,
                la terrasse devient beaucoup plus vivable. N'aimeriez-vous pas
                prendre votre petit-déjeuner sur cette terrasse ?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame4}
                  alt="Exemple de Home Staging : Terrasse Avant et Après"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Il semble incroyable comment une propriété peut être améliorée
                avec un investissement minimal. Le budget peut être aussi petit
                que de la peinture et beaucoup de nettoyage si c'est fait
                physiquement, ou quelques clics et moins de 50 € si c'est fait
                virtuellement.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Bonjour ! Je suis Felix Ingla, le fondateur de{" "}
              <Link className="article-link" to="/fr">
                Pedra
              </Link>
              , une application web innovante de home staging virtuel et de
              photographie immobilière qui transforme la façon dont les
              propriétés sont présentées dans le monde numérique. Mon dévouement
              à créer une technologie de pointe et une solution intuitive a
              établi Pedra comme un outil de confiance pour les professionnels
              de l'immobilier cherchant à améliorer leurs annonces de
              propriétés.
            </p>
            <p>
              Si vous souhaitez entrer en contact et discuter de la technologie
              immobilière, n'hésitez pas à me contacter via mon{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Vous pouvez également en savoir plus{" "}
              <Link className="article-link" to="/fr/about">
                sur Pedra ici
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogFrArticle7;
