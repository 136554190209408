import React from "react";

const VideoPricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.8571 7.28571V12.4286C12.8571 13.8343 11.9771 14.7143 10.5714 14.7143H4.28571C2.88 14.7143 2 13.8343 2 12.4286V7.28571C2 5.88 2.88 5 4.28571 5H10.5714C11.9771 5 12.8571 5.88 12.8571 7.28571ZM16.8571 5.85714L14 8.71429V11L16.8571 13.8571H18V5.85714H16.8571Z"
        fill="#A37EE3"
      ></path>
    </svg>
  );
};

export default VideoPricingIcon;
