import React from "react";
import ReleaseJun2024Frame1 from "../Images/ReleaseJun2024Frame1.png";
import ReleaseJun2024Frame2 from "../Images/ReleaseJun2024Frame2.png";
import ReleaseJun2024Frame3 from "../Images/ReleaseJun2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsJun24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">JUNIO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renueva espacios desde el móvil, edita pies de foto y cambia
            las marcas de agua
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Portada ilustrativa sobre la IA y el sector inmobiliario"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Por fin puedes utilizar Pedra desde el móvil. Te dejo aquí este
              lanzamiento y los avances que hemos hecho este junio:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Pedra para móvil</h2>
              Ahora podrás usar Pedra desde el navegador de tu móvil. Ideal para
              impresionar a clientes durante las visitas, crear una{" "}
              <Link to="/es/render" className="article-link">
                propuesta de renovación rápida
              </Link>{" "}
              o editar fotografías cuando estés lejos de tu ordenador.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame1}
                  alt="Renueva espacios con IA desde el móvil"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mueve la marca de agua y edita su transparencia
              </h2>
              Ya puedes editar el grado de transparencia de las marcas de agua,
              y además, podrás colocarlas donde quieras de tu imagen. Protege tu
              marca sin obstruir tus fotografías.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame2}
                  alt="Mueve y edita la transparencia de la marca de agua"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Cambia el texo del pie de foto
              </h2>
              Asegura que las personas que vean tus infografías sepan que no son
              reales. Ahora podrás editar el texto del pie de foto y su tamaño.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJun2024Frame3}
                alt="Cambia el pie de foto de una foto IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>

            <div>
              <li>
                Incremento de la calidad de los muebles insertados al "Amueblar"
              </li>
              <li>Opción de "Amueblar" una entrada</li>
              <li>
                Nuevo diseño de la página de inicio: ve cual es la primera
                imagen dentro de cada proyecto sin tener que abrirlo{" "}
              </li>
              <li>
                Hemos añadido un tutorial de 2min para que sepas cómo utilizar
                Pedra, y puedes verlo{" "}
                <a
                  className="article-link"
                  href="https://www.youtube.com/watch?v=z6O8EX2fJPI"
                  rel="nofollow"
                >
                  aquí
                </a>{" "}
              </li>{" "}
              <li>Opción de escoger gres porcelánico al "Editar suelo"</li>
              <li>Arreglos varios</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsJun24;
