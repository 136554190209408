// Home.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomerLogos from "./CustomerLogos.js";
import EnlightRooms from "./Images/EnlightRooms.png";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import HomeAmueblaEstanciasEn1Click from "./Images/HomeAmueblaEstanciasEn1Click.png";
import HomeVaciaEstanciasEn1Click from "./Images/HomeVaciaEstanciasEn1Click.png";
import HomeStaging1 from "./Images/HomeStaging1.jpeg";
import HomeStaging2 from "./Images/HomeStaging2.png";
import HomeStaging3 from "./Images/HomeStaging3.png";
import HomeStaging4 from "./Images/HomeStaging4.png";
import HDImage from "./Images/HDImage.png";
import FrameFloorPlanFeature from "./Images/FrameFloorPlanFeature.png";
import UploadAnImage from "./Images/UploadAnImage.png";
import PickAnOption from "./Images/PickAnOption.png";
import WaitAndDownloadResult from "./Images/WaitAndDownloadResult.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import CurtainEffect from "./CurtainEffect.js";

// Icons
import { ReactComponent as HouseIcon } from "./Images/House icon.svg";
import { ReactComponent as ColorPaletteIcon } from "./Images/Color palette icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeRollerIcon from "./Icons/OrangeRollerIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangePenIcon from "./Icons/OrangePenIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import OrangeVirtualVideoIcon from "./Icons/OrangeVirtualVideoIcon.jsx";
import OrangeSettingsIcon from "./Icons/OrangeSettingsIcon.jsx";
import OrangeWatermarkIcon from "./Icons/OrangeWatermarkIcon.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function Home(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    console.log(example);
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
      console.log("Furnish");
    } else if (example === "Renovate") {
      setExample({
        name: "Renovate",
        beforeImage: BeforeRenovate,
        afterImage: AfterRenovate,
      });
    } else if (example === "Empty") {
      setExample({
        name: "Empty",
        beforeImage: BeforeEmpty,
        afterImage: AfterEmpty,
      });
    } else {
      setExample({
        name: "Enhance",
        beforeImage: BeforeEnhance,
        afterImage: AfterEnhance,
      });
    }
  };

  return (
    <div>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">
            Show the potential of your properties{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
                borderBottom: "2px solid #ddd",
              }}
            >
              in 1 click
            </span>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  The virtual home staging tool you're looking for:
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Fast:</strong> Results in seconds
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Easy:</strong> Intuitive from the start
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Quality:</strong> Hyper-realistic results
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Help:</strong> Human assistance if needed
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 for 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Before virtually emptying the room"}
                  altAfter={"After virtually emptying the room"}
                />
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Furnish
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Empty" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Empty")}
                    >
                      <PurpleEmptyIcon />
                      Empty
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Renovate" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Renovate")}
                    >
                      <PurpleRenovateIcon />
                      Renovate
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Enhance" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Enhance")}
                    >
                      <PurpleEnhanceIcon />
                      Enhance
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Renders with AI</h2>
          <div className="title-secondary">
            Generate AI renders with Pedra. <br /> Renovate spaces, empty rooms,
            or make a virtual home staging in 1 click.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <RenovateIcon />
            <h2 className="feature-title">Renders with AI in 1 click</h2>
            <div className="feature-text">
              Use Pedra to generate renders with AI in 15 seconds. Accelerate
              your real estate sales by showcasing the true potential of your
              properties. How would this second-hand house look like after a
              renovation?
            </div>
          </div>
          <img
            src={FrameRenovateRealEstateAgents}
            alt="AI Render Tool – Software Screenshot"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">Empty a room with AI</h2>
                <div className="feature-text">
                  Use Pedra's AI to empty rooms in 1 click – just like magic.
                </div>
              </div>
              <img
                src={HomeVaciaEstanciasEn1Click}
                alt="Empty A Room With AI – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Furnish empty rooms in 1 click
                </h2>
                <div className="feature-text">
                  Use AI to make a virtual home staging. Select the style and
                  Pedra will take care of the rest.
                </div>
              </div>
              <img
                src={HomeAmueblaEstanciasEn1Click}
                alt="Virtual Home Staging AI Tool – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              “Using Pedra to edit our images is helping us get more visits!"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logotipo de Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">Fincas Llúria</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Virtual home staging</h2>
          <div className="title-secondary">
            Pedra offers a simple yet powerful home staging virtual tool. <br />
            Choose weather you want an automatic proposal or if you want to
            stage a room piece by piece.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangeFeatherIcon />
            <h2 className="feature-title">Automatic virtual home staging</h2>
            <div className="feature-text">
              Make properties more attractive with Pedra's virtual home staging
              tool. Use the automatic option or manually stage the room
              yourself. You choose.
            </div>
          </div>
          <img
            src={HomeStaging1}
            alt="Virtual Home Staging With AI – Software Screenshot"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRollerIcon />
                <h2 className="feature-title">
                  Change floors and walls with AI
                </h2>
                <div className="feature-text">
                  Render a new floor with Pedra's AI. Change the material of the
                  walls.
                </div>
              </div>
              <img
                src={HomeStaging2}
                alt="AI Tool To Change Floors And Walls Virtually – Software screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">Manual home staging</h2>
                <div className="feature-text">
                  Use Pedra's furniture library to make a custom home staging.
                  Add the furniture where you need it and make a render with AI
                  so that it looks real.
                </div>
              </div>
              <img
                src={HomeStaging3}
                alt="Virtual Home Staging AI Tool with Custom Furntiure – Software screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">Virtual home staging styles</h2>
                <div className="feature-text">
                  Pedra offers many styles so that you can generate a virtual
                  staging that best adapts to your customer needs.
                </div>
              </div>
              <img
                src={HomeStaging4}
                alt="Virtual Home Staging Tool With AI With Several Styles – Software screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeVideoIcon />
                <h2 className="feature-title">
                  Generate a video showing the staging evolution
                </h2>
                <div className="feature-text">
                  Make a video to better position your property in real estate
                  platforms and social media. Coomunicate the potential of a
                  property with Pedra's video generation tool.
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "30px",
                  paddingTop: "30px",
                  marginBottom: "-8px",
                }}
              >
                <video
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  loop
                  muted
                  alt="Software For AI Renders, Virtual Home Staging And Floor Plan – Video"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "1px solid #EEEEEE",
                    borderRight: "1px solid #EEEEEE",
                    borderTop: "1px solid #EEEEEE",
                  }}
                >
                  <source src="/HomeStaging5.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "You have thought about everyting ! Pedra has all that we need"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Vives Homes Logo"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Edit floor plans and images</h2>
          <div className="title-secondary">
            Generate floor plans in a simpel way and make your real estate
            images shine. You will not need to leave Pedra to elevate your
            listings.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangePenIcon />
            <h2 className="feature-title">The easiest floor planning tool</h2>
            <div className="feature-text">
              Create floor plans to better position your property. Pedra's floor
              planning tool is easy and has everything you will need.
            </div>
          </div>
          <img
            src={FrameFloorPlanFeature}
            alt="Floor Planning Tool – Software screenshot"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Fix the light in your real estate photographs
                </h2>
                <div className="feature-text">
                  Automatically fix the lighting of your photos.
                </div>
              </div>
              <img
                src={EnlightRooms}
                alt="Light correction tool – Software screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Increment the definition of AI renders and images
                </h2>
                <div className="feature-text">
                  Make your images HD with Pedra. Press a button and get your
                  results!
                </div>
              </div>
              <img
                src={HDImage}
                alt="Tool to augment the definition of images (HD) and renders with AI – Software screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Remove family pictures, crosses, flags, and other personal
                  items from your pictures
                </h2>
                <div className="feature-text">
                  Cater to a wider audience by removing personal items from
                  pictures – in 5 seconds, without any blurs nor smudges!
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h2 className="feature-title">
                  Add personalized AI disclaimers as footers
                </h2>
                <div className="feature-text">
                  Directly add footers that advertise that real estate
                  advertisements are e.g., non-contractual, AI-generated, etc.,
                  and avoid any confusions.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h2 className="feature-title">
                  Correct your real estate photos' perspective in 1 click
                </h2>
                <div className="feature-text">
                  Forget editing real estate pictures one by one, and directly
                  fix their perspective with Pedra.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeVirtualVideoIcon />
                <h2 className="feature-title">
                  Create real estate videos automatically
                </h2>
                <div className="feature-text">
                  Automatically create real estate videos of your properties in
                  1 minute, so that potential buyers see videos in video format.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSettingsIcon />
                <h2 className="feature-title">
                  Download real estate images in any format
                </h2>
                <div className="feature-text">
                  With Pedra you can download images in any format, and convert
                  images to any format like PNG, JPG, or JPEG.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeWatermarkIcon />
                <h2 className="feature-title">
                  Remove any watermark perfectly
                </h2>
                <div className="feature-text">
                  Remove watermarks from real estate photographs easily and with
                  clean results!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">Use cases</div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <HouseIcon />
                <div className="feature-title">Real Estate</div>
                <div className="feature-text">
                  Accelerate real estate sales with Pedra’s AI and tools.
                  <br />
                  <br />
                  <Link
                    to="/realestate"
                    className="vertical-links"
                    style={{
                      color: "#F8792A",
                      marginLeft: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    Learn more <ArrowIcon />
                  </Link>
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <ColorPaletteIcon />
                <div className="feature-title">Interior Design</div>
                <div className="feature-text">
                  Increase furniture and interior design sales with Pedra’s AI
                  and tools.
                  <br />
                  <br />
                  <Link
                    to={"interiordesign"}
                    className="vertical-links"
                    style={{ color: "#57A4EB", marginLeft: "0px" }}
                  >
                    Learn more <ArrowIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">How it works?</div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>STEP 1</h5>
            <h2>Create a project and upload an image</h2>
            <div className="value-prop-text">
              Upload 1 or more images to Pedra.
            </div>
          </div>
          <div className="picture">
            <img
              src={UploadAnImage}
              alt="How To Upload Files To Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>STEP 2</h5>
            <h2>Pick an option</h2>
            <div className="value-prop-text">
              Upload an image and click generate.
            </div>
          </div>
          <div className="picture">
            <img
              src={PickAnOption}
              alt="How To Pick a Render Or AI Option With Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>STEP 3</h5>
            <h2>Wait and download result</h2>
            <div className="value-prop-text">
              Images take on average 15 seconds.
            </div>
          </div>
          <div className="picture">
            <img
              src={WaitAndDownloadResult}
              alt="How To Download Render From Pedra – Infographic"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="long-break"> </div>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Try Pedra <ArrowIcon />
        </button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="section-one-column-left">
        <div className="title-container">Questions & answers</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is an AI render?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                An AI render, in the context of architectural design and
                visualization, refers to the application of artificial
                intelligence (AI) to generate high-quality rendered images of
                architectural spaces. Artificial intelligence is used to
                re-create spaces based on inputs images, allowing for realistic
                and precise results.
                <br />
                <br />
                AI allows for faster results than traditional rendering methods.
                Older methods involved manually re-creating 3D models of the
                rooms and processing images to create stunning renders. With AI
                this is now different. We can start from images to create new
                visuals based on the original image and a newly applied style.
                <br />
                <br />
                Artificial intelligence in rendering of real estate images not
                only saves time but provides a more flexible way to imagine
                spaces for the realtor, the owners and the buyers. The
                technology helps visualize rapidly the potential of spaces in a
                way never seen before.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What is a virtual home staging?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Virtual home staging is a technique used in the real estate
                market to present a property in a more attractive and appealing
                way to potential buyers or tenants, but unlike traditional home
                staging, which involves physical preparation and decoration of
                the space, virtual home staging is done digitally
                <br />
                <br />
                In virtual home staging, tools such as 3D design software,
                virtual reality (VR), and digital photography are used to create
                realistic digital representations of a property. These
                representations may include high-quality rendered images,
                virtual tours of the property, and interactive visualizations
                showing how the space would look with different furniture and
                decor configurations.
                <br />
                <br />
                The goal of virtual home staging is to allow potential buyers or
                tenants to get a better idea of how the property would look once
                furnished and decorated, which can help increase interest and
                chances of sale or rental. Additionally, virtual home staging
                can be especially useful in situations where the property is not
                fully furnished or is vacant, as it can help clients visualize
                the potential of the space.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Is Pedra easy to use?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Yes, Pedra is designed to offer a simple and agile experience.
                <br />
                <br />
                After creating a project you will be able to upload one or more
                images. You can edit them by clicking on them. This will show a
                bar menu with a options. The options include:{" "}
                <span style={{ fontWeight: "600px" }}> Edit with AI</span>, add
                watermark, download in a specific image format and more. When
                creating a project will also be able to draw a floor plan. The
                floor plan contains several figures, which you will be able to
                edit by selecting them, dragging them or via the bar menu.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">How much does Pedra cost?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra costs 29€ plus tax per month.
                <br />
                <br />
                It's a monthly subscription, therefore you can cancel your
                subscription if you don't need it anymore.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
