import React from "react";

const FloorPlanHeaderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25 8.06147L23.4172 10.4957C22.397 12.0636 21.5868 13.065 20.2628 14.389L16.3995 18.2523L13.7477 15.6005L17.611 11.7372C18.935 10.4132 19.9327 9.60304 21.5043 8.58283L23.9385 7L25 8.06147Z"
        fill="#f8792a"
      />
      <path
        d="M12.8512 16.827L15.1767 19.1525L13.0013 23.5072H11.3359C10.447 23.5072 9.84309 23.7585 9.21296 24.3849L8.59033 25.0075L7 23.4172L7.62263 22.7945C8.25276 22.1644 8.50031 21.5605 8.50031 20.6716V19.0063L12.855 16.8308L12.8512 16.827Z"
        fill="#f8792a"
      />
      <path
        d="M12.2136 20.9173C12.2136 20.2946 11.711 19.792 11.0884 19.792C10.4657 19.792 9.96312 20.2946 9.96312 20.9173C9.96312 21.5399 10.4657 22.0425 11.0884 22.0425C11.711 22.0425 12.2136 21.5399 12.2136 20.9173Z"
        fill="white"
      />
    </svg>
  );
};

export default FloorPlanHeaderIcon;
