import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import { Link } from "react-router-dom";
import CustomerLogos from "../CustomerLogos";
import ArrowIcon from "../Icons/ArrowIcon";

const FreeDescriptionGeneratorEs = () => {
  function redirectToPage() {
    if (window.location.href.includes("/es")) {
      window.open("https://app.pedra.so/es", "_blank");
    } else {
      window.open("https://app.pedra.so", "_blank");
    }
  }

  const [formData, setFormData] = useState({
    propertyType: "",
    condition: "",
    numberOfBedrooms: "",
    location: "",
    locationVibe: "",
    area: "",
    otherWarnings: "",
    highlightedFeatures: "",
  });
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "propertyType",
      "condition",
      "numberOfBedrooms",
      "area",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "Este campo es obligatorio";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateListing = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedOutput("");

    try {
      const response = await fetch(
        "/.netlify/functions/generate-ad-description",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to generate listing");
      }

      setGeneratedOutput(data.output);
    } catch (err) {
      console.error("Error in generateListing:", err);
      setError(
        "Estamos experimentando un gran volumen de tráfico – por favor inténtalo en un par de minutos"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (
    name,
    label,
    type = "text",
    rows = 1,
    placeholder = ""
  ) => (
    <div>
      <label htmlFor={name} className="free-tool-label">
        {label}:
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          rows={rows}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px", height: "100px" }}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px" }}
          placeholder={placeholder}
        />
      )}
      {fieldErrors[name] && (
        <div style={{ color: "red", fontSize: "0.8em" }}>
          {fieldErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Generador de Anuncios Inmobiliarios{" "}
            <span className="text-highlight">Gratis</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% gratuito</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% potenciado por IA</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Anuncios en 10 segundos</span>
            </p>
          </div>
        </div>
        <h2>Ingrese los detalles de la propiedad</h2>
        <form
          onSubmit={generateListing}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {renderField(
            "propertyType",
            "Tipo de Propiedad",
            "text",
            1,
            "Apartamento, Casa, Chalet"
          )}
          {renderField(
            "condition",
            "Condición",
            "text",
            1,
            "Nuevo, Reformado, A reformar"
          )}
          {renderField(
            "numberOfBedrooms",
            "Número de Habitaciones",
            "text",
            1,
            "2, 3, 4+"
          )}
          {renderField(
            "location",
            "Ubicación (opcional)",
            "text",
            1,
            "Centro de Madrid, Barrio de Salamanca"
          )}
          {renderField(
            "locationVibe",
            "Ambiente del Barrio (opcional)",
            "text",
            1,
            "Tranquilo, Animado, Familiar"
          )}
          {renderField("area", "Área", "text", 1, "80m², 100m², 150m²")}
          {renderField(
            "highlightedFeatures",
            "Partes a Destacar",
            "textarea",
            3,
            "Terraza amplia, Cocina equipada, Vistas panorámicas"
          )}
          {renderField(
            "otherWarnings",
            "Otras Advertencias (opcional)",
            "textarea",
            3,
            "Necesita pequeñas reparaciones, No se admiten mascotas"
          )}

          <button
            type="submit"
            disabled={isLoading}
            style={{ cursor: "pointer" }}
            className="free-tool-button"
          >
            {isLoading ? "Generando..." : "Generar Anuncio"}
          </button>
        </form>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <strong>Error:</strong> {error}
          </div>
        )}

        {generatedOutput && (
          <div className="step-container article">
            <article className="article-text free-tool-result-container">
              <div style={{ marginTop: "20px" }}>
                <h2 className="article-subtitleh2">
                  {" "}
                  <span className="text-highlight">Anuncio Generado:</span>{" "}
                </h2>
                <p>{generatedOutput}</p>
              </div>
            </article>
          </div>
        )}

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              ¿Qué es un generador de anuncios inmobiliarios?
            </h2>
            <p>
              Un generador de descripciones de inmobiliarias es una herramienta
              que ayuda a los agentes inmobiliarios y propietarios a crear
              descripciones atractivas y profesionales para sus propiedades en
              venta o alquiler. Esta herramienta utiliza algoritmos avanzados e
              inteligencia artificial para generar descripciones persuasivas y
              detalladas basadas en la información proporcionada sobre la
              propiedad.
            </p>

            <h2 className="article-subtitleh2">
              Cómo crear anuncios inmobiliarios
            </h2>
            <p>
              Si quieres obtener las descripciones de propiedades más efectivas
              y atractivas, utiliza Pedra. Nuestro generador de descripciones
              utiliza un método único que combina inteligencia artificial
              avanzada con el conocimiento del mercado inmobiliario local.
            </p>
            <p>
              El procedimiento de cómo crear un anuncio inmobiliario de forma
              gratuita es el siguiente:
            </p>

            <h2 className="article-subtitleh2">
              Completa los Detalles Básicos de la Propiedad
            </h2>
            <p>
              Tipo de Propiedad: Selecciona el tipo de inmueble que estás
              promocionando, como apartamento, casa o chalet. Esto ayuda a
              categorizar correctamente el anuncio y a definir las expectativas
              del comprador desde el inicio.
            </p>
            <p>
              Condición de la Propiedad: Indica si la propiedad es nueva, está
              reformada o necesita reformas. Esta información es crucial ya que
              establece la condición actual del inmueble y puede influir en la
              decisión de los compradores.
            </p>

            <h2 className="article-subtitleh2">
              Especifica las Características Principales
            </h2>
            <p>
              Número de Habitaciones: Ingresa cuántas habitaciones tiene la
              propiedad. Este es uno de los detalles más buscados por los
              compradores y debe ser claro y preciso.
            </p>
            <p>
              Área: Proporciona la superficie total del inmueble en metros
              cuadrados. Asegúrate de ser preciso, ya que esto ayuda a los
              interesados a comparar fácilmente diferentes propiedades.
            </p>

            <h2 className="article-subtitleh2">
              Agrega Información Opcional para Enriquecer el Anuncio
            </h2>
            <p>
              Ubicación: Aunque es opcional, añadir detalles sobre la ubicación,
              como "Centro de Madrid" o "Barrio de Salamanca", puede hacer que
              tu anuncio sea más relevante para quienes buscan en áreas
              específicas.
            </p>
            <p>
              Ambiente del Barrio: Describe el ambiente del vecindario, por
              ejemplo, "Tranquilo", "Animado" o "Familiar". Esto ayuda a los
              interesados a imaginar el estilo de vida que podrían tener en esa
              propiedad.
            </p>

            <h2 className="article-subtitleh2">
              Destaca las Partes Clave de la Propiedad
            </h2>
            <p>
              Partes a Destacar: Utiliza este campo para resaltar
              características especiales como "Terraza amplia", "Cocina
              equipada" o "Vistas panorámicas". Estos detalles pueden ser
              decisivos para captar la atención y diferenciar tu anuncio de
              otros similares.
            </p>

            <h2 className="article-subtitleh2">
              Incluye Advertencias o Restricciones
            </h2>
            <p>
              Otras Advertencias (opcional): Añade cualquier restricción
              importante, como "Necesita pequeñas reparaciones" o "No se admiten
              mascotas". Ser transparente con estas condiciones evitará
              malentendidos y atraerá a interesados que estén dispuestos a
              aceptar estas condiciones.
            </p>

            <h2 className="article-subtitleh2">
              Ventajas del generador de anuncios de inmuebles
            </h2>
            <h3>Eficiencia en tiempo</h3>
            <p>
              Crear un anuncio inmobiliario desde cero puede ser un proceso
              largo y detallado, especialmente si quieres asegurarte de que sea
              persuasivo y profesional. Con el generador de anuncios, solo
              necesitas ingresar la información clave de la propiedad, y en
              cuestión de segundos, obtendrás una descripción completa y bien
              estructurada. Esto te permite ahorrar tiempo valioso que puedes
              dedicar a otras tareas importantes, como la atención a clientes o
              la gestión de más propiedades.
            </p>

            <h3>Calidad profesional</h3>
            <p>
              Una descripción mal redactada o poco atractiva puede desmotivar a
              potenciales compradores o inquilinos desde el primer momento. El
              generador utiliza un lenguaje profesional y convincente,
              asegurándose de que cada anuncio resalte las mejores
              características del inmueble y capte la atención del lector.
              Además, la redacción se adapta a las mejores prácticas del mercado
              inmobiliario, lo que aumenta la probabilidad de atraer
              interesados.
            </p>

            <h3>Facilidad de uso</h3>
            <p>
              El generador de anuncios está diseñado para ser intuitivo y fácil
              de usar, incluso para aquellos que no tienen experiencia previa en
              redacción de contenidos o marketing inmobiliario. Solo necesitas
              llenar unos pocos campos con la información básica de la
              propiedad, como el tipo, la ubicación, y las características
              destacadas, y la herramienta se encarga del resto. Esta
              simplicidad facilita que cualquier persona pueda crear anuncios
              efectivos sin complicaciones.
            </p>

            <h3>Gratuito</h3>
            <p>
              A diferencia de otras herramientas o servicios de redacción que
              pueden tener un coste asociado, este generador de anuncios es
              completamente gratis. Esto permite a los usuarios, ya sean agentes
              independientes, agencias pequeñas o grandes inmobiliarias, acceder
              a descripciones de alta calidad sin incurrir en costes
              adicionales.
            </p>

            <h2 className="article-subtitleh2">
              Mejores casos de uso del generador de descripciones
            </h2>
            <h3>Para agentes inmobiliarios independientes</h3>
            <p>
              Los agentes inmobiliarios independientes suelen manejar múltiples
              propiedades y tienen la responsabilidad de gestionar todo el
              proceso de ventas o alquileres, desde la captación del inmueble
              hasta la creación de anuncios atractivos. El generador de
              descripciones les permite ahorrar tiempo y esfuerzo en la
              redacción de anuncios, asegurando que cada descripción sea
              profesional y persuasiva. Esto les facilita enfocarse en otras
              tareas críticas, como la negociación con clientes o la
              organización de visitas, aumentando su productividad y efectividad
              en el cierre de tratos.
            </p>

            <h3>Para propietarios que venden por su cuenta</h3>
            <p>
              Los propietarios que optan por vender o alquilar sus propiedades
              por su cuenta (sin intermediarios) a menudo carecen de la
              experiencia en marketing inmobiliario necesaria para redactar
              descripciones atractivas y efectivas. El generador de
              descripciones les proporciona una solución sencilla y gratuita
              para crear anuncios que destaquen las mejores características de
              su propiedad. Esto les ayuda a captar la atención de compradores o
              inquilinos potenciales, aumentando las probabilidades de éxito en
              la venta o alquiler, sin la necesidad de contratar a un agente o
              redactor profesional.
            </p>

            <h3>Para agencias inmobiliarias</h3>
            <p>
              Las agencias inmobiliarias manejan un gran volumen de propiedades
              y necesitan mantener una consistencia en el tono y la calidad de
              los anuncios que publican. El generador de descripciones les
              permite estandarizar la creación de contenido, asegurando que
              todas las propiedades se presenten de manera coherente y
              profesional. Además, reduce el tiempo y los costes asociados con
              la redacción manual, permitiendo a los equipos de marketing
              centrarse en estrategias de promoción más amplias. Al ofrecer
              descripciones de alta calidad de manera eficiente, las agencias
              pueden mejorar su imagen de marca y atraer a más clientes.
            </p>

            <h2 className="article-subtitleh2">
              ¿Para quién está diseñado el generador de anuncios inmobiliarios?
            </h2>
            <p>
              Aunque cualquiera puede usar el generador de descripciones de
              Pedra, estos son los usuarios más comunes:
            </p>
            <ul>
              <li>Agentes inmobiliarios</li>
              <li>Propietarios de viviendas</li>
              <li>Inversores inmobiliarios</li>
            </ul>
            <p>
              Descubre cómo nuestra herramienta puede transformar tus listados
              inmobiliarios hoy mismo.
            </p>

            <h2 className="article-subtitleh2">
              Mejora la presentación visual de tu anuncio inmobiliario
            </h2>
            <p>
              Además de una descripción convincente, la presentación visual de
              tu propiedad es crucial para atraer a potenciales compradores. En
              Pedra, entendemos la importancia de mostrar el potencial completo
              de cada propiedad. Por eso, ofrecemos servicios adicionales para
              mejorar la presentación de tus listados:
            </p>
            <ul>
              <li>
                Fotografía inmobiliaria profesional: Nuestro software mejorará
                tus imágenes en 1 click, para que se vean con mejor resolución,
                luz y color.
              </li>
              <li>
                Home staging virtual: Utilizamos tecnología avanzada para
                mostrar cómo se vería la propiedad con diferentes estilos de
                decoración, ayudando a los compradores a visualizar su
                potencial.
              </li>
            </ul>
            <p>
              Estos servicios complementarios, junto con nuestras descripciones
              generadas por IA, te ayudarán a crear listados inmobiliarios que
              destaquen en el mercado competitivo actual. Contáctanos para saber
              cómo podemos ayudarte a presentar tu propiedad de la mejor manera
              posible.
            </p>

            <h2 className="article-subtitleh2">
              Crea tu anuncio listo para publicitar en los portales
              inmobiliarios
            </h2>
            <p>
              Con nuestro generador gratuito de descripciones y la herramienta
              de{" "}
              <Link to="/es/home-staging-virtual" className="article-link">
                home staging virtual
              </Link>{" "}
              para optimizar tus fotos inmobiliarias, podrás gestionar toda la
              información de tus inmuebles de manera rápida y sencilla. Estas
              herramientas te permitirán automatizar tareas complejas,
              reduciendo significativamente las horas de trabajo y mejorando la
              calidad de los resultados, sin necesidad de dedicar demasiado
              tiempo y esfuerzo.
            </p>
            <p>
              Una vez realices la descripción y optimices tus fotos con ayuda de
              Pedra, estarán listas para ser publicadas en los diferentes
              portales de empleo para atraer a los futuros inquilinos.
            </p>
          </article>
        </div>
        <br />
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
        <div className="section-first-button-container">
          <button className="button-top-of-page" onClick={redirectToPage}>
            Prueba Pedra <ArrowIcon />
          </button>
        </div>
      </section>
    </section>
  );
};

export default FreeDescriptionGeneratorEs;
